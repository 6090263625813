import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { DriveFileRenameOutlineOutlined } from "@mui/icons-material";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { updateCommonTreats } from "services/doctorService";

const UpdateTreat = ({ selected, close }) => {
  const [value, setValue] = useState(selected?.treatmentName);
  const [errors, setErrors] = useState({});
  const { handleClick } = useContext(ToastContext);

  const validate = () => {
    const errors = {};
    if (!value) {
      errors.value = "Please enter valid treatment name.";
    }
    return errors;
  };

  const updateTreat = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});

      if (selected) {
        const data = {
          id: selected.id,
          treatmentName: value,
        };
        try {
          await updateCommonTreats(selected.id, data);
          handleClick("success", "Treatment has been successfully updated.");
          close();
        } catch (e) {
          handleClick("error", "There seems to be an error updating the treatment.");
        }
      }
    }
  };

  return (
    <Box
      flex="1"
      alignSelf={"center"}
      minHeight="18vh"
      p={3}
      borderRadius={3}
      bgcolor={"background.default"}
      color={"text.primary"}
    >
      <Typography variant="h4" textAlign="center">
        Update Treatment
      </Typography>
      <FormInputField
        style={{ marginTop: "20px" }}
        label={"Treatment"}
        value={value}
        error={Boolean(errors.value)}
        errorText={errors.value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        startAdornment={<DriveFileRenameOutlineOutlined />}
        size="small"
      />
      <Box sx={{ mt: 3 }} display="flex" justifyContent="center" alignItems="center">
        <CustomButton className="btn--primary" onClick={updateTreat} label="Update" />
      </Box>
    </Box>
  );
};

export default UpdateTreat;
