import { Box, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { DescriptionOutlined, DriveFileRenameOutlineOutlined } from "@mui/icons-material";
import { updateChronicDiseases } from "services/EntitiesServices";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { Formik } from "formik";
import { chronicDiseaseValidation } from "../../Common/ValidationSchema/chronicDiseaseValidation";

const UpdateChronicDisease = ({ selected, close }) => {
  const { handleClick } = useContext(ToastContext);

  const updateChronicDisease = async (values) => {
    if (selected) {
      const payload = {
        id: selected.id,
        name: values.diseaseName,
        description: values.diseaseDescription,
      };
      try {
        await updateChronicDiseases(selected.id, payload);
        handleClick("success", "Chronic disease has been successfully updated.");
        close();
      } catch (e) {
        handleClick("error", "There seems to be an error updating the chronic-disease.");
      }
    }
  };

  return (
    <Box
      flex="1"
      alignSelf={"center"}
      minHeight="18vh"
      p={3}
      borderRadius={3}
      bgcolor={"background.default"}
      color={"text.primary"}
    >
      <Typography variant="h4" textAlign="center">
        Update Chronic Disease
      </Typography>
      <Formik
        validateOnMount={true}
        initialValues={{
          diseaseName: selected.name,
          diseaseDescription: selected.description ? selected?.description : "",
        }}
        validationSchema={chronicDiseaseValidation}
        onSubmit={async (values, { resetForm }) => {
          updateChronicDisease(values);
          resetForm();
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <>
            <Grid container spacing={2} sx={{ maxWidth: "350px", marginTop: "5px" }}>
              <Grid item xs={12}>
                <FormInputField
                  label={"Chronic Disease Name"}
                  value={values.diseaseName}
                  name={"diseaseName"}
                  inputProps={{ maxLength: 50 }}
                  error={Boolean(touched.diseaseName && errors.diseaseName)}
                  errorText={errors.diseaseName}
                  onChange={handleChange}
                  startAdornment={<DriveFileRenameOutlineOutlined />}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "10px" }}>
                <FormInputField
                  label={"Chronic Disease Description"}
                  value={values.diseaseDescription}
                  name={"diseaseDescription"}
                  inputProps={{ maxLength: 255 }}
                  error={Boolean(touched.diseaseDescription && errors.diseaseDescription)}
                  errorText={errors.diseaseDescription}
                  onChange={handleChange}
                  startAdornment={<DescriptionOutlined />}
                  size="small"
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 3 }} display="flex" justifyContent="center" alignItems="center">
              <CustomButton className="btn--primary" onClick={handleSubmit} label="Update" />
            </Box>
          </>
        )}
      </Formik>
    </Box>
  );
};

export default UpdateChronicDisease;
