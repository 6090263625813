import axios from "axios";
import { Assignment, Healing, LocalHospital, MonetizationOn } from "@mui/icons-material";
import dayjs from "dayjs";
// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

export const appEnv = `${process.env.REACT_APP_ENV}`;

export const baseUrl = `${process.env.REACT_APP_URL}`;
export const socketBaseUrl = `${process.env.REACT_APP_SOCKET_URL}`;
export const citizenDomainUrl = `${process.env.REACT_APP_CITIZEN_DOMAIN}`;
export const citizenUrl = `${process.env.REACT_APP_CITIZEN_URL}`;
export const argusDomainUrl = `${process.env.REACT_APP_ARGUS_DOMAIN}`;
export const argusUrl = `${process.env.REACT_APP_ARGUS_URL}`;
export const clinicDomainUrl = `${process.env.REACT_APP_CLINIC_DOMAIN}`;
export const clinicUrl = `${process.env.REACT_APP_CLINIC_URL}`;
export const hiuToken = `${process.env.REACT_APP_ABDM_HIU_TOKEN}`;

export const ACTIVE_STATE = "ACTIVE";
export const INACTIVE_STATE = "INACTIVE";
export const TELE_CONSULTATION = "TELE_CONSULTATION";
export const NONE = "NONE";
export const CLINIC = "CLINIC";
export const CLINIC_VISIT = "CLINIC_VISIT";
export const BOTH = "BOTH";
export const SCHEDULED = "SCHEDULED";
export const COMPLETED = "COMPLETED";
export const CANCELLED = "CANCELLED";
export const RESCHEDULED = "RESCHEDULED";
export const NO_SHOW = "NO_SHOW";
export const NO_SHOW_LABEL = "NO SHOW";
export const PRESCRIPTION_DONE = "PRESCRIPTION_DONE";
export const PRESCRIPTION_DONE_LABEL = "PRESCRIPTION DONE";
export const SUPER_ADMIN = "SUPER ADMIN";
export const ADMIN = "ADMIN";
export const CLINIC_ADMIN = "HOSPITAL/CLINIC ADMIN";
export const DOCTOR = "DOCTOR";
export const PATIENT = "PATIENT";
export const ORGANIZATION = "ORGANIZATION";
export const FRONT_DESK = "FRONT DESK";
export const GENERAL_PRACTITIONER = "GENERAL PRACTITIONER";
export const PARAMEDIC = "PARAMEDIC";
export const MALE = "MALE";
export const FEMALE = "FEMALE";
export const OTHER = "OTHER";
export const AADHAAR_OTP = "AADHAAR_OTP";
export const MOBILE_OTP = "MOBILE_OTP";
export const PASSWORD = "PASSWORD";
export const PENDING = "PENDING";
export const DISCOUNT = "DISCOUNT";
export const MOBILE_NUMBER = "LOGIN_MOBILE_NUMBER";
export const HPR_ID = "LOGIN_HPR";
export const ABHA_NUMBER = "LOGIN_ABHA_NUMBER";
export const VERIFY_MOBILE_NUMBER = "VERIFY_MOBILE_NUMBER";
export const VERIFY_HPR = "VERIFY_HPR";
export const VERIFY_ABHA_NUMBER = "VERIFY_ABHA_NUMBER";
export const TEXT_FOR_CLINIC_VISIT = "Clinic visit";
export const TEXT_FOR_TELECONSULTATION = "Teleconsultation";
export const TEXT_FOR_CHECKED_IN = "Checked In";
export const TOTAL = "Total";
export const CHECKED_IN = "CHECKED_IN";
export const GET_API_METHOD_NAME = "get";
export const SKELETON_LOADING_TIME_IN_MILLISECONDS = 500;
export const AVAILABILITY_TIME_FOR_THE_24TH_HOUR = "00:00";
export const ARTICLE = "ARTICLE";
export const MEDIA = "MEDIA";
export const CAMPAIGN = "CAMPAIGN";
export const CREATE_ABHA_VIA_AADHAAR = "create_abha_via_aadhaar";
export const VERIFY_AADHAAR_OTP = "aadhaar_otp";
export const UPDATE_MOBILE_NUMBER = "update_mobile_number";
export const ABHA_MOBILE_NUMBER = "mobile_number";
export const ABHA_ADDRESS = "abha_address";
export const basePackage = "Base Package";
export const APPOINTMENT_TYPE_SYSTEM = "SYSTEM";
export const APPOINTMENT_TYPE_UHI = "UHI";

export const appointmentTypeMapper = {
  SCHEDULED: "Scheduled",
  RESCHEDULED: "Rescheduled",
  PRESCRIPTION_DONE: "Prescription Done",
  NO_SHOW: "No Show",
  COMPLETED: "Completed",
  CANCELLED: "Cancelled",
  CHECKED_IN: "Checked In",
};

let roleIdByName = null;

const fetchRoleData = async (handleClick) => {
  try {
    const response = await axios.get(`${baseUrl}/api/roles`);
    roleIdByName = response.data;
  } catch {
    console.error("Error fetching roleId");
  }
};

export const getRoleIdByName = async (roleName, handleClick) => {
  if (roleIdByName) {
    const role = roleIdByName.find((role) => role.name === roleName);
    return role ? role.id : null;
  } else {
    await fetchRoleData(handleClick);
    const role = roleIdByName.find((role) => role.name === roleName);
    return role ? role.id : null;
  }
};

export const HeadersForAccessTokenAPI = {
  "Content-Type": "application/json",
  Authorization: "Basic " + btoa("hc-web:healthcloud-secret"), // .toString('Base64')
};

export const genderList = [
  {
    value: MALE,
    menuLabel: "Male",
  },
  {
    value: FEMALE,
    menuLabel: "Female",
  },
  {
    value: OTHER,
    menuLabel: "Other",
  },
];

export const timeFormats = [
  {
    value: "12",
    menuLabel: "12 Hour",
  },
  {
    value: "24",
    menuLabel: "24 Hour",
  },
];

export const contentTypes = [
  {
    value: ARTICLE,
    menuLabel: "Article",
  },
  {
    value: MEDIA,
    menuLabel: "Media",
  },
  {
    value: CAMPAIGN,
    menuLabel: "Campaign",
  },
];

export const blockCalendeOptions = [
  {
    value: CLINIC_VISIT,
    menuLabel: "Can Do Clinic Visit",
  },
  {
    value: TELE_CONSULTATION,
    menuLabel: "Can Do Tele Consultation",
  },
  {
    value: NONE,
    menuLabel: NONE,
  },
];

export const familyRelations = [
  { value: "FATHER", menuLabel: "Father", gender: MALE },
  { value: "MOTHER", menuLabel: "Mother", gender: FEMALE },
  { value: "BROTHER", menuLabel: "Brother", gender: MALE },
  { value: "SISTER", menuLabel: "Sister", gender: FEMALE },
  { value: "WIFE", menuLabel: "Wife", gender: FEMALE },
  { value: "HUSBAND", menuLabel: "Husband", gender: MALE },
  { value: "GRANDFATHER", menuLabel: "Grandfather", gender: MALE },
  { value: "GRANDMOTHER", menuLabel: "Grandmother", gender: FEMALE },
  { value: "UNCLE", menuLabel: "Uncle", gender: MALE },
  { value: "AUNT", menuLabel: "Aunt", gender: FEMALE },
  { value: "SON", menuLabel: "Son", gender: MALE },
  { value: "DAUGHTER", menuLabel: "Daughter", gender: FEMALE },
  { value: "NEPHEW", menuLabel: "Nephew", gender: MALE },
  { value: "NIECE", menuLabel: "Niece", gender: FEMALE },
  { value: "FRIEND", menuLabel: "Friend" },
  { value: "OTHER", menuLabel: "Other" },
];

const filterRelationsByGender = (gender) => {
  return familyRelations.filter((relation) => !relation.gender || relation.gender === gender);
};

// Example usage
export const filteredRelationsForMale = filterRelationsByGender(MALE);
export const filteredRelationsForFemale = filterRelationsByGender(FEMALE);

export const RELATIONSHIPS_WITH_OLDER_AGE = ["FATHER", "MOTHER", "GRANDFATHER", "GRANDMOTHER"];

export const RELATIONSHIPS_WIT_YOUNGER_AGE = ["SON", "DAUGHTER"];

export const AGE_DIFFERENCE_MAP = {
  FATHER: 15,
  MOTHER: 15,
  GRANDFATHER: 30,
  GRANDMOTHER: 30,
  SON: 15,
  DAUGHTER: 15,
};

export const SELF = "SELF";

export const currentActiveUser = () => JSON.parse(localStorage.getItem("currentActiveUser"));
export const accessToken = () => localStorage.getItem("accessToken");
export const username = () => localStorage.getItem("username");
export const isOTPLogin = () => localStorage.getItem("isOTPLogin");
export const mobilenumber = () => localStorage.getItem("mobilenumber");
export const wasFamilyMemberLogin = () => localStorage.getItem("familyMemberId");
export const loggedInUser = () => JSON.parse(localStorage.getItem("loggedInUser"));
export const loggedIn = () => localStorage.getItem("loggedIn");
export const isDemo = () => JSON.parse(localStorage.getItem("isDemo"));
export const isOverlayOpen = () => JSON.parse(localStorage.getItem("isOverlayOpen"));
export const isGuidedDemoOn = () => JSON.parse(localStorage.getItem("isGuidedDemoOn"));
export const demoDataSet = () => JSON.parse(localStorage.getItem("demoDataSet"));
export const refreshToken = () => localStorage.getItem("refreshToken");
export const orgId = () => localStorage.getItem("orgId");
export const activeMember = () => JSON.parse(localStorage.getItem("activeMember"));
export const familyMember = () => JSON.parse(localStorage.getItem("selectedMember"));
export const data = () => JSON.parse(localStorage.getItem("adhaarNewUser"));
export const parentFamilyId = () => JSON.parse(localStorage.getItem("parentMemberId"));
export const roleId = () => JSON.parse(localStorage.getItem("currentActiveUser"))?.roleId;
export const roleName = () => JSON.parse(localStorage.getItem("currentActiveUser"))?.roleName;
export const hprProfile = () => JSON.parse(localStorage.getItem("hprProfile"));
export const peers = () => JSON.parse(localStorage.getItem("inRoom"));
export const timeFormat = () => JSON.parse(localStorage.getItem("timeFormat"));

export const doctorTableColumns = [
  { field: "Sr. No.", label: "Sr. No" },
  { field: "userResponse.name", label: "Name" },
  { field: "status", label: "Status" },
  { field: "Actions", label: "Actions" },
];

export const profileForm = [
  {
    type: "allergies",
    url: "/api/patient/allergies",
    formData: {
      name: "",
      description: "",
      severity: "",
    },
  },
];

export const MAX_AGE_FOR_REGUAR_DOB = 124;
export const MIN_AGE_FOR_DOCTOR = 20;
export const MIN_AGE_FOR_GENERAL_PRACTITIONER_AND_PARAMEDIC = 18;
export const MIN_AGE_FOR_QUALIFICATION = 20;

export const CUSTOM_PRIMARY = "#004C70";
export const CUSTOM_SECONDARY = "#29BF91";
export const CUSTOM_TERNARY = "#29BF911A";

export const SIZE_10_MB = 10 * 1024 * 1024;
export const SIZE_1_MB = 1 * 1024 * 1024;
export const SIZE_5_MB = 5 * 1024 * 1024;

export const DAY_GRID_CALENDAR = "timeGridDay";
export const WEEK_GRID_CALENDAR = "timeGridWeek";
export const MONTH_GRID_CALENDAR = "dayGridMonth";

export const fileTypes = {
  // Images
  "image/jpeg": "jpeg",
  "image/png": "png",
  "image/gif": "gif",
  "image/bmp": "bmp",
  "image/svg+xml": "svg+xml",
  "image/tiff": "tiff",
  "image/webp": "webp",

  // Documents
  "application/pdf": "pdf",
  "application/msword": "msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "wordprocessingml.document",
  "application/vnd.ms-excel": "ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "spreadsheetml.sheet",
  "application/vnd.ms-powerpoint": "ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    "presentationml.presentation",
  "text/plain": "plain",
  "application/rtf": "rtf",

  // Audio
  "audio/mpeg": "mpeg",
  "audio/wav": "wav",
  "audio/ogg": "ogg",
  "audio/midi": "midi",
  "audio/aac": "aac",

  //Video
  "video/mp4": "mp4",
  "video/webm": "webm",
  "video/ogg": "ogg",
  "video/x-msvideo": "x-msvideo",
  "video/mpeg": "mpeg",

  //Archives
  "application/zip": "zip",
  "application/gzip": "gzip",
  "application/x-tar": "x-tar",
  "application/x-7z-compressed": "x-7z-compressed",

  //CodeAndMarkup
  "text/html": "html",
  "text/css": "css",
  "application/javascript": "js",
  "application/json": "json",
  "application/xml": "xml",
  "text/csv": "csv",
};

export const DATE_FORMAT = "YYYY-MM-DD";
export const TIME_FORMAT = "HH:mm:ss";
export const HOUR_MINUTE_FORMAT = "HH:mm";
export const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss";
export const DATE_FORMAT_DMY = "DD-MM-YYYY";
export const featureMapper = {
  abhaDetails: "ABHA",
  manageUser: "USER",
  myAppointments: "APPOINTMENT",
  doctorAvailability: "AVAILABILITY",
  payment: "BILLING",
  generateInvoice: "BILLING",
  billing: "BILLING",
  prescriptionPad: "PRESCRIPTION",
  doctorProfile: "DOCTOR",
  doctorProfileForPatient: "APPOINTMENT",
  Patient: "PATIENT",
  managePatient: "PATIENT",
  doctorProfessionalDetails: "DOCTOR",
  OtpScreen: "HPR",
  "OtpScreen/create": "HPR",
  reviewList: "REVIEW",
  patientPayment: "APPOINTMENT",
  review: "REVIEW",
  doctorSearch: "DOCTOR_SEARCH",
  patientAppointments: "APPOINTMENT",
  recentVisits: "APPOINTMENT",
  patientUhiAppointments: "APPOINTMENT",
  patientProfile: "PATIENT",
  patientEmergencyContact: "PATIENT",
  patientMedicalInfo: "PATIENT",
  patientLifestyle: "PATIENT",
  patientRecords: "PATIENT",
  patientFamilyDetails: "PATIENT",
  template: "APPOINTMENT",
  myPrescription: "APPOINTMENT",
  manageAbhaNumber: "ABHA",
  manageFamilyMembers: "PATIENT",
  ManageRole: "ROLE",
  user: "USER",
  updateProfile: "USER",
  Doctor: "DOCTOR",
  DoctorSpecializations: "DOCTOR",
  Organization: "ORGANIZATION",
  generateDashboard: "USER",
  manageOrganization: "ORGANIZATION",
  notification: "NOTIFICATION",
  Roles: "ROLE",
  CommonTreats: "DOCTOR",
  Procedure: "DOCTOR",
  Language: "USER",
  features: "FEATURE",
  featuresAssociation: "FEATURE",
  Allergy: "DOCTOR",
  MedicalServiceCategory: "ORGANIZATION",
  TaxRates: "ORGANIZATION",
  ProductAndServices: "ORGANIZATION",
  ChronicDisease: "DOCTOR",
  VaccineTypes: "DOCTOR",
  VitalSignTypes: "DOCTOR",
  manageDoctor: "DOCTOR",
  abhaClinicAdmin: "ABHA",
  hfrBasicInfo: "HFR",
  hfrDetailedInfo: "HFR",
  linkHfr: "HFR",
  hfrAdditionalInfo: "HFR",
  hfrSubmit: "HFR",
  availability: "AVAILABILITY",
  Appointment: "APPOINTMENT",
  qrcode: "ORGANIZATION",
  HprLogin: "HFR",
  InfoPage: "ORGANIZATION",
  ehr: "EHR",
  Templates: "DOCTOR",
  "health-feeds": "CONTENT",
  manageContent: "CONTENT",
  "health-feeds/content": "CONTENT",
};

export const pathMapper = {
  users: ["/home/manageUser"],
  patient: ["/home/managePatient"],
  doctors: ["/home/manageDoctor"],
  organization: ["/home/manageOrganization"],
  role: ["/home/manageRole"],
  patientAppointments: ["/home/myPrescription"],
  billingFrontDesk: ["/home/generateInvoice", "/home/payment"],
  familyDetails: ["/home/manageFamilyMembers"],
  ProductAndServices: [
    "/home/DoctorEntities/MedicalServiceCategory",
    "/home/DoctorEntities/TaxRates",
  ],
  DoctorSearch: ["/home/doctorSearch", "/home/doctorProfile"],
};

export const dashboardKey = {
  id: "default",
  title: "Dashboard",
  url: "/home/dashboard",
  iconClass: "ri-dashboard-line ri-xl",
};

export const keysForMenu = {
  user: {
    id: "users",
    title: "Users",
    url: "/home/user",
    iconClass: "ri-user-line ri-xl",
    feature: "USER",
  },
  specializations: {
    id: "DoctorSpecializations",
    title: "Specializations",
    url: "/home/DoctorSpecializations",
    iconSrc: "/images/hc_specialization.svg",
    feature: "DOCTOR",
  },
  patient: {
    id: "patient",
    title: "My Patients",
    url: "/home/patient",
    iconClass: "ri-user-add-line ri-xl",
    feature: "PATIENT",
  },
  role: {
    id: "role",
    title: "Roles",
    url: "/home/Roles",
    iconSrc: "/images/hc_user_check.svg",
    feature: "ROLE",
  },
  organization: {
    id: "organization",
    title: "Organization",
    url: "/home/Organization",
    iconClass: "ri-building-fill ri-xl",
    feature: "ORGANIZATION",
  },
  notification: {
    id: "notification",
    title: "Notifications",
    url: "/home/notification",
    iconClass: "ri-notification-line ri-xl",
    feature: "NOTIFICATION",
  },
  generateDashboard: {
    id: "generateDashboard",
    title: "Generate Dashboard",
    url: "/home/generateDashboard",
    iconClass: "ri-dashboard-line ri-xl",
    feature: "USER",
  },
  content: {
    id: "content",
    title: "Health-Feeds",
    url: "/home/health-feeds",
    iconClass: "ri-profile-line ri-xl",
    feature: "CONTENT",
  },
  review: {
    id: "review",
    title: "Review",
    url: "/home/reviewList",
    iconClass: "ri-feedback-line ri-xl",
    feature: "REVIEW",
  },
};

export const abdmKeysForMenu = {
  HPR: {
    id: "HPR",
    title: "HPR",
    url: "/home/OtpScreen",
    feature: "HPR",
  },
  HFR: {
    id: "HFR",
    title: "HFR",
    url: "/home/HprLogin",
    feature: "HFR",
  },
  ABHA: {
    id: "ABHA",
    title: "ABHA",
    url: "/home/abhaClinicAdmin",
    iconSrc: "/images/hc_aadhaar.svg",
    feature: "ABHA",
  },
};

export const childrenKeys = {
  doctor: {
    id: "doctors",
    title: "Doctors",
    url: "/home/doctor",
    iconSrc: "/images/hc_doctors.svg",
    feature: "DOCTOR",
  },
  recentVisits: {
    id: "recentVisits",
    title: "Recent Visits",
    url: "/home/recentVisits",
    iconSrc: "/images/hc_appointments.svg",
    feature: "APPOINTMENT",
  },
  generateQr: {
    id: "generateQr",
    title: "Generate QR",
    url: "/home/qrcode",
    iconClass: "ri-qr-code-line ri-xl",
    feature: "ORGANIZATION",
  },
  productAndServices: {
    id: "ProductAndServices",
    title: "Product And Services",
    url: "/home/ProductAndServices",
    feature: "ORGANIZATION",
  },
  HPR: abdmKeysForMenu.HPR,
  HFR: abdmKeysForMenu.HFR,
  ABHA: abdmKeysForMenu.ABHA,
  content: keysForMenu.content,
  package: {
    id: "Package",
    title: "Packages",
    url: "/home/packages",
    feature: "SUBSCRIPTION",
  },
  Features: {
    id: "Features",
    title: "Features",
    url: "/home/features",
    feature: "FEATURE",
  },
  FeaturesAssociation: {
    id: "FeaturesAssociation",
    title: "Feature Association",
    url: "/home/featuresAssociation",
    feature: "FEATURE",
  },
  allergy: {
    id: "Allergy",
    title: "Allergy",
    url: "/home/DoctorEntities/Allergy",
    feature: "DOCTOR",
  },
  chronicDisease: {
    id: "Chronic Disease",
    title: "Chronic Disease",
    url: "/home/DoctorEntities/ChronicDisease",
    feature: "DOCTOR",
  },
  vaccineTypes: {
    id: "Vaccine Type",
    title: "Vaccine Type",
    url: "/home/DoctorEntities/VaccineTypes",
    feature: "DOCTOR",
  },
  vitalSignTypes: {
    id: "Vital Sign Types",
    title: "Vital Sign Types",
    url: "/home/DoctorEntities/VitalSignTypes",
    feature: "DOCTOR",
  },
  commonTreats: {
    id: "CommonTreats",
    title: "Common Treatments",
    url: "/home/DoctorEntities/CommonTreats",
    feature: "DOCTOR",
  },
  procedure: {
    id: "Procedure",
    title: "Procedure",
    url: "/home/DoctorEntities/Procedure",
    feature: "DOCTOR",
  },
  language: {
    id: "Language",
    title: "Language",
    url: "/home/DoctorEntities/Language",
    feature: "USER",
  },
  InfoPage: {
    id: "InfoPage",
    title: "Info Page",
    url: "/home/InfoPage",
    feature: "ORGANIZATION",
  },
};

export const patientKeysForMenu = () => {
  return {
    doctorSearch: {
      id: "DoctorSearch",
      title: "Find a doctor",
      url: "/home/doctorSearch",
      iconSrc: "/images/hc_booking.svg",
      feature: "PATIENT",
    },
    patientAppointments: {
      id: "patientAppointments",
      title: "My Appointments",
      url: "/home/patientAppointments",
      iconSrc: "/images/hc_appointments.svg",
      feature: "APPOINTMENT",
    },
    patientUhiAppointments: {
      id: "patientUhiAppointments",
      title: "UHI Appointments",
      url: "/home/patientUhiAppointments",
      iconSrc: "/images/hc_appointments.svg",
      feature: "APPOINTMENT",
    },
    ehr: {
      id: "EHR",
      title: "EHR",
      url: `/home/ehr/${currentActiveUser()?.roleBasedId}`,
      iconSrc: "/images/hc_appointments.svg",
      feature: "EHR",
    },
  };
};

export const doctorKeysForMenu = {
  allAvailability: {
    id: "All Availability",
    title: "All Availability",
    url: "/home/availability",
    iconClass: "ri-time-line ri-xl",
    feature: "AVAILABILITY",
  },
  allAppointment: {
    id: "All Appointment",
    title: "All Appointments",
    iconClass: "ri-briefcase-line ri-xl",
    url: "/home/appointment",
    feature: "APPOINTMENT",
  },
  MyAppointments: {
    id: "MyAppointments",
    title: "My Appointments",
    url: "/home/myAppointments",
    iconSrc: "/images/hc_appointments.svg",
    feature: "APPOINTMENT",
  },
  doctorAvailability: {
    id: "doctorAvailability",
    title: "My Availability",
    url: "/home/doctorAvailability",
    iconClass: "ri-time-line ri-xl",
    feature: "AVAILABILITY",
  },
  availability: {
    id: "availability",
    title: "Availability",
    url: "/home/availability",
    iconClass: "ri-time-line ri-xl",
    feature: "AVAILABILITY",
  },
  appointment: {
    id: "appointment",
    title: "All Appointments",
    url: "/home/appointment",
    iconSrc: "/images/hc_appointments.svg",
    feature: "APPOINTMENT",
  },

  billingFrontDesk: {
    id: "billingFrontDesk",
    title: "Billing",
    url: "/home/billing",
    iconClass: "ri-bill-line ri-xl",
    feature: "BILLING",
  },
};

export const abdmMenu = {
  id: "AbdmFacilities",
  title: "ABDM Services",
  url: "/home/OtpScreen",
  iconClass: "ri-hospital-line ri-xl",
  children: [abdmKeysForMenu.HPR, abdmKeysForMenu.HFR, abdmKeysForMenu.ABHA],
};

export const entitiesInfo = [
  {
    id: "CommonTreats",
    info: `For doctors and clinic admins, the section on "commonly treats" highlights the health issues they manage and their areas of expertise. This list includes common conditions addressed by the practitioners, such as chronic diseases for internists or skin disorders for dermatologists. It provides patients with clear insights into the services offered and helps them choose the right care based on their needs. This information reflects the clinic’s overall capabilities and the expertise of its medical team.`,
    icon: <Healing fontSize="large" color="primary" />,
  },
  {
    id: "Procedure",
    info: `For doctors and clinic admins, the "procedures" section details the medical and surgical interventions offered. This includes routine procedures like blood tests and vaccinations, as well as specialized treatments such as minimally invasive surgeries or diagnostic imaging. By outlining the procedures performed, these provide patients with insight into the clinic's capabilities and the range of services available, helping them make informed decisions about their care based on the procedures they may need.`,
    icon: <Assignment fontSize="large" color="secondary" />,
  },
  {
    id: "MedicalServiceCategory",
    info: `This feature enables clinic administrators to organize and categorize their products and services into specific groups. By defining categories such as "Diagnostic Tests," "Consultation fees," and "Therapeutic Treatments," administrators can streamline service management and help patients easily find the care they need. This structured approach improves internal efficiency along with clearly presenting the clinic's range of offerings.`,
    icon: <LocalHospital fontSize="large" color="success" />,
  },
  {
    id: "TaxRates",
    info: `Under this section, clinics can manage all standard tax rates applicable to their products and services. This feature allows administrators to set, update, and apply the appropriate tax rates to ensure accurate billing and compliance with tax regulations. By managing these rates centrally, clinics can streamline their financial processes and ensure that all transactions reflect the correct tax charges.`,
    icon: <MonetizationOn fontSize="large" color="error" />,
  },
];

export const SEVERITY_TYPES = [
  { value: "NONE", menuLabel: "" },
  { value: "MILD", menuLabel: "Mild" },
  { value: "MODERATE", menuLabel: "Moderate" },
  { value: "SEVERE", menuLabel: "Severe" },
  { value: "CRITICAL", menuLabel: "Critical" },
];

export const DOSE_TIMINGS = [
  { value: "NONE", menuLabel: "" },
  { value: "BEFORE_MEAL", menuLabel: "Before Meal" },
  { value: "AFTER_MEAL", menuLabel: "After Meal" },
  { value: "AT_BEDTIME", menuLabel: "At Bedtime" },
  { value: "EMPTY_STOMACH", menuLabel: "Empty Stomach" },
];

export const startsAndEndsWithAlphabetic = (value) => /^[a-zA-Z].*[a-zA-Z]$/.test(value);

export const startsWithAlphabetic = (value) => /^[a-zA-Z]/.test(value);

export const convertTimeForTimeFormat = (value) => {
  let formattedTime;
  if (timeFormat() === 12) {
    formattedTime = dayjs(`2000-01-01T${value}`).format("hh:mm A");
  } else {
    formattedTime = dayjs(`2000-01-01T${value}`).format("HH:mm");
  }
  return formattedTime;
};
