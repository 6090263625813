import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Input,
  Menu,
  MenuList,
  Skeleton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { getAllContent, searchContent } from "services/ContentService";
import { PATIENT, roleName, SKELETON_LOADING_TIME_IN_MILLISECONDS } from "store/constant";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import Reveal from "views/utilities/Reveal";
import { useNavigate } from "react-router";
import CustomMenuItem from "ui-component/custom-components/CustomMenuItem";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { ClearOutlined } from "@mui/icons-material";

const Content = () => {
  const navigate = useNavigate();
  const { handleClick } = useContext(ToastContext);
  const [contents, setContents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState({});
  const handleEditContent = (contentId) => {
    navigate("/home/manageContent", { state: { contentId: contentId } });
  };

  useEffect(() => {
    const getContentBySearchTerm = async () => {
      try {
        const filteredContent = await searchContent(searchTerm?.replace(/\s/g, ""));
        setContents(filteredContent?.data);
      } catch (e) {
        handleClick("error", "There seems to be an error in searching content");
      }
    };

    if (searchTerm) {
      getContentBySearchTerm();
    }
  }, [handleClick, searchTerm]);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const contents = await getAllContent();
        setContents(contents?.data);
      } catch (e) {
        console.error("Can not fetch content");
      }
    };

    fetchContent();
  }, [handleClick]);

  const handleMenuOpen = (event, index) => {
    setAnchorEl({ ...anchorEl, [index]: event.currentTarget });
  };

  const handleMenuClose = (index) => {
    setAnchorEl({ ...anchorEl, [index]: null });
  };

  return (
    <ContentSkeletonStructure>
      <div>
        <Reveal>
          <div className="section-heading">
            <h2 className="page-title">Content</h2>
          </div>
          <Grid display={"flex"} alignItems={"center"} sx={{ pt: "10px", mb: 1.4 }}>
            <FormInputField
              style={{ width: "40.8%" }}
              label={"Search Content"}
              value={searchTerm}
              startAdornment={<i className="ri-search-line ri-lg" />}
              endAdornment={
                <IconButton onClick={() => setSearchTerm("")} edge="end">
                  {<ClearOutlined fontSize="small" />}
                </IconButton>
              }
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              size="small"
            ></FormInputField>
            {roleName() !== PATIENT && (
              <CustomButton
                className="ri-add-fill ri-lg btn--primary"
                style={{ marginLeft: "auto" }}
                label="Add Content"
                onClick={() => {
                  navigate("/home/manageContent", { state: { contentId: null } });
                }}
              />
            )}
          </Grid>
        </Reveal>
        <Grid container className="row mb-0 mx-0 column-patient-details">
          {!contents.length > 0 && <Typography variant="h3">No Content Found</Typography>}
          {contents.length > 0 &&
            contents?.map((content, index) => {
              const options = { year: "numeric", month: "long", day: "numeric" };

              const formattedDate = new Date(content?.modifiedOn).toLocaleDateString(
                "en-GB",
                options
              );

              return (
                <Grid key={index} item md={6} lg={4} xs={12} className="col">
                  <Reveal>
                    <Card
                      key={index}
                      sx={{
                        margin: "10px",
                        height: "300px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <CardContent>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderRadius: "5px",
                          }}
                        >
                          <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                            <Typography
                              gutterBottom
                              variant="h3"
                              className="icon-primary-blue"
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 2,
                                lineHeight: "1.5",
                                maxHeight: "3em",
                                minHeight: "3em",
                              }}
                            >
                              {content?.title}
                            </Typography>
                          </div>
                          {roleName() !== PATIENT && (
                            <div>
                              <CustomButton
                                key={index}
                                iconButton={<i className="ri-more-2-fill" />}
                                style={{ padding: 0 }}
                                onClick={(event) => {
                                  handleMenuOpen(event, index);
                                }}
                                aria-label="Actions"
                              />
                              <Menu
                                elevation={1}
                                anchorEl={anchorEl[index]}
                                open={Boolean(anchorEl[index])}
                                onClose={() => handleMenuClose(index)}
                                PaperProps={{
                                  style: {
                                    borderRadius: "4px",
                                  },
                                }}
                              >
                                <MenuList dense sx={{ p: 0 }}>
                                  <CustomMenuItem
                                    onClick={() => {
                                      handleMenuClose(index);
                                      handleEditContent(content?.uuid);
                                    }}
                                    icon={<i className="ri-edit-fill ri-xl icon-primary-blue" />}
                                    text={"Edit"}
                                  />
                                </MenuList>
                              </Menu>
                            </div>
                          )}
                        </div>

                        <Typography gutterBottom style={{ textAlign: "right" }}>
                          {formattedDate}
                        </Typography>
                        <br></br>
                        <Typography
                          variant="body2"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 5,
                          }}
                        >
                          {content?.description}
                        </Typography>
                      </CardContent>
                      <CardActions
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          paddingRight: "16px",
                          paddingBottom: "16px",
                        }}
                      >
                        <CustomButton
                          label="Read Full Article"
                          className="btn--primary"
                          onClick={() => {
                            navigate(`/home/health-feeds/content/${content?.uuid}`, {
                              state: {
                                contentId: content?.uuid,
                              },
                            });
                          }}
                        />
                      </CardActions>
                    </Card>
                  </Reveal>
                </Grid>
              );
            })}
        </Grid>
      </div>
    </ContentSkeletonStructure>
  );
};

const ContentSkeletonStructure = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={350}
          />
        </Grid>

        <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
          <Grid item xs={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Content;
