import { startsAndEndsWithAlphabetic } from "store/constant";
import * as Yup from "yup";

export const abhaClinicAdminPageValidationForNewPatientRegistration = Yup.object().shape({
  name: Yup.string()
    .required("Please enter your full name.")
    .min(3, "Minimum 3 characters are required")
    .max(100, "Maximum 100 characters are allowed")
    .matches(/^[a-zA-Z\s]*$/, "Name should only contain letters and space")
    .test(
      "contains-alphabetic",
      "Name must start and end with with alphabet",
      startsAndEndsWithAlphabetic
    ),
  mobileNumber: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, "Please enter a valid mobile number")
    .required("Please enter a valid mobile number"),
  emailId: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Please enter a valid email")
    .required("Please enter a valid email.")
    .max(255, "Maximum 255 characters are allowed."),
  gender: Yup.string().required("Please select your gender."),
});

export const abhaClinicAdminValidationForNewFamilyMemberRegistration = Yup.object().shape({
  name: Yup.string()
    .required("Please enter the full name.")
    .min(3, "Minimum 3 characters are required")
    .max(100, "Maximum 100 characters are allowed")
    .matches(/^[a-zA-Z\s]*$/, "Name should only contain letters and space")
    .test(
      "contains-alphabetic",
      "Name must start and end with alphabet",
      startsAndEndsWithAlphabetic
    ),
  gender: Yup.string().required("Please select the gender."),
  relationship: Yup.string().required("Please select the relationship."),
  dateOfBirth: Yup.date().required("Please select the date of birth."),
});

export const abhaClinicAdminPageValidationForExistingUser = Yup.object().shape({
  mobileNumber: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, "Please enter a valid mobile number")
    .required("Please enter a valid mobile number"),
  selectedPatientId: Yup.string().required("Please select a user from dropdown."),
});
