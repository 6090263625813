import * as Yup from "yup";
import { getAllRoles } from "../../../../services/roleService";

export const manageRoleSchema = Yup.object().shape({
  role: Yup.string()
    .required("Required")
    .min(2, "Role must be at least 2 characters")
    .max(50, "Role must be less than 50 characters")
    .matches(/^[a-zA-Z0-9_ ]+$/, "Role can only contain letters, numbers, and underscores")
    .test("is-unique", "Role name already exists", async (value) => {
      // Example check, replace with actual API call
      const exists = await checkRoleNameExists(value);
      return !exists;
    }),
  description: Yup.string().max(200, "Description must be less than 200 characters"),
  status: Yup.string()
    .required("Status is required")
    .oneOf(["ACTIVE", "INACTIVE"], "Invalid status value"),
});

export const checkRoleNameExists = async (roleName) => {
  try {
    // Fetch all roles from the API
    const response = await getAllRoles();

    if (!response || !response.data) {
      throw new Error("Failed to fetch roles");
    }

    // Check if the roleName exists in the fetched roles
    const roles = response.data;
    const roleExists = roles.some((role) => role.name === roleName);

    return roleExists;
  } catch (error) {
    console.error("Failed to check role name existence:", error);
    return false; // Return false or handle the error appropriately
  }
};
