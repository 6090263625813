import React from "react";
import { useLoader } from "./LoaderContext";
import "assets/scss/loader.scss";
import Lottie from "lottie-react";
import animationData from "assets/animations/loader.json";

const LoaderComponent = () => {
  const { loading, loaderMessage } = useLoader();
  return loading ? (
    <div className="overlay">
      <div className="spinner">
        <Lottie
          animationData={animationData}
          loop={true}
          style={{ width: "100px", height: "100px" }}
        />
        {loaderMessage && (
          <div style={{ display: "flex" }}>
            <p className="loader-message">{loaderMessage}</p>
            <div className="dots">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default LoaderComponent;
