import { PersonOutlineOutlined, VaccinesOutlined } from "@mui/icons-material";
import { Grid } from "@mui/material";
import dayjs from "dayjs";
import { useContext } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import { createPatientVital, editPatientVital } from "services/patientService";
import { ACTIVE_STATE, DATE_FORMAT_DMY, DATE_TIME_FORMAT } from "store/constant";
import CustomAutoComplete from "ui-component/custom-components/CustomAutoComplete";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { getIsDemo } from "store/Reducers/DemoReducer";

const PatientVitalSignModal = ({
  patientDOB,
  vitalSignTypes,
  patientId,
  selected,
  close,
  apData,
  initialValue,
  dateAdministeredReadOnly,
  successButtonLabel,
  successButtonOnClick,
}) => {
  const [dateAdministered, setDateAdministered] = useState(
    selected?.dateFrom
      ? dayjs(selected?.dateFrom, DATE_FORMAT_DMY)
      : initialValue?.dateFrom
      ? dayjs(initialValue?.dateFrom, DATE_FORMAT_DMY)
      : null
  );
  const [readingValue, setReadingValue] = useState(
    selected?.value ? selected?.value : initialValue?.value ? initialValue?.value : ""
  );
  const [readingValueTouched, setReadingValueTouched] = useState(false);

  const [vitalSignType, setVitalSignType] = useState(
    initialValue
      ? vitalSignTypes.find(
          (vitalSignType) => vitalSignType.id === initialValue?.vitalSignTypeId
        ) ?? null
      : null
  );

  const [errors, setErrors] = useState({});
  const { handleClick } = useContext(ToastContext);

  const minDateForVital = dayjs(patientDOB ? patientDOB : "1900-01-01");

  const isDemo = useSelector(getIsDemo);

  const [isPrescriptionPage, setisPrescriptionPage] = useState(false);
  useEffect(() => {
    if (apData !== null && apData !== undefined) {
      setisPrescriptionPage(true);
    }
  }, [apData]);
  const currentDate = dayjs();
  const [dateTouched, setDateTouched] = useState(false);

  const validateReadingValue = (errors, readingValue) => {
    const pattern = /^-?\d+(\.\d{1,3})?$/;
    if (!readingValue) {
      errors.readingValue = "Please enter a valid reading value.";
    } else if (parseInt(readingValue) === 0) {
      errors.readingValue = "Reading value can not be zero";
    } else if (!pattern.test(readingValue)) {
      errors.readingValue =
        "Reading value can only contain decimal float numbers with up to 3 decimal places";
    }
  };

  const validate = () => {
    const errors = {};

    setDateTouched(true);
    if (dateAdministered === null && !isPrescriptionPage) {
      errors.dateAdministered = "Please select a valid date.";
    } else if (!(dateAdministered?.isValid ? dateAdministered.isValid() : true)) {
      errors.dateAdministered = "Please select a valid 'Date' value.";
    } else if (dateAdministered > currentDate) {
      errors.dateAdministered = "Date cannot be in future.";
    } else if (dateAdministered < minDateForVital) {
      errors.dateAdministered = "Date can't be less than DOB.";
    }

    setReadingValueTouched(true);
    validateReadingValue(errors, readingValue);

    if (!vitalSignType) {
      errors.vitalSignType = "Please select a vital sign type.";
    }

    return errors;
  };

  useEffect(() => {
    if (selected && vitalSignTypes) {
      const VitalSign = vitalSignTypes.find((vitalSignType) => {
        return vitalSignType.id === selected.vitalSignTypeId;
      });
      setVitalSignType(VitalSign);
    }
  }, [selected, vitalSignTypes]);

  const saveVitalSign = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});

      if (isPrescriptionPage && (!vitalSignType || !readingValue)) {
        handleClick("error", "Kindly provide complete details");
        return;
      }
      if (!isPrescriptionPage && (!vitalSignType || !dateAdministered || !readingValue)) {
        handleClick("error", "Kindly provide complete details");
        return;
      }
      let data = {
        dataType: vitalSignType?.name,
        patientId: patientId,
        dateFrom: isPrescriptionPage
          ? dayjs(apData.appointmentDate).format(DATE_TIME_FORMAT)
          : dayjs(dateAdministered).format(DATE_TIME_FORMAT),
        dateTo: isPrescriptionPage
          ? dayjs(apData.appointmentDate).format(DATE_TIME_FORMAT)
          : dayjs(dateAdministered).format(DATE_TIME_FORMAT),
        value: readingValue,
        unit: vitalSignType?.unit,
        status: ACTIVE_STATE,
        sourceType: "DOCTOR",
      };

      if (isDemo) {
        close("save");
      } else {
        if (selected) {
          try {
            await editPatientVital(selected.id, data);
            handleClick("success", "Patient Vital Sign has been successfully updated.");
            close("save");
          } catch (error) {
            handleClick("error", "There seems to be an error updating the patient vital sign");
          }
        } else {
          try {
            const postData = { ...data, vitalSignTypeId: vitalSignType.id };
            await createPatientVital(postData);
            handleClick("success", "Patient Vital Sign has been successfully created.");
            close("save");
          } catch (error) {
            handleClick("error", "There seems to be an error creating the patient vital Sign");
          }
        }
      }
    }
  };

  const customSuccessButtonOnClick = (vitalSignTypeId, value) => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      successButtonOnClick(vitalSignTypeId, value);
    }
  };

  const getUnitValue = () => {
    return vitalSignType ? vitalSignType.unitAbbreviation : null;
  };

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: "10px", width: "400px" }}>
        <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
          <CustomAutoComplete
            options={vitalSignTypes}
            readOnly={selected}
            label={"Vital Sign"}
            placeholder="Select"
            required
            iconStart={<VaccinesOutlined />}
            getOptionLabel={(option) => option?.displayName}
            onChange={(event, value) => {
              setVitalSignType(value);
              const newErrors = { ...errors };
              delete newErrors.vitalSignType;
              setErrors(newErrors);
            }}
            value={vitalSignType}
            error={Boolean(errors.vitalSignType)}
            errorText={errors.vitalSignType}
          />
        </Grid>

        {!isPrescriptionPage && (
          <>
            <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
              <FormDatePicker
                inputFormat={DATE_FORMAT_DMY}
                label={"Administered Date"}
                required
                disableFuture
                value={dateAdministered}
                minDate={minDateForVital}
                size={"big"}
                handleBlur={() => {
                  setDateTouched(true);
                }}
                onChange={(date) => {
                  setDateAdministered(date);
                  if (date === null) {
                    setErrors({ ...errors, dateAdministered: "Please select a valid date." });
                  } else if (!date.isValid()) {
                    setErrors({
                      ...errors,
                      dateAdministered: "Please select a valid 'Date' value.",
                    });
                  } else if (date > currentDate) {
                    setErrors({
                      ...errors,
                      dateAdministered: "Date cannot be a future date.",
                    });
                  } else if (date < minDateForVital) {
                    setErrors({
                      ...errors,
                      dateAdministered: "Date can't be less than Date of birth.",
                    });
                  } else {
                    const { dateAdministered, ...newError } = errors;
                    setErrors(newError);
                  }
                }}
                style={{
                  width: "100%",
                  marginRight: "30px",
                  marginTop: "7px",
                }}
                error={Boolean(errors.dateAdministered && dateTouched)}
                errorText={errors.dateAdministered}
                readOnly={dateAdministeredReadOnly}
              ></FormDatePicker>
            </Grid>
          </>
        )}
        <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Reading Value"
            name="readingValue"
            type={"text"}
            required
            value={readingValue}
            onBlur={(e) => {
              setReadingValueTouched(true);
              const newError = {};
              validateReadingValue(newError, readingValue);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setReadingValue(e.target.value);
              if (readingValueTouched) {
                const newError = {};
                validateReadingValue(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.readingValue;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<PersonOutlineOutlined />}
            size={"big"}
            error={Boolean(errors.readingValue)}
            errorText={errors.readingValue}
          />
        </Grid>
        {getUnitValue() && (
          <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
            <FormInputField
              style={{ width: "100%", marginTop: "7px" }}
              label="Reading Unit"
              name="readingUnit"
              value={getUnitValue()}
              startAdornment={<PersonOutlineOutlined />}
              size={"big"}
              disabled
            />
          </Grid>
        )}

        <Grid item sx={{ mt: 1 }} container justifyContent={"center"}>
          <CustomButton
            label={"Cancel"}
            className={"btn--error"}
            style={{ width: "100px" }}
            onClick={() => close("cancel")}
          />

          <CustomButton
            onClick={
              successButtonOnClick
                ? () => {
                    customSuccessButtonOnClick(vitalSignType, readingValue);
                  }
                : saveVitalSign
            }
            label={successButtonLabel ? successButtonLabel : "Save"}
            className={"btn--secondary"}
            style={{ marginLeft: "10px", width: "100px" }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PatientVitalSignModal;
