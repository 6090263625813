import { HeadersForAccessTokenAPI } from "store/constant";
import { api as API } from "./AxiosInterceptor";

export const otpGenerate = (contactField, fieldType) =>
  API.post(
    `/hc-auth/otp/generate`,
    {
      contactField: contactField,
      otpType: fieldType,
      otp: null,
    },
    { showLoader: false }
  );

export const getOauthAccessToken = (contactField, fieldType, otp) =>
  API.post(
    `/hc-auth/token`,
    { contactField: contactField, otpType: fieldType, otp: otp },
    {
      headers: HeadersForAccessTokenAPI,
      withCredentials: true,
    }
  );

export const getAppDwnldLinks = () => API.get("/hc-auth/util");

export const login = (mobileNumber) => API.get(`/api/login?mobileNumber=${mobileNumber}`);

export const verifyInit = (data) => API.post(`hc-auth/verification/init`, data);

export const verifyComplete = (data) => API.post(`hc-auth/verification/complete`, data);
