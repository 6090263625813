import { ClearOutlined, PersonOutlineOutlined } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import { useContext } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { updateDoctor } from "services/doctorService";
import { getDoctorsByOrg } from "services/organizationService";
import { linkUserWithOrganization } from "services/userService";
import { ORGANIZATION, accessToken } from "store/constant";
import ModalUI from "ui-component/ModalUI";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import Reveal from "views/utilities/Reveal";

const OrgDoctors = ({ ownershipArr, setownershipArr, organizationId }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  // const [specializations, setSpecializations] = useState([]);
  const { handleClick } = useContext(ToastContext);

  const closeModal = () => {
    setOpen(false);
    setSelected(null);
  };

  const openAddAmenityModal = () => {
    setOpen(true);
  };

  const [searchQuery, setSearchQuery] = useState("");

  const [orgDoctors, setOrgDoctors] = useState([]);
  const [displayOrgDoctors, setDisplayOrgDoctors] = useState([]);

  const clearSearch = () => {
    setSearchQuery("");
  };

  const updateDoctor = (doctor) => {
    setSelected(doctor);
    setOpen(true);
  };

  const handleMarkInactive = (doctor) => {};
  const accessTokenValue = accessToken();

  const fetchOrgDoctors = useCallback(async () => {
    try {
      const responseDoctorByOrg = await getDoctorsByOrg(organizationId);
      const updatedDoctors = responseDoctorByOrg.data.map((doctor) => ({
        ...doctor,
        userResponse: {
          ...doctor.userResponse,
          name: `Dr. ${doctor.userResponse.name}`,
        },
      }));
      setOrgDoctors(updatedDoctors);
      return updatedDoctors;
    } catch (error) {
      console.error("Error fetching doctors");
    }
  }, [organizationId]);

  useEffect(() => {
    fetchOrgDoctors();
  }, [accessTokenValue, fetchOrgDoctors]);

  useEffect(() => {
    setDisplayOrgDoctors(
      orgDoctors.filter((item) => {
        const values = Object.values(item);
        const lowerCaseQuery = searchQuery.toLowerCase();
        return values.some((value, index) => {
          if (index === 12) {
            const obj = Object.values(value);
            return obj.some((el, i) => {
              if (i === 2 || i === 14 || i === 15) {
                return el.toLowerCase().includes(lowerCaseQuery);
              }
              return false;
            });
          }
          return false;
        });
      })
    );
  }, [orgDoctors, searchQuery]);

  const columns = [
    { field: "Sr. No.", label: "Sr. No" },
    { field: "userResponse.name", label: "Name" },
    { field: "userResponse.mobileNumber", label: "Mobile Number" },
    { field: "userResponse.emailId", label: "Email" },
    { field: "Actions", label: "Actions" },
  ];

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: updateDoctor,
    },
    {
      label: (rowData) => {
        return "Delete";
      },
      icon: <i className="ri-delete-bin-fill ri-xl icon-primary-blue" />,
      onClick: handleMarkInactive,
    },
  ];

  return (
    <Reveal>
      <Grid display={"flex"} alignItems={"center"} sx={{ mt: 1, mb: 1.4 }}>
        <FormInputField
          style={{ width: "40.8%" }}
          label={"Search Doctor"}
          value={searchQuery}
          startAdornment={<i className="ri-search-line ri-lg" />}
          endAdornment={
            <IconButton onClick={clearSearch} edge="end">
              {<ClearOutlined fontSize="small" />}
            </IconButton>
          }
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          size="small"
        ></FormInputField>
        <CustomButton
          style={{ marginLeft: "auto" }}
          label={"Add Doctor"}
          className={"ri-add-fill ri-lg btn--primary"}
          onClick={() => openAddAmenityModal()}
        />
      </Grid>

      <CustomizedTable
        actions={actions}
        columns={columns}
        tableData={displayOrgDoctors}
        rowsPerPageInTable={3}
      />

      <ModalUI
        visible={open}
        close={closeModal}
        title={selected ? "Update Doctor" : "Create Doctor"}
        component={
          <OrganizationAddDoctors
            fetchOrgDoctors={fetchOrgDoctors}
            ownershipArr={ownershipArr}
            setownershipArr={setownershipArr}
            // specializations={specializations}
            displayOrgDoctors={displayOrgDoctors}
            setDisplayOrgDoctors={setDisplayOrgDoctors}
            orgDoctors={orgDoctors}
            setOrgDoctors={setOrgDoctors}
            selected={selected}
            handleClick={handleClick}
            organizationId={organizationId}
            close={() => {
              closeModal();
            }}
            onCancel={() => {
              closeModal();
            }}
          />
        }
      />
    </Reveal>
  );
};

const OrganizationAddDoctors = ({
  selected,
  handleClick,
  close,
  // specializations,
  setOrgDoctors,
  orgDoctors,
  organizationId,
  setDisplayOrgDoctors,
  displayOrgDoctors,
  fetchOrgDoctors,
  ownershipArr,
  setownershipArr,
}) => {
  const [firstName, setFirstName] = useState(
    selected?.userResponse?.firstName ? selected.userResponse.firstName : ""
  );
  const [mobileNumber, setMobileNumber] = useState(
    selected?.userResponse?.mobileNumber ? selected.userResponse.mobileNumber : ""
  );
  const [emailId, setEmailId] = useState(
    selected?.userResponse?.emailId ? selected.userResponse.emailId : ""
  );
  // const [specialization, setSpecialization] = useState(
  //   selected?.specialization?.id ? selected.specialization.id : ""
  // );

  const [errors, setErrors] = useState({});

  const validate = () => {
    const errors = {};
    const mobilePattern = /^[6-9][0-9]{9}$/;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!firstName) {
      errors.firstName = "Please enter doctor's first name.";
    } else if (firstName.length > 50) {
      errors.firstName = "Maximum 50 characters are allowed";
    }

    if (!mobileNumber) {
      errors.mobileNumber = "Please enter doctor's mobile number";
    } else if (!mobilePattern.test(mobileNumber)) {
      errors.mobileNumber = "Please enter valid mobile number";
    }

    if (!emailId) {
      errors.emailId = "Please enter doctor's email.";
    } else if (!emailPattern.test(emailId)) {
      errors.emailId = "Please enter valid email";
    }

    // if (!specialization) {
    //   errors.specialization = "Required";
    // }

    return errors;
  };

  const saveDoctor = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      if (selected) {
        const data = {
          ...selected,
          userResponse: {
            ...selected.userResponse,
            firstName,
            mobileNumber,
            emailId,
            // specialization: { id: specialization },
          },
        };
        const index = displayOrgDoctors.findIndex((el) => el.id === selected.id);
        displayOrgDoctors.splice(index, 1);

        const ownershipIndex = ownershipArr.findIndex((el) => el.id === selected.id);
        ownershipArr.splice(ownershipIndex, 1);

        const updatedDoctor = await updateDoctor(selected.id, data);
        setownershipArr((prevRows) => [
          ...prevRows,
          { ...updatedDoctor.data, name: `Dr. ${updatedDoctor.data.userResponse.name}` },
        ]);
        setDisplayOrgDoctors((prevRows) => [
          ...prevRows,
          {
            ...updatedDoctor.data,
            userResponse: {
              ...updatedDoctor.data.userResponse,
              name: `Dr. ${updatedDoctor.data.userResponse.name}`,
            },
          },
        ]);
      } else {
        const data = {
          firstName,
          mobileNumber,
          email: emailId,
          orgId: organizationId,
          isDoctor: true,
          isAdmin: false,
        };
        try {
          await linkUserWithOrganization(data);
          // setDisplayOrgDoctors([...orgDoctors, response.data]);
          // const updatedDoctorResponse = await updateDoctor(response.data.id, {
          //   ...response.data,
          //   specialization: { id: specialization },
          // });
          // setownershipArr((prevRows) => [
          //   ...prevRows,
          //   { ...updatedDoctorResponse.data, name: updatedDoctorResponse.data.user.name },
          // ]);
          const newDoctorsList = await fetchOrgDoctors();

          setownershipArr([
            { id: ORGANIZATION, name: "Organization" },
            ...newDoctorsList.map((el) => {
              return { ...el, name: el.userResponse.name };
            }),
          ]);
        } catch (error) {
          handleClick("error", "There seems to be an error creating doctor");
        }
      }
      close();
    }
  };

  return (
    <Grid container spacing={2} sx={{ marginTop: "10px", width: "400px" }}>
      <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
        <FormInputField
          style={{ width: "100%", marginTop: "7px" }}
          label="First Name"
          name="firstName"
          required
          value={firstName}
          error={Boolean(errors.firstName)}
          errorText={errors.firstName}
          onChange={(e) => setFirstName(e.target.value)}
          startAdornment={<PersonOutlineOutlined />}
          size={"big"}
        />
      </Grid>
      <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
        <FormInputField
          style={{ width: "100%", marginTop: "7px" }}
          label="Mobile Number"
          name="mobileNumber"
          readOnly={selected ? true : false}
          value={mobileNumber}
          inputProps={{
            maxLength: 10,
          }}
          required
          type={"tel"}
          error={Boolean(errors.mobileNumber)}
          errorText={errors.mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
          startAdornment={<i className="ri-phone-line ri-xl" />}
          size={"big"}
        />
      </Grid>
      <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
        <FormInputField
          style={{ width: "100%", marginTop: "7px" }}
          label="Email"
          name="emailId"
          type="text"
          required
          value={emailId}
          readOnly={selected ? true : false}
          error={Boolean(errors.emailId)}
          errorText={errors.emailId}
          onChange={(e) => setEmailId(e.target.value)}
          startAdornment={<i className="ri-mail-line ri-lg" />}
          size={"big"}
        />
      </Grid>
      {/* <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
        <FormInputField
          style={{ width: "100%", marginTop: "7px" }}
          label="HPR ID"
          name="hprId"
          value={firstName}
          error={Boolean(errors.firstName)}
          errorText={errors.firstName}
          onChange={(e) => setFirstName(e.target.value)}
          startAdornment={<PersonOutlineOutlined />}
          size={"big"}
        />
      </Grid> */}

      {/* <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
        <FormSelectField
          style={{ width: "100%", marginTop: "7px" }}
          label="Specialization"
          name="specialization"
          value={specialization}
          onChange={(e) => setSpecialization(e.target.value)}
          menuItems={specializations.map((el) => {
            return {
              value: el.id,
              menuLabel: el.name,
            };
          })}
          error={Boolean(errors.specialization)}
          errorText={errors.specialization}
          startAdornment={<FolderSpecialOutlined />}
        />
      </Grid> */}
      <Grid item sx={{ mt: 1 }} container justifyContent={"center"}>
        <CustomButton
          label={"Cancel"}
          className={"btn--error"}
          style={{ width: "100px" }}
          onClick={() => close()}
        />

        <CustomButton
          // disabled={!(reactionDetails && severity && allergy)}
          onClick={saveDoctor}
          label={"Save"}
          className={"btn--secondary"}
          style={{ marginLeft: "10px", width: "100px" }}
        />
      </Grid>
    </Grid>
  );
};

export default OrgDoctors;
