import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import {
  createPatientLifeStyle,
  updatePatientLifeStyle,
  getLifestyleQuestions,
  getPatientLifestyleByPatientId,
} from "services/patientService";
import { currentActiveUser } from "store/constant";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";

const PatientLifeStyle = () => {
  const [questionnaire, setQuestionnaire] = useState([]);
  const patientId = currentActiveUser()?.roleBasedId;
  const [errors, setErrors] = useState({});
  const [occupationTouched, setOccupationTouched] = useState(false);
  const [questions, setQuestions] = useState([]);

  const { handleClick } = useContext(ToastContext);

  const validateOccupation = (errors, input) => {
    const startsWithAlphabet = /^[A-Za-z]/;
    if (input !== "" && !startsWithAlphabet.test(input)) {
      errors.occupation = "must start with alphabet";
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const questionResponse = await getLifestyleQuestions();
        setQuestions(questionResponse.data);
        const answerResponse = await getPatientLifestyleByPatientId(patientId);
        const listOfQuestions = questionResponse.data.map((question) => {
          let answer = answerResponse.data.find((ans) => ans.questionId === question.id);
          if (!answer) {
            answer = {
              id: null,
              patientId: patientId,
              questionId: question.id,
              response: null,
            };
          }
          return {
            question: question,
            answer: answer,
          };
        });

        setQuestionnaire(listOfQuestions);
      } catch (error) {
        console.error("Error fetching lifestyle");
      }
    };
    fetchData();
  }, []);

  const handleChange = (index, value, checked) => {
    let selectedOptions = questionnaire[index].answer.response?.split(",") || [];
    if (checked === true) {
      selectedOptions.push(value);
      const updatedQuestionnaire = [...questionnaire];
      updatedQuestionnaire[index].answer.response = selectedOptions.join(",");
      setQuestionnaire(updatedQuestionnaire);
    } else if (checked === false) {
      selectedOptions = selectedOptions.filter((option) => option !== value);
      const updatedQuestionnaire = [...questionnaire];
      updatedQuestionnaire[index].answer.response = selectedOptions.join(",");

      setQuestionnaire(updatedQuestionnaire);
    } else {
      const updatedQuestionnaire = [...questionnaire];
      updatedQuestionnaire[index].answer["response"] = value;
      setQuestionnaire(updatedQuestionnaire);
    }
  };

  const handleSave = async () => {
    questionnaire.map(async (lifestyle) => {
      try {
        if (lifestyle.answer.id) {
          await updatePatientLifeStyle(lifestyle.answer.id, lifestyle.answer);
        } else if (lifestyle.answer.response) {
          await createPatientLifeStyle(lifestyle.answer);
          const answerResponse = await getPatientLifestyleByPatientId(patientId);
          const listOfQuestions = questions.map((question) => {
            let answer = answerResponse.data.find((ans) => ans.questionId === question.id);
            if (!answer) {
              answer = {
                id: null,
                patientId: patientId,
                questionId: question.id,
                response: null,
              };
            }
            return {
              question: question,
              answer: answer,
            };
          });

          setQuestionnaire(listOfQuestions);
          handleClick("success", "Lifestyle details has been successfully saved.");
        }
        handleClick("success", "Lifestyle details has been successfully saved.");
      } catch (error) {
        handleClick("error", "There seems to be an error saving the lifestyle.");
      }
    });
  };

  return (
    <div className="field">
      <div className="section-heading">
        <h2 className="page-title">Lifestyle Questions</h2>
      </div>
      <Card className="lifestyle-card">
        <CardContent className="flex">
          <div className="lifestyle">
            {questionnaire.map((lifestyle, quesNumber) => (
              <div key={lifestyle.question.id} style={{ marginBottom: "25px" }}>
                <Typography variant="body2">
                  {quesNumber + 1 + "."} {lifestyle.question.questionText}
                </Typography>
                {lifestyle.question.responseType === "SINGLE_CHOICE" && (
                  <RadioGroup
                    name={`${lifestyle.question.id}`}
                    value={lifestyle.answer?.response}
                    onChange={(event) => {
                      handleChange(quesNumber, event.target.value);
                    }}
                    row
                  >
                    {lifestyle.question.possibleChoices.map((option, index) => (
                      <FormControlLabel
                        key={index}
                        value={option}
                        control={<Radio />}
                        label={option}
                      />
                    ))}
                  </RadioGroup>
                )}
                {lifestyle.question.responseType === "MULTIPLE_CHOICE" && (
                  <FormGroup row>
                    {lifestyle.question.possibleChoices.map((option, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            checked={lifestyle?.answer?.response?.split(",").includes(option)}
                            onChange={(event) => {
                              handleChange(quesNumber, option, event.target.checked);
                            }}
                          />
                        }
                        label={option}
                      />
                    ))}
                  </FormGroup>
                )}
                {lifestyle.question.responseType === "TEXT" && (
                  <TextField
                    variant="standard"
                    style={{ width: "40%" }}
                    value={lifestyle.answer?.response || ""}
                    onChange={(event) => {
                      handleChange(quesNumber, event.target.value);
                      if (occupationTouched) {
                        const newError = {};
                        validateOccupation(newError, event.target.value);
                        const updatedErrors = { ...errors };
                        delete updatedErrors.occupation;
                        setErrors({ ...updatedErrors, ...newError });
                      }
                    }}
                    onBlur={(event) => {
                      setOccupationTouched(true);
                      const newError = {};
                      validateOccupation(newError, questionnaire[quesNumber].answer.response);
                      setErrors({ ...errors, ...newError });
                    }}
                    inputProps={{ maxLength: 50 }}
                    error={Boolean(errors.occupation)}
                    helperText={errors.occupation}
                  ></TextField>
                )}
              </div>
            ))}
          </div>
        </CardContent>
        <div
          style={{
            marginBottom: "25px",
            display: "flex",
            justifyContent: "end",
            marginRight: "25px",
          }}
        >
          <CustomButton
            label="Reset"
            onClick={() => {
              const temp = questionnaire.map((lifestyle) => {
                return {
                  question: lifestyle.question,
                  answer: {
                    ...lifestyle.answer,
                    response: "",
                  },
                };
              });

              setQuestionnaire([...temp]);
            }}
            className="btn--error"
          />
          <CustomButton
            label={"Save"}
            style={{ marginLeft: "10px" }}
            onClick={handleSave}
            className={"btn--secondary"}
          />
        </div>
      </Card>
    </div>
  );
};

export default PatientLifeStyle;
