import { startsWithAlphabetic } from "store/constant";
import * as Yup from "yup";

export const reviewSchema = Yup.object().shape({
  title: Yup.string()

    .required("Please provide a valid title.")

    .min(3, "Title is too short. Please provide a valid one.")
    .max(50, "Title is too long. Please provide a valid one.")
    .test("contains-alphabetic", "Must start with alphabet", startsWithAlphabetic),
  description: Yup.string()

    .required("Please provide a valid description.")

    .min(3, "Description is too short. Please provide a valid description.")
    .max(255, "Description is too long. Please provide a valid description.")
    .test("contains-alphabetic", "Must start with alphabet", startsWithAlphabetic),
});
