import { useDispatch, useSelector } from "react-redux";
import { MENU_OPEN } from "store/actions";
import { useEffect } from "react";
import CustomAppointmentCard from "ui-component/custom-components/CustomAppointmentCard";
import "../../../assets/scss/style.scss";
import {
  Box,
  Grid,
  Skeleton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  StepContent,
  StepLabel,
  Step,
  Stepper,
} from "@mui/material";
import CustomTabCard from "ui-component/custom-components/CustomTabCard";
import { useState } from "react";
import {
  currentActiveUser,
  CHECKED_IN,
  CLINIC_VISIT,
  RESCHEDULED,
  SCHEDULED,
  TELE_CONSULTATION,
  TEXT_FOR_CLINIC_VISIT,
  TEXT_FOR_TELECONSULTATION,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  TOTAL,
  NO_SHOW,
  HOUR_MINUTE_FORMAT,
  orgId,
  DATE_FORMAT,
  FRONT_DESK,
  DOCTOR,
  DATE_FORMAT_DMY,
  TEXT_FOR_CHECKED_IN,
} from "store/constant";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  setAppointmentdata,
  setSelectedAppointmentData,
} from "store/Actions/appointmentDataAction";
import { useContext } from "react";
import { getFilteredAppointments, updateAppointmentStatus } from "services/Appointments";
import { getAppointmentData } from "store/Reducers/appointmentDataReducer";
import Reveal from "views/utilities/Reveal";
import { getUserOrgId, getFrontDeskUserLinking, getUserFeatures } from "store/Reducers/userReducer";
import { Build, CorporateFareOutlined, EventAvailable, Stars } from "@mui/icons-material";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import { getAllOrganizations } from "services/organizationService";
import { setFrontDeskUserLinking, setUserOrgId } from "store/Actions/userActions";
import { linkUserWithOrganization } from "services/userService";
import dayjs from "dayjs";
import {
  doSpecializationAndExperienceExist,
  doesAvailabilityExist,
  doesProductServicesExist,
  getDoctorByOrgId,
} from "services/doctorService";
import CustomButton from "ui-component/custom-components/CustomButton";
import BookAppointmentModal from "ui-component/Appointment/BookAppointmentModal";
import ModalUI from "ui-component/ModalUI";
import { useLocation, useNavigate } from "react-router";
import { SidebarUtilContext } from "layout/SidebarUtilContext";
import { profileDashboard } from "menu-items/dashboard";
import { ThemeProvider } from "@mui/system";
import themes from "themes";

const CommonDashboardForFrontDeskAndDoctor = () => {
  const { handleClick } = useContext(ToastContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();
  const allAppointments = useSelector(getAppointmentData);
  const userFeatures = useSelector(getUserFeatures);
  const [activeTab, setActiveTab] = useState(TOTAL);

  const [checkProfileCompletedApiCalled, setCheckProfileCompletedApiCalled] = useState(false);
  const completedDetails = useLocation()?.state?.completedDetails;

  const navigate = useNavigate();
  const { toggleMainClass, setSecondarySideBarData } = useContext(SidebarUtilContext);

  const activeUser = currentActiveUser();

  const [specialtyAndExperiencePending, setSpecialtyAndExperiencePending] = useState(true);
  const [availabilityPending, setAvailabilityPending] = useState(true);
  const [prodServciesPending, setProdServciesPending] = useState(true);

  const [pendingTaskPopupVisible, setPendingTaskPopupVisible] = useState(false);

  const [appointmentsMenuLabel, setAppointmentsMenuLabel] = useState("Today");
  const handleAppointmentsMenu = (menuItem) => {
    setAppointmentsMenuLabel(menuItem.label);
  };

  useEffect(() => {
    if (!specialtyAndExperiencePending || !availabilityPending || !prodServciesPending) {
      setPendingTaskPopupVisible(true);
    } else if (checkProfileCompletedApiCalled && completedDetails) {
      handleClick("success", "You're all set to go!!");
    }
  }, [
    specialtyAndExperiencePending,
    availabilityPending,
    prodServciesPending,
    checkProfileCompletedApiCalled,
  ]);

  const handlePendingPopupClose = () => {
    setPendingTaskPopupVisible(false);
  };

  const [loading, setLoading] = useState(true);

  const [appointmentInfo, setAppointmentInfo] = useState({
    totalAppointments: [],
    offlineAppointments: [],
    onlineAppointments: [],
  });

  const frontDeskUserLinking = useSelector(getFrontDeskUserLinking);
  if (frontDeskUserLinking === null) {
    const data = localStorage.getItem("frontDeskUserLinking");
    dispatch(setFrontDeskUserLinking(data));
  }

  const [doctorAppointmentsForAllOrganization, setDoctorAppointmentsForAllOrganization] =
    useState(null);
  const doctorOrgId = useSelector(getUserOrgId);

  const fetchData = async () => {
    let doctorId = activeUser?.roleName === "DOCTOR" ? activeUser.roleBasedId : null;
    try {
      const response = await getFilteredAppointments(
        doctorId ? { doctorId } : { orgId: doctorOrgId }
      );
      let apIdArr = [];
      const appointmentData = response.data.content.map((element) => {
        const timeArr = element.appointmentTime.split(":");
        let apDate = dayjs(element.appointmentDate);
        apDate = apDate.set("hour", Number(timeArr[0])).set("minute", Number(timeArr[1]));
        element.appointmentTime = apDate.format(HOUR_MINUTE_FORMAT);
        if (element["appointmentStatus"] === SCHEDULED) {
          // starting of todays date
          const currDate = dayjs().startOf("day");
          const isDayPassed = dayjs(element.appointmentDate).isBefore(currDate);
          // appointment status should be changed to NO_SHOW
          if (isDayPassed) {
            element["appointmentStatus"] = NO_SHOW;
            apIdArr.push(element.id);
          }
        }
        return {
          ...element,
          formattedDate: apDate.format(DATE_FORMAT_DMY),
        };
      });
      if (apIdArr.length > 0) {
        apIdArr.forEach(async (id) => {
          try {
            await updateAppointmentStatus(id, NO_SHOW);
          } catch (error) {
            handleClick("error", "There seems to be some error updating appointment status.");
          }
        });
      }
      setDoctorAppointmentsForAllOrganization(appointmentData);
    } catch (error) {
      console.error("Error fetching doctor's appointments");
      setLoading(false);
    }
  };

  const fetchOrganizationsData = async () => {
    try {
      const response = await getAllOrganizations();
      setOrganizationDetails(response.data);
    } catch (error) {
      console.error("Error fetching organization details.");
      setLoading(false);
    }
  };

  const checkForProfileSetupDetails = async () => {
    if (activeUser.roleName === DOCTOR) {
      try {
        const speAndExpBool = await doSpecializationAndExperienceExist(activeUser.roleBasedId);
        const availBool = await doesAvailabilityExist(activeUser.roleBasedId, doctorOrgId);

        const orgAssociationRecord = activeUser.userOrganizationAssociationList.filter(
          (el) => el.organizationId === doctorOrgId
        )[0];
        if (orgAssociationRecord.isAdmin) {
          const prodServBool = await doesProductServicesExist(doctorOrgId);
          setProdServciesPending(prodServBool.data);
        }
        setSpecialtyAndExperiencePending(speAndExpBool.data);
        setAvailabilityPending(availBool.data);
        setCheckProfileCompletedApiCalled(true);
      } catch (error) {}
    }
  };

  useEffect(() => {
    checkForProfileSetupDetails();
  }, []);

  useEffect(() => {
    if (frontDeskUserLinking === true) {
      fetchOrganizationsData();
      setOpenModal(true);
    } else {
      fetchOrganizationsData();
      setOpenModal(false);
    }
  }, [frontDeskUserLinking]);

  useEffect(() => {
    fetchData();
  }, [handleClick]);

  useEffect(() => {
    if (doctorAppointmentsForAllOrganization) {
      dispatch(
        setAppointmentdata(
          doctorAppointmentsForAllOrganization.filter(
            (appointment) =>
              (appointment.appointmentStatus === SCHEDULED ||
                appointment.appointmentStatus === RESCHEDULED ||
                appointment.appointmentStatus === CHECKED_IN) &&
              appointment.orgId === doctorOrgId
          )
        )
      );
      setLoading(false);
    }
  }, [doctorAppointmentsForAllOrganization, doctorOrgId]);

  const handleModalOpen = () => {
    dispatch(
      setSelectedAppointmentData({
        orgId: orgId(),
        appointmentDate: dayjs().format(DATE_FORMAT),
      })
    );
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    dispatch(setSelectedAppointmentData({}));
    setIsModalOpen(false);
    fetchData();
  };
  const handleFilter = (appointmentsMenu) => {
    const today = dayjs().format(DATE_FORMAT);
    const formattedTomorrow = dayjs().add(1, "day").format(DATE_FORMAT);

    let updatedList = allAppointments ? [...allAppointments] : [];
    if (appointmentsMenu !== "All") {
      updatedList = updatedList.filter((appointment) => {
        if (appointmentsMenu === "Today") {
          return appointment.appointmentDate === today;
        } else if (appointmentsMenu === "Tomorrow") {
          return appointment.appointmentDate === formattedTomorrow;
        }
        return false;
      });
    }
    changeAppointmentCount(updatedList);
    if (activeTab === TEXT_FOR_TELECONSULTATION) {
      updatedList = updatedList.filter((appointment) => appointment?.type === TELE_CONSULTATION);
    } else if (activeTab === TEXT_FOR_CLINIC_VISIT) {
      updatedList = updatedList.filter((appointment) => appointment?.type === CLINIC_VISIT);
    }
    return updatedList;
  };

  useEffect(() => {
    if (!allAppointments) return;
    const online = allAppointments?.filter(
      (appointment) => appointment?.type === TELE_CONSULTATION
    );

    const offline = allAppointments?.filter((appointment) => appointment?.type === CLINIC_VISIT);

    setAppointmentInfo({
      totalAppointments: allAppointments,
      onlineAppointments: online,
      offlineAppointments: offline,
    });

    handleFilter(appointmentsMenuLabel);
  }, [allAppointments]);

  useEffect(() => {
    dispatch({ type: MENU_OPEN, id: "default" });
  }, [dispatch]);

  const handleTabChange = (tabTitle) => {
    setActiveTab(tabTitle);
  };

  const getAppointmentLen = (idx) => {
    return (
      [
        appointmentInfo.totalAppointments,
        appointmentInfo.offlineAppointments,
        appointmentInfo.onlineAppointments,
      ][idx]?.length || 0
    );
  };

  const [openModal, setOpenModal] = useState(false);
  const [orgLink, setorgLink] = useState("");
  const [organizationDetails, setOrganizationDetails] = useState([]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleLinkUser = async () => {
    const currUserData = activeUser;
    const data = {
      firstName: currUserData.firstName,
      middleName: currUserData.middleName,
      lastName: currUserData.lastName,
      mobileNumber: currUserData.mobileNumber,
      hprId: currUserData.hprId,
      email: currUserData.emailId,
      orgId: orgLink,
      isDoctor: false,
      isAdmin: false,
    };
    try {
      await linkUserWithOrganization(data);
      dispatch(setFrontDeskUserLinking(false));
      dispatch(setUserOrgId(orgLink));
      handleCloseModal();
      fetchData();
      handleClick("success", "Organization has been successfully linked!");
    } catch (error) {
      handleCloseModal();
      handleClick("error", "There seems to be an error linking the organization!");
    }
  };

  const changeAppointmentCount = (updatedList) => {
    if (!updatedList) return;
    const online = updatedList?.filter((appointment) => appointment?.type === TELE_CONSULTATION);
    const offline = updatedList?.filter((appointment) => appointment?.type === CLINIC_VISIT);
    setAppointmentInfo({
      totalAppointments: updatedList,
      onlineAppointments: online,
      offlineAppointments: offline,
    });
  };

  const steps = [
    {
      label: "Set Your Speciality, Experience and Language in profile",
      icon: <Stars />,
      condition: specialtyAndExperiencePending,
      onClick: () => {
        const profileObj = profileDashboard(
          userFeatures,
          doctorOrgId,
          activeUser,
          activeUser.roleName
        );
        setSecondarySideBarData(profileObj);
        navigate("/home/doctorProfile", { state: { pendingTask: true } });
        toggleMainClass((prevClass) => "main sub-sidebar");
      },
    },
    {
      label: "Help us set your Availability Schedule",
      icon: <EventAvailable />,
      condition: availabilityPending,
      onClick: () => {
        navigate("/home/doctorAvailability", { state: { pendingTask: true } });
      },
    },
    {
      label: "Complete Product-Service Information",
      icon: <Build />,
      condition: prodServciesPending,
      onClick: () => {
        const profileObj = profileDashboard(
          userFeatures,
          doctorOrgId,
          activeUser,
          activeUser.roleName
        );
        setSecondarySideBarData(profileObj);
        navigate("/home/ProductAndServices", {
          state: { organizationId: doctorOrgId, pendingTask: true },
        });
        toggleMainClass((prevClass) => "main sub-sidebar");
      },
    },
  ];

  return (
    <DoctorAndFrontdeskDashboardSkeleton>
      <Grid item lg={12} className="px-0">
        <Reveal className="mui-tabs__bar">
          {[TOTAL, TEXT_FOR_CLINIC_VISIT, TEXT_FOR_TELECONSULTATION, TEXT_FOR_CHECKED_IN].map(
            (slab, i) => (
              <CustomTabCard
                key={i}
                title={slab}
                value={getAppointmentLen(i)}
                isActive={activeTab === slab}
                onClick={() => handleTabChange(slab)}
              />
            )
          )}
          {activeUser.roleName === FRONT_DESK && (
            <CustomButton
              className="ri-add-fill ri-lg btn--secondary"
              label="New Appointment"
              onClick={handleModalOpen}
              style={{ marginLeft: "auto", marginTop: "auto" }}
            ></CustomButton>
          )}
        </Reveal>
        <br></br>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <CustomAppointmentCard
            activeTab={activeTab}
            handleFilter={handleFilter}
            appointmentsMenuLabel={appointmentsMenuLabel}
            handleAppointmentsMenu={handleAppointmentsMenu}
          />
        )}
      </Grid>
      <ModalUI
        visible={isModalOpen}
        close={handleModalClose}
        title="Book Appointment"
        style={{
          overflowY: "scroll",
          height: "550px",
          width: "610px",
        }}
        component={<BookAppointmentModal handleClick={handleClick} closeModal={handleModalClose} />}
      />

      <ModalUI
        visible={pendingTaskPopupVisible}
        close={handlePendingPopupClose}
        title={"Complete below profile details"}
        style={{
          overflowY: "auto",
        }}
        component={<PendingDetailsPopupComponent steps={steps} />}
      />

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle sx={{ "font-size": "20px" }}>Link User with Organization</DialogTitle>
        <form onSubmit={(e) => e.preventDefault()}>
          <DialogContent sx={{ width: 400, height: "40%" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} mt={2}>
                <FormSelectField
                  label="Organization"
                  name={"orgLink"}
                  style={{ width: "100%" }}
                  value={orgLink}
                  onChange={(e) => {
                    setorgLink(e.target.value);
                  }}
                  startAdornment={<CorporateFareOutlined />}
                  menuItems={organizationDetails.map((el) => {
                    return {
                      value: el.id,
                      menuLabel: el.name,
                    };
                  })}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleCloseModal();
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={() => {
                if (orgLink) {
                  handleLinkUser();
                } else {
                  handleClick("info", "Please fill all fields!");
                }
              }}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </DoctorAndFrontdeskDashboardSkeleton>
  );
};

const DoctorAndFrontdeskDashboardSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"}>
          <Grid item xs={2} sx={{ mr: 1 }}>
            <Skeleton
              sx={{ borderRadius: "8px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={90}
            />
          </Grid>
          <Grid item xs={2} sx={{ mr: 1 }}>
            <Skeleton
              sx={{ borderRadius: "8px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={90}
            />
          </Grid>
          <Grid item xs={2} sx={{ mr: 1 }}>
            <Skeleton
              sx={{ borderRadius: "8px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={90}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={400}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export const PendingDetailsPopupComponent = ({ steps }) => {
  const customization = {
    colors: {
      primaryMain: "#29bf91",
    },
    ...useSelector((state) => state.customization),
  };

  const sortedSteps = steps.sort((a, b) =>
    a.condition === b.condition ? 0 : a.condition ? -1 : 1
  );
  const activeStep = sortedSteps.findIndex((el) => !el.condition);

  return (
    // <Grid display={"flex"} flexDirection="column" spacing={4} sx={{ mt: 2 }}>
    //   {steps.map(
    //     (step, index) =>
    //       !step.condition && (
    //         <Grid key={index}>
    //           <ListItem sx={{ maxHeight: "70px", p: 0 }}>
    //             <ListItemButton sx={{ maxHeight: "70px" }} onClick={step.onClick}>
    //               <Box
    //                 sx={{
    //                   display: "flex",
    //                   alignItems: "center",
    //                 }}
    //               >
    //                 <Box
    //                   sx={{
    //                     display: "flex",
    //                     alignItems: "center",
    //                     justifyContent: "center",
    //                     width: 40,
    //                     height: 40,
    //                     bgcolor: step.condition ? "#29bf91" : "#29bf91", //F7CB73
    //                     borderRadius: "50%",
    //                     color: "white",
    //                     boxShadow: 3,
    //                     p: 1,
    //                     mr: 1,
    //                   }}
    //                 >
    //                   {step.icon}
    //                 </Box>
    //                 <Typography variant="h5">{step.label}</Typography>
    //               </Box>
    //             </ListItemButton>
    //           </ListItem>
    //         </Grid>
    //       )
    //   )}
    // </Grid>
    <ThemeProvider theme={themes(customization)}>
      <Grid sx={{ mt: 2 }}>
        <Stepper orientation="vertical" activeStep={activeStep}>
          {sortedSteps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                sx={{
                  "& .MuiStepIcon-text": {
                    fill: "#ffffff",
                  },
                }}
              >
                {step.label}
              </StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
                <Box sx={{ mb: 2 }}>
                  <div>
                    <CustomButton
                      label={"Continue"}
                      className={"btn--secondary"}
                      onClick={step.onClick}
                    />
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Grid>
    </ThemeProvider>
  );
};

export default CommonDashboardForFrontDeskAndDoctor;
