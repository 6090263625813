import { useState, useEffect } from "react";
import { Box, FormControlLabel, Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import { useNavigate, useLocation } from "react-router";
import * as React from "react";
import {
  userClinicAdminSchema,
  userDoctorSchema,
  userFrontDeskSchema,
  userSchema,
} from "../ValidationSchema/manageUserValidation";
import {
  ACTIVE_STATE,
  CLINIC_ADMIN,
  DATE_FORMAT,
  DOCTOR,
  GENERAL_PRACTITIONER,
  MAX_AGE_FOR_REGUAR_DOB,
  MIN_AGE_FOR_DOCTOR,
  MIN_AGE_FOR_GENERAL_PRACTITIONER_AND_PARAMEDIC,
  PARAMEDIC,
  PATIENT,
  genderList,
  getRoleIdByName,
  FRONT_DESK,
  DATE_FORMAT_DMY,
} from "store/constant";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import Checkbox from "@mui/material/Checkbox";
import { getAllRoles } from "services/roleService";
import {
  createUserProfile,
  deleteUserOrgLink,
  getUserById,
  linkUserWithOrganization,
  updateLinkUserOrg,
  updateUserProfile,
} from "services/userService";
import { accessToken, currentActiveUser } from "store/constant";
import CustomButton from "ui-component/custom-components/CustomButton";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ControlCameraOutlinedIcon from "@mui/icons-material/ControlCameraOutlined";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import SubCard from "ui-component/cards/SubCard";
import { DomainOutlined, FolderSpecialOutlined } from "@mui/icons-material";
import { getDoctorById, updateDoctor } from "services/doctorService";
import { getAllOrganizations } from "services/organizationService";
import { useContext } from "react";
import { getSpecializations } from "services/EntitiesServices";
import { useCallback } from "react";
import { ListItem, ListItemText } from "@mui/material";
import { useRef } from "react";
import Reveal from "views/utilities/Reveal";
import dayjs from "dayjs";

const ManageUser = ({ ...others }) => {
  const isClinicAdmin = currentActiveUser() ? currentActiveUser().roleName === CLINIC_ADMIN : false;

  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const [selectedOrganizationsForClinicAdmin, setSelectedOrganizationsForClinicAdmin] = useState(
    []
  );

  const [copySelectedOrganizations, setCopySelectedOrganizations] = useState([]);
  const [prevSelectedOrg, setPrevSelectedOrg] = useState([]);
  const [isDoctorMap, setIsDoctorMap] = useState({});
  const [isAdminMap, setIsAdminMap] = useState({});
  const [selectedfrontDeskOrganization, setselectedfrontDeskOrganization] = useState(null);
  const { handleClick } = useContext(ToastContext);
  const [roles, setRoles] = useState([]);
  const accessTokenValue = accessToken();

  const formRef = useRef(null);
  const LOWER_BOUND_DATE_FOR_REGUAR_DOB = dayjs().subtract(MAX_AGE_FOR_REGUAR_DOB, "year");
  const MAX_DATE_FOR_DOCTOR = dayjs().subtract(MIN_AGE_FOR_DOCTOR, "year").toDate();
  const MAX_DATE_FOR_GENERAL_PRACTITIONER_AND_PARAMEDIC = dayjs()
    .subtract(MIN_AGE_FOR_GENERAL_PRACTITIONER_AND_PARAMEDIC, "year")
    .toDate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllRoles();
        if (isClinicAdmin) {
          const currFilteredRoles = response.data
            .filter(
              (role) => role.name === CLINIC_ADMIN || role.name === DOCTOR || role.name === PATIENT
            )
            .map((el) => {
              return {
                ...el,
                value: el.id,
                menuLabel: el.name,
              };
            });
          setRoles(currFilteredRoles);
        } else {
          const roles = response.data.map((el) => {
            return {
              ...el,
              value: el?.id,
              menuLabel: el?.name,
            };
          });
          setRoles(roles);
        }
      } catch (error) {
        console.error("Fetch roles failed");
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessTokenValue]);

  const [organizations, setOrganizations] = useState([]);
  const [specializations, setSpecializations] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const orgResponse = await getAllOrganizations();
        orgResponse.data.forEach((el) => {
          el["value"] = el.id;
          el["menuLabel"] = el.name;
        });
        setOrganizations(orgResponse.data);

        const specializationRes = await getSpecializations();
        specializationRes.data.forEach((el) => {
          el["value"] = el.id;
          el["menuLabel"] = el.name;
        });
        setSpecializations(specializationRes.data);
      } catch (error) {
        console.error("Error fetching dropdown data");
      }
    };
    fetchData();
  }, [accessTokenValue, handleClick]);

  const navigate = useNavigate();

  const navigateToUsers = () => {
    navigate("/home/user");
  };

  const { state } = useLocation();
  const userId = state?.userId ? state.userId : null;
  const roleId = state?.roleId ? state.roleId : null;
  const formAccessorRole = state?.roleOfFormAccessor ? state.roleOfFormAccessor : null;
  const [user, setUser] = useState(null);
  const [isClinicAdminSelected, setIsClinicAdminSelected] = useState(false);
  const [isDoctorSelected, setIsDoctorSelected] = useState(false);
  const [isFrontDeskSelected, setIsFrontDeskSelected] = useState(false);
  const [docSpecId, setDocSpecId] = useState("");
  const [isLoading, setisLoading] = useState();

  const currentDate = dayjs();
  const [dobError, setDobError] = useState(userId ? "" : "Date of Birth is required.");

  useEffect(() => {
    if (userId) {
      setisLoading(true);
    } else {
      setisLoading(false);
    }
  }, [userId]);

  const checkClinicAdmin = useCallback(
    (roleId) => {
      return roles.find((r) => r?.id === roleId)?.name === CLINIC_ADMIN;
    },
    [roles]
  );

  const checkIsDoctor = useCallback(
    (roleId) => {
      return roles.find((r) => r?.id === roleId)?.name === DOCTOR;
    },
    [roles]
  );

  const checkFrontDeskUser = useCallback(
    (roleId) => {
      return roles.find((r) => r?.id === roleId)?.name === FRONT_DESK;
    },
    [roles]
  );

  useEffect(() => {
    if (userId && roles.length > 0 && organizations.length > 0) {
      const fetchData = async () => {
        try {
          const response = await getUserById(userId);
          const isClinicAdmin = checkClinicAdmin(response.data.roleId);
          const isDoctor = checkIsDoctor(response.data.roleId);
          const isFrontDeskSelected = checkFrontDeskUser(response.data.roleId);
          setIsClinicAdminSelected(isClinicAdmin);
          setIsDoctorSelected(isDoctor);

          if (isDoctor) {
            const docResponse = await getDoctorById(response?.data?.roleBasedId);
            const doctorMap = {};
            const adminMap = {};
            const selectedOrg = [];

            response.data.userOrganizationAssociationList.forEach((org) => {
              doctorMap[org?.organizationId] = org?.isDoctor;
              adminMap[org?.organizationId] = org?.isAdmin;
              selectedOrg.push({
                ...org,
                orgId: org.organizationId,
                name: organizations.find((el) => el.id === org.organizationId).name,
              });
            });

            setSelectedOrganizations(selectedOrg);
            setPrevSelectedOrg(selectedOrg);
            setCopySelectedOrganizations(selectedOrg.map((el) => el.name));
            setIsDoctorMap(doctorMap);
            setIsAdminMap(adminMap);
            setDocSpecId(docResponse.data.specialization?.id);
          } else if (isFrontDeskSelected) {
            //  because there will be only one organization for front desk
            if (
              response.data.userOrganizationAssociationList &&
              response.data.userOrganizationAssociationList.length > 0
            ) {
              const tempOrg = response.data.userOrganizationAssociationList[0];
              setIsFrontDeskSelected(true);
              setPrevSelectedOrg([
                {
                  ...tempOrg,
                  orgId: tempOrg.organizationId,
                  name: tempOrg.organizationName,
                },
              ]);
              setselectedfrontDeskOrganization(tempOrg.organizationId);
            }
          } else {
            const doctorMap = {};
            const adminMap = {};
            const selectedOrg = [];
            response.data.userOrganizationAssociationList.forEach((org) => {
              doctorMap[org.organizationId] = org.isDoctor;
              adminMap[org.organizationId] = org.isAdmin;
              selectedOrg.push({
                ...org,
                orgId: org.organizationId,
                name: org.organizationName,
              });
            });
            setIsDoctorMap(doctorMap);
            setIsAdminMap(adminMap);
            setSelectedOrganizationsForClinicAdmin(selectedOrg);
            setPrevSelectedOrg(selectedOrg);
            setCopySelectedOrganizations(selectedOrg.map((el) => el.name));
          }
          setUser(response.data);
          setisLoading(false);
        } catch (error) {
          console.error("Fetch user by email failed");
          setisLoading(false);
        }
      };
      fetchData();
    }
  }, [
    accessTokenValue,
    userId,
    roles,
    organizations,
    handleClick,
    checkIsDoctor,
    checkClinicAdmin,
    checkFrontDeskUser,
  ]);

  const [validationErrors, setValidationErrors] = useState({});

  const validateCheckboxes = () => {
    let errors = "";
    selectedOrganizations.forEach((org) => {
      if (!isDoctorMap[org.orgId] && !isAdminMap[org.orgId]) {
        errors = "*At least one role must be selected for each organizaiton";
      }
    });
    setValidationErrors(errors);
    return !Boolean(errors);
  };

  useEffect(() => {
    validateCheckboxes();
  }, [selectedOrganizations, isDoctorMap, isAdminMap]);

  const getOrgCodeNameText = (el) => {
    return `${el.code} - ${el.name}`;
  };

  const handleCheckboxChange = (event, orgId) => {
    const { checked } = event.target;
    const newIsDoctorMap = { ...isDoctorMap };
    const newIsAdminMap = { ...isAdminMap };

    if (event.target.name === "isDoctor") {
      newIsDoctorMap[orgId] = checked;
    } else {
      newIsAdminMap[orgId] = checked;
    }

    setIsDoctorMap(newIsDoctorMap);
    setIsAdminMap(newIsAdminMap);
  };

  const handleDeleteOrganization = async (org, values, setFieldValue) => {
    setFieldValue(
      "doctorOrganizationAssociation",
      values.doctorOrganizationAssociation.filter((prev) => prev !== org.name)
    );
    if (org.id) {
      setSelectedOrganizations((prevOrgs) => prevOrgs.filter((prevOrg) => prevOrg?.id !== org.id)); // Remove from list with ID
    } else {
      setSelectedOrganizations((prevOrgs) => prevOrgs.filter((prevOrg) => prevOrg !== org)); // Remove from list without ID
    }
  };

  return (
    <Reveal style={{ display: "flex" }}>
      {!isLoading && (
        <Formik
          innerRef={formRef}
          enableReinitialize={true}
          initialValues={{
            firstName: user?.firstName ? user.firstName : "",
            middleName: user?.middleName ? user.middleName : "",
            lastName: user?.lastName ? user.lastName : "",
            mobileNumber: user?.mobileNumber ? user.mobileNumber : "",
            role: user?.roleId ? user.roleId : roleId ? roleId : "",
            doctorOrganizationAssociation: copySelectedOrganizations.slice(),
            adminOrganizationAssociation: copySelectedOrganizations.slice(),
            frontDeskOrganizationAssociation: selectedfrontDeskOrganization,
            specialization: docSpecId ? docSpecId : "",
            email: user?.emailId ? user.emailId : "",
            gender: user?.gender ? user.gender : "",
            status: user?.status ? user.status : ACTIVE_STATE,
            dateOfBirth: user?.dateOfBirth ? dayjs(user.dateOfBirth) : null,
            submit: "",
          }}
          validationSchema={() => {
            if (formRef.current.values.role === roles.find((role) => role.name === DOCTOR)?.id) {
              return userDoctorSchema;
            } else if (
              formRef.current.values.role === roles.find((role) => role.name === CLINIC_ADMIN)?.id
            ) {
              return userClinicAdminSchema;
            } else if (
              formRef.current.values.role === roles.find((role) => role.name === FRONT_DESK)?.id
            ) {
              return userFrontDeskSchema;
            } else {
              return userSchema;
            }
          }}
          onSubmit={async (values) => {
            let isValidated = true;
            if (isDoctorSelected) {
              isValidated = validateCheckboxes();
            }
            if (!dobError && isValidated) {
              const data = {
                firstName: values.firstName,
                middleName: values.middleName,
                lastName: values.lastName,
                roleId: values.role,
                roleName: roles.find((el) => el.id === values.role).name,
                mobileNumber: values.mobileNumber,
                emailId: values?.email ? values.email : null,
                gender: values?.gender,
                status: values.status,
                dateOfBirth: values.dateOfBirth.format(DATE_FORMAT),
              };
              try {
                if (userId) {
                  // for update
                  data["id"] = user.id;
                  const response = await updateUserProfile(user.id, data);
                  const isClinicAdminSelected = checkClinicAdmin(values.role);
                  if (isClinicAdminSelected) {
                    selectedOrganizationsForClinicAdmin.forEach(async (org) => {
                      const payload = {
                        ...org,
                        mobileNumber: values.mobileNumber,
                      };
                      if (!prevSelectedOrg.map((el) => el.orgId).includes(org.orgId)) {
                        await linkUserWithOrganization(payload);
                      }
                    });

                    prevSelectedOrg.forEach(async (org) => {
                      if (
                        !selectedOrganizationsForClinicAdmin
                          .map((el) => el.orgId)
                          .includes(org.orgId)
                      ) {
                        await deleteUserOrgLink(org.id);
                      }
                    });
                  } else if (isDoctorSelected) {
                    if (values.specialization !== docSpecId) {
                      let payload = {
                        specialization: specializations.find(
                          (el) => el.id === values.specialization
                        ),
                      };
                      await updateDoctor(response.data.roleBasedId, payload);
                    }
                    selectedOrganizations.forEach(async (org) => {
                      if (prevSelectedOrg.map((el) => el.orgId).includes(org.orgId)) {
                        const linkDataFromDB = prevSelectedOrg.find((o) => o.orgId === org.orgId);
                        const payloadOfUpdatedLink = {
                          ...linkDataFromDB,
                          isDoctor: isDoctorMap[org.orgId],
                          isAdmin: isAdminMap[org.orgId],
                        };
                        if (
                          linkDataFromDB.isDoctor !== payloadOfUpdatedLink.isDoctor ||
                          linkDataFromDB.isAdmin !== payloadOfUpdatedLink.isAdmin
                        ) {
                          await updateLinkUserOrg(payloadOfUpdatedLink.id, payloadOfUpdatedLink);
                        }
                      } else {
                        const payload = {
                          ...org,
                          mobileNumber: values.mobileNumber,
                          isDoctor: isDoctorMap[org.orgId] ? isDoctorMap[org.orgId] : org.isDoctor,
                          isAdmin: isAdminMap[org.orgId] ? isAdminMap[org.orgId] : org.isDoctor,
                        };
                        await linkUserWithOrganization(payload);
                      }
                    });

                    prevSelectedOrg.forEach(async (org) => {
                      if (!selectedOrganizations.map((el) => el.orgId).includes(org.orgId)) {
                        await deleteUserOrgLink(org.id);
                      }
                    });
                  } else if (isFrontDeskSelected) {
                    // because for front desk user there will be only one previous selected organization
                    if (
                      prevSelectedOrg.length > 0 &&
                      prevSelectedOrg[0].id !== values.frontDeskOrganizationAssociation
                    ) {
                      await deleteUserOrgLink(prevSelectedOrg[0].id);
                      const payload = {
                        orgId: values.frontDeskOrganizationAssociation,
                        mobileNumber: values.mobileNumber,
                        isDoctor: false,
                        isAdmin: false,
                      };
                      await linkUserWithOrganization(payload);
                    }
                  }
                } else {
                  const response = await createUserProfile(data);
                  const isClinicAdminSelected = checkClinicAdmin(values.role);
                  if (isClinicAdminSelected) {
                    selectedOrganizationsForClinicAdmin.forEach(async (el) => {
                      const payload = {
                        ...el,
                        mobileNumber: values.mobileNumber,
                      };
                      await linkUserWithOrganization(payload);
                    });
                  } else if (isDoctorSelected) {
                    let payload = {
                      specialization: specializations.find((el) => el.id === values.specialization),
                    };
                    await updateDoctor(response.data.roleBasedId, payload);

                    selectedOrganizations.forEach(async (el) => {
                      const payload = {
                        orgId: el.orgId,
                        mobileNumber: values.mobileNumber,
                        isDoctor: isDoctorMap[el.orgId] ? isDoctorMap[el.orgId] : el.isDoctor,
                        isAdmin: isAdminMap[el.orgId] ? isAdminMap[el.orgId] : el.isAdmin,
                      };
                      await linkUserWithOrganization(payload);
                    });
                  } else if (isFrontDeskSelected) {
                    const payload = {
                      orgId: values.frontDeskOrganizationAssociation,
                      mobileNumber: values.mobileNumber,
                      isDoctor: false,
                      isAdmin: false,
                    };
                    await linkUserWithOrganization(payload);
                  }
                }
                handleClick(
                  "success",
                  userId
                    ? "User has been successfully updated."
                    : "User has been successfully added."
                );
                setTimeout(() => {
                  navigateToUsers();
                }, 1000);
              } catch (error) {
                handleClick("error", error?.response?.data?.message);
              }
            }
          }}
        >
          {({ values, errors, touched, setFieldValue, handleBlur, handleChange, handleSubmit }) => (
            <form noValidate onSubmit={handleSubmit} {...others}>
              <Typography
                sx={{
                  fontSize: "21px",
                  fontWeight: 600,
                  color: "#004C70",
                  mb: 2,
                }}
              >
                {userId ? "User Update" : "User Registration"}
              </Typography>
              <SubCard style={{ padding: "15px" }}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 500,
                    color: "#000000",
                    mb: 1.5,
                  }}
                >
                  Basic details
                </Typography>
                <FormInputField
                  style={{
                    width: "30%",
                    marginRight: "30px",
                    marginBottom: "25px",
                  }}
                  label="First Name"
                  name="firstName"
                  value={values.firstName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  error={Boolean(touched.firstName && errors.firstName)}
                  errorText={errors.firstName}
                  startAdornment={<PersonOutlineOutlinedIcon />}
                  size={"big"}
                />

                <FormInputField
                  style={{
                    width: "30%",
                    marginRight: "30px",
                    marginBottom: "25px",
                  }}
                  label="Middle Name"
                  name="middleName"
                  value={values.middleName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.middleName && errors.middleName)}
                  errorText={errors.middleName}
                  startAdornment={<PersonOutlineOutlinedIcon />}
                  size={"big"}
                />

                <FormInputField
                  style={{
                    width: "30%",
                    marginRight: "30px",
                    marginBottom: "25px",
                  }}
                  label="Last Name"
                  name="lastName"
                  required
                  value={values.lastName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.lastName && errors.lastName)}
                  errorText={errors.lastName}
                  startAdornment={<PersonOutlineOutlinedIcon />}
                  size={"big"}
                />

                <FormSelectField
                  style={{
                    width: "30%",
                    marginBottom: "25px",
                    marginRight: "30px",
                  }}
                  label="Gender"
                  name="gender"
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.gender && errors.gender)}
                  errorText={errors.gender}
                  startAdornment={<PersonOutlineOutlinedIcon />}
                  menuItems={genderList}
                  value={values.gender}
                  size={"big"}
                ></FormSelectField>

                {roles.length > 0 && (
                  <FormSelectField
                    style={{
                      width: "30%",
                      marginBottom: "25px",
                      marginRight: "30px",
                    }}
                    label="Role"
                    name="role"
                    required
                    disabled={formAccessorRole === DOCTOR || userId ? true : undefined}
                    onBlur={handleBlur}
                    onChange={async (e) => {
                      setIsClinicAdminSelected(checkClinicAdmin(e.target.value));
                      setIsDoctorSelected(checkIsDoctor(e.target.value));
                      setIsFrontDeskSelected(checkFrontDeskUser(e.target.value));
                      setFieldValue("role", e.target.value);
                      if (values.dateOfBirth) {
                        if (
                          e.target.value ===
                            (await getRoleIdByName(GENERAL_PRACTITIONER, handleClick)) ||
                          e.target.value === (await getRoleIdByName(PARAMEDIC, handleClick))
                        ) {
                          if (
                            values.dateOfBirth > MAX_DATE_FOR_GENERAL_PRACTITIONER_AND_PARAMEDIC
                          ) {
                            setDobError(
                              `Age must be at least ${currentDate.diff(
                                MAX_DATE_FOR_GENERAL_PRACTITIONER_AND_PARAMEDIC,
                                "year"
                              )} years.`
                            );
                          } else {
                            setDobError("");
                          }
                        } else if (
                          e.target.value === (await getRoleIdByName(DOCTOR, handleClick))
                        ) {
                          if (values.dateOfBirth > MAX_DATE_FOR_DOCTOR) {
                            setDobError(
                              `Age must be at least ${currentDate.diff(
                                MAX_DATE_FOR_DOCTOR,
                                "year"
                              )} years.`
                            );
                          } else {
                            setDobError("");
                          }
                        }
                      }
                      // setFieldValue('isClinicAdminSelected', isClinicAdminSelected);
                    }}
                    startAdornment={<ControlCameraOutlinedIcon />}
                    menuItems={roles}
                    error={Boolean(touched.role && errors.role)}
                    errorText={errors.role}
                    value={values.role}
                    size={"big"}
                  ></FormSelectField>
                )}

                {specializations.length > 0 && isDoctorSelected && (
                  <FormSelectField
                    style={{
                      width: "30%",
                      marginBottom: "25px",
                      marginRight: "30px",
                    }}
                    label="Specialization"
                    name="specialization"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    startAdornment={<FolderSpecialOutlined />}
                    menuItems={specializations}
                    error={Boolean(touched.specialization && errors.specialization)}
                    errorText={errors.specialization}
                    required
                    value={values.specialization}
                    size={"big"}
                  ></FormSelectField>
                )}

                <FormInputField
                  style={{
                    width: "30%",
                    marginRight: "30px",
                    marginBottom: "25px",
                  }}
                  label="Mobile Number"
                  type={"tel"}
                  name="mobileNumber"
                  disabled={Boolean(userId)}
                  required
                  value={values.mobileNumber}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 10,
                  }}
                  error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                  errorText={errors.mobileNumber}
                  startAdornment={<i className="ri-phone-line ri-xl" />}
                  size={"big"}
                />
                {(isDoctorSelected || isClinicAdminSelected || isFrontDeskSelected) && (
                  <>
                    {organizations && isDoctorSelected && (
                      <FormSelectField
                        style={{
                          width: "30%",
                          marginBottom: "25px",
                          marginRight: "30px",
                        }}
                        label={"Organization"}
                        name={"doctorOrganizationAssociation"}
                        onBlur={handleBlur}
                        required
                        value={values.doctorOrganizationAssociation}
                        error={Boolean(
                          errors.doctorOrganizationAssociation &&
                            touched.doctorOrganizationAssociation
                        )}
                        errorText={errors.doctorOrganizationAssociation}
                        size={"big"}
                        multiple
                        renderValue={(selected) => {
                          return selected.map((el) => (el?.name ? el.name : el)).join(", ");
                        }}
                        startAdornment={<DomainOutlined />}
                        onChange={(e) => {
                          let orgList = [];
                          e.target.value.forEach((org) => {
                            const orgId = organizations.find(
                              (o) => o.name === (org?.name ? org.name : org)
                            ).id;
                            orgList.push({
                              orgId: orgId,
                              name: org?.name ? org.name : org,
                              isDoctor: false,
                              isAdmin: false,
                            });
                          });
                          setSelectedOrganizations(orgList);
                          const {
                            target: { value },
                          } = e;
                          const data = typeof value === "string" ? value.split(",") : value;
                          setFieldValue("doctorOrganizationAssociation", data);
                        }}
                        menuItems={organizations.map((el) => {
                          return {
                            ...el,
                            value: el?.name,
                            menuLabel: getOrgCodeNameText(el),
                          };
                        })}
                      ></FormSelectField>
                    )}
                    {organizations && isClinicAdminSelected && (
                      <FormSelectField
                        style={{
                          width: "30%",
                          marginBottom: "25px",
                          marginRight: "30px",
                        }}
                        label={"Organization"}
                        name={"adminOrganizationAssociation"}
                        onBlur={handleBlur}
                        required
                        value={values.adminOrganizationAssociation}
                        error={Boolean(
                          errors.adminOrganizationAssociation &&
                            touched.adminOrganizationAssociation
                        )}
                        errorText={errors.adminOrganizationAssociation}
                        size={"big"}
                        multiple
                        renderValue={(selected) => {
                          return selected.map((el) => (el?.name ? el.name : el)).join(", ");
                        }}
                        startAdornment={<DomainOutlined />}
                        onChange={(e) => {
                          let orgList = [];
                          e.target.value.forEach((org) => {
                            const orgId = organizations.find(
                              (o) => o.name === (org?.name ? org.name : org)
                            ).id;
                            orgList.push({
                              orgId: orgId,
                              name: org?.name ? org.name : org,
                              isDoctor: false,
                              isAdmin: true,
                            });
                          });
                          setSelectedOrganizationsForClinicAdmin(orgList);
                          const {
                            target: { value },
                          } = e;
                          const data = typeof value === "string" ? value.split(",") : value;
                          setFieldValue("adminOrganizationAssociation", data);
                        }}
                        menuItems={organizations.map((el) => {
                          return {
                            ...el,
                            value: el?.name,
                            menuLabel: getOrgCodeNameText(el),
                          };
                        })}
                      ></FormSelectField>
                    )}
                    {organizations && isFrontDeskSelected && (
                      <FormSelectField
                        style={{
                          width: "30%",
                          marginBottom: "25px",
                          marginRight: "30px",
                        }}
                        label="Organization"
                        name="frontDeskOrganizationAssociation"
                        required
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("frontDeskOrganizationAssociation", e.target.value);
                        }}
                        startAdornment={<DomainOutlined />}
                        menuItems={organizations}
                        error={Boolean(
                          touched.frontDeskOrganizationAssociation &&
                            errors.frontDeskOrganizationAssociation
                        )}
                        errorText={errors.frontDeskOrganizationAssociation}
                        value={values.frontDeskOrganizationAssociation}
                        size={"big"}
                      ></FormSelectField>
                    )}
                  </>
                )}
                <FormDatePicker
                  inputFormat={DATE_FORMAT_DMY}
                  disableFuture
                  label={"Birth date"}
                  value={values.dateOfBirth}
                  minDate={LOWER_BOUND_DATE_FOR_REGUAR_DOB}
                  openTo={"year"}
                  size={"big"}
                  onChange={async (date) => {
                    setFieldValue("dateOfBirth", date);
                    if (date === null) {
                      setDobError("Please select a valid 'Date of birth' value.");
                    } else if (!date.isValid()) {
                      setDobError("Please select a valid 'Date of birth' value.");
                    } else if (date > currentDate) {
                      setDobError("'Date of birth' can't be a future date.");
                    } else if (date < LOWER_BOUND_DATE_FOR_REGUAR_DOB) {
                      setDobError(
                        `'Date of birth' can't be less than the year ${LOWER_BOUND_DATE_FOR_REGUAR_DOB.year()}.`
                      );
                    } else if (
                      (values.role === (await getRoleIdByName(GENERAL_PRACTITIONER, handleClick)) ||
                        values.role === (await getRoleIdByName(PARAMEDIC, handleClick))) &&
                      date > MAX_DATE_FOR_GENERAL_PRACTITIONER_AND_PARAMEDIC
                    ) {
                      setDobError(
                        `Age must be at least ${currentDate.diff(
                          MAX_DATE_FOR_GENERAL_PRACTITIONER_AND_PARAMEDIC,
                          "year"
                        )} years.`
                      );
                    } else if (
                      values.role === (await getRoleIdByName(DOCTOR, handleClick)) &&
                      date > MAX_DATE_FOR_DOCTOR
                    ) {
                      setDobError(
                        `Age must be at least ${currentDate.diff(
                          MAX_DATE_FOR_DOCTOR,
                          "year"
                        )} years.`
                      );
                    } else {
                      setDobError("");
                    }
                  }}
                  required
                  style={{
                    width: "30%",
                    marginBottom: "25px",
                    marginRight: "30px",
                  }}
                  error={Boolean(touched.dateOfBirth && dobError)}
                  errorText={dobError}
                ></FormDatePicker>

                <FormInputField
                  style={{
                    width: "30%",
                    marginRight: "30px",
                    marginBottom: "25px",
                  }}
                  label="Email"
                  name="email"
                  required
                  disabled={Boolean(userId)}
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.email && errors.email)}
                  errorText={errors.email}
                  startAdornment={<i className="ri-mail-line ri-xl" />}
                  size={"big"}
                />

                {isDoctorSelected && touched.doctorOrganizationAssociation && validationErrors && (
                  <Grid display={"flex"} justifyContent={"end"}>
                    <Typography color="error" variant="body2">
                      {validationErrors}
                    </Typography>
                  </Grid>
                )}
                {isDoctorSelected && (
                  <Box sx={{ mt: 2, width: "100%", bgcolor: "#F4F8FE" }}>
                    {selectedOrganizations.map((org) => (
                      <ListItem key={org.orgId}>
                        <ListItemText
                          primary={getOrgCodeNameText(
                            organizations.find((el) => el.id === org.orgId)
                          )}
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isDoctorMap[org.orgId] || false}
                              name="isDoctor"
                              onChange={(event) => handleCheckboxChange(event, org.orgId)}
                            />
                          }
                          label="As Doctor"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isAdminMap[org.orgId] || false}
                              name="isAdmin"
                              onChange={(event) => handleCheckboxChange(event, org.orgId)}
                            />
                          }
                          label="As Admin"
                        />

                        <CustomButton
                          iconButton={<i className="ri-delete-bin-fill ri-xl icon-primary-red" />}
                          onClick={() => handleDeleteOrganization(org, values, setFieldValue)}
                          aria-label="settings"
                        />
                      </ListItem>
                    ))}
                  </Box>
                )}
              </SubCard>

              <Box sx={{ mt: 4 }} display="flex">
                <CustomButton
                  className="btn--secondary"
                  style={{ marginRight: "20px" }}
                  type="submit"
                  label={"Save"}
                ></CustomButton>
                <CustomButton
                  className="btn--error"
                  onClick={() => {
                    navigateToUsers();
                  }}
                  label="Cancel"
                />
              </Box>
            </form>
          )}
        </Formik>
      )}
    </Reveal>
  );
};

export default ManageUser;
