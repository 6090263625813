import {
  AlternateEmailOutlined,
  ArrowCircleRightOutlined,
  ContentCopyOutlined,
  CreditCardOutlined,
  CurrencyRupeeOutlined,
  DoneAllOutlined,
  KeyboardArrowLeftOutlined,
  PaymentsOutlined,
  PrintOutlined,
  RestartAltOutlined,
  ShareOutlined,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { getDoctorById } from "services/doctorService";
import { getPatientData } from "services/patientService";
import ModalUI from "ui-component/ModalUI";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { useContext } from "react";
import { getBillingInvoiceById, getInvoicePDFById } from "services/BillingService";
import Reveal from "views/utilities/Reveal";
import dayjs from "dayjs";
import { DOCTOR, currentActiveUser } from "store/constant";
import { getUserOrgId } from "store/Reducers/userReducer";
import { useSelector } from "react-redux";
import { getAmenitiesByOrgId } from "services/organizationService";

const columns = [
  { field: "service.name", label: "Description", align: "left" },
  { field: "service.price", label: "Fees", align: "center" },
];

const Payment = ({ ...others }) => {
  const { state } = useLocation();
  const invoiceId = state ? state.invoiceId : "";
  const appointmentId = state ? state.appointmentId : "";
  const [patientDetails, setPatientDetails] = useState(null);
  const [doctorDetails, setDoctorDetails] = useState(null);
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const userOrgId = useSelector(getUserOrgId);
  const loggedInUserRole = currentActiveUser()?.roleName;
  const [paymentMapper, setpaymentMapper] = useState({
    "Debit/Credit Cards": false,
    Cash: false,
    UPI: false,
  });
  const navigate = useNavigate();
  const { handleClick } = useContext(ToastContext);

  const [paymentMode, setPaymentMode] = useState("");
  const [paymentDone, setPaymentDone] = useState(false);

  const [openMoveToFrontDeskModal, setOpenMoveToFrontDeskModal] = useState(false);
  const closeMoveToFrontDeskModal = () => {
    setOpenMoveToFrontDeskModal(false);
  };

  const [openUpiPaymentModal, setOpenUpiPaymentModal] = useState(false);
  const closeUpiPaymentModal = () => {
    setOpenUpiPaymentModal(false);
  };

  const markAsPaid = () => {
    setPaymentDone(true);
    generatePDF();
    handleClick("success", "Payment has been successfully received.");
  };

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        if (userOrgId) {
          const amenitiesResponse = await getAmenitiesByOrgId(userOrgId);
          const acceptedPayments = amenitiesResponse.data.filter(
            (c) => c.category === "Payment Mode Accepted"
          );
          if (acceptedPayments.length > 0) {
            acceptedPayments.forEach((element) => {
              paymentMapper[element.name] = true;
            });
            setpaymentMapper(paymentMapper);
          }
        }
        const invoiceResponse = await getBillingInvoiceById(invoiceId);
        setInvoiceDetails(invoiceResponse.data);

        const patientResponse = await getPatientData(invoiceResponse.data.patientId);
        setPatientDetails(patientResponse.data);

        const doctorResponse = await getDoctorById(invoiceResponse.data.doctorId);
        setDoctorDetails(doctorResponse.data);
      } catch (error) {
        console.error("Error fetching invoice details");
      }
    };
    fetchInvoice();
  }, []);

  const generatePDF = () => {
    const fetchInvoice = async () => {
      try {
        // toDo need to check once the pr is merged
        const pdfRes = await getInvoicePDFById(invoiceId, null);
        const blob = new Blob([pdfRes.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "invoice.pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        handleClick("error", "There seems to be an error generating invoice PDF");
      }
    };
    fetchInvoice();
  };

  return (
    <Reveal>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "21px",
            fontWeight: 600,
            color: "#004C70",
          }}
        >
          Payment
        </Typography>
        <CustomButton
          iconButton={<PrintOutlined />}
          style={{ marginLeft: "auto" }}
          className={"btn--secondary-light"}
          onClick={generatePDF}
        />
        <CustomButton
          iconButton={<ShareOutlined />}
          style={{ marginLeft: "10px" }}
          className={"btn--secondary-light"}
        />
        {loggedInUserRole === DOCTOR && (
          <CustomButton
            style={{ marginLeft: "10px" }}
            className={"btn--secondary"}
            label={"Move to Front desk"}
            startIcon={<ContentCopyOutlined />}
            onClick={() => setOpenMoveToFrontDeskModal(true)}
          />
        )}
        {loggedInUserRole === DOCTOR && (
          <CustomButton
            style={{ marginLeft: "10px" }}
            className={"btn--secondary"}
            label={"Edit prescription"}
            startIcon={<i className="ri-edit-line ri-xl icon-primary-white" />}
            onClick={() => {
              navigate(`/home/prescriptionPad/${appointmentId}`);
            }}
          />
        )}
        <CustomButton
          onClick={() => {
            navigate("/home/dashboard");
          }}
          style={{ marginLeft: "10px" }}
          className={"btn--primary"}
          label={"Save and Exit"}
          startIcon={<DoneAllOutlined />}
        />
      </Grid>
      {invoiceDetails && patientDetails && doctorDetails && (
        <Grid display={"flex"} justifyContent={"space-between"}>
          <Grid
            sx={{
              borderRadius: "10px",
              background: "#ffffff",
              marginTop: "25px",
              width: "63%",
              // height: '88.5vh',
              height: "100%",
              padding: "30px 12px 30px 12px",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "#000000",
                }}
              >
                INVOICE
              </Typography>
            </Grid>
            <Divider sx={{ marginTop: "10px", borderWidth: "1px" }} />

            <Grid
              sx={{
                display: "flex",
                padding: "15px 0px",
                justifyContent: "space-between",
              }}
            >
              <Grid>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 500,
                    color: "#000000",
                  }}
                >
                  Patient Details
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#45483C",
                  }}
                >
                  {patientDetails.user.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#45483C",
                  }}
                >
                  {patientDetails.user.mobileNumber}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#45483C",
                  }}
                >
                  Email: {patientDetails.user.emailId}
                </Typography>
              </Grid>
              <Grid sx={{ paddingRight: "50px" }}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 500,
                    color: "#000000",
                  }}
                >
                  Clinic/Doctor's Details
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#45483C",
                  }}
                >
                  Dr.{doctorDetails.userResponse.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#45483C",
                  }}
                >
                  {doctorDetails.specialization?.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#45483C",
                  }}
                >
                  {doctorDetails.userResponse.mobileNumber}
                </Typography>
              </Grid>
            </Grid>

            <Divider sx={{ borderWidth: "1px" }} />

            <Grid
              sx={{
                display: "flex",
                padding: "15px 0px",
                justifyContent: "space-between",
              }}
            >
              <Grid>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 400,
                    color: "#000000",
                  }}
                >
                  Invoice Number
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "#000000",
                  }}
                >
                  {invoiceDetails.id}
                </Typography>
              </Grid>

              <Grid>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 400,
                    color: "#000000",
                  }}
                >
                  Date
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "#000000",
                  }}
                >
                  {dayjs(invoiceDetails.issueDate).format("MMMM D, YYYY")}
                </Typography>
              </Grid>
            </Grid>

            <Divider sx={{ borderWidth: "1px" }} />

            <Grid sx={{ padding: "15px 0px" }}>
              <CustomizedTable
                columns={columns}
                tableData={invoiceDetails.invoiceItems}
                rowsPerPageInTable={15}
                noPagination={true}
              />
              {/* <Grid sx={{display: 'flex', justifyContent: 'center'}}>
                                <CustomButton 
                                    style={{borderRadius: '20px'}}
                                    iconButton={<AddCircleOutlineOutlined />}
                                />
                            </Grid> */}
            </Grid>

            <Divider sx={{ borderWidth: "1px" }} />

            <Grid
              sx={{
                padding: "15px 0px",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#000000",
                  marginRight: "25px",
                }}
              >
                Total:
              </Typography>
              <CurrencyRupeeOutlined fontSize="small" sx={{ color: "#000000" }} />
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#000000",
                }}
              >
                {invoiceDetails.adjustmentResponses.length > 0 &&
                invoiceDetails.adjustmentResponses[0].amount
                  ? invoiceDetails.totalAmount - invoiceDetails.adjustmentResponses[0].amount
                  : invoiceDetails.totalAmount}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            sx={{
              borderRadius: "10px",
              background: "#ffffff",
              marginTop: "25px",
              width: "35%",
              padding: "20px 12px 30px 12px",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                alignItems: "end",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "#004c70",
                }}
              >
                Payment
              </Typography>
              <CustomButton
                style={{
                  marginLeft: "auto",
                  padding: "1px",
                  alignItems: "end",
                }}
                iconButton={<RestartAltOutlined />}
              />
              <CustomButton
                style={{
                  marginLeft: "10px",
                  padding: "2px 1px 0px 1px",
                  alignItems: "end",
                }}
                iconButton={<i className="ri-more-2-fill" />}
              />
            </Grid>

            <Divider sx={{ marginTop: "8px", borderWidth: "1px" }} />

            <Grid>
              <FormControl sx={{ marginTop: "13px" }}>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  sx={{
                    color: "#000000",
                    fontWeight: 500,
                    marginBottom: "10px",
                  }}
                >
                  Mode Of Payment
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={paymentMode}
                  onChange={(e) => {
                    setPaymentMode(e.target.value);
                  }}
                >
                  {paymentMapper["Debit/Credit Cards"] === true && (
                    <>
                      <FormControlLabel
                        value="Card"
                        control={<Radio size="small" sx={{ padding: "3px 5px 3px 9px" }} />}
                        label={
                          <Grid display={"flex"}>
                            <CreditCardOutlined htmlColor="#000000" />
                            <Typography
                              sx={{
                                fontSize: "15px",
                                fontWeight: 450,
                                color: "#45483C",
                                paddingTop: "2px",
                                marginLeft: "7px",
                              }}
                            >
                              Card
                            </Typography>
                          </Grid>
                        }
                      />
                    </>
                  )}
                  {paymentMapper["Cash"] === true && (
                    <>
                      <FormControlLabel
                        value="Cash"
                        control={<Radio size="small" sx={{ padding: "3px 5px 3px 9px" }} />}
                        label={
                          <Grid display={"flex"}>
                            <img
                              src="/images/cash_payment.svg"
                              alt=""
                              style={{
                                width: "25px",
                                height: "25px",
                                marginTop: "2px",
                                marginLeft: "1px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "15px",
                                fontWeight: 450,
                                color: "#45483C",
                                paddingTop: "2px",
                                marginLeft: "6px",
                              }}
                            >
                              Cash
                            </Typography>
                          </Grid>
                        }
                      />
                    </>
                  )}
                  {paymentMapper["UPI"] === true && (
                    <>
                      <FormControlLabel
                        value="UPI"
                        control={<Radio size="small" sx={{ padding: "3px 5px 3px 9px" }} />}
                        label={
                          <Grid display={"flex"}>
                            <img
                              src="/images/upi_logo.svg"
                              alt=""
                              style={{
                                width: "25px",
                                height: "20px",
                                marginTop: "2px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "15px",
                                fontWeight: 450,
                                color: "#45483C",
                                paddingTop: "2px",
                                marginLeft: "6px",
                              }}
                            >
                              UPI
                            </Typography>
                          </Grid>
                        }
                      />
                    </>
                  )}
                </RadioGroup>
              </FormControl>
            </Grid>

            {!paymentDone && (
              <CustomButton
                onClick={() => {
                  if (paymentMode === "UPI") {
                    setOpenUpiPaymentModal(true);
                  } else {
                    markAsPaid();
                  }
                }}
                style={{ marginTop: "15px" }}
                disabled={paymentMode ? undefined : true}
                label={paymentMode === "UPI" ? "Proceed" : "Mark as Paid"}
                className={"btn--secondary"}
                startIcon={
                  paymentMode === "UPI" ? <ArrowCircleRightOutlined /> : <DoneAllOutlined />
                }
              />
            )}

            {paymentDone && (
              <>
                <Divider sx={{ marginTop: "10px", borderWidth: "1px" }} />

                <Grid sx={{ paddingTop: "12px" }}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#000000",
                    }}
                  >
                    {" "}
                    Transaction
                  </Typography>
                </Grid>

                <Grid
                  sx={{
                    border: "0.5px dashed",
                    display: "flex",
                    borderRadius: "10px",
                    padding: "10px",
                    marginTop: "10px",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="/images/transaction_logo.svg"
                    alt=""
                    style={{
                      width: "35px",
                      height: "35px",
                      marginTop: "2px",
                    }}
                  />
                  <Grid sx={{ marginLeft: "8px" }}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "#29bf91",
                        marginBottom: "3px",
                      }}
                    >
                      {" "}
                      {invoiceDetails.adjustmentResponses.length > 0 &&
                      invoiceDetails.adjustmentResponses[0].amount
                        ? invoiceDetails.totalAmount - invoiceDetails.adjustmentResponses[0].amount
                        : invoiceDetails.totalAmount}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#194B5A",
                      }}
                    >
                      {" "}
                      {patientDetails.user.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#616B67",
                      }}
                    >
                      {" "}
                      {paymentMode} Payment
                    </Typography>
                  </Grid>

                  <Grid sx={{ marginLeft: "auto" }}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#616B67",
                      }}
                    >
                      {" "}
                      21 Jan 2024 | 8:45 AM
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      )}
      <ModalUI
        visible={openMoveToFrontDeskModal}
        close={closeMoveToFrontDeskModal}
        title={"Confirmation"}
        component={
          <MoveToFrontDeskModal
            handleClick={handleClick}
            close={closeMoveToFrontDeskModal}
            services={invoiceDetails?.invoiceItems}
            total={
              invoiceDetails?.adjustmentAmount
                ? invoiceDetails?.totalAmount - invoiceDetails?.adjustmentAmount
                : invoiceDetails?.totalAmount
            }
          />
        }
      />

      <ModalUI
        visible={openUpiPaymentModal}
        close={closeUpiPaymentModal}
        title={"UPI Payment"}
        component={
          <UpiPaymentModal
            handleClick={handleClick}
            close={closeUpiPaymentModal}
            total={
              invoiceDetails?.adjustmentAmount
                ? invoiceDetails?.totalAmount - invoiceDetails?.adjustmentAmount
                : invoiceDetails?.totalAmount
            }
            setPaymentDone={setPaymentDone}
          />
        }
      />
    </Reveal>
  );
};

const MoveToFrontDeskModal = ({ close, handleClick, services, total }) => {
  return (
    <Box sx={{ width: "650px", paddingTop: "10px" }}>
      <Typography
        sx={{
          fontSize: "15px",
          fontWeight: 430,
          color: "#45483C",
          paddingBottom: "10px",
        }}
      >
        {" "}
        Note: Please verify the billing details before processing
      </Typography>
      <CustomizedTable
        columns={columns}
        tableData={services}
        rowsPerPageInTable={15}
        noPagination={true}
      />
      <Divider sx={{ marginTop: "8px", borderWidth: "1px" }} />
      <Grid sx={{ padding: "5px 0px", display: "flex", justifyContent: "end" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#000000",
            marginRight: "25px",
          }}
        >
          Total:
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#000000",
          }}
        >
          {total}
        </Typography>
      </Grid>
      <Divider sx={{ borderWidth: "1px" }} />
      <Grid sx={{ paddingTop: "30px", display: "flex", justifyContent: "end" }}>
        <CustomButton
          label={"Move to Front Desk"}
          className={"btn--primary"}
          startIcon={<ContentCopyOutlined />}
        />
      </Grid>
    </Box>
  );
};

const UpiPaymentModal = ({ close, total, handleClick, setPaymentDone }) => {
  const [upiId, setUpiId] = useState("");

  return (
    <Box sx={{ width: "650px", paddingTop: "15px" }}>
      <Typography
        sx={{
          fontSize: "15px",
          fontWeight: 430,
          color: "#45483C",
          paddingBottom: "10px",
        }}
      >
        {" "}
        Note: Please verify the billing details before processing
      </Typography>
      <Divider sx={{ marginTop: "8px", borderWidth: "1px" }} />
      <Grid
        sx={{
          padding: "5px 0px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#000000",
            marginRight: "25px",
          }}
        >
          Total:
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#000000",
          }}
        >
          {total}
        </Typography>
      </Grid>
      <Divider sx={{ borderWidth: "1px" }} />

      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: 500,
          color: "#616B67",
          marginTop: "25px",
        }}
      >
        Please enter UPI Id:
      </Typography>

      <Grid
        sx={{
          paddingTop: "5px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FormInputField
          style={{ width: "60%" }}
          label="Enter UPI id"
          value={upiId}
          onChange={(e) => {
            setUpiId(e.target.value);
          }}
          startAdornment={<AlternateEmailOutlined />}
          endAdorment={
            <>
              <CustomButton
                label={"verify"}
                textAndIconColor={"#004c70"}
                customBackGroundColor={"transparent"}
              />
            </>
          }
          size={"small"}
        />
        <CustomButton
          label={"Request Payment"}
          className={"btn--secondary"}
          startIcon={<PaymentsOutlined />}
        />
      </Grid>

      <Grid sx={{ marginTop: "30px", display: "flex", justifyContent: "start" }}>
        <CustomButton
          label={"Mark As Paid"}
          className={"btn--primary"}
          startIcon={<DoneAllOutlined />}
          onClick={() => {
            setPaymentDone(true);
            close();
            handleClick("success", "Payment has been successfully received.");
          }}
        />
      </Grid>
    </Box>
  );
};

export default Payment;
