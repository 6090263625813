import { startsWithAlphabetic } from "store/constant";
import * as Yup from "yup";

export const allergyValidation = Yup.object().shape({
  allergyName: Yup.string()
    .required("Please enter an allergy name.")
    .min(3, "Minimum 3 characters are required")
    .max(50, "Maximum 50 characters are allowed")
    .test("contains-alphabetic", "Name must start with alphabet", startsWithAlphabetic)
    .matches(/^[a-zA-Z ()]+$/, "Only alphabets and () are allowed"),
  allergyDescription: Yup.string()
    .required("Please enter the allergy description.")
    .min(3, "Minimum 3 characters are required")
    .max(255, "Maximum 255 characters are allowed")
    .test("contains-alphabetic", "Must start with alphabet", startsWithAlphabetic),
});
