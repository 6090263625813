import { Fragment, useState } from "react";
import CustomButton from "ui-component/custom-components/CustomButton";
import {
  Grid,
  Card,
  CardContent,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import "assets/scss/availability.scss";
import {
  convertTimeForTimeFormat,
  TEXT_FOR_CLINIC_VISIT,
  TEXT_FOR_TELECONSULTATION,
} from "store/constant";

const EditAvailabilityCard = ({
  cardInfo,
  handleDeleteAvailability,
  handleOpen,
  getDataForEditAvailability,
  clinicVisitAvailabilities,
  teleconsultationAvailabilities,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [availabilityDelete, setAvailabilityDelete] = useState(null);

  const handleDialogYes = async () => {
    setDialogOpen(false);
    handleDeleteAvailability(availabilityDelete);
    setAvailabilityDelete(null);
  };

  const handleDialogNo = () => {
    setDialogOpen(false);
    setAvailabilityDelete(null);
  };

  const handleEditAvailability = (availability) => {
    getDataForEditAvailability(availability);
    handleOpen();
  };

  return (
    <>
      {/* Dialog box for confirmation to delete */}
      <Dialog open={dialogOpen} onClose={handleDialogNo}>
        <DialogTitle id="alert-dialog-title" fontSize={16}>
          Delete Availability?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Availability will be deleted, and Appointments cancelled.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: "#004c70", fontWeight: 600 }} onClick={handleDialogNo}>
            No
          </Button>
          <Button style={{ color: "#f5574c", fontWeight: 600 }} onClick={handleDialogYes}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Card variant="outlined">
        <CardContent>
          <div className="organization">
            <Typography className="organization-name">{cardInfo.name}</Typography>
            <Typography className="organization-address">{cardInfo.address}</Typography>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={6} className="cst-borger-right">
              <CommonTemplate
                type={TEXT_FOR_CLINIC_VISIT}
                dataArr={clinicVisitAvailabilities}
                handleEditAvailability={handleEditAvailability}
                setDialogOpen={setDialogOpen}
                setAvailabilityDelete={setAvailabilityDelete}
              ></CommonTemplate>
            </Grid>
            <Grid item xs={6}>
              <CommonTemplate
                type={TEXT_FOR_TELECONSULTATION}
                dataArr={teleconsultationAvailabilities}
                handleEditAvailability={handleEditAvailability}
                setDialogOpen={setDialogOpen}
                setAvailabilityDelete={setAvailabilityDelete}
              ></CommonTemplate>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

// todo
const CommonTemplate = ({
  type,
  dataArr,
  handleEditAvailability,
  setDialogOpen,
  setAvailabilityDelete,
}) => {
  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  const getDayNumber = (day) => {
    switch (day) {
      case "Monday":
        return 1;
      case "Tuesday":
        return 2;
      case "Wednesday":
        return 3;
      case "Thursday":
        return 4;
      case "Friday":
        return 5;
      case "Saturday":
        return 6;
      case "Sunday":
        return 7;
      default:
        return 1;
    }
  };

  return (
    <>
      <Typography className="cst-title">{type}</Typography>
      {dataArr &&
        dataArr.map((availability, index) => (
          <Fragment key={index}>
            <div key={index} style={{ display: "flex", marginBottom: "5px" }}>
              <div className="edit-timing">
                {`${convertTimeForTimeFormat(availability.startTime)} - ${convertTimeForTimeFormat(
                  availability.endTime
                )}`}
              </div>
              {daysOfWeek.map((day, index) => (
                <div
                  key={index}
                  style={{
                    width: "30px",
                    height: "30px",
                    marginRight: "10px",
                    borderRadius: "50%",
                    padding: "7px",
                    textAlign: "center",
                    background: availability.dayOfWeek.includes(getDayNumber(day))
                      ? "#247391"
                      : "#F2F6F8",
                  }}
                >
                  <Typography
                    className="day-icon"
                    style={{
                      color: availability.dayOfWeek.includes(getDayNumber(day))
                        ? "#fff"
                        : "#616B67",
                    }}
                  >
                    {day[0]}
                  </Typography>
                </div>
              ))}
              <CustomButton
                iconButton={<i className="ri-edit-fill icon-primary-blue" />}
                onClick={() => {
                  handleEditAvailability(availability);
                }}
              ></CustomButton>
              <CustomButton
                iconButton={<i className="ri-delete-bin-fill icon-primary-red" />}
                onClick={() => {
                  setDialogOpen(true);
                  setAvailabilityDelete(availability);
                }}
              ></CustomButton>
            </div>
          </Fragment>
        ))}
      {dataArr && dataArr.length === 0 && (
        <>
          <Typography className="cst-title no-text">There are no availabilities yet!</Typography>
        </>
      )}
    </>
  );
};

export default EditAvailabilityCard;
