import * as Yup from "yup";

export const notificationTypeCreation = Yup.object().shape({
  notificationName: Yup.string()
    .min(5, "Notification name must be at least 5 characters")
    .max(50, "Notification name cannot exceed 50 characters")
    .required("Notification name is required"),
  notificationType: Yup.string().required("Notification type is required"),
  notificationDescription: Yup.string()
    .min(10, "Notification description must be at least 10 characters")
    .max(200, "Notification description cannot exceed 200 characters")
    .required("Notification description is required"),
  email: Yup.boolean(),
  sms: Yup.boolean(),
  whatsapp: Yup.boolean(),
  pushNotification: Yup.boolean(),
  emailContent: Yup.object().when("email", {
    is: true,
    then: () =>
      Yup.object().shape({
        subject: Yup.string().required(`Subject is required`),
        body: Yup.string().required(`Email content is required`),
        footer: Yup.string().required(`Footer is required`),
      }),
  }),
  whatsappContent: Yup.object().when("whatsapp", {
    is: true,
    then: () =>
      Yup.object().shape({
        phoneNumber: Yup.string()
          .matches(/^[0-9]{10}$/, "Invalid phone number format")
          .required(`WhatsApp number is required`),
        message: Yup.string().required(`WhatsApp message is required`),
      }),
  }),
  smsContent: Yup.object().when("sms", {
    is: true,
    then: () =>
      Yup.object().shape({
        msg91TemplateId: Yup.string().required(`Template ID is required`),
        msg91ContentWithVariables: Yup.string().required(`SMS content is required`),
      }),
  }),
  pushNotificationContent: Yup.object().when("pushNotification", {
    is: true,
    then: () =>
      Yup.object().shape({
        heading: Yup.string().required(`Heading is required`),
        message: Yup.string().required(`Message is required`),
      }),
  }),
});

export const notificationCreation = Yup.object({
  notificationType: Yup.string().required("Notification type is required"),
  scheduleType: Yup.string().required("Schedule type is required"),
  scheduledDate: Yup.string().when("scheduleType", {
    is: "SCHEDULED",
    then: () =>
      Yup.date()
        .required("Scheduled date is required")
        .min(new Date(), "Please enter a valid scheduling date"),
  }),
  scheduledTime: Yup.string().when("scheduleType", {
    is: "SCHEDULED",
    then: () => Yup.string().required("Scheduled time is required"),
  }),
  params: Yup.object(),
});
