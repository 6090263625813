import { useState, useEffect, useRef } from "react";
import { api } from "services/AxiosInterceptor";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import AddAvailability from "../Appointment/AddAvailability";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import {
  ACTIVE_STATE,
  CLINIC_VISIT,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  currentActiveUser,
  DATE_FORMAT,
  blockCalendeOptions,
  DATE_FORMAT_DMY,
} from "store/constant";
import dayjs from "dayjs";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import EditAvailabilityCard from "../Availability/EditAvailabilityCard";
import ModalUI from "ui-component/ModalUI";
import CustomButton from "ui-component/custom-components/CustomButton";
import "assets/scss/availability.scss";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import { getDoctorAvailability, getDoctorById } from "services/doctorService";
import {
  deleteAvailabilities,
  getBlockAvailabilities,
  cancelBlockAvailability,
  createBlockAvailabilities,
} from "services/Availability";
import { getAppointmentCountBetweenDates } from "services/Appointments";
import { useContext } from "react";
import Reveal from "views/utilities/Reveal";
import {
  Box,
  Grid,
  Skeleton,
  Typography,
  Radio,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import eventBus from "utils/eventBus";
import { useSelector } from "react-redux";
import { getUserOrgId } from "store/Reducers/userReducer";
import { useLocation } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import { Formik } from "formik";
import { blockCalendarSchema } from "../Common/ValidationSchema/blockCalendarSchema";

const DoctorAvailability = () => {
  const { handleClick } = useContext(ToastContext);
  const [availabilities, setAvailabilities] = useState([]);
  const [doctorDetail, setDoctorDetail] = useState(null);
  const [doctorOrganizations, setDoctorOrganizations] = useState([]);
  const doctorId = currentActiveUser()?.roleBasedId;
  const [editAvailabilityData, seteditAvailabilityData] = useState({});
  const [isUpdate, setisUpdate] = useState(false);
  const doctorOrgId = useSelector(getUserOrgId);
  const datePickerRef = useRef(null);
  const [blockAvailability, setBlockAvailability] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isBlock, setIsBlock] = useState(false);
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const [appointmentBlock, setAppointmentBlock] = useState({
    selectedAppointmentOption: "",
    blockFromDate: null,
    blockToDate: null,
    blockFromTime: "00:00",
    blockToTime: "23:59",
    blockingType: "SINGLE_DATE",
    timeRange: "ALL",
  });
  const [editId, setEditId] = useState();
  const [closeOnSelect, setCloseOnSelect] = useState(true);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [blockEditButton, setBlockEditButton] = useState();
  const { state } = useLocation();
  const pendingTask = state?.pendingTask;
  const [open, setOpen] = useState(pendingTask ? true : false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseBlockModal = () => {
    setIsBlockModalOpen(false);
    // getBlockCalendar();
    // getAppointmentEvents(doctorData);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDeleteAvailability = async (availability) => {
    try {
      await deleteAvailabilities(availability.id);
      handleAvailabilities();
    } catch (error) {
      handleClick("error", "There seems to be an error deleting availability");
    }
  };

  const getDataForEditAvailability = (availability) => {
    seteditAvailabilityData(availability);
    setisUpdate(true);
  };

  const handleAvailabilities = async () => {
    try {
      const availabilityResponse = await getDoctorAvailability(doctorId);
      setAvailabilities(availabilityResponse);
    } catch (error) {
      console.error("Error fetching availability");
    }
  };

  /* Will be used when we have to edit block availability */

  // const handleEditBlockAvailability = async (data) => {
  //   try {
  //     const response = await editBlockAvailabilities(data);
  //   } catch (error) {
  //     handleClick("error", "Error editing block availability");
  //   }
  // };

  useEffect(() => {
    fetchBlockAvailabilities();
  }, [doctorId, doctorOrgId]);

  const fetchBlockAvailabilities = async () => {
    try {
      const blockAvailabilityResponse = await getBlockAvailabilities(doctorId, doctorOrgId);
      setBlockAvailability(blockAvailabilityResponse.data);
    } catch (error) {
      console.error("Error fetching block dates list");
    }
  };

  const handleCancelBlockAvailability = async (editId) => {
    try {
      await cancelBlockAvailability(editId);
      handleClick("success", "Cancelled successfully.");
      eventBus.emit("doctorAvailability", { isBlockedDateChanged: true });
      fetchBlockAvailabilities();
    } catch (error) {
      handleClick("error", "Error cancelling block availability");
    }
  };

  const highlightedDates = Array.isArray(blockAvailability)
    ? blockAvailability
        .map((availability) => {
          const startDate = new Date(availability.startDate);
          const endDate = new Date(availability.endDate);
          const dates = [];

          // Loop from startDate to endDate
          while (startDate <= endDate) {
            dates.push(new Date(startDate));
            startDate.setDate(startDate.getDate() + 1);
          }
          return dates;
        })
        .flat()
    : [];

  const formattedDates = highlightedDates.map((date) => dayjs(date).format(DATE_FORMAT));

  const manageAvailabilities = (organization) => {
    let clinicVisitAvailabilities = [],
      teleconsultationAvailabilities = [];
    const availabilitiesArr = Array.isArray(availabilities) ? availabilities : [availabilities];
    availabilitiesArr.forEach((element) => {
      if (element.organizationId === organization.id) {
        if (element.consultationType === CLINIC_VISIT) {
          clinicVisitAvailabilities.push(element);
        } else {
          teleconsultationAvailabilities.push(element);
        }
      }
    });
    return {
      clinicArr: clinicVisitAvailabilities,
      teleArr: teleconsultationAvailabilities,
    };
  };

  const getAvailabilitiesForOtherOrganizations = () => {
    const availabilitiesArr = Array.isArray(availabilities) ? availabilities : [availabilities];
    return availabilitiesArr.filter((el) => el.organizationId !== doctorOrgId);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const doctorResponse = await getDoctorById(doctorId);
        setDoctorDetail(doctorResponse.data);
        const organizationIds = [];
        doctorResponse.data.userResponse.userOrganizationAssociationList.forEach((org) => {
          if (org.isDoctor === true && org.status === ACTIVE_STATE) {
            organizationIds.push(org.organizationId);
          }
        });
        const organizationResponse = await api.get(`/api/organizations/ids?ids=${organizationIds}`);
        setDoctorOrganizations(organizationResponse.data);

        const availabilityResponse = await getDoctorAvailability(doctorId);
        setAvailabilities(availabilityResponse);
      } catch (error) {
        console.error("Error fetching availabilities.");
      }
    };
    fetchData();
  }, [doctorId]);

  const BlockCalendarModal = ({ doctorId, organizationId, closeModal, handleClick }) => {
    const [firstSubmit, setFirstSubmit] = useState(true);
    const [blockConfirmation, setBlockConfirmation] = useState(false);
    const [effectedAppointments, setEffectedAppointments] = useState(0);
    const fetchAppointmentCount = async (fromDate, toDate, fromTime, toTime) => {
      try {
        const payload = {
          fromDate,
          toDate,
          fromTime,
          toTime,
          doctorId: doctorId,
          orgId: doctorOrgId,
        };
        const response = await getAppointmentCountBetweenDates(payload);
        setEffectedAppointments(response.data.totalAppointments);
        setBlockConfirmation(true);
        return response.data.totalAppointments;
      } catch (error) {
        handleClick("error", "Error fetching appointment count");
      }
    };
    const handleFetchSaveData = async (values) => {
      try {
        const payload = {
          startDate: dayjs(values.fromDate).format(DATE_FORMAT),
          endDate: dayjs(values.toDate).format(DATE_FORMAT),
          startTime: values.fromTime,
          endTime: values.toTime,
          blockingType: values.blockingType,
          doctorId,
          organizationId: [organizationId],
          serviceType: values.appointmentOption,
        };
        if (blockConfirmation || effectedAppointments === 0) {
          await createBlockAvailabilities(payload);
          handleClick("success", "Availability has been successfully blocked.");
          if (dayjs(payload.startDate).isSame(dayjs(), "day")) {
            eventBus.emit("doctorAvailability", { isBlockedDateChanged: true });
          }
          closeModal();
          fetchBlockAvailabilities();
        }
      } catch (error) {
        handleClick("error", "There seems to be an error saving block calendar");
      }
    };

    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={{
            fromDate: appointmentBlock.blockFromDate,
            toDate: appointmentBlock.blockToDate,
            fromTime: appointmentBlock.blockFromTime,
            toTime: appointmentBlock.blockToTime,
            appointmentOption: appointmentBlock.selectedAppointmentOption,
            blockingType: appointmentBlock.blockingType,
            timeRange: appointmentBlock.timeRange,
          }}
          validationSchema={blockCalendarSchema}
          onSubmit={async (values, { setSubmitting, submitForm }) => {
            if (firstSubmit) {
              setSubmitting(false);
              const appointmentCount = await fetchAppointmentCount(
                dayjs(values.fromDate).format(DATE_FORMAT),
                dayjs(values.toDate).format(DATE_FORMAT),
                values.fromTime,
                values.toTime
              );
              setFirstSubmit(false);
              if (appointmentCount === 0) {
                await submitForm();
              }
            } else {
              setSubmitting(true);
              await handleFetchSaveData(values);
              await fetchBlockAvailabilities();
            }
          }}
        >
          {({ values, errors, touched, setFieldValue, handleChange, handleSubmit }) => (
            <form noValidate onSubmit={handleSubmit}>
              <>
                <Grid container spacing={3} mt={0.7}>
                  <Grid item style={{ paddingTop: 0, marginTop: "20px" }}>
                    <Typography sx={{ mt: 0, mb: 0, fontWeight: 500, color: "#000000" }}>
                      Blocking Type
                    </Typography>
                    <Grid item style={{ display: "flex", paddingTop: 0, mb: 0 }}>
                      <RadioGroup
                        value={values.blockingType}
                        name="blockingType"
                        onChange={(e) => {
                          handleChange(e);
                          if (e.target.value === "CUSTOM_DATE_TIME_RANGE") {
                            setFieldValue(
                              "toDate",
                              dayjs(values.fromDate).add(1, "day").startOf("day")
                            );
                          }
                          if (e.target.value === "SINGLE_DATE") {
                            setFieldValue("toDate", values.fromDate);
                          }
                          setBlockConfirmation(false);
                        }}
                        style={{ height: "50px" }}
                      >
                        <Grid item xs={12}>
                          <FormControlLabel
                            value="SINGLE_DATE"
                            control={<Radio />}
                            label="Single Date"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            value="CUSTOM_DATE_TIME_RANGE"
                            control={<Radio />}
                            label="Custom"
                          />
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={3} mt={0.7}>
                  <Grid item xs={6} style={{ paddingTop: 0 }}>
                    <FormDatePicker
                      inputFormat={DATE_FORMAT_DMY}
                      label={values.blockingType === "SINGLE_DATE" ? "On Date" : "From Date"}
                      value={values.fromDate}
                      size={"big"}
                      name="fromDate"
                      required
                      minDate={dayjs().startOf("day")}
                      onChange={(e) => {
                        setFieldValue("fromDate", e);
                        setBlockConfirmation(false);
                      }}
                      style={{
                        width: "100%",
                        marginRight: "30px",
                        marginTop: "7px",
                      }}
                      error={Boolean(errors.fromDate && touched.fromDate)}
                      errorText={errors.fromDate}
                    ></FormDatePicker>
                  </Grid>

                  {values.blockingType !== "SINGLE_DATE" && (
                    <Grid item xs={6} style={{ paddingTop: 0 }}>
                      <FormDatePicker
                        inputFormat={DATE_FORMAT_DMY}
                        label={"To Date"}
                        name="toDate"
                        required
                        value={values.toDate}
                        size={"big"}
                        onChange={(e) => {
                          setFieldValue("toDate", e);
                          setBlockConfirmation(false);
                        }}
                        style={{
                          width: "100%",
                          marginRight: "30px",
                          marginTop: "7px",
                        }}
                        error={Boolean(errors.toDate && touched.toDate)}
                        errorText={errors.toDate}
                      ></FormDatePicker>
                    </Grid>
                  )}
                </Grid>

                {values.blockingType === "SINGLE_DATE" && (
                  <Grid item style={{ display: "flex", paddingTop: 8, marginBottom: -10 }}>
                    <RadioGroup
                      value={values.timeRange}
                      name={"timeRange"}
                      onChange={(e) => {
                        handleChange(e);
                        setBlockConfirmation(false);
                      }}
                      style={{ height: "50px" }}
                    >
                      <Grid item xs={12}>
                        <FormControlLabel value="ALL" control={<Radio />} label="Whole Day" />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          value="CUSTOM_TIME_RANGE"
                          control={<Radio />}
                          label="Custom Time Range"
                        />
                      </Grid>
                    </RadioGroup>
                  </Grid>
                )}
                {(values.blockingType !== "SINGLE_DATE" ||
                  values.timeRange === "CUSTOM_TIME_RANGE") && (
                  <Grid container spacing={3} mb={3} mt={0.1}>
                    <Grid item sx={{ width: "50%" }} style={{ paddingTop: 0, marginTop: "20px" }}>
                      <FormInputField
                        style={{ width: "100%" }}
                        label="From Time"
                        name="fromTime"
                        type="time"
                        shrink={true}
                        value={values.fromTime}
                        onChange={(e) => {
                          setFieldValue("fromTime", e.target.value);
                          setBlockConfirmation(false);
                        }}
                        error={Boolean(errors.fromTime && touched.fromTime)}
                        errorText={errors.fromTime}
                        size={"big"}
                      />
                    </Grid>
                    <Grid item sx={{ width: "50%" }} style={{ paddingTop: 0, marginTop: "20px" }}>
                      <FormInputField
                        style={{ width: "100%" }}
                        label="To Time"
                        shrink={true}
                        name="toTime"
                        type="time"
                        value={values.toTime}
                        onChange={(e) => {
                          setFieldValue("toTime", e.target.value);
                          setBlockConfirmation(false);
                        }}
                        error={Boolean(errors.toTime && touched.toTime)}
                        errorText={errors.toTime}
                        size={"big"}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={3}>
                  <Grid item xs={6} mt={0}>
                    <FormSelectField
                      style={{ width: "100%" }}
                      label="Appointment Options"
                      name="appointmentOption"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      startAdornment={<FilterAltIcon />}
                      menuItems={blockCalendeOptions}
                      value={values.appointmentOption}
                      size={"big"}
                      shrink={true}
                      error={Boolean(errors.appointmentOption && touched.appointmentOption)}
                      errorText={errors.appointmentOption}
                    ></FormSelectField>
                  </Grid>
                </Grid>
                {blockConfirmation && effectedAppointments > 0 && (
                  <Typography style={{ marginTop: "20px", fontWeight: "500", color: "#004c70" }}>
                    {`You have ${effectedAppointments} appointments scheduled, which will be cancelled.`}{" "}
                    <br></br> {`Do you want to proceed?`}
                  </Typography>
                )}
                <div style={{ display: "flex" }}>
                  <CustomButton
                    className={"mui-btn--primary"}
                    height="36px"
                    label={"Block Calendar"}
                    type="submit"
                    style={{ marginTop: "20px", marginRight: "20px" }}
                  ></CustomButton>

                  <CustomButton
                    className={"btn--secondary-light"}
                    height="36px"
                    label={"Cancel"}
                    onClick={closeModal}
                    style={{ marginTop: "20px", marginRight: "20px" }}
                  ></CustomButton>
                </div>
              </>
            </form>
          )}
        </Formik>
      </>
    );
  };

  function findMatchingDate(blockAvailability, date) {
    return blockAvailability.find((item) => {
      const startDate = dayjs(item.startDate).startOf("day");
      const endDate = dayjs(item.endDate).endOf("day");
      return dayjs(date).startOf("day").isBetween(startDate, endDate, null, "[]");
    });
  }

  return (
    <DoctorAvailabilitySkeleton doctorOrganizations={doctorOrganizations}>
      <div className="doctor-availability">
        <Reveal
          className="row justify-content-space-between align-items-center roll-content"
          style={{ height: "36px" }}
        >
          <div className="col breadcrumb-row">
            <h6 className="page-title">My Availability</h6>
          </div>
          <div className="col" style={{ position: "relative" }}>
            <CustomButton
              className="calendar ri-calendar-2-line ri-lg btn--secondary"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                setIsOpen(!isOpen);
                setIsBlock(false);
              }}
            />
            {isOpen && (
              <>
                <div
                  style={{
                    position: "absolute",
                    zIndex: "1",
                    top: "360px",
                    transform: "translateX(-60px)",
                  }}
                >
                  <div ref={datePickerRef}>
                    <FormDatePicker
                      className="datepicker-custom"
                      hideInput={true}
                      value={new Date()}
                      popperStyle={{
                        top: "110px !important",
                        left: "unset !important",
                        right: "75px",
                      }}
                      closeOnSelect={false}
                      blockedDates={formattedDates}
                      disablePastDates={true}
                      onChange={(date) => {
                        setIsButtonVisible(true);
                        setIsBlock(true);
                        setAppointmentBlock({
                          ...appointmentBlock,
                          blockFromDate: date,
                          blockToDate: date,
                        });
                        if (
                          formattedDates.some((highlightedDate) =>
                            dayjs(date).isSame(dayjs(highlightedDate), "day")
                          )
                        ) {
                          setBlockEditButton(true);
                        } else {
                          setBlockEditButton(false);
                        }
                        const matchedObject = findMatchingDate(blockAvailability, date);
                        if (matchedObject) {
                          setAppointmentBlock({
                            ...appointmentBlock,
                            blockFromDate: matchedObject.startDate,
                            blockToDate: matchedObject.endDate,
                          });
                          setEditId(matchedObject.id);
                        } else {
                        }
                      }}
                      onClose={(e) => {
                        setIsButtonVisible(false);
                        setIsOpen(false);
                      }}
                    />
                  </div>
                  {isBlock && (
                    <div
                      style={{
                        width: "100%",
                        // background: "white",
                        // padding: "4px",
                        display: "flex",
                        justifyContent: "center",
                        gap: "12px",
                        // border: "1px solid #aeaeae",
                        // borderRadius: "0.3rem",
                      }}
                    >
                      {isButtonVisible && (
                        <CustomButton
                          onClick={() => {
                            setIsBlockModalOpen(true);
                            setCloseOnSelect(false);
                            setIsButtonVisible(false);
                          }}
                          label="Block date"
                          disabled={blockEditButton}
                          style={{
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Adjust these values for your shadow
                          }}
                        />
                      )}
                      {isButtonVisible && (
                        <CustomButton
                          className={""}
                          // onClick={() => setIsBlockModalOpen(true)}
                          onClick={() => {
                            setIsCancelModalOpen(true);
                            setCloseOnSelect(false);
                            setIsButtonVisible(false);
                            // setIsOpen(!isOpen)
                          }}
                          label="Cancel"
                          disabled={!blockEditButton}
                          style={{
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Adjust these values for your shadow
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
            <ModalUI
              visible={isBlockModalOpen}
              close={() => {
                setIsBlockModalOpen(false);
                setAppointmentBlock({
                  ...appointmentBlock,
                  selectedAppointmentOption: "",
                  blockFromDate: null,
                  blockToDate: null,
                  blockFromTime: "00:00",
                  blockToTime: "23:59",
                  blockingType: "SINGLE_DATE",
                  timeRange: "ALL",
                });
                setIsOpen(false);
              }}
              title={"Block Calendar By Date"}
              style={{
                overflowY: "none",
                height: "fit-content",
                width: "610px",
              }}
              component={
                <BlockCalendarModal
                  doctorId={doctorId}
                  organizationId={doctorOrgId}
                  closeModal={handleCloseBlockModal}
                  handleClick={handleClick}
                ></BlockCalendarModal>
              }
            />
            <ModalUI
              visible={isCancelModalOpen}
              close={() => setIsCancelModalOpen(false)} // Close modal on cancel
              title="Confirm Cancellation"
              component={
                <div>
                  <p>
                    Your blocked dates from{" "}
                    {dayjs(appointmentBlock.blockFromDate).format(DATE_FORMAT_DMY)} to{" "}
                    {dayjs(appointmentBlock.blockToDate).format(DATE_FORMAT_DMY)} will be cancelled!
                  </p>
                  <p>Are you sure you want to cancel?</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}
                  >
                    <button
                      style={{
                        padding: "10px",
                        backgroundColor: "#29BF91",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                      }}
                      onClick={() => {
                        handleCancelBlockAvailability(editId);
                        // cancelBlockAvailability(editId);
                        // Handle the confirm action here
                        setIsCancelModalOpen(false);
                        fetchBlockAvailabilities();
                      }}
                    >
                      Confirm Cancel
                    </button>
                  </div>
                </div>
              }
            />{" "}
            <CustomButton
              className="ri-add-fill ri-lg btn--secondary"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                handleOpen();
                setisUpdate(false);
              }}
              label="Add Availability"
            />
          </div>
        </Reveal>

        {doctorOrganizations.map((organization, index) => (
          <Reveal key={index}>
            <EditAvailabilityCard
              cardInfo={{
                name: organization.name,
                address: organization.address,
              }}
              handleOpen={handleOpen}
              getDataForEditAvailability={getDataForEditAvailability}
              handleDeleteAvailability={handleDeleteAvailability}
              clinicVisitAvailabilities={manageAvailabilities(organization).clinicArr}
              teleconsultationAvailabilities={manageAvailabilities(organization).teleArr}
            ></EditAvailabilityCard>
            <br></br>
          </Reveal>
        ))}
      </div>
      <AddAvailability
        isOpen={open}
        pendingTask={pendingTask}
        onClose={handleCloseModal}
        doctors={[doctorDetail]}
        docOrganizations={doctorOrganizations}
        availabilities={availabilities}
        handleAvailabilities={handleAvailabilities}
        columns={[]}
        actionsForAvailabilities={[]}
        isUpdate={isUpdate}
        isDelete={false}
        editAvailabilityData={editAvailabilityData}
        isDoctorAdding={true}
        otherOrgavailabilities={getAvailabilitiesForOtherOrganizations()}
      ></AddAvailability>
    </DoctorAvailabilitySkeleton>
  );
};

const DoctorAvailabilitySkeleton = ({ children, doctorOrganizations }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={3}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
          <Grid item xs={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
        </Grid>

        {Array.from({ length: 2 }, (_, index) => index).map((el, index) => (
          <Grid item xs={12}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={135}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DoctorAvailability;
