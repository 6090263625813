// LoaderContext.js
import React, { createContext, useContext, useState } from "react";

/**
 * Set a loader message for the API request.
 *
 * Use the `loaderMessage` property in the Axios request configuration to provide feedback to users
 * while the request is being processed. This message will be displayed by the `LoaderContext`
 * to inform users about the ongoing operation.
 *
 * Example usage:
 *
 * // DELETE request example
 * API.delete('/api/resource/123', {
 *   loaderMessage: 'Deleting resource, please wait',
 * });
 *
 * // POST request example
 * API.post('/api/resource', data, {
 *   loaderMessage: 'Submitting data, please wait',
 * });
 *
 * // GET request example
 * API.get('/api/resource', {
 *   loaderMessage: 'Fetching data, please wait',
 * });
 *
 */

const LoaderContext = createContext();

export const LoaderProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState(null);

  const showLoader = () => setLoading(true);
  const hideLoader = () => setLoading(false);

  return (
    <LoaderContext.Provider
      value={{ loading, showLoader, hideLoader, loaderMessage, setLoaderMessage }}
    >
      {children}
    </LoaderContext.Provider>
  );
};

export const useLoader = () => useContext(LoaderContext);
