import { ClearOutlined } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { deletePatientVital, getPatientVitalByPatientId } from "services/patientService";
import ModalUI from "ui-component/ModalUI";
import CustomButton from "ui-component/custom-components/CustomButton";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import PatientVitalSignModal from "./PatientVitalSignModal";
import { getVitalSignTypes } from "services/EntitiesServices";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import Reveal from "views/utilities/Reveal";
import dayjs from "dayjs";
import { DATE_FORMAT_DMY } from "store/constant";

const columns = [
  { field: "Sr. No.", label: "Sr. No" },
  { field: "vitalSignTypeResponse.displayName", label: "Vital Sign" },
  { field: "dateFrom", label: "Date Administered" },
  { field: "value", label: "Reading Value" },
  { field: "vitalSignTypeResponse.unitAbbreviation", label: "Reading Unit" },
  { field: "Actions", label: "Actions" },
];

const PatientVital = ({ patientDOB, patientId, fetchPatientLatestVitalSigns }) => {
  const [patientVitals, setPatientVitals] = useState([]);
  const [displayPatientVitals, setDisplayPatientVitals] = useState([]);
  const [vitalSignTypes, setVitalSignTypes] = useState([]);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const { handleClick } = useContext(ToastContext);

  const clearSearch = () => {
    setSearchQuery("");
  };

  const handleMarkInactive = async (row) => {
    try {
      await deletePatientVital(row.id);
      handleClick("success", "Patient Vital sign has been successfully deleted.");
      const index = patientVitals.findIndex((el) => el.id === row.id);
      patientVitals.splice(index, 1);
      setDisplayPatientVitals(patientVitals);
      fetchPatientLatestVitalSigns();
    } catch (error) {
      handleClick("error", "There seems to be an error deleting the Patient vital sign");
    }
  };

  const closeModal = () => {
    setOpen(false);
    setSelected(null);
  };

  const openUpdateModal = (pVital) => {
    setSelected(pVital);
    setOpen(true);
  };

  const openCreateModal = () => {
    setOpen(true);
  };

  const fetchVitalSignTypes = async () => {
    try {
      const response = await getVitalSignTypes();
      setVitalSignTypes(response.data);
    } catch (error) {
      console.error("Error fetching Vital sign types");
    }
  };

  const fetchPatientVitalSigns = async () => {
    try {
      const response = await getPatientVitalByPatientId(patientId);
      setPatientVitals(
        response.data.map((vital) => ({
          ...vital,
          dateFrom: dayjs(vital.dateFrom).format(DATE_FORMAT_DMY),
        }))
      );
    } catch (error) {
      console.error("Error fetching patient vital Sign");
    }
  };

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-line ri-xl icon-primary-blue" />,
      onClick: openUpdateModal,
    },
    {
      label: (rowData) => {
        return "Delete";
      },
      icon: <i className="ri-delete-bin-fill ri-xl icon-primary-blue" />,
      onClick: handleMarkInactive,
    },
  ];

  useEffect(() => {
    if (vitalSignTypes.length > 0 && patientId) {
      fetchPatientVitalSigns();
    }
  }, [vitalSignTypes, patientId]);

  useEffect(() => {
    fetchVitalSignTypes();
  }, []);

  useEffect(() => {
    setDisplayPatientVitals(
      patientVitals.filter((item) => {
        const values = Object.values(item);
        const lowerCaseQuery = searchQuery.toLowerCase();
        return values.some((value, index) => {
          if (index === 7) {
            return value.toLowerCase().includes(lowerCaseQuery);
          } else if (index === 3) {
            const obj = Object.values(value);
            return obj.some((element, index) => {
              if (index === 2) {
                return element.toLowerCase().includes(lowerCaseQuery);
              }
              return false;
            });
          }
          return false;
        });
      })
    );
  }, [patientVitals, searchQuery]);

  return (
    <Reveal>
      <Grid display={"flex"} alignItems={"center"} sx={{ mb: 1 }}>
        <FormInputField
          style={{ width: "40%" }}
          label={"Search Vital Sign"}
          value={searchQuery}
          startAdornment={<i className="ri-search-line ri-lg" />}
          endAdornment={
            <IconButton onClick={clearSearch} edge="end">
              {<ClearOutlined fontSize="small" />}
            </IconButton>
          }
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          size="small"
        ></FormInputField>
        <CustomButton
          style={{ marginLeft: "auto" }}
          label={"Add"}
          className={"ri-add-fill ri-lg btn--primary"}
          onClick={openCreateModal}
        />
      </Grid>

      <CustomizedTable
        columns={columns}
        tableData={displayPatientVitals}
        actions={actions}
        rowsPerPageInTable={10}
      />

      <ModalUI
        visible={open}
        close={closeModal}
        title={selected ? "Update Patient Vital Sign" : "Create Patient Vital Sign"}
        component={
          <PatientVitalSignModal
            selected={selected}
            close={(operation) => {
              if (operation === "save") {
                closeModal();
                fetchPatientVitalSigns();
                fetchPatientLatestVitalSigns();
              } else {
                closeModal();
              }
            }}
            patientDOB={patientDOB}
            patientId={patientId}
            vitalSignTypes={vitalSignTypes}
          />
        }
      />
    </Reveal>
  );
};

export default PatientVital;
