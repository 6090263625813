import React, { useState, useEffect } from "react";
import AddAvailability from "./AddAvailability";
import "assets/scss/appointment.scss";
import CustomButton from "ui-component/custom-components/CustomButton";
import TimerSharpIcon from "@mui/icons-material/TimerSharp";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  CLINIC_ADMIN,
  DATE_FORMAT,
  HOUR_MINUTE_FORMAT,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  accessToken,
  currentActiveUser,
  orgId,
  SCHEDULED,
  RESCHEDULED,
  FRONT_DESK,
  DOCTOR,
  roleName,
  NO_SHOW,
  PRESCRIPTION_DONE,
  PRESCRIPTION_DONE_LABEL,
  NO_SHOW_LABEL,
  TIME_FORMAT,
  DATE_FORMAT_DMY,
  convertTimeForTimeFormat,
  DAY_GRID_CALENDAR,
  CHECKED_IN,
  COMPLETED,
  MONTH_GRID_CALENDAR,
  appointmentTypeMapper,
} from "store/constant";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  getAppointmentById,
  getAppointments,
  updateAppointmentStatus,
} from "services/Appointments";
import { useContext } from "react";
import BookAppointmentModal from "ui-component/Appointment/BookAppointmentModal";
import ModalUI from "ui-component/ModalUI";
import { useDispatch } from "react-redux";
import { setSelectedAppointmentData } from "store/Actions/appointmentDataAction";
import { getDoctorByOrgId, getDoctorByUserId } from "services/doctorService";
import { getOrgById } from "services/organizationService";
import { useCallback } from "react";
import Reveal from "views/utilities/Reveal";
import { Box, Card, Grid, Skeleton } from "@mui/material";
import dayjs from "dayjs";
import { Person } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { getPrescriptionByAppointmentId } from "services/PrescriptionsService";
import {
  setPrescriptionId,
  setPrescriptionPadData,
} from "store/Actions/PrescriptionPadDataActions";
import CustomFullCalendar from "ui-component/custom-components/CustomFullCalendar";
import { useSelector } from "react-redux";
import { getUserOrgId } from "store/Reducers/userReducer";
import {
  getAppointmentEvents,
  handleMonthViewMoreClick,
  updateFilteredAppointment,
} from "utils/appointment-utils";
import PatientCard from "../Doctor/PatientDTO/PatientCard";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { getInvoicePDFById } from "services/BillingService";

const getColumns = () => {
  let columns;

  const c = [
    { field: "Sr. No.", label: "Sr. No" },
    { field: "patientName", label: "PATIENT NAME" },
    { field: "appointmentDate", label: "DATE" },
    { field: "appointmentTime", label: "TIME" },
    { field: "appointmentStatus", label: "Status" },
    { field: "Actions", label: "ACTIONS", actions: true },
  ];

  if (roleName() === FRONT_DESK || roleName() === CLINIC_ADMIN) {
    columns = [...c.slice(0, 2), { field: "doctorName", label: "DOCTOR NAME" }, ...c.slice(2)];
    return columns;
  } else {
    return c;
  }
};

const Appointment = () => {
  const dispatch = useDispatch();

  const [appointment, setAppointment] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const { handleClick } = useContext(ToastContext);
  const [availabilityOpen, setAvailabilityOpen] = useState(false);
  const [currOrg, setcurrOrg] = useState({});
  const [doctorLabel, setDoctorLabel] = useState({ label: "All Doctors" });
  const [isModalOpen, setisModalOpen] = useState(false);

  /** custom calendar view */
  const [calendarView, setCalendarView] = useState(DAY_GRID_CALENDAR);
  const [viewInfo, setViewInfo] = useState(null);
  const [filteredDoctorEvents, setFilteredDoctorEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [doctorAppointments, setDoctorAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState("");
  const [doctorBlockCalendar, setDoctorBlockCalendar] = useState([]);
  const [doctorBlockCalendarEvents, setDoctorBlockCalendarEvents] = useState([]);
  const [doctorData, setDoctorData] = useState({});
  const [appointmentList, setAppointmentList] = useState([]);
  const [showAppointmentList, setShowAppointmentList] = useState(true);
  const currentUser = currentActiveUser();
  const [dateMessage, setDateMessage] = useState(null);
  const userOrgId = useSelector(getUserOrgId);
  const accessTokenValue = accessToken();
  const [isReschedule, setIsRechedule] = useState(false);

  const navigate = useNavigate();

  const handleModalClose = () => {
    dispatch(setSelectedAppointmentData({}));
    setisModalOpen(false);
    setIsRechedule(false);
    handleGetAppointmentEvents(doctorLabel);
  };

  const handleModalOpen = () => {
    dispatch(
      setSelectedAppointmentData({
        orgId: userOrgId,
        appointmentDate: dayjs().format(DATE_FORMAT),
      })
    );
    setisModalOpen(true);
  };

  const handlePatientCheckIn = async (appointment) => {
    try {
      await updateAppointmentStatus(appointment.id, CHECKED_IN);
      appointment.appointmentStatus = CHECKED_IN;
      handleClick("success", "You have successfully checked-in!");
    } catch (error) {
      handleClick("error", "There seems to be an error during check-in!");
    }
    handleGetAppointmentEvents(doctorLabel);
  };

  const fetchData = useCallback(async () => {
    try {
      const response = await getAppointments();
      if (
        currentUser?.roleName === CLINIC_ADMIN ||
        currentUser?.roleName === FRONT_DESK ||
        currentUser?.roleName === DOCTOR
      ) {
        if (doctorLabel.label !== "All Doctors") {
          setAppointment(
            response.data.filter(
              (appointment) => appointment.doctor?.userResponse.name === doctorLabel.label
            )
          );
        } else {
          setAppointment(response.data.filter((appointment) => appointment.orgId === userOrgId));
        }
      } else {
        setAppointment(response.data);
      }
    } catch (error) {
      console.error("Error fetching appointments");
    }

    try {
      const response = await getDoctorByOrgId(userOrgId);
      setDoctors(response.data);
    } catch (error) {
      console.error("Issue fetching doctors.");
    }

    try {
      const organizationResponse = await getOrgById(userOrgId);
      if (organizationResponse) {
        setcurrOrg(organizationResponse.data);
      }
    } catch (error) {
      console.error("Issue fetching doctors.");
    }
  }, [doctorLabel, userOrgId, handleClick]);

  const extractedData = appointment
    .map((row) => {
      const patientName = row?.patient?.user?.name;
      const doctorName = `Dr. ${row.doctor?.userResponse.name}`;
      return {
        ...row,
        appointmentStatus:
          row?.appointmentStatus === NO_SHOW
            ? NO_SHOW_LABEL
            : row?.appointmentStatus === PRESCRIPTION_DONE
            ? PRESCRIPTION_DONE_LABEL
            : row?.appointmentStatus,
        appointmentDate: row?.appointmentDate
          ? dayjs(row?.appointmentDate).format(DATE_FORMAT_DMY)
          : null,
        patientName,
        doctorName,
        appointmentTime: convertTimeForTimeFormat(row?.appointmentTime),
      };
    })
    .sort((a, b) => {
      const dateA = dayjs(a.appointmentDate);
      const dateB = dayjs(b.appointmentDate);

      if (!dateA.isSame(dateB)) {
        return dateB.diff(dateA);
      }

      const timeA = dayjs(a.appointmentTime, TIME_FORMAT);
      const timeB = dayjs(b.appointmentTime, TIME_FORMAT);

      return timeB.diff(timeA);
    });

  useEffect(() => {
    fetchData();
  }, [accessTokenValue, fetchData]);

  const handleDelete = async (appointment) => {
    try {
      await updateAppointmentStatus(appointment.id, "CANCELLED");
      handleClick("success", "Your appointment has been successfully cancelled!");
      fetchData();
    } catch (error) {
      handleClick("error", "There seems to be an error deleting appointment");
    }
  };

  const isDisableGenerateInvoice = (row) => {
    if (row?.appointmentStatus === PRESCRIPTION_DONE_LABEL) {
      return false;
    } else {
      return true;
    }
  };

  const handleGenerateInvoice = (row) => {
    navigate("/home/generateInvoice", {
      state: {
        appointmentId: row.id,
        patientId: row?.patientId,
        doctorId: row.doctorId,
        orgId: row.orgId,
      },
    });
  };

  const handleDownloadPrescription = (appointment) => {
    navigate("/home/prescription/" + appointment.id);
  };

  const handleDownloadInvoice = (appointment) => {
    const fetchInvoice = async () => {
      try {
        // toDo need to check once the pr is merged
        const pdfRes = await getInvoicePDFById(null, appointment.id);
        const blob = new Blob([pdfRes.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "invoice.pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        handleClick("error", "There seems to be an error generating invoice PDF");
      }
    };
    fetchInvoice();
  };

  const handleViewPrescription = async (appointment) => {
    try {
      const response = await getPrescriptionByAppointmentId(appointment?.id);
      const prescriptionId = response?.data?.id;

      const diagnosisArr = [
        ...response?.data?.prescriptionDiagnosisResponses?.map((diagnosis) => {
          return {
            ...diagnosis,
            displayName: diagnosis?.diagnosisResponse?.name,
            category: "DX",
          };
        }),
      ];
      const symptomsArr = [
        ...response?.data?.prescriptionSymptomResponses?.map((symptom) => {
          return {
            ...symptom,
            displayName: symptom?.symptomResponse?.name,
            category: "CC",
          };
        }),
      ];

      const medicationsArr = [
        ...response?.data?.prescriptionMedicationResponses?.map((medication) => {
          return {
            ...medication,
            displayName: medication?.medicationResponse?.name,
            category: "RX",
          };
        }),
      ];
      const labInvestigationsArr = [
        ...response?.data?.prescriptionLabReportResponses?.map((labTest) => {
          return {
            ...labTest,
            displayName: labTest?.labTestResponse?.name,
            category: "LAB",
          };
        }),
      ];

      const advice = response?.data?.additionalRecommendations;
      let advicesArr = [];
      if (advice !== "") {
        advicesArr = response?.data?.additionalRecommendations.split(",").map((advice) => {
          return {
            category: "ADV",
            displayName: advice,
          };
        });
      }
      dispatch(setPrescriptionId(prescriptionId));
      dispatch(
        setPrescriptionPadData({
          diagnosisArr,
          symptomsArr,
          medicationsArr,
          labInvestigationsArr,
          advicesArr,
        })
      );
      navigate(`/home/prescriptionPad/${appointment?.id}`, {
        state: {
          isEditable: true,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const isDisable = (row) => {
    if (row?.appointmentStatus === SCHEDULED || row?.appointmentStatus === RESCHEDULED) {
      const now = dayjs();
      const nowString = now.format(DATE_FORMAT);
      const currentTime = now.format(HOUR_MINUTE_FORMAT);
      if (
        nowString === row.appointmentDate &&
        currentTime > row.appointmentTime.replaceAll(" ", "")
      ) {
        return true;
      } else {
        return undefined;
      }
    } else {
      return true;
    }
  };
  const isPrescriptionDisable = (row) => {
    return row?.doctor?.id === currentUser?.roleBasedId &&
      row?.appointmentStatus === "PRESCRIPTION DONE"
      ? undefined
      : true;
  };
  const isPrescriptionVisible = (row) => {
    return row?.doctor?.id === currentUser?.roleBasedId;
  };

  const handleFilterAppointments = (label) => {
    setDoctorLabel((prevLabel) => {
      if (label?.id === prevLabel?.id) {
        fetchData();
        return prevLabel;
      } else {
        return label;
      }
    });
  };

  const actions = [
    {
      label: "Generate Invoice",
      icon: <i className="ri-invision-fill ri-xl icon-primary-blue" />,
      onClick: handleGenerateInvoice,
      checkDisable: isDisableGenerateInvoice,
    },
    {
      label: "Cancel",
      icon: <i className="ri-delete-bin-fill ri-xl icon-primary-blue" />,
      onClick: handleDelete,
      checkDisable: isDisable,
    },
    {
      label: "View prescription",
      icon: <i className="ri-eye-line ri-1x icon-primary-blue" />,
      onClick: handleViewPrescription,
      checkVisible: isPrescriptionVisible,
      checkDisable: isPrescriptionDisable,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const appointments = filteredDoctorEvents
        .map((appointment) => {
          return {
            ...doctorAppointments.find((docApp) => docApp.id === appointment.appointmentId),
            eventSelected:
              calendarView !== MONTH_GRID_CALENDAR ? appointment?.eventSelected : false,
          };
        })
        .filter(Boolean);
      const appointmentDetails = await Promise.all(
        appointments.map(async (appointment) => {
          try {
            const response = await getAppointmentById(appointment.id);
            return {
              ...response.data,
              eventSelected: appointment?.eventSelected,
            };
          } catch (error) {
            console.error("error fetching patient appointments");
          }
        })
      );
      setDateMessage(viewInfo?.view.title);
      setShowAppointmentList(true);
      const sortedAppointments = appointmentDetails.sort((a, b) => {
        if (a?.eventSelected) return -1;
        if (b?.eventSelected) return 1;
        return 0;
      });
      setAppointmentList(sortedAppointments);
    };
    fetchData();
  }, [filteredDoctorEvents]);

  const eventClick = async (eventInfo) => {
    if (eventInfo.event.extendedProps.appointmentType === "Blocked") {
      return;
    }
    try {
      const appointmentId = doctorAppointments.find(
        (appointment) => appointment.id === eventInfo.event.extendedProps.appointmentId
      )?.id;

      const appointmentResponse = await getAppointmentById(appointmentId);
      setSelectedAppointment(appointmentResponse.data);
      setShowAppointmentList(true);

      const updatedEvents = filteredDoctorEvents.map((item) => {
        if (item.appointmentId === eventInfo.event.extendedProps.appointmentId) {
          return { ...item, eventSelected: true };
        } else {
          return { ...item, eventSelected: false };
        }
      });
      setFilteredDoctorEvents(updatedEvents);
    } catch (error) {
      console.error("Error fetching appointment detail");
    }
  };

  const handleGetAppointmentEvents = async (doctorDetail) => {
    const appointmentInfoData = await getAppointmentEvents(doctorDetail, userOrgId);
    setDoctorAppointments([...appointmentInfoData.doctorAppointments]);
    setAllEvents([...appointmentInfoData.allEvents]);
    const filteredAppointments = updateFilteredAppointment(allEvents, viewInfo, calendarView);
    handleFilteredAppointmentsChange(filteredAppointments);
    setFilteredDoctorEvents(filteredAppointments);
  };

  // on click of the icon button on month view
  const handleMonthViewMore = async (eventInfo) => {
    const appointmentDetails = await handleMonthViewMoreClick(eventInfo, doctorAppointments);
    setAppointmentList(appointmentDetails);
    setShowAppointmentList(true);
    setSelectedAppointment("");

    if (appointmentDetails.length > 0) {
      setDateMessage(dayjs(appointmentDetails[0].appointmentDate).format("MMMM D, YYYY"));
    }
  };

  const handleFilteredAppointmentsChange = async (appointments) => {
    try {
      const sortedAppointments = [...appointments].sort(
        (a, b) => dayjs(a.start).valueOf() - dayjs(b.start).valueOf()
      );

      appointments.forEach((appointment) => {
        if (appointment.appointmentId === sortedAppointments[0].appointmentId) {
          appointment.eventSelected = true;
        } else {
          appointment.eventSelected = false;
        }
      });

      if (sortedAppointments.length > 0) {
        const appointmentDetails = await getAppointmentById(sortedAppointments[0].appointmentId);
        setSelectedAppointment(appointmentDetails.data);
      } else {
        setSelectedAppointment(undefined);
      }
    } catch (error) {
      console.error("Error fetching appointment");
    }
  };

  useEffect(() => {
    let intervalId;

    const fetchData = async () => {
      try {
        setDoctorData(doctorLabel);
        handleGetAppointmentEvents(doctorLabel);
        intervalId = setInterval(() => {
          handleGetAppointmentEvents(doctorLabel);
        }, 3000000);
      } catch (error) {
        console.error("Error fetching details");
      }
    };
    fetchData();

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    // eslint-disable-next-line
  }, [doctorLabel]);

  const handleNewModalOpenForReschedulingAppointment = (selectedAppointment) => {
    const appointment = {
      id: selectedAppointment?.id,
      patientMobile: selectedAppointment?.patient?.user?.mobileNumber,
      patientName: selectedAppointment?.patient?.user?.name,
      orgId: selectedAppointment?.orgId,
      appointmentTime: selectedAppointment?.appointmentTime.slice(0, -3),
      patientId: selectedAppointment?.patientId,
      doctorId: selectedAppointment?.doctorId,
      appointmentDate: selectedAppointment?.appointmentDate,
      notes: selectedAppointment?.notes,
      type: selectedAppointment?.type,
    };
    dispatch(setSelectedAppointmentData(appointment));
    setIsRechedule(true);
    setisModalOpen(true);
  };

  return (
    <AppointmentSkeleton>
      <Reveal className="appointment-container doctor-appointments">
        <div className="section-heading">
          <h2 className="page-title">All Appointments</h2>
          <div className="buttons">
            {(roleName() === FRONT_DESK || roleName() === CLINIC_ADMIN) && (
              <div className="section-heading">
                <CustomButton
                  label={doctorLabel.label}
                  style={{ marginLeft: "auto" }}
                  className={"btn--secondary-light"}
                  startIcon={<Person />}
                  endIcon={<i className="ri-arrow-down-s-fill" />}
                  dropDownMenuItems={[
                    { label: "All Doctors", doctorId: null },
                    ...doctors.map((doctor) => ({
                      label: doctor?.userResponse?.name,
                      ...doctor,
                    })),
                  ]}
                  onMenuItemClick={handleFilterAppointments}
                  menuItemHoverColor="#29BF91"
                />
              </div>
            )}
            <CustomButton
              className="button btn--primary"
              onClick={() => {
                setAvailabilityOpen(true);
              }}
              icon={<TimerSharpIcon />}
              label="Set Availability"
            ></CustomButton>
            <CustomButton
              onClick={handleModalOpen}
              className="button ri-add-fill ri-lg btn--secondary"
              label="New Appointment"
            ></CustomButton>
          </div>
        </div>

        {roleName() === FRONT_DESK ? (
          <div className="row justify-content-space-between align-items-center roll-content">
            <Grid
              container
              spacing={2}
              className="cst-height mx-0 row justify-content-space-between align-items-center mb-0 appointment-layout"
            >
              <Grid item md={8} xs={12} style={{ height: "100%" }}>
                <Reveal style={{ height: "100%" }}>
                  <Box className="left-box">
                    <CustomFullCalendar
                      setCalendarView={setCalendarView}
                      viewInfo={viewInfo}
                      setViewInfo={setViewInfo}
                      calendarView={calendarView}
                      setFilteredDoctorEvents={setFilteredDoctorEvents}
                      allEvents={allEvents}
                      filteredDoctorEvents={filteredDoctorEvents}
                      eventClick={eventClick}
                      blockedDays={doctorBlockCalendar}
                      blockedEvents={doctorBlockCalendarEvents}
                      slotDuration={doctorData?.avgAppointmentDuration <= 15 ? 30 : 60}
                      onFilteredAppointmentsChange={handleFilteredAppointmentsChange}
                      handleMonthViewMore={handleMonthViewMore}
                      setShowAppointmentList={setShowAppointmentList}
                      handlePrescriptionClick={handleViewPrescription}
                    ></CustomFullCalendar>
                  </Box>
                </Reveal>
              </Grid>
              <Grid item md={4} xs={12} style={{ height: "100%" }}>
                <Reveal style={{ height: "100%" }}>
                  <Card
                    className="custom-card-ui"
                    style={{ backgroundColor: "white", height: "100%", scrollbarWidth: "none" }}
                  >
                    <div className="row justify-content-space-between align-items-center">
                      <div className="col card-title">{"Appointments List"}</div>
                      <div className="col filters">
                        {showAppointmentList && <div className="heading-date">{dateMessage}</div>}
                      </div>
                    </div>
                    {showAppointmentList &&
                      appointmentList.length > 0 &&
                      appointmentList.map((appointment) => (
                        <Reveal>
                          <Card
                            key={appointment?.id}
                            className="appointment-card"
                            sx={{
                              boxShadow: appointment?.eventSelected
                                ? "0px 4px 16px rgba(0, 0, 0, 0.4) !important"
                                : undefined,
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  textAlign: "center",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "#004c70",
                                }}
                              >
                                Status : {appointmentTypeMapper[appointment?.appointmentStatus]}
                              </p>
                            </div>
                            <PatientCard
                              selectedAppointment={appointment}
                              handleClick={handleClick}
                            ></PatientCard>
                            <Grid container className="row mb-0 mx-0 column-patient-details">
                              <Grid item md={12} className="px-0">
                                <div className="row w-100 mb-0 align-items-left flex-direction-column">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {[SCHEDULED, RESCHEDULED].includes(
                                      appointment?.appointmentStatus
                                    ) && (
                                      <CustomButton
                                        className={"btn--secondary"}
                                        style={{ marginRight: "12px", height: "34px" }}
                                        label={"Check-In"}
                                        startIcon={<InfoOutlinedIcon />}
                                        onClick={() => {
                                          handlePatientCheckIn(appointment);
                                        }}
                                      ></CustomButton>
                                    )}
                                    {[SCHEDULED].includes(appointment?.appointmentStatus) && (
                                      <CustomButton
                                        className={"mui-btn--primary"}
                                        style={{ marginRight: "12px", height: "34px" }}
                                        label={"Reschedule"}
                                        startIcon={<InfoOutlinedIcon />}
                                        onClick={() => {
                                          handleNewModalOpenForReschedulingAppointment(appointment);
                                        }}
                                      ></CustomButton>
                                    )}
                                    {[PRESCRIPTION_DONE].includes(
                                      appointment?.appointmentStatus
                                    ) && (
                                      <CustomButton
                                        className={"mui-btn--primary"}
                                        style={{ height: "34px" }}
                                        label={"Generate Invoice"}
                                        endIcon={
                                          <KeyboardDoubleArrowRightIcon></KeyboardDoubleArrowRightIcon>
                                        }
                                        onClick={() => {
                                          handleGenerateInvoice(appointment);
                                        }}
                                      ></CustomButton>
                                    )}
                                    {[COMPLETED].includes(appointment?.appointmentStatus) && (
                                      <CustomButton
                                        className={"btn--secondary"}
                                        style={{ height: "34px" }}
                                        label={"Invoice"}
                                        startIcon={<i className="ri-download-line ri-lg"></i>}
                                        onClick={() => {
                                          handleDownloadInvoice(appointment);
                                        }}
                                      ></CustomButton>
                                    )}
                                    {[COMPLETED, PRESCRIPTION_DONE].includes(
                                      appointment?.appointmentStatus
                                    ) && (
                                      <CustomButton
                                        className={"mui-btn--primary"}
                                        style={{ height: "34px" }}
                                        label={"Prescription"}
                                        startIcon={<i className="ri-eye-line ri-xl"></i>}
                                        onClick={() => {
                                          handleDownloadPrescription(appointment);
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </Card>
                        </Reveal>
                      ))}
                  </Card>
                </Reveal>
              </Grid>
            </Grid>
          </div>
        ) : (
          <CustomizedTable columns={getColumns()} tableData={extractedData} actions={actions} />
        )}
        <AddAvailability
          isOpen={availabilityOpen}
          onClose={() => {
            dispatch(setSelectedAppointmentData({}));
            setAvailabilityOpen(false);
          }}
          doctors={doctors}
          docOrganizations={[currOrg]}
          handleAvailabilities={() => {}}
          columns={[]}
          isUpdate={false}
          isDoctorAdding={false}
        ></AddAvailability>

        <ModalUI
          visible={isModalOpen}
          close={handleModalClose}
          title={isReschedule ? "Reschedule Appointment" : "Book Appointment"}
          style={{
            overflowY: "scroll",
            height: "550px",
            width: "610px",
          }}
          component={
            <BookAppointmentModal
              handleClick={handleClick}
              reschedule={isReschedule}
              setReschedule={setIsRechedule}
              closeModal={handleModalClose}
            />
          }
        />
      </Reveal>
    </AppointmentSkeleton>
  );
};

export const AppointmentSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={3}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
          <Grid item xs={3}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={250}
          />
        </Grid>

        <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
          <Grid item xs={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Appointment;
