import { Grid, Menu, MenuList, Pagination, Paper, TablePagination } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import CustomMenuItem from "./CustomMenuItem";
import { Stack } from "@mui/system";
import { useEffect } from "react";
import NoDataFoundMessageInTable from "./NoDataFoundMessageInTable";
import React from "react";
import CustomButton from "./CustomButton";
import { currentActiveUser } from "store/constant";

export const StyledTableContainer = styled(TableContainer, {
  shouldForwardProp: (prop) => prop !== "isTeleconsultation" && prop !== "tableData",
})(({ theme, isTeleconsultation, tableData }) => {
  const teleconsultationStyles = {
    borderRadius: "6px",
    margin: "auto",
    width: "95%",
    height: tableData && !tableData.length ? "28px" : "auto",
    overflowY: "auto",
    overflowX: "hidden",
    backgroundColor: "transparent",
    scrollbarWidth: "none",
  };

  const defaultStyles = {
    borderRadius: "3px 3px 0 0",
  };

  return {
    ...(isTeleconsultation ? teleconsultationStyles : defaultStyles),
  };
});

export const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "isTeleconsultation",
})(({ theme, isTeleconsultation }) => {
  const teleconsultationStyles = {
    fontSize: 14,
    color: "#FFFFFF",
  };

  const defaultStyles = {
    fontSize: 14,
    color: "",
  };

  return {
    [`&.${tableCellClasses.head}`]: {
      minWidth: "80px",
      backgroundColor: "#004C70",
      color: "#FFFFFF",
      fontSize: 14,
    },
    [`&.${tableCellClasses.body}`]: isTeleconsultation ? teleconsultationStyles : defaultStyles,
  };
});

export const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== "isTeleconsultation",
})(({ theme, isTeleconsultation }) => {
  const teleconsultationStyles = {
    "&:nth-of-type(odd)": {
      backgroundColor: "transparent",
      color: "#FFFFFF",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "transparent",
      color: "#FFFFFF",
    },
  };

  const defaultStyles = {
    "&:nth-of-type(odd)": {
      backgroundColor: "#FFFFFF",
      color: "",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#E6EEF1",
      color: "",
    },
  };

  return {
    ...(isTeleconsultation ? teleconsultationStyles : defaultStyles),
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  };
});

const CustomizedTable = ({
  columns,
  tableData,
  actions,
  rowsPerPageInTable = 10,
  displayPageSelect = false,
  noPagination,
  doNotShowTableHead,
  fontSize,
  isTeleconsultation = false,
  // handleMenuOpen,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageInTable);
  const [rowsPerPageOptionsArray, setRowsPerPageOptionArray] = useState(null);
  const [clickedRow, setClickedRow] = useState(null);
  const numOptions = 5;

  useEffect(() => {
    if (rowsPerPageInTable && tableData) {
      setPage(0);
      if (tableData.length <= rowsPerPageInTable) {
        setRowsPerPageOptionArray([rowsPerPageInTable]);
      } else {
        const increment = Math.ceil((tableData.length - rowsPerPageInTable) / (numOptions - 1));
        const rowsPerPageOptions = [rowsPerPageInTable];
        // Generate additional options based on the increment
        for (let i = 1; i < numOptions - 1; i++) {
          const option = rowsPerPageInTable + i * increment;
          rowsPerPageOptions.push(option);
        }
        rowsPerPageOptions.push(tableData.length);
        setRowsPerPageOptionArray(rowsPerPageOptions);
      }
    }
  }, [rowsPerPageInTable, tableData]);

  const getFieldValue = (obj, field) => {
    const fieldParts = field.split(".");
    let value = obj;
    for (const part of fieldParts) {
      value = value?.[part];
      if (value === undefined) return ""; // Return an empty string if any part is undefined
      if (value === 0) return "0";
    }
    return value;
  };

  const handleMenuOpen = (event, row) => {
    setClickedRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <StyledTableContainer
        component={Paper}
        isTeleconsultation={isTeleconsultation}
        tableData={tableData}
      >
        <Table aria-label="Customized Table">
          {!doNotShowTableHead && (
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <StyledTableCell
                    key={index}
                    align={column.align ? column.align : "center"}
                    isTeleconsultation={isTeleconsultation}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
          )}

          <TableBody>
            {tableData &&
              tableData.length > 0 &&
              (rowsPerPage > 0 //line 128
                ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : tableData
              ).map((row, rowIndex) => (
                <StyledTableRow key={row.id} isTeleconsultation={isTeleconsultation}>
                  {columns.map((column) => (
                    <React.Fragment key={column.field}>
                      <StyledTableCell
                        sx={{
                          padding: "0px 16px",
                          lineHeight: "28px",
                          fontSize: `${fontSize} !important`,
                        }}
                        style={{
                          textAlign: column.align ? column.align : "center",
                        }}
                        isTeleconsultation={isTeleconsultation}
                      >
                        {column.field === "Actions" && actions && (
                          <>
                            <CustomButton
                              iconButton={<i className="ri-more-2-fill" />}
                              onClick={(event) => handleMenuOpen(event, row)}
                              aria-label="Actions"
                            />
                            <Menu
                              elevation={1}
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handleMenuClose}
                              PaperProps={{
                                style: {
                                  borderRadius: "4px",
                                },
                              }}
                            >
                              <MenuList dense sx={{ p: 0 }}>
                                {actions
                                  .filter((action) => {
                                    if (typeof action.checkVisible === "function") {
                                      return action.checkVisible(clickedRow) !== false;
                                    }
                                    return true;
                                  })
                                  .map((action, actionIndex) => {
                                    return (
                                      <CustomMenuItem
                                        disabled={
                                          action?.checkDisable
                                            ? action?.checkDisable(clickedRow)
                                            : undefined
                                        }
                                        key={actionIndex}
                                        text={
                                          typeof action.label === "function"
                                            ? action.label(row)
                                            : action.label
                                        }
                                        icon={action.icon}
                                        onClick={() => {
                                          handleMenuClose();
                                          action.onClick(clickedRow);
                                        }}
                                      />
                                    );
                                  })}
                              </MenuList>
                            </Menu>
                          </>
                        )}
                        {getFieldValue(row, column.field) && column.field !== "Actions"
                          ? getFieldValue(row, column.field)
                          : ""}
                        {!getFieldValue(row, column.field) &&
                          column.field !== "Actions" &&
                          column.field !== "Sr. No." &&
                          "N/A"}
                        {column.field === "Sr. No." && rowIndex + 1}
                      </StyledTableCell>
                    </React.Fragment>
                  ))}
                </StyledTableRow>
              ))}
            {tableData && !tableData.length && (
              <NoDataFoundMessageInTable
                colSpan={columns.length}
                isTeleconsultation={isTeleconsultation}
              />
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>
      {!noPagination && (
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            {rowsPerPageOptionsArray && rowsPerPageOptionsArray.length > 0 && (
              <TablePagination
                component="div"
                count={tableData ? tableData.length : 0}
                sx={{
                  "& .MuiTablePagination-actions": {
                    visibility: "hidden",
                  },
                  "& .css-1ojzxaw-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1ojzxaw-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1ojzxaw-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      padding: "6px 32px 6px 16px",
                    },
                  "& .MuiTablePagination-input": {
                    display: displayPageSelect ? undefined : "none",
                  },
                  "& .MuiTablePagination-selectLabel": {
                    display: displayPageSelect ? undefined : "none",
                  },
                }}
                labelRowsPerPage={"Items per page"}
                SelectProps={{ variant: "outlined" }}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count} items`}
                page={page}
                onPageChange={(event, newPage) => {
                  setPage(newPage);
                }}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={rowsPerPageOptionsArray}
              />
            )}
          </Grid>
          <Grid item alignItems={"center"} display={"flex"}>
            <Stack>
              <Pagination
                count={tableData ? Math.ceil(tableData.length / rowsPerPage) : 0}
                showFirstButton
                showLastButton
                page={page + 1}
                sx={{
                  "& .MuiPaginationItem-root.Mui-selected": {
                    backgroundColor: "#004C70", // Set the background color for the selected page
                    color: "#FFFFFF", // Optionally, set the text color for the selected page
                  },
                  "& .MuiPaginationItem-root.Mui-selected:hover": {
                    backgroundColor: "#678ea0", // Set the background color for when hover the selected page
                  },
                }}
                // variant={"outlined"}
                onChange={(event, newPage) => {
                  setPage(newPage - 1);
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CustomizedTable;
