import { Box, Typography } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { DriveFileRenameOutlineOutlined } from "@mui/icons-material";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { updateSpecializations, getSpecializations } from "services/EntitiesServices";

const UpdateSpecialization = ({ selected, close }) => {
  const [value, setValue] = useState(selected?.name);
  const [errors, setErrors] = useState({});
  const { handleClick } = useContext(ToastContext);
  const [existingSpecializations, setExistingSpecializations] = useState([]);

  useEffect(() => {
    // Fetch existing specializations when the component loads
    const fetchSpecializations = async () => {
      try {
        const response = await getSpecializations();
        setExistingSpecializations(response.data); // Adjust based on your API response structure
      } catch (error) {
        console.error("Error fetching specializations:", error);
      }
    };

    fetchSpecializations();
  }, []);

  const validate = () => {
    const errors = {};
    const trimmedValue = value.trim();

    if (!value) {
      errors.value = "Please enter valid specialization.";
    }

    if (!value.trim()) {
      errors.value = "Specialization name cannot be empty";
    }

    if (value.trim().length < 3) {
      errors.value = "Specialization name must be at least 3 characters long";
    }

    if (value.trim().length > 50) {
      errors.value = "Specialization name cannot exceed 50 characters";
    }

    const specialCharPattern = /[^a-zA-Z0-9\s]/;
    if (specialCharPattern.test(value)) {
      errors.value = "Specialization name can only contain letters and numbers";
    }

    const prohibitedWords = ["admin", "null", "undefined"];
    if (prohibitedWords.includes(value.toLowerCase())) {
      errors.value = "This specialization name is not allowed";
    }

    const nameExists = existingSpecializations.some(
      (spec) => spec.name.toLowerCase() === trimmedValue.toLowerCase() && spec.id !== selected.id
    );
    if (nameExists) {
      errors.value = "Specialization name already exists";
    }

    return errors;
  };

  const updateSpecialization = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});

      if (selected) {
        const data = {
          id: selected.id,
          name: value,
        };

        try {
          await updateSpecializations(selected.id, data);
          handleClick("success", "Specialization has been successfully updated.");
          close();
        } catch (e) {
          handleClick("error", "There seems to be an error updating the specialization.");
        }
      }
    }
  };

  return (
    <Box
      flex="1"
      alignSelf={"center"}
      minHeight="18vh"
      p={3}
      borderRadius={3}
      bgcolor={"background.default"}
      color={"text.primary"}
    >
      <Typography variant="h4" textAlign="center">
        Update Specialization
      </Typography>
      <FormInputField
        style={{ marginTop: "20px" }}
        label={"Specialization"}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        startAdornment={<DriveFileRenameOutlineOutlined />}
        size="small"
        error={Boolean(errors.value)}
        errorText={errors.value}
      />
      <Box sx={{ mt: 3 }} display="flex" justifyContent="center" alignItems="center">
        <CustomButton className="btn--primary" onClick={updateSpecialization} label="Update" />

        <CustomButton
          style={{ marginLeft: "10px" }}
          className="btn--primary-light"
          onClick={close}
          label="Cancel"
        />
      </Box>
    </Box>
  );
};

export default UpdateSpecialization;
