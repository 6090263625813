import { Menu, MenuList } from "@mui/material";
import React, { useCallback, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { deleteTemplates } from "services/PrescriptionsService";
import { setPrescriptionPadData } from "store/Actions/PrescriptionPadDataActions";
import { loggedInUser } from "store/constant";
import CustomMenuItem from "ui-component/custom-components/CustomMenuItem";
import { ToastContext } from "ui-component/custom-components/CustomToast";

const TemplateCard = ({ data, onQuickCreate, onDelete, onEdit }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const { handleClick } = useContext(ToastContext);

  const handleEdit = useCallback(() => {
    const templateData = JSON.parse(data.templateContent);
    dispatch(
      setPrescriptionPadData({
        diagnosisArr: templateData.diagnosis,
        symptomsArr: templateData.symptoms,
        medicationsArr: templateData.medications,
        labInvestigationsArr: templateData.labInvestigations,
        advicesArr: templateData.advices,
      })
    );
    // onQuickCreate();
    onEdit(data);
  }, [data, dispatch, onEdit]);

  const handleDelete = useCallback(async () => {
    const templateId = data?.id;
    try {
      await deleteTemplates(templateId);
      onDelete(templateId);
    } catch (error) {
      handleClick("error", "There seems to be an error deleting template");
    }
  }, [data, onDelete]);

  const handleQuickCreate = useCallback(async () => {
    const templateData = JSON.parse(data.templateContent);
    dispatch(
      setPrescriptionPadData({
        diagnosisArr: templateData.diagnosis,
        symptomsArr: templateData.symptoms,
        medicationsArr: templateData.medications,
        labInvestigationsArr: templateData.labInvestigations,
        advicesArr: templateData.advices,
      })
    );
    onQuickCreate(data);
  }, [data, onQuickCreate, dispatch]);

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-line ri-xl icon-primary-blue" />,
      onClick: handleEdit,
    },
    {
      label: "Quick Create",
      icon: <i className="ri-file-line ri-xl icon-primary-blue" />,
      onClick: handleQuickCreate,
    },
    {
      label: "Share",
      icon: <i className="ri-share-line ri-xl icon-primary-blue" />,
    },
    {
      label: "Delete",
      icon: <i className="ri-delete-bin-fill ri-xl icon-primary-blue" />,
      onClick: handleDelete,
    },
  ];

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div
        className="template-card"
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          flexDirection: "row",
          marginBottom: "10px",
          height: "80px",
          backgroundColor: "white",
          border: "2px",
          borderRadius: "10px",
        }}
      >
        <div style={{ width: "15%", marginLeft: "10px" }}>
          <p style={{ color: "#004c70", fontSize: "14px", fontWeight: "500" }}>
            {data.templateName}
          </p>
        </div>
        <div style={{ width: "20%" }}>
          <p style={{ color: "lightGray" }}>Created On</p>
          <p>{data.createdOn}</p>
        </div>
        <div style={{ width: "20%" }}>
          <p style={{ color: "lightGray" }}>Modified On</p>
          <p>{data.modifiedOn}</p>
        </div>
        <div style={{ width: "20%" }}>
          <p style={{ color: "lightGray" }}>Created By</p>
          <p>Dr. {loggedInUser()?.name}</p>
        </div>
        <div style={{ width: "8%" }}>
          <i
            style={{ cursor: "pointer" }}
            className="ri-more-2-fill ri-lg"
            onClick={(event) => handleMenuOpen(event)}
          />
        </div>
        <Menu
          elevation={1}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          PaperProps={{
            style: {
              borderRadius: "4px",
            },
          }}
        >
          <MenuList dense sx={{ p: 0 }}>
            {actions.map((action, actionIndex) => (
              <CustomMenuItem
                key={actionIndex}
                onClick={() => {
                  handleMenuClose();
                  action.onClick && action.onClick(data);
                }}
                icon={action.icon}
                text={action.label}
              />
            ))}
          </MenuList>
        </Menu>
      </div>
    </div>
  );
};

export default TemplateCard;
