import { Box, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import CustomButton from "ui-component/custom-components/CustomButton";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import {
  getAllNotifications,
  deleteNotification,
  createNotification,
  updateNotification,
  createNotificationType,
  getAllNotificationTypes,
  updateNotificationType,
  deleteNotificationType,
} from "services/NotificationService";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { accessToken } from "store/constant";
import { useMemo } from "react";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import ConfigureNotification from "./ConfigureNotification";
import ModalUI from "ui-component/ModalUI";
import CloseIcon from "@mui/icons-material/Close";
import ConfigureNotificationType from "./ConfigureNotificationType";

const MyDialog = ({
  open,
  onClose,
  submitNotification,
  notification,
  isNotificationType,
  submitNotificationType,
  notificationTypeEdit,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle style={{ display: "flex", alignItems: "center", height: "70px" }}>
        {!isNotificationType ? "Notification Settings" : "Notification Type Settings"}
        <CustomButton
          onClick={onClose}
          iconButton={<CloseIcon></CloseIcon>}
          style={{ marginLeft: "auto" }}
        ></CustomButton>
      </DialogTitle>

      <DialogContent>
        {!isNotificationType ? (
          <ConfigureNotification
            submitNotification={submitNotification}
            notification={notification}
          />
        ) : (
          <ConfigureNotificationType
            submitNotificationType={submitNotificationType}
            notificationTypeEdit={notificationTypeEdit}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

const Notification = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [notification, setNotification] = useState();
  const [notificationType, setNotificationType] = useState();
  const [isNotificationType, setIsNotificationType] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = (type) => {
    setIsNotificationType(type);
    setOpen(true);
  };
  const handleModalClose = () => {
    setNotification();
    setNotificationType();
    setOpen(false);
  };

  const showDetails = (notification) => {
    setNotification(notification);
    setNotificationType(
      notificationTypes.find((item) => item.id === notification.notificationTypeId)
    );
    handleOpen(false);
  };

  const showDetailsOfType = (notificationType) => {
    setNotificationType(notificationType);
    setNotification();
    handleOpen(true);
  };

  const handleOpenDialog = (type) => {
    setIsNotificationType(type);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setNotification();
    setNotificationType();
    setOpenDialog(false);
  };

  const handleUpdate = (notification) => {
    if (notification.scheduleType === "SCHEDULED") {
      if (new Date() < new Date(notification.scheduledOn)) {
        setNotification(notification);
        handleOpenDialog(false);
      } else {
        handleClick(
          "error",
          "Notification details cannot be edited as it is past the scheduled date."
        );
      }
    } else {
      handleClick("error", "Notification cannot be edited.");
    }
  };

  const handleUpdateNotificationType = (notificationType) => {
    setNotificationType(notificationType);
    handleOpenDialog(true);
  };

  const [notifications, setNotifications] = useState([]);
  const [notificationTypes, setNotificationTypes] = useState([]);
  const { handleClick } = useContext(ToastContext);
  const accessTokenValue = accessToken();

  const columns = [
    { field: "Sr. No.", label: "Sr. No." },
    { field: "receiverUserId", label: "Receiver" },
    { field: "channelType", label: "Channel Type " },
    { field: "scheduleType", label: "Schedule Type" },
    { field: "scheduledOn", label: "Scheduled On" },
    { field: "notificationStatus", label: "Status" },
    { field: "Actions", label: "Actions" },
  ];

  const columns1 = [
    { field: "Sr. No.", label: "Sr. No." },
    { field: "name", label: "Name" },
    { field: "type", label: "Type" },
    { field: "description", label: "Description" },
    { field: "status", label: "Status" },
    { field: "Actions", label: "Actions" },
  ];

  //validation
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllNotifications();
        const notificationTypesData = await getAllNotificationTypes();
        setNotificationTypes(notificationTypesData.data.filter((type) => type.status === "ACTIVE"));
        setNotifications(response.data);
      } catch (error) {
        console.error("Fetch notification failed");
      }
    };
    fetchData();
  }, [accessTokenValue, handleClick]);

  useEffect(() => {
    const filteredNotifications = notifications.filter((notification) =>
      notificationTypes.some((type) => type.id === notification.notificationTypeId)
    );
    setNotifications(filteredNotifications);
  }, [notificationTypes]);

  const filteredRows = useMemo(() => {
    return notifications;
  }, [notifications]);

  const notificationTypeData = useMemo(() => {
    return notificationTypes;
  }, [notificationTypes]);

  const validateNotificationData = (data) => {
    if (!data.receiverUserId) {
      const Errors = { notificationName: "Receiver ID is required." };
      setErrors(Errors);
      return false;
    }
    if (!data.channelType) {
      errors.type = "Channel Type is required.";
      const Errors = { notificationName: "Channel Type is required." };
      setErrors(Errors);
      return false;
    }
    if (data.scheduleType === "SCHEDULED" && !data.scheduledOn) {
      const Errors = {
        notificationName: "Scheduled Date is required for scheduled notifications.",
      };
      setErrors(Errors);
      return false;
    }
    if (new Date(data.scheduledOn) < new Date()) {
      const Errors = { notificationName: "Scheduled Date must be in the future." };
      setErrors(Errors);
      return false;
    }
    if (data.channelType === "SMS" && data.content && data.content.length > 160) {
      const Errors = { notificationName: "SMS content exceeds the 160 character limit." };
      setErrors(Errors);
      return false;
    }
    if (Object.keys(data).length === 0) {
      const Errors = { notificationName: "Notification data cannot be empty." };
      setErrors(Errors);
      return false;
    }

    return true;
  };

  const validateNotificationTypeData = (data) => {
    const specialCharPattern = /[^a-zA-Z0-9 ]/g;
    if (!data.name) {
      const Errors = { notificationType: "Notification type name is required." };
      setErrors(Errors);
      return false;
    }
    if (!data.type) {
      const Errors = { notificationType: "Notification type is required." };
      setErrors(Errors);
      return false;
    }
    if (!data.description) {
      const Errors = { notificationDescription: "Description is required." };
      setErrors(Errors);
      return false;
    }
    if (specialCharPattern.test(data.name)) {
      const Errors = {
        notificationName: "Notification type name cannot contain special characters.",
      };
      setErrors(Errors);
      return false;
    }
    if (data.name.length > 50) {
      const Errors = { notificationName: "Notification name cannot exceed 50 characters." };
      setErrors(Errors);
      return false;
    }
    if (data.name.length < 3) {
      const Errors = { notificationName: "Notification name must be at least 3 characters long." };
      setErrors(Errors);
      return false;
    }
    if (data.name.trim().length !== data.name.length) {
      const Errors = {
        notificationName: "Notification name cannot have leading or trailing spaces.",
      };
      setErrors(Errors);
      return false;
    }
    if (/\s{2,}/.test(data.name)) {
      const Errors = { notificationName: "Notification name cannot have consecutive spaces." };
      setErrors(Errors);
      return false;
    }
    const nameLowerCase = data.name.toLowerCase();
    const existingNames = notifications.map((notification) => notification.name.toLowerCase());
    if (existingNames.includes(nameLowerCase)) {
      handleClick("error", "Notification name must be unique.");
      return false;
    }
    return true;
  };

  const submitNotification = async (notificationData, notificationId, editNotification) => {
    if (!validateNotificationData(notificationData)) return;
    try {
      if (!editNotification) {
        const response = await createNotification(notificationData);
        setNotifications([...notifications, response.data]);
      } else {
        const response = await updateNotification(notificationId, notificationData);
        setNotifications((notifications) =>
          notifications.map((notification) =>
            notification.id === response.data.id
              ? { ...notification, ...response.data }
              : notification
          )
        );
      }
      handleCloseDialog();
    } catch (error) {
      handleClick("error", "There seems to be an error creating notification");
    }
  };

  const submitNotificationType = async (
    notificationTyeData,
    notificationTypeId,
    editNotificationType
  ) => {
    if (!validateNotificationTypeData(notificationTyeData)) return;
    try {
      if (!editNotificationType) {
        const response = await createNotificationType(notificationTyeData);
        setNotificationTypes([...notificationTypes, response.data]);
      } else {
        const response = await updateNotificationType(notificationTypeId, notificationTyeData);
        setNotificationTypes((notificationTypes) =>
          notificationTypes.map((notificationType) =>
            notificationType.id === response.data.id
              ? { ...notificationType, ...response.data }
              : notificationType
          )
        );
      }
      handleCloseDialog();
    } catch (error) {
      handleCloseDialog("error", "Error submitting notification type");
    }
  };

  const handleDelete = async (notification) => {
    if (window.confirm("Are you sure you want to delete this notification?")) {
      try {
        await deleteNotification(notification.id);
        setNotifications(notifications.filter((item) => item.id !== notification.id));
      } catch (error) {
        handleClick("error", "Error deleting notification");
      }
    }
  };

  const handleDeleteOfType = async (notificationType) => {
    try {
      await deleteNotificationType(notificationType.id);
      setNotificationTypes(notificationTypes.filter((item) => item.id !== notificationType.id));
    } catch (error) {
      handleClick("error", "There seems to be an error deleting notification type");
    }
  };

  const actions = [
    {
      label: "Edit",
      icon: <EditIcon style={{ color: "#004C70" }} />,
      onClick: handleUpdate,
    },
    {
      label: "View Content",
      icon: <InfoIcon style={{ color: "#004C70" }} />,
      onClick: showDetails,
    },
    {
      label: "Delete",
      icon: <i className="ri-delete-bin-fill ri-xl icon-primary-blue" />,
      onClick: handleDelete,
    },
  ];

  const actionsNotificationType = [
    {
      label: "Edit",
      icon: <EditIcon style={{ color: "#004C70" }} />,
      onClick: handleUpdateNotificationType,
    },
    {
      label: "View Content",
      icon: <InfoIcon style={{ color: "#004C70" }} />,
      onClick: showDetailsOfType,
    },
    {
      label: "Delete",
      icon: <i className="ri-delete-bin-fill ri-xl icon-primary-blue" />,
      onClick: handleDeleteOfType,
    },
  ];

  const replaceVariables = (text) => {
    if (notification) {
      let replacedText = text;
      let variables = notification.params;
      Object.keys(variables).forEach((variable) => {
        const regex = new RegExp(`##${variable}##`, "g");
        replacedText = replacedText.replace(regex, variables[variable]);
      });
      return replacedText;
    } else {
      return text;
    }
  };

  const renderNotificationContent = (type, content) => (
    <>
      <Typography variant="h5" component="div" gutterBottom>
        {type}:
      </Typography>
      {type === "Email" && (
        <>
          <Typography variant="body1" component="div">
            <strong>Subject: &nbsp;</strong> {replaceVariables(content.subject)}
          </Typography>
          <Typography variant="body1" component="div">
            <strong>Body: &nbsp;</strong> {replaceVariables(content.body)}
          </Typography>
          <Typography variant="body1" component="div">
            <strong>Footer: &nbsp; </strong> {replaceVariables(content.footer)}
          </Typography>
          <Divider sx={{ my: 2 }} />
        </>
      )}
      {type === "SMS" && (
        <>
          <Typography variant="body1" component="div">
            <strong>TemplateId: &nbsp;</strong> {replaceVariables(content.msg91TemplateId)}
          </Typography>
          <Typography variant="body1" component="div">
            <strong>Content: &nbsp;</strong> {replaceVariables(content.msg91ContentWithVariables)}
          </Typography>
          <Divider sx={{ my: 2 }} />
        </>
      )}
      {type === "WhatsApp" && (
        <>
          <Typography variant="body1" component="div">
            <strong>Message: &nbsp;</strong> {replaceVariables(content.message)}
          </Typography>
          <Divider sx={{ my: 2 }} />
        </>
      )}
      {type === "Push Notification" && (
        <>
          <Typography variant="body1" component="div">
            <strong>Heading: &nbsp;</strong> {replaceVariables(content.heading)}
          </Typography>
          <Typography variant="body1" component="div">
            <strong>Message: &nbsp;</strong> {replaceVariables(content.message)}
          </Typography>
          <Divider sx={{ my: 2 }} />
        </>
      )}
    </>
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
          justifyContent: "space-between",
        }}
      >
        <h2 style={{ display: "inline" }}>Notification Types</h2>
        <div style={{ display: "flex", gap: "10px" }}>
          <CustomButton
            className="btn--primary"
            label="+ Configure Notification Type"
            onClick={() => handleOpenDialog(true)}
          />
          <CustomButton
            className="btn--primary"
            label="+ Configure Notification"
            onClick={() => handleOpenDialog(false)}
          />
        </div>
      </div>
      <CustomizedTable
        columns={columns1}
        tableData={notificationTypeData}
        actions={actionsNotificationType}
      />
      <h2 style={{ marginTop: "20px", marginBottom: "10px" }}>Notifications</h2>
      <CustomizedTable columns={columns} tableData={filteredRows} actions={actions} />
      <MyDialog
        open={openDialog}
        onClose={handleCloseDialog}
        submitNotification={submitNotification}
        notification={notification}
        isNotificationType={isNotificationType}
        submitNotificationType={submitNotificationType}
        notificationTypeEdit={notificationType}
      />

      {notification && (
        <ModalUI
          visible={open}
          close={handleModalClose}
          title="Notification Details"
          component={
            <Box sx={{ width: "500px", margin: "auto", paddingTop: "15px", paddingBottom: "10px" }}>
              {notificationType?.template.smsTemplateResponse &&
                renderNotificationContent("SMS", notificationType.template.smsTemplateResponse)}
              {notificationType?.template.emailTemplateResponse &&
                renderNotificationContent("Email", notificationType.template.emailTemplateResponse)}
              {notificationType?.template.whatsappTemplateResponse &&
                renderNotificationContent(
                  "WhatsApp",
                  notificationType?.template.whatsappTemplateResponse
                )}
              {notificationType?.template.pushNotificationTemplateResponse &&
                renderNotificationContent(
                  "Push Notification",
                  notificationType?.template.pushNotificationTemplateResponse
                )}

              <Typography variant="h5" component="div" gutterBottom>
                Status: &nbsp; {notification.notificationStatus}
              </Typography>
            </Box>
          }
        />
      )}
      {notificationType && !notification && (
        <ModalUI
          visible={open}
          close={handleModalClose}
          title="Notification Details"
          component={
            <>
              <Box
                sx={{ width: "500px", margin: "auto", paddingTop: "15px", paddingBottom: "10px" }}
              >
                <Typography variant="h5" component="div" gutterBottom>
                  Name: &nbsp; {notificationType.name}
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h5" component="div" gutterBottom>
                  Type: &nbsp; {notificationType.type}
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h5" component="div" gutterBottom>
                  Description: &nbsp; {notificationType.description}
                </Typography>
                <Divider sx={{ my: 2 }} />
                {notificationType.template.smsTemplateResponse &&
                  renderNotificationContent("SMS", notificationType.template.smsTemplateResponse)}
                {notificationType.template.emailTemplateResponse &&
                  renderNotificationContent(
                    "Email",
                    notificationType.template.emailTemplateResponse
                  )}
                {notificationType.template.whatsappTemplateResponse &&
                  renderNotificationContent(
                    "WhatsApp",
                    notificationType.template.whatsappTemplateResponse
                  )}
                {notificationType.template.pushNotificationTemplateResponse &&
                  renderNotificationContent(
                    "Push Notification",
                    notificationType.template.pushNotificationTemplateResponse
                  )}

                <Typography variant="h5" component="div" gutterBottom>
                  Status: &nbsp; {notificationType.status}
                </Typography>
              </Box>
            </>
          }
        />
      )}
    </>
  );
};

export default Notification;
