import { startsAndEndsWithAlphabetic, startsWithAlphabetic } from "store/constant";
import * as Yup from "yup";

export const chronicDiseaseValidation = Yup.object().shape({
  diseaseName: Yup.string()
    .required("Please enter a disease name.")
    .min(3, "Minimum 3 characters are required")
    .max(50, "Maximum 50 characters are allowed")
    .matches(/^[a-zA-Z ()]+$/, "Only alphabets and () are allowed")
    .test("contains-alphabetic", "Name must start with alphabet", startsWithAlphabetic),
  diseaseDescription: Yup.string()
    .required("Please enter the description.")
    .min(3, "Minimum 3 characters are required")
    .max(255, "Maximum 255 characters are allowed")
    .test("contains-alphabetic", "Must start and end with alphabet", startsAndEndsWithAlphabetic),
});
