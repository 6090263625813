import { Grid, Typography } from "@mui/material";
import SwitchLeftIcon from "@mui/icons-material/SwitchLeft";
import { Box } from "@mui/system";
import CustomButton from "ui-component/custom-components/CustomButton";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import ModalUI from "ui-component/ModalUI";
import { useState } from "react";
import { useEffect } from "react";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { CorporateFare, Diversity3, Person } from "@mui/icons-material";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import dayjs from "dayjs";
import { ACTIVE_STATE, startsWithAlphabetic } from "store/constant";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  createMemberships,
  createRegistrations,
  deleteMemberships,
  deleteRegistrations,
  updateMemberships,
  updateRegistrations,
} from "services/doctorService";
import Reveal from "views/utilities/Reveal";

const calendarValue = (year) => {
  if (year) {
    return dayjs(`${year}-01-01`);
  }

  return null;
};

const registrationColumns = [
  { field: "registrationId", label: "Registration Id" },
  { field: "registrationCouncil", label: "Registartion Council" },
  { field: "validFromYear", label: "Valid From Year" },
  { field: "Actions", label: "ACTIONS", actions: true },
];

const memberOfSocityColumns = [
  { field: "memberOf", label: "Society Name" },
  { field: "memberFromYear", label: "Member from Year" },
  { field: "memberTillYear", label: "Member till Year" },
  { field: "Actions", label: "ACTIONS", actions: true },
];

const DoctorRegistrationAndMemberships = ({
  doctorDetails,
  setDoctorDetails,
  readOnly,
  setReadOnly,
  refreshData,
  ...others
}) => {
  const [openRegistration, setOpenRegistration] = useState(false);
  const [openMember, setOpenMember] = useState(false);

  const [selectedRegistration, setSelectedRegistration] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);
  const { handleClick } = useContext(ToastContext);

  const MIN_AGE_FOR_REGISTRATION = 20;

  const doctorDateOfBirth = dayjs(doctorDetails?.userResponse?.dateOfBirth);
  const minRegistrationDate = doctorDateOfBirth
    .add(MIN_AGE_FOR_REGISTRATION, "year")
    .startOf("year");

  const currentDate = dayjs();

  const closeModal = () => {
    setOpenRegistration(false);
    setOpenMember(false);
    setSelectedRegistration(null);
    setSelectedMember(null);
  };

  const openAddAward = () => {
    setOpenRegistration(true);
  };

  const openUpdateRegistration = (registration) => {
    setOpenRegistration(true);
    setSelectedRegistration(registration);
  };

  const openAddMember = () => {
    setOpenMember(true);
  };

  const openUpdateMember = (member) => {
    setOpenMember(true);
    setSelectedMember(member);
  };

  const handleRegistartionMarkInactive = async (row) => {
    if (row.status === ACTIVE_STATE) {
      try {
        await deleteRegistrations(row.id);
        handleClick("success", "Registration has been marked Inactive");
      } catch (error) {
        handleClick("error", "There seems to be an error marking the Registration as Inactive.");
      }
    } else {
      handleClick("info", "Registration is already inactive!");
    }
  };

  const handleMembershipMarkInactive = async (row) => {
    if (row.status === ACTIVE_STATE) {
      try {
        await deleteMemberships(row.id);
        handleClick("success", "Membership has been marked Inactive");
      } catch (error) {
        handleClick("error", "There seems to be an error marking the Membership as Inactive.");
      }
    } else {
      handleClick("error", "Membership is already inactive!");
    }
  };

  const registrationActions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: openUpdateRegistration,
    },
    {
      label: (rowData) => {
        return rowData.status === ACTIVE_STATE ? "Mark as Inactive" : "Mark as Active";
      },
      icon: <SwitchLeftIcon style={{ color: "#004C70" }} />,
      onClick: handleRegistartionMarkInactive,
    },
  ];

  const memberActions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: openUpdateMember,
    },
    {
      label: (rowData) => {
        return rowData.status === ACTIVE_STATE ? "Mark as Inactive" : "Mark as Active";
      },
      icon: <SwitchLeftIcon style={{ color: "#004C70" }} />,
      onClick: handleMembershipMarkInactive,
    },
  ];

  return (
    <Reveal>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <Typography sx={{ fontSize: "14px", color: "#004C70", fontWeight: "600" }}>
          Registrations
        </Typography>
        <CustomButton
          onClick={openAddAward}
          label="Add"
          className="ri-add-fill ri-lg btn--primary"
        />
      </Box>

      <CustomizedTable
        columns={registrationColumns}
        tableData={doctorDetails?.registrations}
        actions={registrationActions}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <Typography sx={{ fontSize: "14px", color: "#004C70", fontWeight: "600" }}>
          Member of Society
        </Typography>
        <CustomButton
          onClick={openAddMember}
          label="Add"
          className="ri-add-fill ri-lg btn--primary"
        />
      </Box>

      <CustomizedTable
        columns={memberOfSocityColumns}
        tableData={doctorDetails?.memberships}
        actions={memberActions}
      />

      {/* Awards Modal */}
      <ModalUI
        visible={openRegistration}
        close={closeModal}
        title={selectedRegistration ? "Update Registration" : "Add Registration"}
        component={
          <DoctorRegistrationModal
            registrations={doctorDetails?.registrations}
            selected={selectedRegistration}
            handleClick={handleClick}
            minRegistrationDate={minRegistrationDate}
            close={() => {
              closeModal();
            }}
            currentDate={currentDate}
            doctorId={doctorDetails?.id}
            refreshData={refreshData}
          />
        }
      />

      {/* Member of Society Modal */}
      <ModalUI
        visible={openMember}
        close={closeModal}
        title={selectedMember ? "Update Membership" : "Add Membership"}
        component={
          <DoctorMemberModal
            members={doctorDetails?.memberships}
            selected={selectedMember}
            handleClick={handleClick}
            minMembershipDate={minRegistrationDate}
            close={() => {
              closeModal();
            }}
            currentDate={currentDate}
            doctorId={doctorDetails?.id}
            refreshData={refreshData}
          />
        }
      />
    </Reveal>
  );
};

const DoctorRegistrationModal = ({
  registrations,
  selected,
  handleClick,
  close,
  doctorId,
  refreshData,
  currentDate,
  minRegistrationDate,
}) => {
  const [registrationId, setRegistrationId] = useState(
    selected?.registrationId ? selected?.registrationId : ""
  );
  const [registrationCouncil, setRegistrationCouncil] = useState(
    selected?.registrationCouncil ? selected?.registrationCouncil : ""
  );
  const [validFromYear, setValidFromYear] = useState(
    selected?.validFromYear ? calendarValue(selected?.validFromYear) : null
  );

  const [registrationIdTouched, setRegistrationIdTouched] = useState(false);
  const [registrationCouncilTouched, setRegistrationCouncilTouched] = useState(false);

  const [errors, setErrors] = useState({});

  const validateRegistrationId = (errors, registrationId) => {
    const pattern = /^\S*$/;
    if (!registrationId) {
      errors.registrationId = "Please enter valid registration id.";
    } else if (registrationId.length < 3) {
      errors.registrationId = "Registration id must be at least 3 characters long";
    } else if (registrationId.length > 100) {
      errors.registrationId = "Registration id cannot be longer than 100 characters";
    } else if (!pattern.test(registrationId)) {
      errors.registrationId = "Registration id cannot contain space";
    }
  };

  const validateRegistrationCouncil = (errors, registrationCouncil) => {
    const pattern = /^[a-zA-Z0-9\s'-.()]+$/;
    if (!registrationCouncil) {
      errors.registrationCouncil = "Please enter valid registration council name.";
    } else if (registrationCouncil.length < 3) {
      errors.registrationCouncil = "Name must be at least 3 characters long";
    } else if (registrationCouncil.length > 100) {
      errors.registrationCouncil = "Name cannot be longer than 100 characters";
    } else if (!pattern.test(registrationCouncil)) {
      errors.registrationCouncil = "Name can only contain following special characters: .-'()";
    } else if (!startsWithAlphabetic(registrationCouncil)) {
      errors.registrationCouncil = "Name must start with alphabet";
    }
  };

  const validate = () => {
    const errors = {};

    setRegistrationIdTouched(true);
    validateRegistrationId(errors, registrationId);

    setRegistrationCouncilTouched(true);
    validateRegistrationCouncil(errors, registrationCouncil);

    if (validFromYear === null) {
      errors.validFromYear = "Please select valid start year.";
    } else if (!(validFromYear?.isValid ? validFromYear.isValid() : true)) {
      errors.validFromYear = "Please select valid 'Year' value.";
    } else if (validFromYear > currentDate) {
      errors.validFromYear = "Year cannot be in future.";
    } else if (validFromYear.isBefore(minRegistrationDate)) {
      errors.validFromYear = `Year should be at least ${minRegistrationDate.year()} for registrations.`;
    }

    return errors;
  };

  useEffect(() => {
    if (selected && registrations) {
      registrations.find((registration) => {
        return registration.id === selected.id;
      });
    }
  }, [selected, registrations]);

  const saveRegistration = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});

      let data = {
        doctorId: doctorId,
        registrationId: registrationId,
        registrationCouncil: registrationCouncil,
        validFromYear: validFromYear?.$y ? validFromYear.$y : validFromYear.year(),
      };

      if (selected) {
        try {
          const postData = {
            ...data,
            id: selected?.id,
          };
          await updateRegistrations(selected.id, postData);
          handleClick("success", "Registration has been successfully updated.");
          close();
          refreshData();
        } catch (error) {
          handleClick("error", "There seems to be some error updating the registration");
          close();
        }
      } else {
        try {
          const postData = { ...data };
          await createRegistrations(postData);
          handleClick("success", "Registration has been successfully added.");
          close();
          refreshData();
        } catch (error) {
          handleClick("error", "There seems to be some error adding the registration");
          close();
        }
      }
    }
  };

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: "10px", width: "400px" }}>
        <Grid item xs={12}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Registration Id"
            name="registrationId"
            required
            value={registrationId}
            onBlur={(e) => {
              setRegistrationIdTouched(true);
              const newError = {};
              validateRegistrationId(newError, registrationId);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setRegistrationId(e.target.value);
              if (registrationIdTouched) {
                const newError = {};
                validateRegistrationId(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.registrationId;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            size={"big"}
            startAdornment={<Person />}
            error={Boolean(errors.registrationId)}
            errorText={errors.registrationId}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Registration Council"
            name="registrationCouncil"
            required
            value={registrationCouncil}
            onBlur={(e) => {
              setRegistrationCouncilTouched(true);
              const newError = {};
              validateRegistrationCouncil(newError, registrationCouncil);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setRegistrationCouncil(e.target.value);
              if (registrationCouncilTouched) {
                const newError = {};
                validateRegistrationCouncil(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.registrationCouncil;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            size={"big"}
            startAdornment={<CorporateFare />}
            error={Boolean(errors.registrationCouncil)}
            errorText={errors.registrationCouncil}
          />
        </Grid>
        <Grid item xs={12}>
          <FormDatePicker
            label={"Valid from year"}
            required
            style={{ width: "100%", marginTop: "7px" }}
            disableFuture
            value={validFromYear}
            minDate={minRegistrationDate}
            onChange={(year) => {
              setValidFromYear(year);
              if (year === null) {
                setErrors({ ...errors, validFromYear: "Please select valid year." });
              } else if (!year.isValid()) {
                setErrors({
                  ...errors,
                  validFromYear: "Please select valid 'Year' value.",
                });
              } else if (year > currentDate) {
                setErrors({
                  ...errors,
                  validFromYear: "Year cannot be in a future.",
                });
              } else if (year.isBefore(minRegistrationDate)) {
                setErrors({
                  ...errors,
                  validFromYear: `Year should be at least ${minRegistrationDate.year()} for registrations.`,
                });
              } else {
                const { validFromYear, ...newError } = errors;
                setErrors(newError);
              }
            }}
            views={["year"]}
            openTo="year"
            disableBorder={false}
            error={Boolean(errors.validFromYear)}
            errorText={errors.validFromYear}
          />
        </Grid>

        <Grid item sx={{ mt: 1 }} container justifyContent={"center"}>
          <CustomButton
            onClick={saveRegistration}
            label={"Save"}
            className={"btn--secondary"}
            style={{ marginLeft: "auto" }}
          />
        </Grid>
      </Grid>
    </>
  );
};

const DoctorMemberModal = ({
  members,
  currentDate,
  selected,
  handleClick,
  close,
  doctorId,
  refreshData,
  minMembershipDate,
}) => {
  const [memberOf, setMemberOf] = useState(selected?.memberOf ? selected?.memberOf : "");
  const [fromYear, setFromYear] = useState(
    selected?.memberFromYear ? calendarValue(selected?.memberFromYear) : null
  );
  const [tillYear, setTillYear] = useState(
    selected?.memberTillYear ? calendarValue(selected?.memberTillYear) : null
  );

  const [memberOfTouched, setMemberOfTouched] = useState(false);

  const [errors, setErrors] = useState({});
  const pattern = /^[a-zA-Z0-9\s'-.()]+$/;

  const validateMemberOf = (errors, memberOf) => {
    if (!memberOf) {
      errors.memberOf = "Please enter valid name.";
    } else if (memberOf.length < 3) {
      errors.memberOf = "Name must be at least 3 characters long";
    } else if (memberOf.length > 100) {
      errors.memberOf = "Name cannot be longer than 100 characters";
    } else if (!pattern.test(memberOf)) {
      errors.memberOf = "Name can only contain following special characters: .-'()";
    } else if (!startsWithAlphabetic(memberOf)) {
      errors.memberOf = "Name must start with alphabet";
    }
  };

  const validate = () => {
    const errors = {};

    setMemberOfTouched(true);
    validateMemberOf(errors, memberOf);

    if (fromYear === null) {
      errors.fromYear = "Please select valid year.";
    } else if (!(fromYear?.isValid ? fromYear.isValid() : true)) {
      errors.fromYear = "Please select valid 'Year' value.";
    } else if (fromYear > currentDate) {
      errors.fromYear = "Year cannot be in future.";
    } else if (fromYear.isBefore(minMembershipDate)) {
      errors.fromYear = `Year should be after at least ${minMembershipDate.year()} for memberships.`;
    }

    if (tillYear === null) {
      errors.tillYear = "Please select valid year.";
    } else if (!(tillYear?.isValid ? tillYear.isValid() : true)) {
      errors.tillYear = "Please select valid 'Year' value.";
    } else if (tillYear <= fromYear) {
      errors.tillYear = "Year should be after the from year.";
    } else if (tillYear > currentDate) {
      errors.tillYear = "Year cannot be in future.";
    }

    return errors;
  };

  useEffect(() => {
    if (selected && members) {
      members.find((member) => {
        return member.id === selected.id;
      });
    }
  }, [selected, members]);

  const saveMember = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});

      let data = {
        doctorId: doctorId,
        memberOf: memberOf,
        memberFromYear: fromYear?.$y ? fromYear.$y : fromYear.year(),
        memberTillYear: tillYear?.$y ? tillYear.$y : tillYear.year(),
      };

      if (selected) {
        const postData = {
          ...data,
          id: selected?.id,
        };
        try {
          await updateMemberships(selected.id, postData);
          handleClick("success", "Membership has been successfully updated!!");
          close();
          refreshData();
        } catch (error) {
          handleClick("error", "There seems to be some error updating membership.");
          close();
        }
      } else {
        try {
          const postData = { ...data };
          await createMemberships(postData);
          handleClick("success", "Memberships has been successfully added!!");
          close();
          refreshData();
        } catch (error) {
          handleClick("error", "There seems to be some error adding memberships");
          close();
        }
      }
    }
  };

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: "10px", width: "400px" }}>
        <Grid item xs={12}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Membership Name"
            name="memberOf"
            required
            value={memberOf}
            onBlur={(e) => {
              setMemberOfTouched(true);
              const newError = {};
              validateMemberOf(newError, memberOf);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setMemberOf(e.target.value);
              if (memberOfTouched) {
                const newError = {};
                validateMemberOf(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.memberOf;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            size={"big"}
            startAdornment={<Diversity3 />}
            error={Boolean(errors.memberOf)}
            errorText={errors.memberOf}
          />
        </Grid>
        <Grid item xs={12}>
          <FormDatePicker
            label={"From year"}
            style={{ width: "100%", marginTop: "7px" }}
            disableFuture
            required
            value={fromYear}
            minDate={minMembershipDate}
            onChange={(year) => {
              setFromYear(year);
              if (year === null) {
                setErrors({ ...errors, fromYear: "Please select valid year." });
              } else if (!year.isValid()) {
                setErrors({ ...errors, fromYear: "Please select valid 'Year' value." });
              } else if (year > currentDate) {
                setErrors({
                  ...errors,
                  fromYear: "Year cannot be in a future.",
                });
              } else if (year.isBefore(minMembershipDate)) {
                setErrors({
                  ...errors,
                  fromYear: `Year should be after at least ${minMembershipDate.year()} for memberships.`,
                });
              } else {
                const { fromYear, ...newError } = errors;
                if (tillYear && tillYear.isBefore(year)) {
                  newError.tillYear = "End year should be after from year.";
                }
                setErrors(newError);
              }
            }}
            views={["year"]}
            openTo="year"
            disableBorder={false}
            error={Boolean(errors.fromYear)}
            errorText={errors.fromYear}
          />
        </Grid>
        <Grid item xs={12}>
          <FormDatePicker
            label={"Till year"}
            required
            minDate={fromYear ? fromYear : minMembershipDate}
            style={{ width: "100%", marginTop: "7px" }}
            value={tillYear}
            onChange={(year) => {
              setTillYear(year);
              if (year === null) {
                setErrors({ ...errors, tillYear: "Please select valid year." });
              } else if (!year.isValid()) {
                setErrors({ ...errors, tillYear: "Please select valid 'Year' value." });
              } else if (year < fromYear) {
                setErrors({
                  ...errors,
                  tillYear: "End year should be after from year.",
                });
              } else if (year > currentDate) {
                setErrors({
                  ...errors,
                  tillYear: "End year cannot be in a future.",
                });
              } else {
                const { tillYear, ...newError } = errors;
                setErrors(newError);
              }
            }}
            views={["year"]}
            openTo="year"
            disableBorder={false}
            error={Boolean(errors.tillYear)}
            errorText={errors.tillYear}
          />
        </Grid>

        <Grid item sx={{ mt: 1 }} container justifyContent={"center"}>
          <CustomButton
            onClick={saveMember}
            label={"Save"}
            className={"btn--secondary"}
            style={{ marginLeft: "auto" }}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default DoctorRegistrationAndMemberships;
