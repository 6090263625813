import {
  ClearOutlined,
  LocalHospitalOutlined,
  MonitorHeartOutlined,
  PersonOutlineOutlined,
} from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import dayjs from "dayjs";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getProcedures } from "services/EntitiesServices";
import {
  createPatientPastProcedures,
  deletePatientPastProcedures,
  editPatientPastProcedures,
  getPatientPastProceduresByPatientId,
} from "services/patientService";
import ModalUI from "ui-component/ModalUI";
import CustomAutoComplete from "ui-component/custom-components/CustomAutoComplete";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import Reveal from "views/utilities/Reveal";
import {
  DATE_FORMAT,
  DATE_FORMAT_DMY,
  startsAndEndsWithAlphabetic,
  startsWithAlphabetic,
} from "store/constant";

const columns = [
  { field: "Sr. No.", label: "Sr. No" },
  { field: "procedureName", label: "Name" },
  { field: "outcome", label: "Outcome" },
  { field: "datePerformed", label: "Date Performed" },
  { field: "performingDoctor", label: "Performing Doctor" },
  { field: "followUpDate", label: "Follow-up Date" },
  { field: "Actions", label: "Actions" },
];

const PatientPastProcedure = ({ patientDOB, patientId }) => {
  const [patientProcedures, setPatientProcedures] = useState([]);
  const [displayPatientProcedures, setDisplayPatientProcedures] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const { handleClick } = useContext(ToastContext);

  const clearSearch = () => {
    setSearchQuery("");
  };

  const handleMarkInactive = async (row) => {
    try {
      await deletePatientPastProcedures(row.id);
      handleClick("success", "Patient procedure has been successfully deleted.");
      const index = patientProcedures.findIndex((el) => el.id === row.id);
      patientProcedures.splice(index, 1);
      setDisplayPatientProcedures(patientProcedures);
    } catch (error) {
      handleClick("error", "There seems to be an error deleting the Patient procedure.");
    }
  };

  const closeModal = () => {
    setOpen(false);
    setSelected(null);
  };

  const openUpdateModal = (pProcedure) => {
    setSelected(pProcedure);
    setOpen(true);
  };

  const openCreateModal = () => {
    setOpen(true);
  };

  const fetchProcedures = async () => {
    try {
      const Procedures = await getProcedures();
      setProcedures(Procedures.data);
    } catch (error) {
      console.error("Error fetching Procedures");
    }
  };

  const fetchPatientProcedures = async () => {
    try {
      const response = await getPatientPastProceduresByPatientId(patientId);
      const PatientProcedure = response.data.map((pProcedure) => {
        return {
          ...pProcedure,
          procedureName: pProcedure?.procedureResponse.name,
          datePerformed: dayjs(pProcedure?.datePerformed).format(DATE_FORMAT_DMY),
          followUpDate: pProcedure?.followUpDate
            ? dayjs(pProcedure?.followUpDate).format(DATE_FORMAT_DMY)
            : null,
        };
      });
      setPatientProcedures(PatientProcedure);
    } catch (error) {
      console.error("Error fetching patient procedures");
    }
  };

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-line ri-xl icon-primary-blue" />,
      onClick: openUpdateModal,
    },
    {
      label: (rowData) => {
        return "Delete";
      },
      icon: <i className="ri-delete-bin-fill ri-xl icon-primary-blue" />,
      onClick: handleMarkInactive,
    },
  ];

  useEffect(() => {
    if (procedures.length > 0) {
      fetchPatientProcedures();
    }
  }, [procedures]);

  useEffect(() => {
    fetchProcedures();
  }, []);

  useEffect(() => {
    setDisplayPatientProcedures(
      patientProcedures.filter((item) => {
        const values = Object.values(item);
        const lowerCaseQuery = searchQuery.toLowerCase();
        return values.some((value, index) => {
          if (typeof value === "string" && index !== 7) {
            return value.toLowerCase().includes(lowerCaseQuery);
          }
          return false;
        });
      })
    );
  }, [patientProcedures, searchQuery]);

  return (
    <Reveal>
      <Grid display={"flex"} alignItems={"center"} sx={{ mb: 1 }}>
        <FormInputField
          style={{ width: "40%" }}
          label={"Search Procedure"}
          value={searchQuery}
          startAdornment={<i className="ri-search-line ri-lg" />}
          endAdornment={
            <IconButton onClick={clearSearch} edge="end">
              {<ClearOutlined fontSize="small" />}
            </IconButton>
          }
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          size="small"
        ></FormInputField>
        <CustomButton
          style={{ marginLeft: "auto" }}
          label={"Add"}
          className={"ri-add-fill ri-lg btn--primary"}
          onClick={openCreateModal}
        />
      </Grid>

      <CustomizedTable
        columns={columns}
        tableData={displayPatientProcedures}
        actions={actions}
        rowsPerPageInTable={10}
      />

      <ModalUI
        visible={open}
        close={closeModal}
        title={selected ? "Update Patient Procedure" : "Create Patient Procedure"}
        component={
          <PatientProcedureModal
            selected={selected}
            handleClick={handleClick}
            close={(operation) => {
              if (operation === "save") {
                closeModal();
                fetchPatientProcedures();
              } else {
                closeModal();
              }
            }}
            patientDOB={patientDOB}
            patientId={patientId}
            procedures={procedures}
          />
        }
      />
    </Reveal>
  );
};

const PatientProcedureModal = ({
  patientDOB,
  procedures,
  patientId,
  selected,
  handleClick,
  close,
}) => {
  const [outcome, setOutcome] = useState(selected?.outcome ? selected?.outcome : "");
  const [outcomeTouched, setOutcomeTouched] = useState(false);

  const [performingDoctor, setPerformingDoctor] = useState(
    selected?.performingDoctor ? selected?.performingDoctor : ""
  );
  const [performingDoctorTouched, setPerformingDoctorTouched] = useState(false);

  const [datePerformed, setDatePerformed] = useState(
    selected?.datePerformed
      ? dayjs(selected?.datePerformed, DATE_FORMAT_DMY).format(DATE_FORMAT)
      : null
  );
  const [followUpDate, setFOllowUpDate] = useState(
    selected?.followUpDate
      ? dayjs(selected?.followUpDate, DATE_FORMAT_DMY).format(DATE_FORMAT)
      : null
  );
  const [procedure, setProcedure] = useState(null);
  const [, setNewProcedure] = useState(null);
  const [errors, setErrors] = useState({});

  const currentDate = dayjs();
  const minDateForPastProcedure = dayjs(patientDOB ? patientDOB : "1900-01-01");
  const [performedDateTouched, setPerformedDateTouched] = useState(false);
  const [followUpDateTouched, setFollowUpDateTouched] = useState(false);

  const validateOutcome = (errors, outcome) => {
    if (!outcome) {
      errors.outcome = "Outcome is required.";
    } else if (outcome.length < 3) {
      errors.outcome = "Outcome must be at least 3 characters long";
    } else if (outcome.length > 255) {
      errors.outcome = "Outcome cannot be longer than 255 characters";
    } else if (!startsWithAlphabetic(outcome)) {
      errors.outcome = "Outcome must start with alphabet";
    }
  };

  const validatePerformingDoctor = (errors, performingDoctor) => {
    const pattern = /^[a-zA-Z\s]+$/;
    if (!performingDoctor) {
      errors.performingDoctor = "Please enter doctor name.";
    } else if (performingDoctor.length < 3) {
      errors.performingDoctor = "Name must be at least 3 characters long";
    } else if (performingDoctor.length > 100) {
      errors.performingDoctor = "Name cannot be longer than 100 characters";
    } else if (!pattern.test(performingDoctor)) {
      errors.performingDoctor = "Name can only contain letters and space";
    } else if (!startsAndEndsWithAlphabetic(performingDoctor)) {
      errors.performingDoctor = "Name  must start and end with alphabet";
    }
  };

  const validate = () => {
    const errors = {};

    setOutcomeTouched(true);
    validateOutcome(errors, outcome);

    setPerformingDoctorTouched(true);
    validatePerformingDoctor(errors, performingDoctor);

    setPerformedDateTouched(true);
    if (datePerformed === null) {
      errors.datePerformed = "Please select a valid date.";
    } else if (!(datePerformed?.isValid ? datePerformed.isValid() : true)) {
      errors.datePerformed = "Please select a valid 'Date' value.";
    } else if (datePerformed > currentDate) {
      errors.datePerformed = "Date cannot be in future.";
    } else if (datePerformed < minDateForPastProcedure) {
      errors.datePerformed = "Date can't be less than DOB.";
    }

    setFollowUpDateTouched(true);
    if (!(followUpDate?.isValid ? followUpDate.isValid() : true)) {
      errors.followUpDate = "Please select a valid 'Date' value.";
    } else if (followUpDate && followUpDate <= datePerformed) {
      errors.followUpDate = "Date should be after performed date";
    }

    if (!procedure) {
      errors.procedure = "Please select a procedure.";
    }

    return errors;
  };

  useEffect(() => {
    if (selected && procedures) {
      const Procedure = procedures.find((procedure) => {
        return procedure.id === selected.procedureId;
      });
      setProcedure(Procedure);
    }
  }, [selected, procedures]);

  const savePastProcedure = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});

      let data = {
        patientId,
        outcome,
        performingDoctor,
        datePerformed: dayjs(datePerformed).format(DATE_FORMAT),
        followUpDate: followUpDate ? dayjs(followUpDate).format(DATE_FORMAT) : null,
        procedureId: procedure.id,
      };

      if (selected) {
        try {
          await editPatientPastProcedures(selected.id, data);
          handleClick("success", "Patient Procedure has been successfully updated.");
          close("save");
        } catch (error) {
          handleClick("error", "There seems to be an error updating the Patient Procedure");
        }
      } else {
        try {
          const postData = { ...data };
          await createPatientPastProcedures(postData);
          handleClick("success", "Patient Procedure has been successfully created.");
          close("save");
        } catch (error) {
          handleClick("error", "There seems to be an error creating the Patient Procedure.");
        }
      }
    }
  };

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: "10px", width: "400px" }}>
        <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
          <CustomAutoComplete
            options={procedures}
            readOnly={selected}
            label={"Procedure"}
            placeholder="Select"
            required
            iconStart={<LocalHospitalOutlined />}
            getOptionLabel={(option) => option?.name}
            onChange={(event, value) => {
              setProcedure(value);
              const newErrors = { ...errors };
              delete newErrors.procedure;
              setErrors(newErrors);
            }}
            onInput={(event, newInputValue) => {
              setNewProcedure(newInputValue);
            }}
            value={procedure}
            error={Boolean(errors.procedure)}
            errorText={errors.procedure}
          />
        </Grid>
        <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
          <FormDatePicker
            inputFormat={DATE_FORMAT_DMY}
            label={"Performed When"}
            disableFuture
            value={datePerformed}
            minDate={minDateForPastProcedure}
            required
            size={"big"}
            onChange={(date) => {
              setDatePerformed(date);
              if (date === null) {
                setErrors({ ...errors, datePerformed: "Please select a valid date." });
              } else if (!date.isValid()) {
                setErrors({
                  ...errors,
                  datePerformed: "Please select a valid 'Date' value.",
                });
              } else if (date > currentDate) {
                setErrors({
                  ...errors,
                  datePerformed: "Date cannot be in a future.",
                });
              } else if (date < minDateForPastProcedure) {
                setErrors({
                  ...errors,
                  datePerformed: "Date can't be less than Date of birth.",
                });
              } else {
                const { datePerformed, ...newError } = errors;
                if (followUpDate && dayjs(followUpDate).isBefore(date)) {
                  newError.followUpDate = "Date should be after performed date";
                }
                setErrors(newError);
              }
            }}
            style={{
              width: "100%",
              marginRight: "30px",
              marginTop: "7px",
            }}
            error={Boolean(errors.datePerformed)}
            errorText={errors.datePerformed}
          ></FormDatePicker>
        </Grid>
        <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Outcome"
            required
            name="outcome"
            value={outcome}
            onBlur={(e) => {
              setOutcomeTouched(true);
              const newError = {};
              validateOutcome(newError, outcome);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setOutcome(e.target.value);
              if (outcomeTouched) {
                const newError = {};
                validateOutcome(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.outcome;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<MonitorHeartOutlined />}
            size={"big"}
            error={Boolean(errors.outcome)}
            errorText={errors.outcome}
          />
        </Grid>
        <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Performing Doctor"
            name="performingDoctor"
            required
            value={performingDoctor}
            onBlur={(e) => {
              setPerformingDoctorTouched(true);
              const newError = {};
              validatePerformingDoctor(newError, performingDoctor);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setPerformingDoctor(e.target.value);
              if (performingDoctorTouched) {
                const newError = {};
                validatePerformingDoctor(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.performingDoctor;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<PersonOutlineOutlined />}
            size={"big"}
            error={Boolean(errors.performingDoctor)}
            errorText={errors.performingDoctor}
          />
        </Grid>
        <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
          <FormDatePicker
            inputFormat={DATE_FORMAT_DMY}
            label={"Follow-up Date"}
            minDate={datePerformed ? datePerformed : minDateForPastProcedure}
            value={followUpDate}
            size={"big"}
            onChange={(date) => {
              setFOllowUpDate(date);
              if (!date.isValid()) {
                setErrors({ ...errors, followUpDate: "Please select a valid 'Date' value." });
              } else if (date <= datePerformed) {
                setErrors({
                  ...errors,
                  followUpDate: "Date should be after performed date",
                });
              } else {
                const { followUpDate, ...newError } = errors;
                setErrors(newError);
              }
            }}
            style={{
              width: "100%",
              marginRight: "30px",
              marginTop: "7px",
            }}
            error={Boolean(errors.followUpDate && followUpDateTouched)}
            errorText={errors.followUpDate}
          ></FormDatePicker>
        </Grid>

        <Grid item sx={{ mt: 1 }} container justifyContent={"center"}>
          <CustomButton
            label={"Cancel"}
            className={"btn--error"}
            style={{ width: "100px" }}
            onClick={() => close("cancel")}
          />

          <CustomButton
            onClick={savePastProcedure}
            label={"Save"}
            className={"btn--secondary"}
            style={{ marginLeft: "10px", width: "100px" }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PatientPastProcedure;
