import * as Yup from "yup";

export const procedureSchema = Yup.object().shape({
  procedure: Yup.string()
    .required("Please enter the procedure name.")
    .max(50, "Name is too long")
    .matches(/^[^0-9]*$/, "No numeric digits are allowed in Name")
    .matches(/^[A-Za-z0-9\s.,]*$/, "No special characters are allowed in Description")
    .matches(/^(?!\s*$).+$/, "Name must not contain empty spaces"),
});

export const procedureDescriptionSchema = Yup.object().shape({
  procedureDescription: Yup.string()
    .max(255, "Description Text is too long")
    .matches(/^[A-Za-z0-9\s.,]*$/, "No special characters are allowed in Description"),
});
