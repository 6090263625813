import React, { useState, useEffect } from "react";
import { Grid, Card, CardContent, CardHeader } from "@mui/material";
import { accessToken, ACTIVE_STATE, INACTIVE_STATE } from "store/constant";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { getBundles, createBundles, deleteBundle, updateBundles } from "services/Subscription";
import CustomButton from "ui-component/custom-components/CustomButton";
import { useContext } from "react";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import ModalUI from "ui-component/ModalUI";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DescriptionIcon from "@mui/icons-material/Description";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import { Menu, MenuList } from "@mui/material";
import CustomMenuItem from "../../../ui-component/custom-components/CustomMenuItem";
import SwitchLeftIcon from "@mui/icons-material/SwitchLeft";
import { useNavigate } from "react-router";
import { bundleValidation } from "../Common/ValidationSchema/packageValidation";
import { Formik } from "formik";
import { getFeatures } from "services/EntitiesServices";
import { DomainOutlined } from "@mui/icons-material";
import { ListItemText } from "@mui/material";

const Bundles = () => {
  const { handleClick } = useContext(ToastContext);

  const [bundles, setBundles] = useState([]);
  const [features, setFeatures] = useState([]);
  const accessTokenValue = accessToken();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);

  const initialBundleState = {
    name: "",
    description: "",
    price: "",
    usageLimit: "",
    features: [],
  };

  const [bundleInfo, setbundleInfo] = useState(initialBundleState);
  const [anchorEl, setAnchorEl] = useState(null);
  const [clickedRow, setClickedRow] = useState(null);

  const fetchBundles = async () => {
    try {
      const response = await getBundles();
      response.data = response.data.map((el) => {
        if (
          el.bundleFeatureAssociationResponses &&
          el.bundleFeatureAssociationResponses.length > 0
        ) {
          el.features = el.bundleFeatureAssociationResponses.map(
            (subEl) => subEl.featureResponse.name
          );
        } else {
          el.features = [];
        }
        return el;
      });
      setBundles(response.data);
    } catch (error) {
      console.error("Error fetching bundles!");
    }
  };

  useEffect(() => {
    const fetchFeatures = async () => {
      try {
        const response = await getFeatures();
        response.data = response.data.map((el) => {
          el.value = el.id;
          el.menuLabel = el.name;
          return el;
        });
        setFeatures(response.data);
      } catch (error) {
        console.error("Error fetching features!");
      }
    };
    fetchBundles();
    fetchFeatures();
  }, [accessTokenValue, handleClick]);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setbundleInfo(initialBundleState);
  };

  const getFeatureBundleObj = (featureName) => {
    return {
      featureId: features.find((el) => el.name === featureName).id,
      bundleResponse: null,
      featureResponse: null,
      status: ACTIVE_STATE,
    };
  };

  const handleSaveBundle = async (values) => {
    try {
      if (values.hasOwnProperty("id")) {
        // from the new array we need to add the newely selected features
        // and we need to make inactive the deselected features
        const oldFeatureIdArr = values.bundleFeatureAssociationResponses.map((el) => el.featureId);
        const newFeatureIdArr = values.features.map((fName) => {
          return features.find((el) => el.name === fName).id;
        });
        const toBeInactiveIds = oldFeatureIdArr.filter((item) => !newFeatureIdArr.includes(item));
        const toBeAddedIds = newFeatureIdArr.filter((item) => !oldFeatureIdArr.includes(item));
        toBeInactiveIds.forEach((id) => {
          const foundEl = values.bundleFeatureAssociationResponses.find(
            (el) => el.featureId === id
          );
          foundEl.status = INACTIVE_STATE;
        });
        toBeAddedIds.forEach((id) => {
          const tempObj = {
            featureId: id,
            bundleResponse: null,
            featureResponse: null,
            status: ACTIVE_STATE,
          };
          values.bundleFeatureAssociationResponses.push(tempObj);
        });
        const data = {
          id: values.id,
          name: values.name,
          description: values.description,
          //  this can be changed in future not used right now
          type: "HMIS",
          price: values.price,
          usageLimit: values.usageLimit,
          bundleFeatureAssociationResponses: values.bundleFeatureAssociationResponses,
          status: ACTIVE_STATE,
        };
        await updateBundles(data, values.id);
        await fetchBundles();
        closeModal();
        handleClick("success", "Bundle has been successfully updated.");
      } else {
        let selFeatureArr = values.features.map((fName) => {
          return getFeatureBundleObj(fName);
        });
        const data = {
          name: values.name,
          description: values.description,
          //  this can be changed in future not used right now
          type: "HMIS",
          price: values.price,
          usageLimit: values.usageLimit,
          bundleFeatureAssociationResponses: selFeatureArr,
          status: ACTIVE_STATE,
        };
        await createBundles(data);
        await fetchBundles();
        closeModal();
        handleClick("success", "Bundle has been successfully added.");
      }
    } catch (error) {
      handleClick("error", "Something went wrong!");
    }
  };

  const navigateToPackages = () => {
    navigate("/home/packages");
  };

  const handleMenuOpen = (event, row) => {
    setClickedRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleUpdate = (data) => {
    setModalOpen(true);
    setbundleInfo(data);
  };

  const handleDeleteBundle = async (data) => {
    try {
      await deleteBundle(data.id);
      const indexToRemove = bundles.findIndex((el) => el.id === data.id);
      setBundles([...bundles.slice(0, indexToRemove), ...bundles.slice(indexToRemove + 1)]);
      handleClick("success", "Bundle has been successfully deleted.");
    } catch (error) {
      // comparing this message in this case otherwise the backend error message will be displayed in all the cases
      if (
        error?.response?.data?.message === "Cannot delete as bundle is associated with packages."
      ) {
        handleClick(
          "error",
          "Cannot delete as this bundle is already associated with some packages!"
        );
      } else {
        handleClick("error", "There seems to be an error deleting the bundle.");
      }
    }
  };

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: handleUpdate,
    },
    {
      label: "Delete",
      icon: <i className="ri-delete-bin-fill ri-xl icon-primary-blue" />,
      onClick: handleDeleteBundle,
    },
  ];

  const getFeatureNames = (features) => {
    return features.join(", ");
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <h2 style={{ display: "inline" }}>Bundles</h2>
        <CustomButton
          className="btn--secondary"
          label="Manage Packages"
          style={{ marginLeft: "auto" }}
          onClick={() => navigateToPackages()}
        />
        <CustomButton
          className="ri-add-fill ri-lg btn--primary"
          label="Add Bundle"
          style={{ marginLeft: "10px" }}
          onClick={() => handleModalOpen()}
        />
      </div>
      <Grid container className="row mb-0 mx-0">
        {bundles &&
          bundles.length !== 0 &&
          bundles.map((el, index) => {
            return (
              <>
                <Grid key={index} item md={4} className="col">
                  <Card
                    style={{
                      backgroundColor: "#edf1f3",
                      margin: "5px",
                      borderRadius: "15%",
                      padding: "15px",
                    }}
                  >
                    <CardHeader
                      style={{ margin: "15px", textAlign: "center" }}
                      title={<label style={{ fontSize: "25px" }}>{el.name}</label>}
                      action={
                        <>
                          <CustomButton
                            iconButton={<i className="ri-more-2-fill" />}
                            onClick={(event) => handleMenuOpen(event, el)}
                          ></CustomButton>
                          <Menu
                            elevation={1}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            PaperProps={{ style: { borderRadius: "4px" } }}
                          >
                            <MenuList dense sx={{ p: 0 }}>
                              {actions.map((action, actionIndex) => {
                                return (
                                  <CustomMenuItem
                                    key={actionIndex}
                                    text={action.label}
                                    icon={action.icon}
                                    onClick={() => {
                                      handleMenuClose();
                                      action.onClick(clickedRow);
                                    }}
                                  />
                                );
                              })}
                            </MenuList>
                          </Menu>
                        </>
                      }
                    />
                    <CardContent>
                      <div className="row" style={{ margin: "10px", textAlign: "center" }}>
                        <Grid item md={12} className="col mb-2">
                          <label style={{ fontSize: "15px" }}>{el.description}</label>
                        </Grid>
                      </div>
                      <div className="row" style={{ margin: "10px", textAlign: "center" }}>
                        <Grid item md={4} className="col">
                          <label style={{ fontSize: "15px" }}>Price(INR) :</label>
                        </Grid>
                        <Grid item md={8} className="col">
                          <label style={{ fontSize: "15px" }}>
                            <b>{el.price} Rs.</b>
                          </label>
                        </Grid>
                      </div>
                      <div className="row" style={{ margin: "10px", textAlign: "center" }}>
                        <Grid item md={4} className="col">
                          <label style={{ fontSize: "15px" }}>Usage Limit :</label>
                        </Grid>
                        <Grid item md={8} className="col">
                          <label style={{ fontSize: "15px" }}>
                            <b>{el.usageLimit} per day</b>
                          </label>
                        </Grid>
                      </div>
                      <div className="row" style={{ margin: "10px", textAlign: "center" }}>
                        <Grid item md={4} className="col">
                          <label style={{ fontSize: "15px" }}>Features :</label>
                        </Grid>
                        <Grid item md={8} className="col">
                          <label style={{ fontSize: "15px" }}>
                            <b>{getFeatureNames(el.features)}</b>
                          </label>
                        </Grid>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </>
            );
          })}
        {bundles && bundles.length === 0 && (
          <>
            <span style={{ display: "inline", fontSize: "20px", marginTop: "10px" }}>
              There are no active bundles yet!
            </span>
          </>
        )}
      </Grid>
      <ModalUI
        visible={modalOpen}
        close={closeModal}
        title={bundleInfo?.id ? "Update Bundle" : "Add Bundle"}
        component={
          <>
            <Formik
              validateOnMount={true}
              enableReinitialize={true}
              initialValues={bundleInfo}
              validationSchema={bundleValidation}
              onSubmit={async (values) => {
                handleSaveBundle(values);
              }}
            >
              {({ values, errors, touched, handleChange, handleSubmit }) => (
                <>
                  <div style={{ width: "400px" }}>
                    <FormInputField
                      style={{ width: "100%", marginTop: "20px" }}
                      label={"Name*"}
                      name={"name"}
                      inputProps={{ maxLength: 30 }}
                      value={values.name}
                      onChange={handleChange}
                      error={Boolean(touched.name && errors.name)}
                      errorText={errors.name}
                      startAdornment={<DriveFileRenameOutlineIcon></DriveFileRenameOutlineIcon>}
                    ></FormInputField>
                    <FormInputField
                      style={{ width: "100%", marginTop: "20px" }}
                      label="Description*"
                      name="description"
                      value={values.description}
                      inputProps={{ maxLength: 100 }}
                      onChange={handleChange}
                      error={Boolean(touched.description && errors.description)}
                      errorText={errors.description}
                      startAdornment={<DescriptionIcon></DescriptionIcon>}
                    ></FormInputField>
                    <FormInputField
                      style={{ width: "48%", marginTop: "20px", marginRight: "15px" }}
                      label={"Price (INR)*"}
                      name={"price"}
                      type="number"
                      value={values.price}
                      onChange={handleChange}
                      error={Boolean(touched.price && errors.price)}
                      errorText={errors.price}
                      startAdornment={<CurrencyRupeeIcon></CurrencyRupeeIcon>}
                    ></FormInputField>
                    <FormInputField
                      style={{ width: "48%", marginTop: "20px" }}
                      label="Usage Limit (day)*"
                      name="usageLimit"
                      type="number"
                      value={values.usageLimit}
                      onChange={handleChange}
                      error={Boolean(touched.usageLimit && errors.usageLimit)}
                      errorText={errors.usageLimit}
                      startAdornment={<AccessTimeIcon></AccessTimeIcon>}
                    ></FormInputField>
                    <FormSelectField
                      style={{
                        width: "100%",
                        marginTop: "25px",
                        marginRight: "30px",
                      }}
                      label={"Features"}
                      name={"features"}
                      value={values.features}
                      multiple
                      renderValue={(selected) => {
                        return selected.map((el) => (el?.name ? el.name : el)).join(", ");
                      }}
                      startAdornment={<DomainOutlined />}
                      onChange={handleChange}
                      error={Boolean(errors.features && touched.features)}
                      errorText={errors.features}
                      menuItems={features.map((el) => {
                        return {
                          ...el,
                          value: el?.name,
                          menuLabel: (
                            <>
                              <ListItemText primary={el?.name} />
                            </>
                          ),
                        };
                      })}
                    ></FormSelectField>
                    <CustomButton
                      className="btn--secondary"
                      style={{ marginTop: "20px", padding: "5px 50px", float: "right" }}
                      type="submit"
                      label={"Save"}
                      onClick={handleSubmit}
                    ></CustomButton>
                  </div>
                </>
              )}
            </Formik>
          </>
        }
      ></ModalUI>
    </>
  );
};

export default Bundles;
