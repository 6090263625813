import { Box, Divider, Grid, Skeleton, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import ModalUI from "ui-component/ModalUI";
import UpdateTaxRates from "./UpdateTaxRates";
import {
  taxRateSchema,
  taxRateDescriptionSchema,
} from "../../Common/ValidationSchema/taxRatesValidations";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  ACTIVE_STATE,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  accessToken,
  entitiesInfo,
} from "store/constant";
import SwitchLeftIcon from "@mui/icons-material/SwitchLeft";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { createTaxRates, deleteTaxRates, getTaxRates } from "services/BillingService";
import { useContext } from "react";
import { useCallback } from "react";
import Reveal from "views/utilities/Reveal";
import dayjs from "dayjs";
import { PercentOutlined } from "@mui/icons-material";
import InfoModal from "../InfoModal";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router";

const TaxRates = () => {
  const [taxRates, settaxRates] = useState([]);
  const [newTaxRate, setnewTaxRate] = useState("");
  const [newTaxRateDescription, setnewTaxRateDescription] = useState("");
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const { handleClick } = useContext(ToastContext);
  const accessTokenValue = accessToken();
  const entityInfo = entitiesInfo.find((entity) => entity.id === "TaxRates");
  const [openDialog, setOpenDialog] = useState(false);
  const [errors, setErrors] = useState({ taxRate: "", taxRateDescription: "" });
  const [taxRateTouched, setTaxRateTouched] = useState(false);
  const [taxRateDescriptionTouched, setTaxRateDescriptionTouched] = useState(false);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const response = await getTaxRates();
      settaxRates(response.data);
    } catch (error) {
      console.error("Error fetching Tax Rates");
    }
  }, []);

  const handleDelete = async (taxRate) => {
    try {
      await deleteTaxRates(taxRate.id);
      handleClick("success", "Tax Rate has been successfully deleted!");
      fetchData();
    } catch (e) {
      handleClick("error", "There seems to be an error deleting the Tax Rate.");
    }
  };

  const validateTaxRate = async (taxRate) => {
    try {
      await taxRateSchema.validate({ taxRate });
      setErrors((prev) => ({ ...prev, taxRate: "" }));
    } catch (e) {
      setErrors((prev) => ({ ...prev, taxRate: e.message }));
    }
  };

  const validateTaxRateDescription = async (taxRateDescription) => {
    try {
      await taxRateDescriptionSchema.validate({ taxRateDescription });
      setErrors((prev) => ({ ...prev, taxRateDescription: "" }));
    } catch (e) {
      setErrors((prev) => ({ ...prev, taxRateDescription: e.message }));
    }
  };

  const addTaxRates = async () => {
    await validateTaxRate(newTaxRate);
    await validateTaxRateDescription(newTaxRateDescription);
    if (errors.taxRate || errors.taxRateDescription) {
      handleClick(
        "error",
        errors.taxRate +
          (errors.taxRate && errors.taxRateDescription ? ", " : "") +
          errors.taxRateDescription
      );
      return;
    }

    const data = {
      rate: newTaxRate,
      description: newTaxRateDescription,
      applicableFrom: dayjs(),
      applicableTo: dayjs(),
    };

    if (taxRates.some((t) => t.rate === Number(newTaxRate))) {
      handleClick("error", "Tax Rate already exists");
      return;
    }

    try {
      const res = await createTaxRates(data);
      handleClick("success", "Tax Rate has been successfully added.");
      setnewTaxRate("");
      setnewTaxRateDescription("");
      settaxRates([...taxRates, res.data]);
    } catch (e) {
      handleClick("error", "There seems to be an error creating the Tax Rate.");
    }
    setOpenModal(false);
  };

  useEffect(() => {
    fetchData();
  }, [accessTokenValue, fetchData]);

  const closeModal = () => {
    setOpen(false);
    setSelected(null);
  };

  const openUpdateModal = (spec) => {
    setSelected(spec);
    setOpen(true);
  };

  const columns = [
    { field: "Sr. No.", label: "Sr. No" },
    { field: "rate", label: "Tax Rate" },
    { field: "description", label: "Description" },
    { field: "Actions", label: "Actions" },
  ];

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: openUpdateModal,
    },
    {
      label: (rowData) => {
        return rowData.status === ACTIVE_STATE ? "Mark as Inactive" : "Mark as Active";
      },
      icon: <SwitchLeftIcon style={{ color: "#004C70" }} />,
      onClick: handleDelete,
    },
  ];

  return (
    <TaxRatesSkeleton>
      <Reveal>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CustomButton
            className={"btn--secondary-light"}
            iconButton={<img src="/images/hc_back_arrow.svg" alt="back-arrow"></img>}
            onClick={() => navigate(-1)}
          />
          <div style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
            <h2 style={{ marginBottom: "15px", fontSize: "25px", marginTop: "15px" }}>Tax Rates</h2>
            {entityInfo && (
              <>
                <InfoIcon
                  style={{ cursor: "pointer", marginLeft: "15px" }}
                  onClick={() => setOpenDialog(true)}
                />
                <InfoModal
                  open={openDialog}
                  onClose={() => setOpenDialog(false)}
                  entityInfo={{ ...entityInfo, title: "Tax Rates" }}
                />
              </>
            )}
          </div>
        </div>
        <Divider sx={{ mb: "10px", borderBottomWidth: 2 }} />
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <CustomButton
            className="btn--primary"
            label={" + Add Tax Rate"}
            onClick={() => setOpenModal(true)}
          />
        </Grid>
        <ModalUI
          visible={openModal}
          close={() => {
            setOpenModal(false);
            setnewTaxRate("");
            setnewTaxRateDescription("");
          }}
          title={"Tax Rate"}
          style={{
            height: "20rem",
            width: "25rem",
            padding: "20px",
          }}
          component={
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: "19px",
                    fontWeight: 600,
                    marginTop: "15px",
                    // color: "#004C70",
                  }}
                >
                  Add a new Tax Rate
                </Typography>
                <Grid container direction="column" spacing={3} sx={{ mt: 1 }}>
                  <Grid item xs={5} sx={{ mr: 2 }}>
                    <FormInputField
                      style={{ width: "100%" }}
                      type="number"
                      label={"Tax Rate"}
                      value={newTaxRate}
                      error={Boolean(errors.taxRate)}
                      errorText={errors.taxRate}
                      onBlur={(e) => {
                        setTaxRateTouched(true);
                        validateTaxRate(e.target.value);
                      }}
                      onChange={(e) => {
                        setnewTaxRate(e.target.value);
                        if (taxRateTouched) {
                          validateTaxRate(e.target.value);
                        }
                      }}
                      startAdornment={<PercentOutlined />}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={5} sx={{ mr: 2 }}>
                    <FormInputField
                      style={{ width: "100%" }}
                      label={"Tax Rate Description"}
                      value={newTaxRateDescription}
                      error={Boolean(errors.taxRateDescription)}
                      errorText={errors.taxRateDescription}
                      onBlur={(e) => {
                        setTaxRateDescriptionTouched(true);
                        validateTaxRateDescription(e.target.value);
                      }}
                      onChange={(e) => {
                        setnewTaxRateDescription(e.target.value);
                        if (taxRateDescriptionTouched) {
                          validateTaxRateDescription(e.target.value);
                        }
                      }}
                      startAdornment={<DescriptionOutlinedIcon />}
                      size="small"
                    />
                  </Grid>
                  <Grid item sx={{ mt: 0.5, mr: 2, display: "flex", justifyContent: "flex-end" }}>
                    <CustomButton
                      className="btn--primary"
                      disabled={newTaxRate === ""}
                      onClick={addTaxRates}
                      label="Add"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        />
        <Grid
          sx={{
            mt: 2,
            textAlign: "center",
          }}
        ></Grid>
        <CustomizedTable columns={columns} tableData={taxRates} actions={actions} />

        <ModalUI
          visible={open}
          close={closeModal}
          title="Update Tax Rate"
          component={
            <UpdateTaxRates
              selected={selected}
              close={() => {
                closeModal();
                fetchData();
              }}
            />
          }
        />
      </Reveal>
    </TaxRatesSkeleton>
  );
};

const TaxRatesSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"}>
          <Skeleton
            sx={{ borderRadius: "4px", mr: "10%" }}
            animation="wave"
            variant="rectangular"
            width="90%"
            height={80}
          />
        </Grid>

        <Grid item xs={12}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={350}
          />
        </Grid>

        <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
          <Grid item xs={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TaxRates;
