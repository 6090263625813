import dayjs from "dayjs";
import * as Yup from "yup";

export const packageValidation = Yup.object().shape({
  name: Yup.string()
    .required("Please enter a package name.")
    .min(3, "Minimum 3 characters are required")
    .max(30, "Maximum 30 characters are allowed"),
  description: Yup.string()
    .required("Please enter the package description.")
    .min(3, "Minimum 3 characters are required")
    .max(100, "Maximum 100 characters are allowed"),
  price: Yup.number()
    .required("Please enter the price.")
    .min(0, "Price cannot be negative")
    .max(100000, "Price cannot be greater than 100000"),
  type: Yup.string().required("Please select a package type"),
  validTill: Yup.date()
    .required("Please select a valid till date.")
    .min(dayjs(), "Till date can not be in the past"),
  validity: Yup.number()
    .required("Please enter the validity.")
    .min(0, "Validity cannot be negative")
    .max(365, "Validity cannot be greater than 365"),
});

export const bundleValidation = Yup.object().shape({
  name: Yup.string()
    .required("Please enter a bundle name.")
    .max(30, "Maximum 30 characters are allowed"),
  description: Yup.string()
    .required("Please enter the description.")
    .max(100, "Maximum 100 characters allowed"),
  price: Yup.number()
    .required("Please enter the price.")
    .min(0, "Price cannot be negative")
    .max(100000, "Price cannot be greater than 100000"),
  usageLimit: Yup.number()
    .required("Please enter the usage limit.")
    .min(0, "Usage Limit cannot be negative")
    .max(1000, "Usage Limit cannot be greater than 1000"),
  features: Yup.array().min(1, "Please select at least one feature"),
});
