import React, { useRef, useState } from "react";

// material-ui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid,
} from "@mui/material";

// assets
import "../../../assets/scss/style.scss";
import CustomButton from "ui-component/custom-components/CustomButton";
import "../../../assets/scss/animations.scss";

// seful imports

import {
  CorporateFareOutlined,
  HourglassBottomOutlined,
  Settings,
  SettingsOutlined,
} from "@mui/icons-material";
import {
  CLINIC_ADMIN,
  DATE_FORMAT,
  DOCTOR,
  FRONT_DESK,
  NONE,
  PATIENT,
  TIME_FORMAT,
  appEnv,
  isDemo,
  orgId,
  roleName,
  SUPER_ADMIN,
} from "store/constant";
import { currentActiveUser, blockCalendeOptions } from "store/constant";
import { useEffect } from "react";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { useLocation, useNavigate } from "react-router";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  checkInAvailabilities,
  createBlockAvailabilities,
  currentStatusDoctor,
} from "services/Availability";
import { getAllOrganizations } from "services/organizationService";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { setRenderSidebar, setUserOrgId } from "store/Actions/userActions";
import { useSelector } from "react-redux";
import { getIsSyncing } from "store/Reducers/CloudSyncReducer";
import { setShowSyncModal } from "store/Actions/CloudSyncActions";
import RIf from "ui-component/RIf";
import { Formik } from "formik";
import { checkoutValidation } from "views/Components/Common/ValidationSchema/checkoutValidation";
import { getBlockAvailabilitiesByDate } from "services/Availability";
import eventBus from "utils/eventBus";
import { getRenderSidebar } from "store/Reducers/userReducer";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({
  toggleMainClass,
  setSecondarySidebarData,
  handleLeftDrawerToggle,
  settingsIconChecked,
  routeBeforeSettingsSection,
  setRouteBeforeSettingsSection,
}) => {
  /*
  todo : by default we are setting isCheckedIn true when doctor login.
  once backend stores the checkIn flag, we can fetch status and display.
   */
  const [isCheckedIn, setIsCheckedIn] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [allOrganizations, setAllOrganizations] = useState([]);
  const [userOrganisationAssociation, setUserOrganisationAssociation] = useState([]);
  const { handleClick } = useContext(ToastContext);

  const location = useLocation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [organizationDetails, setOrganizationDetails] = useState([]);
  const [buttonLabel, setButtonLabel] = useState("Unavailable");

  const formikRef = useRef(null);

  const [menuItemsAvailability, setMenuItemsAvailability] = useState([
    { label: "Unavailable", badgeColor: "#345678", disabled: false },
    { label: "Available", badgeColor: "#29BF91", disabled: false },
  ]);
  const organizationId = orgId();

  const isRunningOnElectron = appEnv === "electron";

  useEffect(() => {
    setButtonLabel(isCheckedIn ? "Available" : "Unavailable");
    setMenuItemsAvailability([
      {
        label: "Unavailable",
        badgeColor: "#345678",
        disabled: isCheckedIn ? false : true,
      },
      {
        label: "Available",
        badgeColor: "#29BF91",
        disabled: isCheckedIn ? true : false,
      },
    ]);
  }, [isCheckedIn]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: doctorStatus } = await currentStatusDoctor(
          currentActiveUser()?.roleBasedId,
          orgId()
        );
        setIsCheckedIn(doctorStatus);
        setButtonLabel(doctorStatus ? "Available" : "Unavailable");
      } catch (error) {}
    };
    if (currentActiveUser().roleName === DOCTOR) {
      fetchData();
      const intervalId = setInterval(fetchData, 30 * 60 * 1000);
      return () => clearInterval(intervalId); // Clean up the interval when the component unmounts
    }
  }, [organizationId]);

  const menuItemsClinic = organizationDetails.map((item, index) => ({
    label: `${item.name} - ${item.code}`,
    ...item,
  }));

  const getAssociatedOrganization = (userOrganisationAssociation, allOrganizations) => {
    if (userOrganisationAssociation.length > 0 && allOrganizations.data) {
      const associatedOrgIds = userOrganisationAssociation.map((obj) => obj?.organizationId);
      const filteredOrganization = allOrganizations.data.filter((organization) =>
        associatedOrgIds.includes(organization.id)
      );
      setOrganizationDetails(filteredOrganization.sort((a, b) => a.id - b.id));
    }
  };

  useEffect(() => {
    // Early return for role 5
    if (roleName() === PATIENT) return;

    const fetchData = async () => {
      try {
        // Fetch user and organizations in parallel
        const [userResponse, organizationResponse] = await Promise.all([
          currentActiveUser(),
          getAllOrganizations(),
        ]);

        const user = userResponse;
        const allOrganizations = organizationResponse;

        if (
          user?.roleName === DOCTOR ||
          user?.roleName === CLINIC_ADMIN ||
          user?.roleName === FRONT_DESK
        ) {
          setUserOrganisationAssociation(user.userOrganizationAssociationList);
        }
        setAllOrganizations(allOrganizations);
      } catch (error) {
        console.error("Error fetching doctor data");
      }
    };

    fetchData();
  }, [handleClick]);

  useEffect(() => {
    getAssociatedOrganization(userOrganisationAssociation, allOrganizations);
  }, [userOrganisationAssociation, allOrganizations]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    const handleCustomEvent = async (data) => {
      if (data.isBlockedDateChanged === true) {
        await handleCheckInOut(currentActiveUser().roleBasedId);
      }
    };
    eventBus.on("doctorAvailability", handleCustomEvent);
    return () => {
      eventBus.off("doctorAvailability", handleCustomEvent); // Cleanup the event listener
    };
  }, []);

  const handleCheckInOut = async (doctorId) => {
    try {
      const { data: blockAvailabilities } = await getBlockAvailabilitiesByDate(
        doctorId,
        dayjs().format(DATE_FORMAT)
      );
      const currTime = dayjs().format(TIME_FORMAT);
      if (
        blockAvailabilities &&
        blockAvailabilities.length > 0 &&
        blockAvailabilities[0].serviceType === NONE &&
        currTime >= blockAvailabilities[0].startTime &&
        currTime <= blockAvailabilities[0].endTime
      ) {
        setIsCheckedIn(false);
      } else {
        setIsCheckedIn(true);
      }
    } catch (error) {
      handleClick("error", "There seems to be an error while checking-in.");
    }
  };

  // const handleCheckout = async () => {
  //   const toastMessage =
  //     checkoutType === "hours" ? `Checked Out for ${hours} Hours.` : "Checked Out for Full day.";

  //   const now = dayjs();
  //   const startTime = now.format(TIME_FORMAT);
  //   const hoursToAdd = parseInt(hours);
  //   const calculatedHours = now.hour() + hoursToAdd;
  //   if (calculatedHours >= 24) {
  //     handleClick("warning", "Hours exceeding the day");
  //     setHours(null);
  //     setCheckoutType("fullDay");
  //     return;
  //   }
  //   const endTime = now.add(hoursToAdd, "hour").format(TIME_FORMAT);
  //   const payload = {
  //     doctorId: currentActiveUser().roleBasedId,
  //     organizationId:
  //       menuItemsClinic && menuItemsClinic.length > 1
  //         ? selectedOrganizations
  //         : [menuItemsClinic[0].id],
  //     blockingType: checkoutType === "hours" ? "CUSTOM_HOURS" : "ENTIRE_DAY",
  //     startTime: checkoutType === "hours" ? startTime : null,
  //     endTime: checkoutType === "hours" ? endTime : null,
  //     serviceType: selectedAppointmentOption,
  //   };
  //   try {
  //     await createBlockAvailabilities(payload);
  //     handleClick("success", toastMessage);
  //     setIsCheckedIn(false);
  //     handleCloseModal();
  //   } catch (error) {
  //     handleClick("error", "Error checking out");
  //   }
  // };

  const handleAvailabilityMenu = async () => {
    if (isCheckedIn) {
      // check-out
      setOpenModal(true);
    } else {
      // check-in
      const payload = {
        doctorId: currentActiveUser().roleBasedId,
        organizationId: [organizationId],
      };
      try {
        await checkInAvailabilities(payload);
        handleClick("success", "You have successfully checked-in.");
        setIsCheckedIn(true);
      } catch (error) {
        handleClick("error", "There seems to be an error while checking-in.");
      }
    }
  };

  const handleMenuClick = (item) => {
    dispatch(setUserOrgId(item?.id));
    setSecondarySidebarData(null);
    toggleMainClass((prevClass) => {
      if (
        prevClass === "main responsive-shrink" ||
        prevClass === "main shrink" ||
        prevClass === "main sub-sidebar"
      ) {
        return "main";
      } else {
        return prevClass;
      }
    });
    navigate("/home/dashboard");
  };

  const checkRoleAndOrganization = () => {
    const acceptedRoles = [CLINIC_ADMIN, DOCTOR, FRONT_DESK];
    if (acceptedRoles.includes(roleName()) && menuItemsClinic && menuItemsClinic.length > 1) {
      return true;
    } else {
      return false;
    }
  };

  // Sync Business Logic
  const isSyncing = useSelector(getIsSyncing);
  const onSyncButtonClick = async () => {
    dispatch(setShowSyncModal(true));
  };

  const renderSidebarData = useSelector(getRenderSidebar);
  const checkSettingIconVisible = () => {
    const acceptedRoles = [CLINIC_ADMIN, DOCTOR, SUPER_ADMIN];
    return acceptedRoles.includes(roleName());
  };

  return (
    <div
      className={"row header" + (roleName() === DOCTOR ? " responsive-header " : " ") + "fixed-row"}
    >
      <div className="col col-1">
        <form
          className="global-search"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <input className="global-search" type="search" placeholder="Search..." />
          <button className="btn btn-transparent form-button-group">
            <img src="/images/hc_mic.svg" alt="hc_mic" className="inline-icon ri-mic-line ri-lg" />
          </button>
        </form>
      </div>
      <RIf show={isDemo()}>
        <CustomButton
          label="Home"
          textAndIconColor={"#FFF"}
          onClick={() => navigate("/demo")}
          MuiButtoncolor="success"
          style={{ marginRight: "10px" }}
        />
      </RIf>

      <div className="col col-2">
        <ul className="horizontal-ul">
          {checkSettingIconVisible() && (
            <li className="main-actions">
              <CustomButton
                iconButton={
                  settingsIconChecked ? (
                    <Settings style={{ color: "#004C70" }}></Settings>
                  ) : (
                    <SettingsOutlined style={{ color: "#004C70" }}></SettingsOutlined>
                  )
                }
                onClick={() => {
                  if (!settingsIconChecked) {
                    setRouteBeforeSettingsSection(location.pathname);
                    dispatch(setRenderSidebar(true));
                  } else {
                    if (routeBeforeSettingsSection) {
                      navigate(routeBeforeSettingsSection);
                      setRouteBeforeSettingsSection("");
                      dispatch(setRenderSidebar(false));
                    } else {
                      navigate("/home/dashboard");
                    }
                  }
                }}
                style={{ padding: "2px 5px", marginRight: "5px" }}
                customBackGroundColor="#29BF911A"
              ></CustomButton>
            </li>
          )}
          {roleName() === DOCTOR && (
            <li className="tertiary-actions">
              <div className="mui-dropdown">
                <CustomButton
                  label={buttonLabel}
                  className={"ri-timer-line ri-lg btn--secondary-light"}
                  endIcon={<i className="ri-arrow-down-s-fill" />}
                  dropDownMenuItems={menuItemsAvailability}
                  onMenuItemClick={handleAvailabilityMenu}
                  menuItemHoverColor="#29BF91"
                />
              </div>
            </li>
          )}
          {checkRoleAndOrganization() && (
            <li className="tertiary-actions">
              <div className="mui-dropdown">
                {menuItemsClinic.find((e) => e?.id === organizationId) && (
                  <CustomButton
                    className={"btn--secondary-light"}
                    changeButtonLabelOnMenuItemClick={true}
                    endIcon={menuItemsClinic?.length > 1 && <i className="ri-arrow-down-s-fill" />}
                    dropDownMenuItems={menuItemsClinic}
                    defaultSelectedItem={menuItemsClinic.find((e) => e?.id === organizationId)}
                    onMenuItemClick={handleMenuClick}
                    menuItemHoverColor="#29BF91"
                    showMenuItems={menuItemsClinic.length > 1 ? false : true}
                  />
                )}
              </div>
            </li>
          )}

          <RIf show={isRunningOnElectron}>
            <li className="tertiary-actions">
              <div className="mui-dropdown">
                <CustomButton
                  startIcon={<i className={`${isSyncing ? "rotate-loop" : ""} ri-refresh-line`} />}
                  className="btn--secondary-light"
                  label="Sync"
                  onClick={onSyncButtonClick}
                />
              </div>
            </li>
          </RIf>
        </ul>
      </div>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle sx={{ "font-size": "20px" }}>Checkout Duration</DialogTitle>
        <Formik
          innerRef={formikRef}
          enableReinitialize={true}
          initialValues={{
            checkoutType: "fullDay",
            hours: "",
            selectedOrganizations: [],
            selectedAppointmentOption: "",
          }}
          onSubmit={async (values) => {
            const toastMessage =
              values.checkoutType === "hours"
                ? `Checked Out for ${values.hours} Hours.`
                : "Checked Out for Full day.";

            const now = dayjs();
            const startTime = now.format(TIME_FORMAT);
            const hoursToAdd = parseInt(values.hours);
            const calculatedHours = now.hour() + hoursToAdd;
            if (calculatedHours >= 24) {
              handleClick("warning", "Hours exceeding the day");
              // setHours(null);
              // setCheckoutType("fullDay");
              return;
            }
            const endTime = now.add(hoursToAdd, "hour").format(TIME_FORMAT);
            const payload = {
              doctorId: currentActiveUser().roleBasedId,
              organizationId:
                menuItemsClinic && menuItemsClinic.length > 1
                  ? values.selectedOrganizations
                  : [menuItemsClinic[0].id],
              blockingType: values.checkoutType === "hours" ? "CUSTOM_HOURS" : "ENTIRE_DAY",
              startTime: values.checkoutType === "hours" ? startTime : null,
              endTime: values.checkoutType === "hours" ? endTime : null,
              serviceType: values.selectedAppointmentOption,
            };
            try {
              await createBlockAvailabilities(payload);
              handleClick("success", toastMessage);
              setIsCheckedIn(false);
              handleCloseModal();
            } catch (error) {
              if (
                error.response.data.errorMessage.includes(
                  "Cannot add overlapping block entry for doctor"
                )
              ) {
                handleClick("error", "There is a conflict in block time");
              } else {
                handleClick("error", "There seems to be an error checking out");
              }
            }
          }}
          validationSchema={() => {
            return checkoutValidation(formikRef.current?.values?.checkoutType, menuItemsClinic);
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent sx={{ width: 400, height: "40%" }}>
                <RadioGroup
                  aria-label="checkout-type"
                  name="checkoutType"
                  value={values.checkoutType}
                  onChange={handleChange}
                >
                  <FormControlLabel value="fullDay" control={<Radio />} label="Full Day" />
                  <FormControlLabel value="hours" control={<Radio />} label="Specific Hours" />
                </RadioGroup>
                <Grid container spacing={2}>
                  {values.checkoutType === "hours" && (
                    <Grid item xs={12}>
                      <FormInputField
                        style={{
                          width: "100%",
                          marginTop: "10px",
                        }}
                        label="Hours"
                        type="number"
                        autoFocus
                        name="hours"
                        required
                        inputProps={{ min: 1, max: 24 }}
                        value={values.hours}
                        onChange={handleChange}
                        startAdornment={<HourglassBottomOutlined />}
                        error={Boolean(errors.hours && touched.hours)}
                        errorText={errors.hours}
                        size={"big"}
                      />
                    </Grid>
                  )}
                  {menuItemsClinic && menuItemsClinic.length > 1 && (
                    <>
                      <Grid item xs={12}>
                        <FormSelectField
                          multiple
                          required
                          name={"selectedOrganizations"}
                          label="Organization"
                          style={{ width: "100%" }}
                          value={values.selectedOrganizations}
                          onChange={handleChange}
                          startAdornment={<CorporateFareOutlined />}
                          menuItems={menuItemsClinic.map((el) => {
                            return {
                              value: el.id,
                              menuLabel: el.name,
                            };
                          })}
                          error={Boolean(
                            errors.selectedOrganizations && touched.selectedOrganizations
                          )}
                          errorText={errors.selectedOrganizations}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <FormSelectField
                      style={{ width: "100%" }}
                      required
                      label="Appointment Options"
                      name="selectedAppointmentOption"
                      onChange={handleChange}
                      startAdornment={<FilterAltIcon />}
                      menuItems={blockCalendeOptions}
                      value={values.selectedAppointmentOption}
                      error={Boolean(
                        errors.selectedAppointmentOption && touched.selectedAppointmentOption
                      )}
                      errorText={errors.selectedAppointmentOption}
                      size={"big"}
                    ></FormSelectField>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    handleCloseModal();
                  }}
                >
                  Cancel
                </Button>
                {/* Add functionality to check out */}
                <Button type="submit">Check Out</Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default Header;
