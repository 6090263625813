import React, { useContext, useEffect, useState } from "react";
import { checkQuery, getDashboard, getAllDashboards } from "services/DashboardService";
import PreviewDashboard from "../Chart/previewDashboard";
import { ToastContext } from "ui-component/custom-components/CustomToast";

const SuperAdminDashboard = () => {
  const [droppableAreas, setDroppableAreas] = useState([]);
  const [dashboardTitle, setDashboardTitle] = useState("");
  const [dashboardId, setDashboardId] = useState();
  const { handleClick } = useContext(ToastContext);

  const initializeComponent = async () => {
    try {
      const response = await getAllDashboards();
      if (response.data.length === 0) {
        setDashboardId("0");
        throw new Error("Dashboard does not exist...Create one");
      }
      setDashboardId(response.data[0].id);
      const { data } = await getDashboard(response.data[0].id);

      setDashboardTitle(data.name);
      const updatedAreaRows = await Promise.all(
        data.widgets.rows.map(async (row) => {
          const updatedColumns = await Promise.all(
            row.columns.map(async (column) => {
              if (column.charts.length === 0) {
                return {
                  columnId: column.columnId,
                  charts: [],
                };
              } else {
                const { name, options, query, type, widgetId } = column.charts[0];
                let queryParams = {};
                if (options && options.params) {
                  queryParams = Object.keys(options.params).reduce((acc, filter) => {
                    return { ...acc, ...options.params[filter] };
                  }, {});
                }

                const { data } = await checkQuery({
                  query,
                  params: queryParams,
                });
                const chartdata = {
                  chartType: type,
                  title: name,
                  queryText: query,
                  queryData: data,
                  widgetId,
                  options,
                };

                if (type === "Bar Chart" || type === "Line Chart") {
                  chartdata["xAxis"] = options.xAxis;
                  chartdata["yAxis"] = options.yAxis;
                }
                if (type === "Pie Chart") {
                  chartdata["Section"] = options.Section;
                  chartdata["Value"] = options.Value;
                }
                if (type === "Table") {
                  chartdata["Columns"] = options.Columns;
                }
                return {
                  columnId: column.columnId,
                  charts: [chartdata],
                };
              }
            })
          );
          row.columns = updatedColumns;
          return row;
        })
      );
      setDroppableAreas([{ id: data.widgets.screenId, rows: updatedAreaRows }]);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    initializeComponent();
  }, []);
  return <PreviewDashboard droppableAreas={droppableAreas} dashboardTitle={dashboardTitle} />;
};

export default SuperAdminDashboard;
