import React, { useState } from "react";
import { Box } from "@mui/material";
import { createAmenity } from "services/organizationService";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { AbcOutlined, CategoryOutlined, DescriptionOutlined } from "@mui/icons-material";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";

const OrganizationAddAmenity = ({ close, onCancel, amenitiesCategory }) => {
  const [category, setCategory] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});
  const { handleClick } = useContext(ToastContext);

  const validate = () => {
    const errors = {};

    if (!category) {
      errors.category = "Please enter valid category.";
    }

    if (!name) {
      errors.name = "Please enter valid amenity name.";
    }

    if (!description) {
      errors.description = "Please enter description.";
    }
    return errors;
  };

  const handleSave = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});

      try {
        const response = await createAmenity({ category, name, description });
        handleClick("success", "Amenity has been successfully created.");
        close(response.data);
      } catch (error) {
        handleClick("error", "There seems to be an error creating the amenity.");
        onCancel();
      }
    }
  };

  return (
    <Box
      sx={{ width: "400px", paddingTop: "20px" }}
      flex="1"
      alignSelf="center"
      minHeight="18vh"
      borderRadius={3}
      bgcolor="background.default"
      color="text.primary"
    >
      <FormSelectField
        style={{ width: "100%", marginBottom: "25px" }}
        label={"Type"}
        name="category"
        value={category}
        size={"big"}
        startAdornment={<CategoryOutlined />}
        onChange={(e) => setCategory(e.target.value)}
        menuItems={amenitiesCategory.map((el) => {
          return {
            ...el,
            value: el,
            menuLabel: el,
          };
        })}
        error={Boolean(errors.category)}
        errorText={errors.category}
      ></FormSelectField>
      <FormInputField
        style={{ width: "100%", marginBottom: "25px" }}
        label="Amenity Name"
        name="reason"
        value={name}
        onChange={(e) => setName(e.target.value)}
        startAdornment={<AbcOutlined />}
        size={"big"}
        error={Boolean(errors.name)}
        errorText={errors.name}
      />
      <FormInputField
        style={{ width: "100%" }}
        label="Description"
        name="description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        startAdornment={<DescriptionOutlined />}
        size={"big"}
        error={Boolean(errors.description)}
        errorText={errors.description}
      />
      <Box sx={{ mt: 2 }} display="flex" justifyContent="center">
        <CustomButton className="btn--primary" onClick={handleSave} label="Save" />
      </Box>
    </Box>
  );
};

export default OrganizationAddAmenity;
