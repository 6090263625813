import {
  ArrowCircleRightOutlined,
  FingerprintOutlined,
  VerifiedOutlined,
} from "@mui/icons-material";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { useState } from "react";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import "assets/scss/doctorOnboarding.scss";
import "assets/css/animate.min.css";
import { useLocation, useNavigate } from "react-router";
import { verifyComplete } from "services/AuthService";
import {
  DATE_TIME_FORMAT,
  DOCTOR,
  FEMALE,
  getRoleIdByName,
  MALE,
  OTHER,
  PATIENT,
  VERIFY_ABHA_NUMBER,
  VERIFY_HPR,
  VERIFY_MOBILE_NUMBER,
} from "store/constant";
import { useContext } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  setHfrData,
  setHprData,
  setShowOnboarding,
  setShowVerify,
} from "store/Actions/doctorOnboardingActions";
import { useSelector } from "react-redux";
import { getShowVerify } from "store/Reducers/doctorOnboardingReducer";
import dayjs from "dayjs";

const RegisterVerification = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const fieldValue = location?.state?.fieldValue;
  const fieldType = location?.state?.fieldType;
  const [otp, setOtp] = useState("");
  const txnId = location?.state?.txnId || "";
  const { handleClick } = useContext(ToastContext);
  const dispatch = useDispatch();
  const showPage = useSelector(getShowVerify);

  useEffect(() => {
    if (!showPage || !fieldValue) {
      navigate("/", { replace: true });
    } else {
      dispatch(setShowVerify(false));
    }
  }, []);

  const verifyOtp = async (payload) => {
    try {
      const response = await verifyComplete(payload);
      if (!response.data.isVerified) {
        handleClick("error", "Wrong OTP. Please verify and re-try again.");
        return;
      }
      const hprResponse = response.data.hpIdAccountResponse;
      const abhaResponse = response.data.healthIdAccountProfile;
      const hfrResponse = response.data?.hfrResponse;
      dispatch(setShowOnboarding(true));
      if (fieldType === VERIFY_MOBILE_NUMBER) {
        const doctorDetail = {
          mobileNumber: fieldValue,
        };
        navigate("/onboarding", {
          state: { validatedBy: fieldType, doctorDetail: doctorDetail },
          replace: true,
        });
      } else if (fieldType === VERIFY_HPR) {
        dispatch(setHprData(hprResponse));
        if (hfrResponse) {
          dispatch(setHfrData(hfrResponse.facilities[0]));
        }
        const dateOfBirth = `${hprResponse.yearOfBirth}-${hprResponse.monthOfBirth}-${hprResponse.dayOfBirth}`;
        const doctorDetail = {
          fullName: hprResponse.name,
          mobileNumber: hprResponse.mobile,
          emailId: hprResponse.email,
          gender: getGenderFromAbbr(hprResponse.gender),
          roleId: await getRoleIdByName(DOCTOR, handleClick),
          hprId: hprResponse.hprId,
          dateOfBirth: dayjs(dateOfBirth).format(DATE_TIME_FORMAT),
        };
        navigate("/onboarding", {
          state: { validatedBy: fieldType, doctorDetail: doctorDetail },
          replace: true,
        });
      } else {
        dispatch(setHprData(abhaResponse));
        const patientDetail = {
          fullName: abhaResponse.name,
          mobileNumber: abhaResponse.mobile,
          emailId: abhaResponse.email,
          gender: getGenderFromAbbr(abhaResponse.gender),
          roleId: await getRoleIdByName(PATIENT, handleClick),
          hprId: abhaResponse.hprId,
        };
        navigate("/register", {
          state: { validatedBy: fieldType, patientDetail: patientDetail },
        });
      }
    } catch (error) {
      handleClick("error", error?.response?.data?.message);
    }
  };

  const getGenderFromAbbr = (gender) => {
    switch (gender) {
      case "M":
        return MALE;
      case "F":
        return FEMALE;
      case "O":
        return OTHER;
      default:
        return null;
    }
  };

  const getLabelFromConstant = (constant) => {
    if (constant === VERIFY_MOBILE_NUMBER) {
      return "Mobile Number";
    } else if (constant === VERIFY_HPR) {
      return "HPR";
    } else if (constant === VERIFY_ABHA_NUMBER) {
      return "ABHA Number";
    }
  };

  const [hfrId, setHfrId] = useState("");
  const [hfrIdTouched, setHfrIdTouched] = useState(false);

  const [hasHfrId, setHasHfrId] = useState(null);
  const [otpTouched, setOtpTouched] = useState(false);

  const [errors, setErrors] = useState({});

  const validateHfr = (errors, hfrId) => {
    const pattern = /^[a-zA-Z0-9\s]+$/;
    if (!hfrId) {
      errors.hfrId = "HFR Id is required. Kindly provide your HFR Id.";
    } else if (hfrId.length < 12) {
      errors.hfrId = "HFR should be 12 characters long";
    } else if (hfrId.length > 12) {
      errors.hfrId = "HFR Can't be longer than 12 characters";
    } else if (!pattern.test(hfrId)) {
      errors.hfrId = "HFR can only contain letters, numbers withou space.";
    }
  };

  const validateOtp = (errors, otp) => {
    const pattern = /^[0-9]{6}$/;
    if (!otp) {
      errors.otp = "Please enter a valid 6-digit otp.";
    } else if (otp.length < 6) {
      errors.otp = "Please enter a valid 6-digit otp.";
    } else if (!pattern.test(otp)) {
      errors.otp = "OTP must only contain numbers.";
    }
  };

  const validate = () => {
    const errors = {};

    if (hasHfrId === "yes") {
      setHfrIdTouched(true);
      validateHfr(errors, hfrId);
    }

    setOtpTouched(true);
    validateOtp(errors, otp);

    if (!hasHfrId && fieldType === VERIFY_HPR) {
      errors.radioButton = "Required";
    }

    return errors;
  };

  const handleButtonClick = useCallback(async () => {
    const payload = {
      contactField: fieldValue,
      verificationType: fieldType,
      otp: otp,
      txnId: txnId,
    };

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      if (fieldType === VERIFY_HPR) {
        if (hasHfrId === "yes") {
          setErrors({});
          const finalData = { ...payload };
          finalData.contactField = `${finalData.contactField},${hfrId}`;
          verifyOtp(finalData);
        } else {
          verifyOtp(payload);
        }
      } else {
        verifyOtp(payload);
      }
    }
  }, [otp, hasHfrId, hfrId]);

  useEffect(() => {
    const handleGlobalKeyPress = async (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
        handleButtonClick();
      }
    };
    window.addEventListener("keydown", handleGlobalKeyPress);
    return () => {
      window.removeEventListener("keydown", handleGlobalKeyPress);
    };
  }, [handleButtonClick]);

  return (
    <div className="doctor-onboarding">
      <NavBar></NavBar>
      <div
        className="back-button-div container"
        onClick={() => {
          navigate("/", { state: { fieldValue: fieldValue, fieldType: fieldType }, replace: true });
        }}
      >
        <div className="btn btn-back">
          <img src="/images/hc_back_arrow.svg" alt="back-arrow"></img>
          login
        </div>
      </div>
      <div className="container content-div align-items-center justify-content-center animate__animated animate__fadeInUp animate__normal">
        <div className="row align-items-center justify-content-center h-100">
          <div className="col-12 align-items-center justify-content-center d-flex flex-column custom-flex-box">
            <img src="/images/Mobile login-bro.png" alt="login-img" className="mx-auto" />
            <form className="form-flex-box align-items-center">
              <div className="mb-3">
                <label htmlFor="inputTypeText" className="form-label onboarding-label">
                  {`Verify your ${getLabelFromConstant(fieldType)}`}
                </label>

                <Grid>
                  <FormInputField
                    style={{ marginBottom: "" }}
                    placeholder="OTP"
                    name={"otp"}
                    required
                    type={"tel"}
                    border={"1px solid #e6eef1"}
                    background={"#fff"}
                    borderOnFocus={"1px solid #29bf91"}
                    inputPropStyle={{ fontSize: "17px", fontWeight: "400" }}
                    size="small"
                    value={otp}
                    inputProps={{
                      maxLength: 6,
                    }}
                    onBlur={(e) => {
                      setOtpTouched(true);
                      const newError = {};
                      validateOtp(newError, otp);
                      setErrors({ ...errors, ...newError });
                    }}
                    onChange={(e) => {
                      setOtp(e.target.value);
                      if (otpTouched) {
                        const newError = {};
                        validateOtp(newError, e.target.value);
                        const updatedErrors = { ...errors };
                        delete updatedErrors.otp;
                        setErrors({ ...updatedErrors, ...newError });
                      }
                    }}
                    error={Boolean(errors?.otp && otpTouched)}
                    errorText={errors?.otp}
                    startAdornment={<FingerprintOutlined />}
                  />
                </Grid>
                {fieldType === VERIFY_HPR && (
                  <>
                    <Grid sx={{ width: "100%", mt: 2 }}>
                      <Typography variant="body1" gutterBottom>
                        Do you have an HFR ID and wish to link it?
                        <span
                          style={{
                            color: errors?.radioButton ? "red" : "#000000",
                            marginLeft: "3px",
                          }}
                        >
                          *
                        </span>
                        {errors.radioButton && (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            {errors?.radioButton}
                          </span>
                        )}
                      </Typography>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          name="hasHfrId"
                          value={hasHfrId}
                          onChange={(e) => {
                            setHasHfrId(e.target.value);
                            const newError = { ...errors };
                            delete newError.radioButton;
                            setErrors(newError);
                          }}
                        >
                          <FormControlLabel
                            value="yes"
                            control={<Radio color="primary" />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio color="primary" />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <>
                      {hasHfrId === "yes" && (
                        <Grid sx={{ width: "100%" }}>
                          <FormInputField
                            style={{
                              width: "100%",
                            }}
                            placeholder="HFR ID"
                            name="hfr"
                            type={"text"}
                            value={hfrId}
                            inputProps={{
                              maxLength: 12,
                            }}
                            onBlur={(e) => {
                              setHfrIdTouched(true);
                              const newError = {};
                              validateHfr(newError, hfrId);
                              setErrors({ ...errors, ...newError });
                            }}
                            onChange={(e) => {
                              setHfrId(e.target.value);
                              if (hfrIdTouched) {
                                const newError = {};
                                validateHfr(newError, e.target.value);
                                const updatedErrors = { ...errors };
                                delete updatedErrors.hfrId;
                                setErrors({ ...updatedErrors, ...newError });
                              }
                            }}
                            border={"1px solid #e6eef1"}
                            background={"#fff"}
                            borderOnFocus={"1px solid #29bf91"}
                            inputPropStyle={{ fontSize: "17px", fontWeight: "400" }}
                            error={Boolean(errors.hfrId)}
                            errorText={errors.hfrId}
                            startAdornment={<VerifiedOutlined />}
                            size={"small"}
                          />
                        </Grid>
                      )}
                    </>
                  </>
                )}
              </div>

              <div className={`btn btn-onboarding-next`} onClick={handleButtonClick}>
                <span>{"Verify"}</span>
                <ArrowCircleRightOutlined
                  style={{ width: "18px", height: "18px" }}
                ></ArrowCircleRightOutlined>
              </div>
            </form>
            <p>
              By proceeding you agree to the{" "}
              <a
                href="https://hc.argusservices.in/refund-policy.html"
                target="_blank"
                className="custom-a"
                rel="noreferrer"
              >
                Terms and Conditions
              </a>{" "}
              and{" "}
              <a
                href="https://hc.argusservices.in/privacy-policy.html"
                target="_blank"
                className="custom-a"
                rel="noreferrer"
              >
                Privacy Policy
              </a>{" "}
              by <b className="text-success">Arog</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
const NavBar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light custom-navbar bg-transparent">
      <div className="container">
        <a className="navbar-brand text-white d-flex align-items-center" href="#">
          <img
            src="/images/arog-icon.svg"
            className="me-1 rounded-lg"
            height="40px"
            width="50px"
            alt="logo"
          />
          <img src="/images/arog-logo-text.svg" height="30px" width="auto" alt="text" />
        </a>
        <a href="#" className="helper-class">
          Need help?
        </a>
      </div>
    </nav>
  );
};

export default RegisterVerification;
