import { Accordion, AccordionDetails, AccordionSummary, Badge, Skeleton } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { getProfilePicture } from "services/patientService";
import { calculateAge } from "utils/calculate-age";
import Reveal from "views/utilities/Reveal";

const PatientEHRBasicDetailsCard = ({ patientEhrData }) => {
  const conditions = [
    { name: "Diabetic", stage: "Type 1", borderColor: "#ff8347" },
    { name: "Hypertensive", stage: "Type 1", borderColor: "#ff8347" },
    { name: "Hypotensive", stage: "Type 3", borderColor: "red" },
  ];
  // const [patientDetails, setPatientDetails] = useState(null);
  // useEffect(() => {
  //   setPatientDetails(patientEhrData?.patientDetails);
  // }, [patientEhrData]);

  const [patientProfilePicture, setPatientProfilePicture] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchProfilePic = async () => {
      try {
        setIsLoading(true);
        const profilePicResponse = await getProfilePicture(patientEhrData?.patientDetails.user.id);
        const content = `data:image/${profilePicResponse.data.extension};base64,${profilePicResponse.data.document}`;
        const profilePicWithContent = {
          filename: profilePicResponse.data.filename,
          content: content,
        };
        setPatientProfilePicture(profilePicWithContent);
      } catch (error) {
        setPatientProfilePicture(null);
        console.error("Error fetching profile picture", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchProfilePic();
  }, [patientEhrData?.patientId]);

  const patientDetails = patientEhrData?.patientDetails;
  const patientAllergies = patientEhrData?.patientAllergies;
  const patientChronicDiseases = patientEhrData?.patientMedicalHistory?.patientChronicDiseases;

  return (
    <div>
      <div
        style={{
          display: "flex",
          marginTop: "1em",
          marginLeft: "1em",
          //   justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ width: "20%" }}>
          {isLoading ? (
            <Skeleton variant="circular" animation="wave" width={47} height={47} />
          ) : (
            <img
              style={{ borderRadius: "50%" }}
              src={
                patientProfilePicture
                  ? patientProfilePicture.content
                  : patientDetails?.user?.gender === "FEMALE"
                  ? "/images/woman.png"
                  : "/images/man.png"
              }
              alt="patient"
              width={47}
              height={47}
            />
          )}
        </div>
        <div style={{ marginLeft: "1em", display: "flex", flexDirection: "column", gap: "0.7em" }}>
          <p style={{ margin: "0px", fontSize: "1.3em", color: "#004c70", fontWeight: 500 }}>
            {patientDetails?.user?.name}
          </p>{" "}
          <p style={{ margin: "0px" }}>
            {calculateAge(patientDetails?.user?.dateOfBirth)} years <span>|</span>{" "}
            {patientDetails?.user?.gender}
          </p>
        </div>
      </div>
      <Reveal>
        <Accordion defaultExpanded sx={{ margin: "0px !important", ":: before": { opacity: 0 } }}>
          <AccordionSummary
            sx={{
              minHeight: "30px !important",
              "& .MuiAccordionSummary-content": {
                margin: "0px !important",
              },
            }}
          >
            <div
              style={{
                borderLeft: `3px solid red`,
                margin: "0px",
                height: "2em",
                alignItems: "center",
                display: "flex",
                marginTop: "1em",
              }}
            >
              <p style={{ marginLeft: "5px" }}>Patient Details</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ marginLeft: "1em", marginRight: "1em" }}>
              {/* to be set later due to style */}
              {/* <div className="patient-basic-details-item">
                <p>Patient Id :</p>
                <p>#12345</p>
              </div> */}
              <div className="patient-basic-details-item">
                <p>Height :</p>
                <p>{patientDetails?.height} cm</p>
              </div>
              <div className="patient-basic-details-item">
                <p>Weight :</p>
                <p>{patientDetails?.weight} Kg</p>
              </div>
              {/* <div className="patient-basic-details-item">
                <p>City :</p>
                <p>#9494939u298</p>
              </div> */}
              <div className="patient-basic-details-item">
                <p>ABHA Id :</p>
                <p>{patientDetails?.abhaId}</p>
              </div>
              <div className="patient-basic-details-item">
                <p>Email :</p>
                <p>{patientDetails?.user?.emailId}</p>
              </div>
              {/* <div className="patient-basic-details-item">
                <p>Marital Status :</p>
                <p>#9494939u298</p>
              </div> */}
              <div className="patient-basic-details-item">
                <p>Address :</p>
                <p>#9494939u298</p>
              </div>
              <div className="patient-basic-details-item">
                <p>Contact :</p>
                <p>{patientDetails?.user?.mobileNumber}</p>
              </div>
              <div className="patient-basic-details-item">
                <p>Emergency Contact :</p>
                <p>#9494939u298</p>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded sx={{ margin: "0px !important", ":: before": { opacity: 0 } }}>
          <AccordionSummary
            sx={{
              minHeight: "30px !important",
              "& .MuiAccordionSummary-content": {
                margin: "0px !important",
                // borderLeft: `3px solid red`,
              },
            }}
          >
            <div
              style={{
                borderLeft: `3px solid blue`,
                margin: "0px",
                height: "2em",
                alignItems: "center",
                display: "flex",
                marginTop: "1em",
              }}
            >
              <p style={{ marginLeft: "5px" }}>Medical Details</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ marginLeft: "5px", marginRight: "5px" }}>
              <div className="patient-basic-details-item">
                <p>Insurance Number:</p>
                <p>#9494939u298</p>
              </div>
              <div className="patient-basic-details-item">
                <p>Insurance provider:</p>
                <p>#9494939u298</p>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p>Allergies:</p>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "end",
                    marginLeft: "2em",
                  }}
                >
                  {patientAllergies?.map((allergy) => (
                    <Badge key={allergy.id} className="badge badge-gray-2 f-size-10">
                      {allergy?.allergyResponse?.name}
                    </Badge>
                  ))}
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded sx={{ margin: "0px !important", ":: before": { opacity: 0 } }}>
          <AccordionSummary
            sx={{
              minHeight: "30px !important",
              "& .MuiAccordionSummary-content": {
                margin: "0px !important",
                // borderLeft: `3px solid red`,
              },
            }}
          >
            <div
              style={{
                borderLeft: `3px solid brown`,
                margin: "0px",
                height: "2em",
                alignItems: "center",
                display: "flex",
                marginTop: "1em",
              }}
            >
              <p style={{ marginLeft: "5px" }}>Chronic Diseases</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "end",
              }}
            >
              {patientChronicDiseases && patientChronicDiseases?.length > 0 ? (
                patientChronicDiseases?.map((disease) => (
                  <Badge
                    key={disease.id}
                    className="badge badge-gray-2 f-size-10"
                    // title={disease.chronicDiseaseResponse.name}
                  >
                    {disease.chronicDiseaseResponse.name}
                  </Badge>
                ))
              ) : (
                <p style={{ margin: "5px" }}>Data Not Available</p>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded sx={{ margin: "0px !important", ":: before": { opacity: 0 } }}>
          <AccordionSummary
            sx={{
              minHeight: "30px !important",
              "& .MuiAccordionSummary-content": {
                margin: "0px !important",
                // borderLeft: `3px solid red`,
              },
            }}
          >
            <div
              style={{
                borderLeft: `3px solid green`,
                margin: "0px",
                height: "2em",
                alignItems: "center",
                display: "flex",
                marginTop: "1em",
              }}
            >
              <p style={{ marginLeft: "5px" }}>Recent Visits</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div></div>
          </AccordionDetails>
        </Accordion>
      </Reveal>
    </div>
  );
};

export default PatientEHRBasicDetailsCard;
