import { Box, Grid, Skeleton } from "@mui/material";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { cancelUhiAppointment } from "services/doctorService";
import {
  APPOINTMENT_TYPE_UHI,
  CANCELLED,
  convertTimeForTimeFormat,
  currentActiveUser,
  DATE_FORMAT,
  DATE_FORMAT_DMY,
  HOUR_MINUTE_FORMAT,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  TIME_FORMAT,
} from "store/constant";
import CustomButton from "ui-component/custom-components/CustomButton";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import Reveal from "views/utilities/Reveal";
import VideocamIcon from "@mui/icons-material/Videocam";
import { useNavigate } from "react-router";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { getAllAppointmentsByUserId } from "services/Appointments";

const PatientUhiAppointments = () => {
  const patientId = currentActiveUser().roleBasedId;
  const userId = currentActiveUser().id;
  const [searchParams, setSearchParams] = useState(null);
  const [appointmentList, setAppointmentList] = useState([]);
  const navigate = useNavigate();
  const { handleClick } = useContext(ToastContext);

  const isDisable = (row) => {
    const now = dayjs();
    if (
      (row?.appointmentDate === now.format(DATE_FORMAT) &&
        row?.appointmentTime <= now.format(TIME_FORMAT)) ||
      row?.appointmentStatus === CANCELLED
    ) {
      return true;
    } else {
      return false;
    }
  };

  const cancelAppointment = async (row) => {
    try {
      const payload = {
        orderId: row.orderId,
        transactionId: row.transactionId,
        providerUri: row.providerDetails.uri,
        providerId: row.providerDetails.id,
        state: CANCELLED,
        tags: "TAGS",
      };
      await cancelUhiAppointment(payload);
      await getUhiAppointments();
    } catch (error) {
      handleClick("error", "There seems to be an error cancelling appointment");
    }
  };
  const columns = [
    { field: "Sr. No.", label: "Sr. No" },
    { field: "doctorDetails.name", label: "Doctor" },
    { field: "patientName", label: "Patient" },
    { field: "orgDetails.name", label: "Hospital" },
    { field: "appointmentType", label: "Type" },
    { field: "appointmentStatus", label: "Status" },
    { field: "formattedDate", label: "Date" },
    { field: "formattedTime", label: "Time" },
    { field: "Actions", label: "Actions" },
  ];
  const joinCall = (row) => {
    if (row && row.teleconsultationUri) {
      window.open(row.teleconsultationUri, "_blank");
    }
  };

  const actions = [
    {
      label: "Cancel",
      icon: <i className="ri-delete-bin-fill ri-xl icon-primary-blue" />,
      onClick: cancelAppointment,
      checkDisable: isDisable,
    },
    {
      label: "Join Call",
      icon: <VideocamIcon style={{ color: "#004C70" }} />,
      onClick: joinCall,
      checkDisable: isDisable,
    },
  ];

  const getUhiAppointments = async () => {
    try {
      const response = await getAllAppointmentsByUserId(userId, searchParams);
      const tempList = response.data.content.map((appointment) => {
        return {
          ...appointment,
          doctorDetails: JSON.parse(appointment.doctorDetails),
          orgDetails: JSON.parse(appointment.orgDetails),
          providerDetails: JSON.parse(appointment.providerDetails),
          formattedDate: dayjs(appointment.appointmentDate).format(DATE_FORMAT_DMY),
          formattedTime: convertTimeForTimeFormat(appointment.appointmentTime),
        };
      });
      setAppointmentList(tempList.filter((e) => e.sourceType === APPOINTMENT_TYPE_UHI));
    } catch (error) {
      console.error("Error fetching Uhi appointment");
    }
  };

  useEffect(() => {
    getUhiAppointments();
  }, []);
  return (
    <PatientUhiAppointmentSkeletonStructure>
      <Reveal>
        <div className="section-heading">
          <h2 className="page-title">UHI Appointments</h2>
          <div className="buttons">
            <CustomButton
              className="ri-add-fill ri-lg btn--secondary"
              label="New Appointment"
              onClick={() => {
                navigate("/home/doctorSearch");
              }}
            ></CustomButton>
          </div>
        </div>
        <CustomizedTable columns={columns} tableData={appointmentList} actions={actions} />
      </Reveal>
    </PatientUhiAppointmentSkeletonStructure>
  );
};
const PatientUhiAppointmentSkeletonStructure = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={2}>
            <Skeleton animation="wave" variant="text" width="100%" height={60} />
          </Grid>
          <Grid item xs={2}>
            <Skeleton animation="wave" variant="text" width="100%" height={60} />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={250}
          />
        </Grid>

        <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={2}>
            <Skeleton animation="wave" variant="text" width="100%" height={60} />
          </Grid>
          <Grid item xs={2}>
            <Skeleton animation="wave" variant="text" width="100%" height={60} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PatientUhiAppointments;
