import { CloudUpload } from "@mui/icons-material";
import { Grid, Input } from "@mui/material";
import SubCard from "ui-component/cards/SubCard";
import CustomButton from "ui-component/custom-components/CustomButton";
import { FILE_ACTIONS } from "../ManageOrganization";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { SIZE_10_MB } from "store/constant";
import Reveal from "views/utilities/Reveal";

const OrgPhotos = ({
  fileState,
  setFieldValue,
  fileDispatch,
  imageRemoveList,
  setImageRemoveList,
}) => {
  const { handleClick } = useContext(ToastContext);

  const handleFileUploads = async (event) => {
    const files = event.target.files;

    let totalSize = 0;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      totalSize += file.size;
    }

    if (totalSize > SIZE_10_MB) {
      handleClick("error", "Total file size exceeds 10MB. Please choose smaller files.");
      event.target.value = null;
      return;
    }

    let updatedSlected;
    const updatedSelectedFiles = Array.from(files).map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve({ file: file, content: reader.result });
        };
      });
    });

    try {
      const updatedFiles = await Promise.all(updatedSelectedFiles);
      updatedSlected = updatedFiles;
      fileDispatch({
        type: FILE_ACTIONS.UPLOAD_IMAGE,
        payload: {
          files: files,
          updatedSelectedFiles: updatedSlected,
        },
      });
    } catch (e) {
      handleClick("error", "Your file cannot be uploaded.", e);
    }
  };

  const onImageRemove = async (fileWithContent) => {
    if (fileWithContent.file.id) {
      setImageRemoveList([...imageRemoveList, { id: fileWithContent.file.id }]);

      fileDispatch({
        type: FILE_ACTIONS.REMOVE_IMAGE_WITH_ID,
        payload: {
          fileWithContent: fileWithContent,
        },
      });
    } else {
      fileDispatch({
        type: FILE_ACTIONS.REMOVE_IMAGE_WITHOUT_ID,
        payload: {
          fileWithContent: fileWithContent,
        },
      });
    }
  };

  return (
    <Reveal>
      <SubCard style={{ padding: "15px" }}>
        {fileState.selectedFile && fileState.selectedFile.length > 0 && (
          <Grid container spacing={2} justifyContent="center">
            {fileState.selectedFile.map((fileWithContent, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3} className="image-container">
                <img src={fileWithContent.content} alt="Uploaded" className={`image-${index}`} />
                <CustomButton
                  onMouseEnter={() => {
                    document.querySelector(`.image-${index}`).style.filter = "blur(3px)";
                  }}
                  onMouseLeave={() => {
                    document.querySelector(`.image-${index}`).style.filter = "none";
                  }}
                  className="delete-image-button"
                  onClick={() => {
                    onImageRemove(fileWithContent);
                    setFieldValue("dirty", true);
                  }}
                  iconButton={<i className="ri-delete-bin-fill ri-xl icon-primary-red" />}
                />
              </Grid>
            ))}
          </Grid>
        )}
        <Grid container justifyContent="center" spacing={2}>
          <Grid item sx={{ mt: 4 }}>
            <CustomButton
              className="btn--primary"
              startIcon={<CloudUpload />}
              label="Upload Photos"
              component="label"
            >
              <Input
                type="file"
                style={{ display: "none" }}
                onChange={(e) => {
                  handleFileUploads(e);
                  setFieldValue("dirty", true);
                }}
                inputProps={{
                  multiple: true,
                }}
              />
            </CustomButton>
          </Grid>
          <Grid item xs={12} style={{ color: "red" }}>
            <Grid>Maximum file size allowed is 1MB.</Grid>
          </Grid>
        </Grid>
      </SubCard>
    </Reveal>
  );
};

export default OrgPhotos;
