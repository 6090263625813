import React, { useState, useEffect } from "react";
import { Grid, Card, CardContent, CardHeader } from "@mui/material";
import {
  accessToken,
  ACTIVE_STATE,
  INACTIVE_STATE,
  basePackage,
  DATE_FORMAT_DMY,
  DATE_FORMAT,
} from "store/constant";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  getPackages,
  createLicences,
  updateLicences,
  deleteLicences,
  getLicence,
} from "services/Subscription";
import CustomButton from "ui-component/custom-components/CustomButton";
import { useContext } from "react";
import ModalUI from "ui-component/ModalUI";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import BorderAllIcon from "@mui/icons-material/BorderAll";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import { getAllOrganizations } from "services/organizationService";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";

const Subscriptions = () => {
  const { handleClick } = useContext(ToastContext);

  const [organizations, setOrganizations] = useState([]);
  const [packages, setPackages] = useState([]);
  const [subscriptionPackageAssociations, setSubscriptionPackageAssociations] = useState([]);

  const accessTokenValue = accessToken();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [update, setUpdate] = useState(false);

  const initialSubscriptionInfo = { packageId: [], organization: [], startDate: dayjs() };
  const [subscriptionInfo, setsubscriptionInfo] = useState(initialSubscriptionInfo);
  const [licenceOrgMapper, setlicenceOrgMapper] = useState({});

  const manipulateAssociationResponse = (el, organizationsData, tempData) => {
    if (el.subscriptionLicensePackageAssociationResponses.length > 0) {
      const orgId = el.orgId;
      const selectedOrg = organizationsData.find((o) => o.id === orgId);
      const tempLicenceObj = el.subscriptionLicensePackageAssociationResponses.map((subEl) => {
        return {
          id: subEl.id,
          licenceId: el.id,
          packageId: subEl.packageId,
          subscriptionType:
            subEl.packageResponse && subEl.packageResponse.name
              ? subEl.packageResponse.name
              : packages.find((p) => p.id === subEl.packageId).name,
          startDate: dayjs(subEl.startDate).format(DATE_FORMAT),
          endDate: subEl.endDate,
          status: subEl.status,
        };
      });
      tempData.push({
        id: el.id,
        licences: tempLicenceObj,
        organization: orgId,
        name: selectedOrg.name,
        contactPerson: selectedOrg.contactName ? selectedOrg.contactName : "N/A",
        contactEmail: selectedOrg.contactEmail ? selectedOrg.contactEmail : "N/A",
        contactNumber: selectedOrg.contactMobile ? selectedOrg.contactMobile : "N/A",
      });
      return tempData;
    }
  };

  const mapLicenceData = (apiRes, organizationsData) => {
    let tempData = [],
      mapperObj = {};
    apiRes.forEach((el) => {
      mapperObj[el.orgId] = el.licenceKey;
      manipulateAssociationResponse(el, organizationsData, tempData);
    });
    setlicenceOrgMapper(mapperObj);
    setSubscriptionPackageAssociations(tempData);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPackages();
        response.data = response.data.map((el) => {
          el.value = el.id;
          el.menuLabel = el.name;
          return el;
        });
        setPackages(response.data);
        const [organizationReponse, licenceResponse] = await Promise.all([
          getAllOrganizations(),
          getLicence(),
        ]);
        setOrganizations(
          organizationReponse.data.map((el) => {
            el.value = el.id;
            el.menuLabel = el.name;
            return el;
          })
        );
        mapLicenceData(licenceResponse.data, organizationReponse.data);
      } catch (error) {
        console.error("Error fetching packages!");
      }
    };
    fetchData();
  }, [accessTokenValue, handleClick]);

  const isSamePackageSelected = (organization, packageId) => {
    const data = subscriptionPackageAssociations.find((el) => el.organization === organization);
    if (data) {
      const index = data.licences.findIndex((el) => el.packageId === packageId);
      if (index > -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleFormInfoChange = (event) => {
    if (event.target.name === "packageId") {
      const isPresent = isSamePackageSelected(subscriptionInfo.organization, event.target.value);
      if (isPresent) {
        handleClick("info", "Please select different package this one is already associated!");
      } else {
        setsubscriptionInfo({
          ...subscriptionInfo,
          [event.target.name]: event.target.value,
        });
      }
    } else {
      setsubscriptionInfo({
        ...subscriptionInfo,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setsubscriptionInfo(initialSubscriptionInfo);
    setUpdate(false);
  };

  const handleSavePackage = async () => {
    try {
      const isPresent = isSamePackageSelected(
        subscriptionInfo.organization,
        subscriptionInfo.packageId
      );
      if (isPresent) {
        handleClick("info", "Please select different package this one is already associated!");
        return;
      }

      const validPackageDays = packages.find((p) => p.id === subscriptionInfo.packageId).validity;
      const startDate = dayjs(subscriptionInfo.startDate).format(DATE_FORMAT);
      const endDate = dayjs(subscriptionInfo.startDate)
        .add(validPackageDays, "days")
        .format(DATE_FORMAT);
      const selPackage = packages.find((p) => p.id === subscriptionInfo.packageId);
      const associationData = {
        licenceId: licenceOrgMapper[subscriptionInfo.organization],
        packageId: subscriptionInfo.packageId,
        startDate: startDate,
        endDate: endDate,
        licenseResponse: null,
        packageResponse: selPackage,
        subscriptionType: selPackage.name,
        status: ACTIVE_STATE,
      };
      const isPresentIndex = subscriptionPackageAssociations.findIndex(
        (el) => el.organization === subscriptionInfo.organization
      );
      if (isPresentIndex > -1) {
        let currArr = subscriptionPackageAssociations[isPresentIndex].licences;
        currArr.push(associationData);
        const data = {
          id: subscriptionPackageAssociations[isPresentIndex].id,
          orgId: subscriptionPackageAssociations[isPresentIndex].organization,
          licenceKey:
            licenceOrgMapper[subscriptionPackageAssociations[isPresentIndex].organization],
          type: "Premium",
          organizationResponse: null,
          subscriptionLicensePackageAssociationResponses: currArr,
          status: ACTIVE_STATE,
        };
        await updateLicences(data, data.id);
        const licenceResponse = await getLicence();
        mapLicenceData(licenceResponse.data, organizations);
        closeModal();
        setsubscriptionInfo(initialSubscriptionInfo);
        handleClick("success", "Association has been successfully added.");
      } else {
        const data = {
          orgId: subscriptionInfo.organization,
          licenceKey: licenceOrgMapper[subscriptionInfo.organization],
          type: "Premium",
          organizationResponse: null,
          subscriptionLicensePackageAssociationResponses: [associationData],
          status: ACTIVE_STATE,
        };
        await createLicences(data);
        const licenceResponse = await getLicence();
        mapLicenceData(licenceResponse.data, organizations);
        closeModal();
        setsubscriptionInfo(initialSubscriptionInfo);
        handleClick("success", "Association has been successfully added.");
      }
    } catch (error) {
      handleClick("error", "There seems to be an error managing the package association.");
    }
  };

  const handleDeleteAssociation = async (data) => {
    try {
      await deleteLicences(data.id);
      const indexToRemove = subscriptionPackageAssociations.findIndex((el) => el.id === data.id);
      setSubscriptionPackageAssociations([
        ...subscriptionPackageAssociations.slice(0, indexToRemove),
        ...subscriptionPackageAssociations.slice(indexToRemove + 1),
      ]);
      handleClick("success", "Associations has been successfully deleted.");
    } catch (error) {
      handleClick("error", "There seems to be an error deleting the associations.");
    }
  };

  const handleDeleteSubAssociation = async (index, subIndex) => {
    try {
      subscriptionPackageAssociations[index].licences[subIndex].status = INACTIVE_STATE;
      const data = {
        id: subscriptionPackageAssociations[index].id,
        orgId: subscriptionPackageAssociations[index].organization,
        licenceKey: licenceOrgMapper[subscriptionPackageAssociations[index].organization],
        type: "Premium",
        organizationResponse: null,
        subscriptionLicensePackageAssociationResponses:
          subscriptionPackageAssociations[index].licences,
        status: ACTIVE_STATE,
      };
      await updateLicences(data, subscriptionPackageAssociations[index].id);
      const licenceResponse = await getLicence();
      mapLicenceData(licenceResponse.data, organizations);
      handleClick("success", "Association has been successfully deleted.");
    } catch (error) {
      handleClick("error", "There seems to be an error removing the association.");
    }
  };

  const navigateToPackages = () => {
    navigate("/home/packages");
  };

  const getDateInFormat = (date) => {
    return dayjs(date).format(DATE_FORMAT_DMY);
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <h2 style={{ display: "inline" }}>Subscriptions</h2>
        <CustomButton
          className="btn--secondary"
          label="Manage Packages"
          style={{ marginLeft: "auto" }}
          onClick={() => navigateToPackages()}
        />
        <CustomButton
          className="btn--primary"
          label="Manage Subscription"
          style={{ marginLeft: "10px" }}
          onClick={() => handleModalOpen()}
        />
      </div>
      <Grid container className="row mb-0 mx-0">
        {subscriptionPackageAssociations &&
          subscriptionPackageAssociations.length !== 0 &&
          subscriptionPackageAssociations.map((el, index) => {
            return (
              <>
                <Grid key={index} item md={4} className="col">
                  <Card
                    style={{
                      backgroundColor: "#edf1f3",
                      margin: "5px",
                      borderRadius: "15%",
                      padding: "15px",
                    }}
                  >
                    <CardHeader
                      style={{ margin: "15px", textAlign: "center" }}
                      title={<label style={{ fontSize: "25px" }}>{el.name}</label>}
                      action={
                        <>
                          <CustomButton
                            iconButton={<i className="ri-delete-bin-7-line" />}
                            onClick={() => handleDeleteAssociation(el)}
                          ></CustomButton>
                        </>
                      }
                    />
                    <CardContent>
                      <div className="row" style={{ margin: "10px", textAlign: "center" }}>
                        <Grid item md={4} className="col">
                          <label style={{ fontSize: "15px" }}>C. Person :</label>
                        </Grid>
                        <Grid item md={8} className="col">
                          <label style={{ fontSize: "15px" }}>
                            <b>{el.contactPerson}</b>
                          </label>
                        </Grid>
                      </div>
                      <div className="row" style={{ margin: "10px", textAlign: "center" }}>
                        <Grid item md={4} className="col">
                          <label style={{ fontSize: "15px" }}>C. Number :</label>
                        </Grid>
                        <Grid item md={8} className="col">
                          <label style={{ fontSize: "15px" }}>
                            <b>{el.contactNumber}</b>
                          </label>
                        </Grid>
                      </div>
                      <div className="row" style={{ margin: "10px", textAlign: "center" }}>
                        <Grid item md={4} className="col">
                          <label style={{ fontSize: "15px" }}>Email :</label>
                        </Grid>
                        <Grid item md={8} className="col">
                          <label style={{ fontSize: "15px" }}>
                            <b>{el.contactEmail}</b>
                          </label>
                        </Grid>
                      </div>
                      {el.licences && el.licences.length > 0 && (
                        <>
                          {el.licences.map((tempEl, subIndex) => {
                            return (
                              <>
                                <div style={{ marginTop: "20px", textAlign: "center" }}>
                                  {tempEl.status === ACTIVE_STATE && (
                                    <>
                                      <Grid item md={12} className="col mb-2">
                                        <label style={{ fontSize: "20px" }}>
                                          <b>{tempEl.subscriptionType}</b>
                                          {/* we are not allowing an user to remove the basic package */}
                                          {tempEl.subscriptionType !== basePackage && (
                                            <>
                                              <CustomButton
                                                iconButton={<i className="ri-close-line" />}
                                                onClick={() =>
                                                  handleDeleteSubAssociation(index, subIndex)
                                                }
                                              ></CustomButton>
                                            </>
                                          )}
                                        </label>
                                      </Grid>
                                      <label style={{ fontSize: "15px" }}>
                                        <b>Start</b> {getDateInFormat(tempEl.startDate)}
                                        &nbsp;&nbsp;&nbsp;
                                        <b>End</b> {getDateInFormat(tempEl.endDate)}
                                      </label>
                                    </>
                                  )}
                                </div>
                              </>
                            );
                          })}
                        </>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              </>
            );
          })}
        {subscriptionPackageAssociations && subscriptionPackageAssociations.length === 0 && (
          <>
            <span style={{ display: "inline", fontSize: "20px", marginTop: "10px" }}>
              There are no active licences yet!
            </span>
          </>
        )}
      </Grid>
      <ModalUI
        visible={modalOpen}
        close={closeModal}
        title={"Manage Subscription"}
        component={
          <>
            <div style={{ width: "400px" }}>
              <FormSelectField
                style={{ width: "100%", marginTop: "20px" }}
                label="Organization*"
                name="organization"
                disabled={update}
                onChange={(event) => handleFormInfoChange(event)}
                startAdornment={<BorderAllIcon />}
                menuItems={organizations}
                value={subscriptionInfo.organization}
                size={"big"}
              ></FormSelectField>
              <FormSelectField
                style={{ width: "100%", marginTop: "20px" }}
                label="Package*"
                name="packageId"
                onChange={(event) => handleFormInfoChange(event)}
                startAdornment={<BorderAllIcon />}
                menuItems={packages}
                value={subscriptionInfo.packageId}
                size={"big"}
              ></FormSelectField>
              {!update && (
                <>
                  <FormDatePicker
                    inputFormat={DATE_FORMAT_DMY}
                    label={"Start date"}
                    value={subscriptionInfo.startDate}
                    size={"big"}
                    minDate={!update ? dayjs().toDate() : null}
                    maxDate={dayjs().add(1, "year").toDate()}
                    onChange={(date) => {
                      const event = {
                        target: {
                          name: "startDate",
                          value: date,
                        },
                      };
                      handleFormInfoChange(event);
                    }}
                    style={{ width: "100%", marginTop: "20px" }}
                  ></FormDatePicker>
                </>
              )}
              <CustomButton
                className="btn--secondary"
                style={{ marginTop: "20px", padding: "5px 50px", float: "right" }}
                type="submit"
                label={"Save"}
                disabled={
                  !(
                    subscriptionInfo.packageId.length > 0 &&
                    subscriptionInfo.organization.length > 0 &&
                    subscriptionInfo.startDate
                  )
                }
                onClick={handleSavePackage}
              ></CustomButton>
            </div>
          </>
        }
      ></ModalUI>
    </>
  );
};

export default Subscriptions;
