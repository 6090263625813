import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import CustomButton from "ui-component/custom-components/CustomButton";
import { DriveFileRenameOutlineOutlined } from "@mui/icons-material";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { updateVaccineTypes } from "services/EntitiesServices";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { vaccineTypeValidation } from "../../Common/ValidationSchema/vaccineTypeValidation";
import { Formik } from "formik";

const UpdateVaccineType = ({ selected, close }) => {
  const { handleClick } = useContext(ToastContext);
  const updateVaccineType = async (values) => {
    if (selected) {
      const payload = {
        id: selected.id,
        name: values.vaccineType,
      };
      try {
        await updateVaccineTypes(selected.id, payload);
        handleClick("success", "Vaccine type has been successfully updated.");
        close();
      } catch (e) {
        handleClick("error", "There seems to be an error updating the vaccine type");
      }
    }
  };

  return (
    <Box
      flex="1"
      alignSelf={"center"}
      minHeight="18vh"
      p={3}
      borderRadius={3}
      bgcolor={"background.default"}
      color={"text.primary"}
    >
      <Typography variant="h4" textAlign="center">
        Update Vaccine Type
      </Typography>
      <Formik
        validateOnMount={true}
        initialValues={{
          vaccineType: selected.name,
        }}
        validationSchema={vaccineTypeValidation}
        onSubmit={async (values, { resetForm }) => {
          updateVaccineType(values);
          resetForm();
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <>
            <FormInputField
              style={{ marginTop: "20px" }}
              label={"Vaccine Type"}
              value={values.vaccineType}
              name={"vaccineType"}
              inputProps={{ maxLength: 50 }}
              error={Boolean(touched.vaccineType && errors.vaccineType)}
              errorText={errors.vaccineType}
              onChange={handleChange}
              startAdornment={<DriveFileRenameOutlineOutlined />}
              size="small"
            />
            <Box sx={{ mt: 3 }} display="flex" justifyContent="center" alignItems="center">
              <CustomButton className="btn--primary" onClick={handleSubmit} label="Update" />
            </Box>
          </>
        )}
      </Formik>
    </Box>
  );
};

export default UpdateVaccineType;
