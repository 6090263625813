import { getPatientLatestVitalByPatientId } from "services/patientService";
import { getColorAndBackgroundColor } from "./calculate-vital-color";

export const fetchPatientLatestVitalSigns = async (
  patientId,
  setPatientLatestVitals,
  handleClick
) => {
  try {
    const response = await getPatientLatestVitalByPatientId(patientId);
    const vitalSignType = [
      "BODY_TEMPERATURE",
      "BLOOD_PRESSURE",
      "BLOOD_GLUCOSE",
      "BODY_MASS_INDEX",
      "BLOOD_OXYGEN",
      "HEART_RATE",
      "RESPIRATORY_RATE",
    ];
    let bpSystolic = null;
    let bpDiastolic = null;
    const filteredData = response.data.filter((vital) => {
      if (vital.vitalSignTypeResponse.name === "BLOOD_PRESSURE_SYSTOLIC") bpSystolic = vital;
      if (vital.vitalSignTypeResponse.name === "BLOOD_PRESSURE_DIASTOLIC") bpDiastolic = vital;
      if (vitalSignType.includes(vital.vitalSignTypeResponse.name)) {
        const vitalColor = getColorAndBackgroundColor(vital.severity);
        vital.color = vitalColor.color;
        vital.bgColor = vitalColor.backgroundColor;
        return vital;
      }
      return false;
    });

    if (bpSystolic && bpDiastolic) {
      const severity = Math.max(bpSystolic?.severity, bpDiastolic?.severity);
      const vitalColor = getColorAndBackgroundColor(severity);
      const bp = {
        vitalSignTypeResponse: {
          displayName: "Blood Pressure",
          name: "BLOOD_PRESSURE",
          unitAbbreviation: "mmHg",
        },
        value: `${!bpSystolic ? "--" : bpSystolic.value}/${
          !bpDiastolic ? "--" : bpDiastolic.value
        }`,
        severity: severity,
        color: vitalColor.color,
        bgColor: vitalColor.backgroundColor,
      };
      filteredData.push(bp);
    }

    filteredData.sort((a, b) => {
      const tempA = a.vitalSignTypeResponse.name;
      const tempB = b.vitalSignTypeResponse.name;

      return vitalSignType.indexOf(tempA) - vitalSignType.indexOf(tempB);
    });

    setPatientLatestVitals(filteredData);
  } catch (error) {
    console.error("Error fetching patient vital Sign");
  }
};
