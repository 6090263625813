import * as Yup from "yup";

const createHprSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Please enter your first name.")
    .min(3, "Minimum 3 characters are required")
    .max(50, "Maximum 50 characters are allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  middleName: Yup.string()
    .required("Please enter your middle name.")
    .min(1, "Minimum 1 character is required")
    .max(50, "Maximum 50 characters are allowed.")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  lastName: Yup.string()

    .required("Please enter your last name")

    .min(3, "Minimum 3 characters are required")
    .max(50, "Maximum 50 characters are allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  dayOfBirth: Yup.string().required("Kindly provide your date of birth"),
  monthOfBirth: Yup.string().required("Kindly provide your month of birth."),
  yearOfBirth: Yup.string().required("Kindly provide your year of birth."),
  email: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Please enter a valid email.")
    .required("Please enter a valid email.")
    .max(255, "Maximum 255 characters are allowed"),
  password: Yup.string()
    .required("Please enter your password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Your password must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is mandatory"),
  stateCode: Yup.string().required("Kindly provide your state."),
  districtCode: Yup.string().required("Please enter district."),
  gender: Yup.string().required("Please enter gender."),
  hpCategoryCode: Yup.string().required("Kindly provide Healthcare Professional Category"),
  hpSubCategoryCode: Yup.string().required("Kindly provide Healthcare Professional Subcategory"),
  address: Yup.string()
    .required("Kindly provide Address")
    .min(3, "Minimum 3 characters are required")
    .max(256, "Maximum 256 characters are allowed"),
  hprId: Yup.string().required("Kindly provide Healthcare Professional Id."),
});

export default createHprSchema;
