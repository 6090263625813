import * as Yup from "yup";

export const patientGeneralInfoSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Please enter your first name.")
    .min(3, "Minimum 3 characters are required")
    .max(50, "Maximum 50 characters are allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  middleName: Yup.string()
    .min(1, "Minimum 1 character is required")
    .max(50, "Maximum 50 characters are allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  lastName: Yup.string()
    .required("Please enter your last name.")
    .min(2, "Minimum 2 characters are required")
    .max(50, "Maximum 50 characters are allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  address: Yup.string()
    .min(3, "Minimum 3 characters are required.")
    .max(255, "Maximum 255 characters are allowed"),
  mobileNumber: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, "Please enter a valid Mobile Number")
    .required("Please enter a valid mobile number."),
  email: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Please enter a valid email.")
    .required("Kindly enter a valid email.")
    .max(255, "Maximum 255 characters are allowed"),
  gender: Yup.string().required("Gender is required. Kindly provide one."),
  height: Yup.number()
    .min(0, "Please enter a valid height in cm")
    .max(250, "Please enter a valid height in cm"),
  weight: Yup.number()
    .min(0, "Please enter a valid weight in kg")
    .max(635, "Please enter a valid weight in kg"),
  dateOfBirth: Yup.date().required("Date of birth is required. Kindly provide one."),
  latitude: Yup.number()
    .min(-90, "Latitude must be greater than or equal to -90")
    .max(90, "Latitude must be less than or equal to 90"),
  longitude: Yup.number()
    .min(-180, "Longitude must be greater than or equal to -180")
    .max(180, "Longitude must be less than or equal to 180"),
});
