import { Box, Grid } from "@mui/material";
import React, { useState, useCallback } from "react";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { DescriptionOutlined, DriveFileRenameOutlineOutlined } from "@mui/icons-material";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  updateMedicalServiceCategories,
  getMedicalServiceCategories,
} from "services/BillingService";

const UpdateMedicalServiceCategory = ({ selected, close }) => {
  const [name, setName] = useState(selected?.name);
  const [description, setDescription] = useState(selected?.description);
  const [errors, setErrors] = useState({});
  const { handleClick } = useContext(ToastContext);
  const [msc, setmsc] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const response = await getMedicalServiceCategories();
      setmsc(response.data.filter((msc) => msc.status === "ACTIVE"));
    } catch (error) {
      handleClick("error", "Error fetching Medical Service Categories");
    }
  }, [handleClick]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const validate = () => {
    const errors = {};
    const trimmedName = name.trim();
    const trimmedDescription = description.trim();

    if (!name) {
      errors.name = "Please enter valid category name.";
    }

    if (!description) {
      errors.description = "Please enter valid category description.";
    }

    if (!trimmedName) {
      errors.name = "Name cannot be empty or whitespace";
    }

    if (!trimmedDescription) {
      errors.description = "Description cannot be empty or whitespace";
    }

    if (!trimmedName) {
      errors.name = "Name cannot be empty or whitespace";
    } else if (name.trim().length < 3) {
      errors.name = "Name must be at least 3 characters long";
    }

    if (!trimmedDescription) {
      errors.description = "Description cannot be empty or whitespace";
    } else if (description.trim().length < 5) {
      errors.description = "Description must be at least 5 characters long";
    }
    if (trimmedName.length > 50) {
      errors.name = "Name cannot exceed 50 characters.";
    }

    if (trimmedDescription.length > 250) {
      errors.description = "Description cannot exceed 250 characters.";
    }
    const specialCharsPattern = /^[a-zA-Z0-9 ]+$/;
    if (!specialCharsPattern.test(trimmedName)) {
      errors.name = "Name can only contain alphanumeric characters and spaces.";
    }
    if (trimmedDescription.toLowerCase() === trimmedName.toLowerCase()) {
      errors.description = "Description cannot be identical to the name.";
    }

    // Ensure update after initial load
    if (
      trimmedName === selected.name.trim() &&
      trimmedDescription === selected.description.trim()
    ) {
      errors.general = "No changes detected. Please update the fields before submitting.";
    }
    if (
      msc.some(
        (msc) => msc.name.toLowerCase() === trimmedName.toLowerCase() && msc.id !== selected.id
      )
    ) {
      errors.name = "Medical Service Category with this name already exists.";
    }

    return errors;
  };

  const updateMSC = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});

      if (selected) {
        const data = {
          id: selected.id,
          name: name,
          description: description,
        };
        try {
          await updateMedicalServiceCategories(selected.id, data);
          handleClick("success", "Medical Service Category has been successfully updated.");
          close();
        } catch (e) {
          handleClick("error", "There seems to be an error updating the Medical Service Category.");
        }
      }
    }
  };

  return (
    <Box
      flex="1"
      alignSelf={"center"}
      minHeight="18vh"
      p={3}
      borderRadius={3}
      bgcolor={"background.default"}
      color={"text.primary"}
    >
      <Grid container spacing={2} sx={{ maxWidth: "350px", marginTop: "5px" }}>
        <Grid item xs={12}>
          <FormInputField
            label={"Category Name"}
            value={name}
            error={Boolean(errors.name)}
            errorText={errors.name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            startAdornment={<DriveFileRenameOutlineOutlined />}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "10px" }}>
          <FormInputField
            label={"Category Description"}
            value={description}
            error={Boolean(errors.description)}
            errorText={errors.description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            startAdornment={<DescriptionOutlined />}
            size="small"
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 3 }} display="flex" justifyContent="center" alignItems="center">
        <CustomButton className="btn--primary" onClick={updateMSC} label="Update" />
      </Box>
    </Box>
  );
};

export default UpdateMedicalServiceCategory;
