import { Box, Divider, Grid, IconButton, Skeleton, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import ModalUI from "ui-component/ModalUI";
import {
  featureNameSchema,
  featureDescriptionSchema,
  featureCodeSchema,
} from "../../Common/ValidationSchema/featureValidation";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  ACTIVE_STATE,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  accessToken,
  entitiesInfo,
} from "store/constant";
import SwitchLeftIcon from "@mui/icons-material/SwitchLeft";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useContext } from "react";
import { createFeatures, deleteFeatures, getFeatures } from "services/EntitiesServices";
import { useCallback } from "react";
import UpdateFeature from "./UpdateFeature";
import Reveal from "views/utilities/Reveal";
import InfoIcon from "@mui/icons-material/Info";
import InfoModal from "../InfoModal";

const Feature = () => {
  const [features, setFeatures] = useState([]);
  const [displayFeature, setDisplayFeature] = useState([]);
  const [newFeatureCode, setNewFeatureCode] = useState("");
  const [newFeature, setNewFeature] = useState("");
  const [newFeatureDescription, setNewFeatureDescription] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const { handleClick } = useContext(ToastContext);
  const accessTokenValue = accessToken();
  const entityInfo = entitiesInfo.find((entity) => entity.id === "Features");
  const [openDialog, setOpenDialog] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const response = await getFeatures();
      setFeatures(response?.data);
    } catch (error) {
      console.error("Error fetching features");
    }
  }, []);

  const handleDelete = async (feature) => {
    if (feature.status === ACTIVE_STATE) {
      try {
        await deleteFeatures(feature.id);
        handleClick("success", "Feature has been marked Inactive!");
        fetchData();
      } catch (e) {
        handleClick("error", "There seems to be an error marking the Feature as Inactive.");
      }
    } else {
      handleClick("info", "Feature is already Inactive!");
    }
  };

  const clearSearch = () => {
    setSearchQuery("");
  };

  const addFeature = async () => {
    if (errors.featureName || errors.featureDescription) {
      return;
    }

    const data = {
      name: newFeature,
      code: newFeatureCode,
      description: newFeatureDescription ? newFeatureDescription : null,
    };

    if (features.some((t) => t.name.toLowerCase() === newFeature.toLowerCase())) {
      handleClick("error", "Feature already exists");
      return;
    }

    try {
      const res = await createFeatures(data);
      handleClick("success", "Feature has been successfully added.");
      setNewFeature("");
      setNewFeatureDescription("");
      setFeatures([...features, res.data]);
    } catch (e) {
      handleClick("error", "There seems to be an error creating the feature.");
    }
  };

  useEffect(() => {
    setDisplayFeature(
      features.filter((s) => s.name.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()))
    );
  }, [features, searchQuery]);

  useEffect(() => {
    fetchData();
  }, [accessTokenValue, fetchData]);

  const closeModal = () => {
    setOpen(false);
    setSelected(null);
  };

  const openUpdateModal = (spec) => {
    setSelected(spec);
    setOpen(true);
  };

  //validation
  const [errors, setErrors] = useState({
    featureName: "",
    featureDescription: "",
  });

  const validateFeatureCode = async (featureCode) => {
    try {
      await featureCodeSchema.validate({ featureCode });
      setErrors((prevErrors) => ({ ...prevErrors, featureCode: "" }));
    } catch (e) {
      setErrors((prevErrors) => ({ ...prevErrors, featureCode: e.message }));
    }
  };

  const validateFeatureName = async (featureName) => {
    try {
      await featureNameSchema.validate({ featureName });
      setErrors((prevErrors) => ({ ...prevErrors, featureName: "" }));
    } catch (e) {
      setErrors((prevErrors) => ({ ...prevErrors, featureName: e.message }));
    }
  };

  const validateFeatureDescription = async (featureDescription) => {
    try {
      await featureDescriptionSchema.validate({ featureDescription });
      setErrors((prevErrors) => ({ ...prevErrors, featureDescription: "" }));
    } catch (e) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        featureDescription: e.message,
      }));
    }
  };

  const columns = [
    { field: "Sr. No.", label: "Sr. No" },
    { field: "name", label: "Name" },
    { field: "description", label: "Description" },
    { field: "Actions", label: "Actions" },
  ];

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: openUpdateModal,
    },
    {
      label: (rowData) => {
        return rowData.status === ACTIVE_STATE ? "Mark as Inactive" : "Mark as Active";
      },
      icon: <SwitchLeftIcon style={{ color: "#004C70" }} />,
      onClick: handleDelete,
    },
  ];

  return (
    <FeaturesSkeleton>
      <Reveal>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h2 style={{ marginBottom: "15px", fontSize: "25px", marginTop: "15px" }}>Features</h2>
          {entityInfo && (
            <>
              <InfoIcon
                style={{ cursor: "pointer", marginLeft: "15px" }}
                onClick={() => setOpenDialog(true)}
              />
              <InfoModal
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                entityInfo={{ ...entityInfo, title: "Features" }}
              />
            </>
          )}
        </div>
        <Divider sx={{ mb: "10px", borderBottomWidth: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: "19px",
                fontWeight: 600,
                color: "#004C70",
              }}
            >
              Search any feature
            </Typography>
            <Grid sx={{ mt: 2 }}>
              <FormInputField
                style={{ width: "30%" }}
                label={"Search feature"}
                value={searchQuery}
                startAdornment={<i className="ri-search-line ri-lg" />}
                endAdornment={
                  <IconButton onClick={clearSearch} edge="end">
                    {<ClearOutlinedIcon fontSize="small" />}
                  </IconButton>
                }
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                size="small"
              ></FormInputField>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: "19px",
                fontWeight: 600,
                color: "#004C70",
              }}
            >
              Add a new feature
            </Typography>
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={3} sx={{ mr: 2 }}>
                <FormInputField
                  label={"Feature Code"}
                  value={newFeatureCode}
                  error={Boolean(errors.featureCode)}
                  errorText={errors.featureCode}
                  onChange={(e) => {
                    setNewFeatureCode(e.target.value);
                    validateFeatureCode(e.target.value);
                  }}
                  startAdornment={<DriveFileRenameOutlineOutlinedIcon />}
                  size="small"
                ></FormInputField>
              </Grid>
              <Grid item xs={3} sx={{ mr: 2 }}>
                <FormInputField
                  label={"Feature Name"}
                  value={newFeature}
                  error={Boolean(errors.featureName)}
                  errorText={errors.featureName}
                  onChange={(e) => {
                    setNewFeature(e.target.value);
                    validateFeatureName(e.target.value);
                  }}
                  startAdornment={<DriveFileRenameOutlineOutlinedIcon />}
                  size="small"
                ></FormInputField>
              </Grid>
              <Grid item xs={4} sx={{ mr: 2 }}>
                <FormInputField
                  label={"Feature Description"}
                  value={newFeatureDescription}
                  error={Boolean(errors.featureDescription)}
                  errorText={errors.featureDescription}
                  onChange={(e) => {
                    setNewFeatureDescription(e.target.value);
                    validateFeatureDescription(e.target.value);
                  }}
                  startAdornment={<DescriptionOutlinedIcon />}
                  size="small"
                ></FormInputField>
              </Grid>
              <Grid item sx={{ mt: 0.5 }}>
                <CustomButton
                  className="btn--primary"
                  disabled={!(newFeature && newFeatureCode)}
                  onClick={addFeature}
                  label="Add"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          sx={{
            mt: 4,
            textAlign: "center",
          }}
        ></Grid>

        <CustomizedTable columns={columns} tableData={displayFeature} actions={actions} />

        <ModalUI
          visible={open}
          close={closeModal}
          title="Update Feature"
          component={
            <UpdateFeature
              selected={selected}
              close={() => {
                closeModal();
                fetchData();
              }}
            />
          }
        />
      </Reveal>
    </FeaturesSkeleton>
  );
};

const FeaturesSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="35%"
            height={80}
          />
        </Grid>

        <Grid item xs={12}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="90%"
            height={80}
          />
        </Grid>

        <Grid item xs={12}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={350}
          />
        </Grid>

        <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
          <Grid item xs={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Feature;
