import React, { useState, useEffect, useContext } from "react";
import { Grid, Card, CardContent, CardHeader } from "@mui/material";
import {
  accessToken,
  ACTIVE_STATE,
  DATE_FORMAT_DMY,
  DATE_TIME_FORMAT,
  INACTIVE_STATE,
} from "store/constant";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  getPackages,
  deletePackage,
  savePackage,
  updatePackage,
  getBundles,
} from "services/Subscription";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import ModalUI from "ui-component/ModalUI";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DescriptionIcon from "@mui/icons-material/Description";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import BorderAllIcon from "@mui/icons-material/BorderAll";
import dayjs from "dayjs";
import { Menu, MenuList } from "@mui/material";
import CustomMenuItem from "../../../ui-component/custom-components/CustomMenuItem";
import SwitchLeftIcon from "@mui/icons-material/SwitchLeft";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import { packageValidation } from "../Common/ValidationSchema/packageValidation";
import { Formik } from "formik";

const Package = () => {
  const { handleClick } = useContext(ToastContext);

  const [packages, setPackages] = useState([]);
  const [bundles, setBundles] = useState([]);
  const [validTillError, setValidTillError] = useState("Please enter valid till date.");
  const accessTokenValue = accessToken();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);

  const typeList = [
    { value: "YEARLY", menuLabel: "Yearly" },
    { value: "MONTHLY", menuLabel: "Monthly" },
    { value: "PERPETUAL", menuLabel: "Perpetual" },
  ];

  const initialPackageState = {
    name: "",
    description: "",
    price: "",
    type: "",
    validity: "",
    validTill: null,
  };

  const [packageInfo, setpackageInfo] = useState(initialPackageState);
  const [anchorEl, setAnchorEl] = useState(null);
  const [clickedRow, setClickedRow] = useState(null);
  const [currBundles, setcurrBundles] = useState([]);
  const [createBundleIndex, setCreateBundleIndex] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPackages();
        response.data = response.data.map((el) => {
          if (
            el.packageBundleAssociationResponses &&
            el.packageBundleAssociationResponses.length > 0
          ) {
            el.bundles = el.packageBundleAssociationResponses.map((subEl) => subEl.bundleId);
          } else {
            el.bundles = [];
          }
          return el;
        });
        setPackages(response.data);
      } catch (error) {
        console.error("Error fetching packages!");
      }
    };
    const fetchBundles = async () => {
      try {
        const response = await getBundles();
        response.data = response.data.map((el) => {
          el.value = el.id;
          el.menuLabel = el.name;
          return el;
        });
        setBundles(response.data);
      } catch (error) {
        console.error("Error fetching bundles!");
      }
    };
    fetchData();
    fetchBundles();
  }, [accessTokenValue, handleClick]);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setpackageInfo(initialPackageState);
  };

  const handleSavePackage = async (values) => {
    if (!validTillError) {
      try {
        const payload = {
          ...values,
          validTill: dayjs(values.validTill).format(DATE_TIME_FORMAT),
        };
        if (payload && payload.id) {
          const res = await updatePackage(payload.id, payload);
          const packageEditIndex = packages.findIndex((el) => el.id === payload.id);
          packages[packageEditIndex] = res.data;
          packages[packageEditIndex]["bundles"] = [];
          setPackages(packages);
          handleClick("success", "Package has been successfully updated.");
        } else {
          const res = await savePackage(payload);
          res.data["bundles"] = [];
          setPackages((prev) => [...prev, res.data]);
          handleClick("success", "Package has been successfully created.");
        }
        closeModal();
      } catch (error) {
        handleClick("error", "There seems to be an error creating the package.");
      }
    }
  };

  const handleUpdate = (data) => {
    setModalOpen(true);
    setpackageInfo(data);
    setValidTillError("");
  };

  const handleDeletePackage = async (data) => {
    try {
      await deletePackage(data.id);
      const indexToRemove = packages.findIndex((el) => el.id === data.id);
      setPackages([...packages.slice(0, indexToRemove), ...packages.slice(indexToRemove + 1)]);
      handleClick("success", "Package has been successfully deleted.");
    } catch (error) {
      // comparing this message in this case otherwise the backend error message will be displayed in all the cases
      if (
        error?.response?.data?.message === "Cannot delete as package is associated with licenses."
      ) {
        handleClick("error", "Cannot delete as package is already associated with some licences!");
      } else {
        handleClick("error", "There seems to be an error deleting the package.");
      }
    }
  };

  const handleMenuOpen = (event, row) => {
    setClickedRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: handleUpdate,
    },
    {
      label: "Delete",
      icon: <i className="ri-delete-bin-fill ri-xl icon-primary-blue" />,
      onClick: handleDeletePackage,
    },
  ];

  const handleChange = (event, el) => {
    const {
      target: { value },
    } = event;
    setcurrBundles(typeof value === "string" ? value.split(",") : value);
    packages[createBundleIndex].bundles = value;
    setPackages(packages);
  };

  const handleaddBundle = (index) => {
    setCreateBundleIndex(index);
    setcurrBundles([]);
  };

  const getBundleNames = (bundleIdArr) => {
    let str = "";
    bundleIdArr.forEach((id, index) => {
      const foundBundle = bundles.find((b) => b.id === id);
      if (foundBundle) {
        if (index === bundleIdArr.length - 1) {
          str += foundBundle.name;
        } else {
          str += foundBundle.name + ", ";
        }
      }
    });
    return str;
  };

  const navigateToSubscriptions = () => {
    navigate("/home/subscriptions");
  };

  const handleSaveBundles = async (index) => {
    try {
      const selBundles = packages[index]["bundles"];
      packages[index].packageBundleAssociationResponses.forEach((el) => {
        if (!selBundles.includes(el.bundleId)) {
          el.status = INACTIVE_STATE;
        }
      });
      selBundles.forEach((b) => {
        const presentCount = packages[index].packageBundleAssociationResponses.findIndex(
          (prevB) => prevB.bundleId === b
        );
        if (presentCount === -1) {
          packages[index].packageBundleAssociationResponses.push({
            id: null,
            packageId: packages[index].id,
            bundleId: b,
            packageResponse: null,
            bundleResponse: null,
            status: ACTIVE_STATE,
          });
        }
      });
      const apiData = { ...packages[index] };
      delete apiData.bundles;
      const res = await updatePackage(packages[index].id, apiData);
      packages[index] = res.data;
      packages[index].bundles = res.data.packageBundleAssociationResponses.map(
        (subEl) => subEl.bundleId
      );
    } catch (error) {
      handleClick("error", "Something went wrong!");
    }
  };

  const navigateToBundles = () => {
    navigate("/home/bundles");
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <h2 style={{ display: "inline" }}>Packages</h2>
        <CustomButton
          className="btn--secondary"
          label="Manage Subscriptions"
          style={{ marginLeft: "auto" }}
          onClick={() => navigateToSubscriptions()}
        />
        <CustomButton
          className="btn--secondary"
          label="Manage Bundles"
          style={{ marginLeft: "10px" }}
          onClick={() => navigateToBundles()}
        />
        <CustomButton
          className="ri-add-fill ri-lg btn--primary"
          label="Add Package"
          style={{ marginLeft: "10px" }}
          onClick={() => handleModalOpen()}
        />
      </div>
      <Grid container className="row mb-0 mx-0">
        {packages &&
          packages.map((el, index) => {
            return (
              <>
                <Grid key={index} item md={4} className="col">
                  <Card
                    style={{
                      backgroundColor: "#edf1f3",
                      margin: "5px",
                      borderRadius: "15%",
                      padding: "15px",
                    }}
                  >
                    <CardHeader
                      style={{ margin: "15px", textAlign: "center" }}
                      title={<label style={{ fontSize: "25px" }}>{el.name}</label>}
                      action={
                        <>
                          <CustomButton
                            iconButton={<i className="ri-more-2-fill" />}
                            onClick={(event) => handleMenuOpen(event, el)}
                          ></CustomButton>
                          <Menu
                            elevation={1}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            PaperProps={{ style: { borderRadius: "4px" } }}
                          >
                            <MenuList dense sx={{ p: 0 }}>
                              {actions.map((action, actionIndex) => {
                                return (
                                  <CustomMenuItem
                                    key={actionIndex}
                                    text={action.label}
                                    icon={action.icon}
                                    onClick={() => {
                                      handleMenuClose();
                                      action.onClick(clickedRow);
                                    }}
                                  />
                                );
                              })}
                            </MenuList>
                          </Menu>
                        </>
                      }
                    />
                    <CardContent>
                      <div className="row" style={{ margin: "10px", textAlign: "center" }}>
                        <Grid item md={12} className="col mb-2">
                          <label style={{ fontSize: "15px" }}>{el.description}</label>
                        </Grid>
                      </div>
                      <div className="row" style={{ textAlign: "center" }}>
                        <Grid item md={4} className="col">
                          <label style={{ fontSize: "15px" }}>Subscriptions :</label>
                        </Grid>
                        <Grid item md={8} className="col">
                          <label style={{ fontSize: "15px" }}>
                            <b>{el.count}</b>
                          </label>
                        </Grid>
                      </div>
                      <div className="row" style={{ margin: "10px", textAlign: "center" }}>
                        <Grid item md={4} className="col">
                          <label style={{ fontSize: "15px" }}>Price(INR) :</label>
                        </Grid>
                        <Grid item md={8} className="col">
                          <label style={{ fontSize: "15px" }}>
                            <b>{el.price} Rs.</b>
                          </label>
                        </Grid>
                      </div>
                      <div className="row" style={{ margin: "10px", textAlign: "center" }}>
                        <Grid item md={4} className="col">
                          <label style={{ fontSize: "15px" }}>Type :</label>
                        </Grid>
                        <Grid item md={8} className="col">
                          <label style={{ fontSize: "15px" }}>
                            <b>{el.type}</b>
                          </label>
                        </Grid>
                      </div>
                      {el.validTill && (
                        <>
                          <div className="row" style={{ margin: "10px", textAlign: "center" }}>
                            <Grid item md={4} className="col">
                              <label style={{ fontSize: "15px" }}>Valid Till :</label>
                            </Grid>
                            <Grid item md={8} className="col">
                              <label style={{ fontSize: "15px" }}>
                                <b>{el.validTill}</b>
                              </label>
                            </Grid>
                          </div>
                        </>
                      )}
                      <div className="row" style={{ margin: "10px", textAlign: "center" }}>
                        <Grid item md={4} className="col">
                          <label style={{ fontSize: "15px" }}>Validity :</label>
                        </Grid>
                        <Grid item md={8} className="col">
                          <label style={{ fontSize: "15px" }}>
                            <b>{el.validity} days</b>
                          </label>
                        </Grid>
                      </div>
                      <div className="row" style={{ margin: "10px", textAlign: "center" }}>
                        <Grid item md={4} className="col">
                          <label style={{ fontSize: "15px" }}>Bundles :</label>
                        </Grid>
                        <Grid item md={8} className="col">
                          {el.bundles && el.bundles.length === 0 && (
                            <>
                              {createBundleIndex !== index && (
                                <>
                                  <a
                                    onClick={() => handleaddBundle(index)}
                                    style={{
                                      color: "blue",
                                      textDecoration: "none",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <b>{"+ Add Bundle"}</b>
                                  </a>
                                </>
                              )}
                            </>
                          )}
                          {createBundleIndex === index && (
                            <>
                              <FormControl sx={{ width: "70%" }}>
                                <Select
                                  labelId="bundleSelection"
                                  id="bundleSelection"
                                  multiple
                                  size="small"
                                  value={currBundles}
                                  onChange={(event) => handleChange(event, el)}
                                  sx={{
                                    "& .MuiSelect-select": {
                                      fontSize: "14px",
                                      color: "#485772",
                                      fontWeight: "500",
                                    },
                                    "& .MuiOutlinedInput-input": {
                                      background: "#dee3e5",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      border: "none",
                                    },
                                    "& .MuiSelect-icon": {
                                      display: "none",
                                    },
                                  }}
                                >
                                  {bundles.map((bundle) => (
                                    <MenuItem key={bundle.id} value={bundle.id}>
                                      {bundle.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <span
                                className="cst-icon-bundle"
                                onClick={() => {
                                  setCreateBundleIndex(null);
                                  handleSaveBundles(index);
                                }}
                              >
                                <DoneAllIcon
                                  style={{ fontSize: "28px", marginTop: "5px" }}
                                ></DoneAllIcon>
                              </span>
                            </>
                          )}
                          {el.bundles && el.bundles.length > 0 && createBundleIndex !== index && (
                            <>
                              <b>{getBundleNames(el.bundles)}</b>
                              <span
                                className="cst-icon-bundle"
                                onClick={() => {
                                  setCreateBundleIndex(index);
                                  setcurrBundles(el.bundles);
                                }}
                              >
                                <EditIcon style={{ fontSize: "20px" }}></EditIcon>
                              </span>
                            </>
                          )}
                        </Grid>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </>
            );
          })}
      </Grid>
      <ModalUI
        visible={modalOpen}
        close={closeModal}
        title={packageInfo?.id ? "Update Package" : "Add Package"}
        component={
          <>
            <Formik
              validateOnMount={true}
              enableReinitialize={true}
              initialValues={packageInfo}
              validationSchema={packageValidation}
              onSubmit={async (values, { resetForm }) => {
                handleSavePackage(values);
                resetForm();
              }}
            >
              {({ values, errors, touched, setFieldValue, handleChange, handleSubmit }) => (
                <>
                  <div style={{ width: "400px" }}>
                    <FormInputField
                      style={{ width: "100%", marginTop: "20px" }}
                      label={"Name*"}
                      name={"name"}
                      inputProps={{ maxLength: 30 }}
                      value={values.name}
                      onChange={handleChange}
                      error={Boolean(touched.name && errors.name)}
                      errorText={errors.name}
                      startAdornment={<DriveFileRenameOutlineIcon></DriveFileRenameOutlineIcon>}
                    ></FormInputField>
                    <FormInputField
                      style={{ width: "100%", marginTop: "20px" }}
                      label="Description*"
                      name="description"
                      value={values.description}
                      inputProps={{ maxLength: 100 }}
                      onChange={handleChange}
                      error={Boolean(touched.description && errors.description)}
                      errorText={errors.description}
                      startAdornment={<DescriptionIcon></DescriptionIcon>}
                    ></FormInputField>
                    <FormInputField
                      style={{ width: "48%", marginTop: "20px", marginRight: "15px" }}
                      label={"Price (INR)*"}
                      name={"price"}
                      type="number"
                      value={values.price}
                      onChange={handleChange}
                      error={Boolean(touched.price && errors.price)}
                      errorText={errors.price}
                      startAdornment={<CurrencyRupeeIcon></CurrencyRupeeIcon>}
                    ></FormInputField>
                    <FormSelectField
                      style={{ width: "48%", marginTop: "20px" }}
                      label="Type*"
                      name="type"
                      onChange={handleChange}
                      error={Boolean(touched.type && errors.type)}
                      errorText={errors.type}
                      startAdornment={<BorderAllIcon />}
                      menuItems={typeList}
                      value={values.type}
                      size={"big"}
                    ></FormSelectField>
                    <FormDatePicker
                      inputFormat={DATE_FORMAT_DMY}
                      label={"Valid Till"}
                      disablePast
                      value={values.validTill}
                      size={"big"}
                      minDate={dayjs()}
                      onChange={(date) => {
                        setFieldValue("validTill", date);
                        if (date === null) {
                          setValidTillError("Please select valid till date.");
                        } else if (!date.isValid()) {
                          setValidTillError("Please select valid till date.");
                        } else if (date < dayjs()) {
                          setValidTillError("Till date can not be in the past.");
                        } else {
                          setValidTillError("");
                        }
                      }}
                      error={Boolean(validTillError && touched.validTill)}
                      errorText={validTillError}
                      style={{ width: "48%", marginTop: "20px", marginRight: "15px" }}
                    ></FormDatePicker>
                    <FormInputField
                      style={{ width: "48%", marginTop: "20px" }}
                      label="Validity (days)*"
                      name="validity"
                      value={values.validity}
                      onChange={handleChange}
                      error={Boolean(touched.validity && errors.validity)}
                      errorText={errors.validity}
                      startAdornment={<AccessTimeIcon></AccessTimeIcon>}
                    ></FormInputField>
                    <CustomButton
                      className="btn--secondary"
                      style={{ marginTop: "20px", padding: "5px 50px", float: "right" }}
                      type="submit"
                      label={"Save"}
                      onClick={handleSubmit}
                    ></CustomButton>
                  </div>
                </>
              )}
            </Formik>
          </>
        }
      ></ModalUI>
    </>
  );
};

export default Package;
