import { startsAndEndsWithAlphabetic } from "store/constant";
import * as Yup from "yup";

export const userSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Please enter your first name.")
    .min(2, "Minimum 2 characters are required.")
    .max(50, "Maximum 50 characters are allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  middleName: Yup.string()
    .min(1, "Minimum 1 character is required")
    .max(50, "Maximum 50 characters are allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  lastName: Yup.string()
    .required("Please enter your last name.")
    .min(2, "Minimum 2 characters are required.")
    .max(50, "Maximum 50 characters are allowed.")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  mobileNumber: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, "Please enter a valid Mobile Number")
    .required("Please enter a valid mobile number."),
  role: Yup.string().required("Role is mandatory. Kindly provide one."),
  gender: Yup.string().required("Gender is mandatory. Kindly provide your gender."),
  email: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Please enter a valid email.")
    .max(255, "Contact email is too long. Kindly provide email upto 255 characters.")
    .required("Please enter a valid email."),
});

export const userDoctorSchema = userSchema.concat(
  Yup.object().shape({
    specialization: Yup.string().required("Please select a specialization."),
    doctorOrganizationAssociation: Yup.array().min(1, "Please select at least one organization"),
  })
);

export const userClinicAdminSchema = userSchema.concat(
  Yup.object().shape({
    adminOrganizationAssociation: Yup.array().min(1, "Please select at least one organization"),
  })
);

export const userFrontDeskSchema = userSchema.concat(
  Yup.object().shape({
    frontDeskOrganizationAssociation: Yup.string().required(
      "Please select at least one organization"
    ),
  })
);

export const userSignUpSchema = Yup.object().shape({
  fullName: Yup.string()
    .required("Please enter your first name.")
    .min(3, "Minimum 3 characters are required")
    .max(100, "Maximum 100 characters are allowed")
    .matches(/^[a-zA-Z\s]*$/, "Name should only contain letters and space")
    .test(
      "contains-alphabetic",
      "Name must start and end with alphabet",
      startsAndEndsWithAlphabetic
    ),
  mobileNumber: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, "Please enter a valid Mobile Number")
    .required("Please enter a valid mobile number"),
  email: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Please enter a valid email.")
    .max(255, "Contact email is too long. Kindly provide email upto 255 characters.")
    .required("Please enter a valid email."),
});

export const updateUserProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Please enter your first name.")
    .min(2, "Minimum 2 characters are required.")
    .max(50, "Maximum 50 characters are allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  middleName: Yup.string()
    .min(1, "Minimum 1 character is required")
    .max(50, "Maximum 50 characters are allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  lastName: Yup.string()
    .required("Please enter your last name.")
    .min(2, "Minimum 2 characters are required.")
    .max(50, "Maximum 50 characters are allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  mobileNumber: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, "Please enter a valid Mobile Number")
    .required("Please enter a valid mobile number."),
  email: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Please enter a valid email.")
    .required("Please enter a valid email.")
    .max(255, "Email is too long. Please provide a valid email."),
  address: Yup.string()

    .required("Please provide your address.")

    .min(3, "Minimum 3 characters are required.")
    .max(256, "Maximum 256 characters are allowed"),
  gender: Yup.string().required("Please select your gender."),
});
