import React, { useState } from "react";
import { FormControl, TextField, InputAdornment, FormHelperText, InputLabel } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined"; // Import the calendar icon
import dayjs from "dayjs";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";

const FormDatePicker = ({
  label,
  value,
  onChange,
  size,
  style,
  background,
  disableBorder,
  iconAtEnd,
  borderRadius = "6px",
  border,
  borderOnFocus,
  inputPropStyle,
  readOnly = false,
  required = false,
  error,
  errorText,
  handleBlur,
  onClose,
  height,
  maxDate,
  views = ["year", "month", "day"],
  hideInput = false,
  blockedDates = [],
  closeOnSelect,
  closeOnBlockClick = false,
  disablePastDates = false,
  popperStyle = {},
  disabledDates,
  disableTextField = false,
  ...restProps
}) => {
  const [openCal, setOpenCal] = useState(hideInput);

  const renderDay = (day, selectedDate, pickersDayProps) => {
    const highlightedDates = blockedDates.map((date) => dayjs(date));
    const today = dayjs();
    const isHighlighted = highlightedDates.some((highlightedDate) =>
      dayjs(day).isSame(highlightedDate, "day")
    );
    const isPast = dayjs(day).isBefore(today, "day"); // Check if the date is before today

    return (
      <PickersDay
        {...pickersDayProps}
        disabled={disablePastDates && isPast} // Disable all past dates
        sx={{
          ...(isHighlighted && {
            backgroundColor: "red !important",
            color: "white",
            "&:hover": {
              backgroundColor: "darkred",
            },
          }),
          ...(isPast &&
            disablePastDates && {
              pointerEvents: "none", // Disable interaction for past dates
              opacity: 0.5, // Style for past dates to show they are disabled
            }),
        }}
      />
    );
  };

  return (
    <FormControl error={error} style={style}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          style={{
            transform: "translateX(1px)",
          }}
          value={value === "" ? `${dayjs()}` : value}
          onChange={(date) => {
            onChange(date);
          }}
          onClose={() => {
            if (onClose) {
              onClose();
            }
            setOpenCal(false);
          }}
          open={openCal}
          PopperProps={{
            disablePortal: false,
            modifiers: [
              {
                name: "flip",
                enabled: true,
                options: {
                  altBoundary: true,
                  rootBoundary: "viewport",
                  padding: 8,
                },
              },
              {
                name: "preventOverflow",
                enabled: true,
                options: {
                  altAxis: true,
                  altBoundary: true,
                  tether: true,
                  rootBoundary: "document",
                  padding: 8,
                },
              },
            ],
            sx: hideInput
              ? {
                  "&.MuiPickersPopper-root": {
                    ...popperStyle,
                  },
                  "& .MuiPickersDay-root:not(.Mui-selected):hover": {
                    backgroundColor: "#999999",
                  },
                }
              : {},
          }}
          views={views}
          defaultCalendarMonth={maxDate ? dayjs(maxDate) : undefined}
          maxDate={maxDate}
          renderInput={(params) => (
            <>
              {!hideInput && (
                <InputLabel required={required} shrink={true}>
                  {label}
                </InputLabel>
              )}
              {!hideInput && (
                <TextField
                  onKeyDown={
                    disableTextField
                      ? (e) => {
                          e.preventDefault();
                        }
                      : undefined
                  }
                  onBlur={handleBlur}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: disableBorder ? "none" : border ? border : undefined,
                      borderRadius: `${borderRadius} !important`,
                    },
                    "& .MuiOutlinedInput-input": {
                      background: background ? background : undefined,
                      height: `${height} !important`,
                    },
                    "& .MuiOutlinedInput-root": {
                      background: background ? background : undefined,
                      "&:hover fieldset": {
                        border: `${border} !important`,
                      },
                      "&.Mui-focused fieldset": {
                        border: `${borderOnFocus} !important`,
                        transition: "0.5s ease",
                      },
                    },
                  }}
                  {...params}
                  InputProps={{
                    sx: {
                      ...inputPropStyle,
                    },
                    label: label,
                    error: error,
                    size: size,
                    startAdornment: !iconAtEnd && !readOnly && (
                      <InputAdornment
                        position="start"
                        sx={{ marginRight: "11px", marginLeft: "1px" }}
                        className="calander"
                      >
                        <EventOutlinedIcon onClick={() => setOpenCal(!openCal)} />
                      </InputAdornment>
                    ),
                    endAdornment: iconAtEnd && !readOnly && (
                      <InputAdornment
                        position="start"
                        sx={{ marginRight: "11px", marginLeft: "1px" }}
                        className="calander"
                      >
                        <EventOutlinedIcon onClick={() => setOpenCal(!openCal)} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              {error && <FormHelperText error>{errorText}</FormHelperText>}
            </>
          )}
          readOnly={readOnly}
          renderDay={renderDay}
          closeOnSelect={!hideInput} // Prevent the date picker from closing on date select
          shouldDisableDate={disabledDates}
          {...restProps}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

export default FormDatePicker;
