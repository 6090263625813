import React, { useEffect, useRef, useState } from "react";
import {
  FormControl,
  Select,
  Button,
  MenuItem,
  InputLabel,
  Box,
  Paper,
  Typography,
  Grid,
} from "@mui/material";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { useContext } from "react";
import { getAllNotificationTypes } from "services/NotificationService";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { notificationCreation } from "../Common/ValidationSchema/notificationSchema";
import { Form, Formik } from "formik";

const ConfigureNotification = ({ submitNotification, notification }) => {
  const { handleClick } = useContext(ToastContext);
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [notificationTypesData, setNotificationTypesData] = useState([]);
  const [initialParams, setInitialParams] = useState(
    notification ? Object.keys(notification.params) : []
  );
  const formRef = useRef(null);

  const initialValues = {
    notificationType: notification?.notificationType || "",
    scheduleType: notification?.scheduleType || "",
    scheduledDate: notification?.scheduledOn
      ? notification.scheduledOn.toString().split("T")[0]
      : "",
    scheduledTime: notification?.scheduledOn
      ? notification.scheduledOn.toString().split("T")[1]
      : "",
    params: notification ? notification.params : {},
  };

  useEffect(() => {
    fetchTypes();
  }, []);

  useEffect(() => {
    const foundItem = notificationTypesData?.find(
      (item) => item.id === notification?.notificationTypeId
    );
    setInitialParams(foundItem ? foundItem.params : []);
  }, [initialValues.notificationType, notificationTypesData]);

  const fetchTypes = async () => {
    let { data } = await getAllNotificationTypes();
    data = data.filter((type) => type.status === "ACTIVE");
    const types = [...new Set(data.map((item) => item.type))];
    setNotificationTypesData(data);
    setNotificationTypes(types);
  };

  useEffect(() => {
    if (notification) {
      const foundItem =
        notificationTypesData.length !== 0
          ? notificationTypesData.find((item) => item.id === notification.notificationTypeId)
          : {};
      formRef.current.setFieldValue("notificationType", foundItem ? foundItem.type : "");
    }
  }, [notificationTypesData, notification]);

  const getChannels = (type) => {
    const channels = [];
    const foundItem = notificationTypesData.find((item) => item.type === type);
    if (foundItem?.template?.emailTemplateResponse) channels.push("EMAIL");
    if (foundItem?.template?.smsTemplateResponse) channels.push("SMS");
    if (foundItem?.template?.whatsappTemplateResponse) channels.push("WHATSAPP");
    if (foundItem?.template?.pushNotificationTemplateResponse) channels.push("PUSH NOTIFICATION");
    return channels.join(",");
  };

  const handleSubmit = async (values) => {
    try {
      const foundItem = notificationTypesData.find((item) => item.type === values.notificationType);

      const notificationData = {
        receiverUserId: "eb6933ed-d434-4cc5-b4a2-0c8251854532",
        scheduleType: values.scheduleType,
        notificationTypeId: foundItem.id,
        channelType: getChannels(values.notificationType),
        params: values.params,
        status: "ACTIVE",
      };

      if (values.scheduleType === "SCHEDULED") {
        notificationData["scheduledOn"] = new Date(
          `${values.scheduledDate}T${values.scheduledTime}:00`
        );
      }

      submitNotification(notificationData, notification?.id, !!notification);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={notificationCreation}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form>
          <Box mb={2}>
            <Paper elevation={3}>
              <Box p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="notification-type-label">Notification Type</InputLabel>
                      <Select
                        labelId="notification-type-label"
                        value={values.notificationType}
                        error={Boolean(touched.notificationType && errors.notificationType)}
                        errorText={errors.notificationType}
                        onChange={(e) => {
                          setFieldValue("notificationType", e.target.value);
                          setFieldValue("params", {});
                          const foundItem = notificationTypesData.find(
                            (item) => item.type === e.target.value
                          );
                          setInitialParams(foundItem ? foundItem.params : []);
                        }}
                        inputProps={{
                          name: "notificationType",
                          id: "notification-type",
                        }}
                      >
                        {notificationTypes.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.notificationType && errors.notificationType && (
                        <div style={{ color: "red" }}>{errors.notificationType}</div>
                      )}
                    </FormControl>
                  </Grid>

                  {initialParams.length > 0 && (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                          Enter Params:
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          px={4}
                          py={1}
                          sx={{
                            boxSizing: "content-box",
                            border: "1px solid silver",
                            borderRadius: "12px",
                          }}
                        >
                          <Grid container item xs={12} spacing={2}>
                            {initialParams.map((param, index) => (
                              <Grid item xs={6} key={index}>
                                <FormInputField
                                  style={{ width: "100%" }}
                                  label={param.charAt(0).toUpperCase() + param.slice(1)}
                                  name={`params.${param}`}
                                  type="text"
                                  required
                                  shrink={true}
                                  value={values.params[param] || ""}
                                  error={Boolean(errors.params?.[param] && touched.params?.[param])}
                                  errorText={errors.params?.[param]}
                                  onChange={(event) =>
                                    setFieldValue(`params.${param}`, event.target.value)
                                  }
                                  size="big"
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="schedule-type-label">Schedule Type</InputLabel>
                      <Select
                        labelId="schedule-type-label"
                        value={values.scheduleType}
                        error={Boolean(touched.scheduleType && errors.scheduleType)}
                        errorText={errors.scheduleType}
                        onChange={(e) => setFieldValue("scheduleType", e.target.value)}
                        inputProps={{
                          name: "scheduleType",
                          id: "schedule-type",
                        }}
                      >
                        {["PRIORITY", "SCHEDULED"].map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.scheduleType && errors.scheduleType && (
                        <div style={{ color: "red" }}>{errors.scheduleType}</div>
                      )}
                    </FormControl>
                  </Grid>

                  {values.scheduleType === "SCHEDULED" && (
                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={6}>
                        <FormInputField
                          style={{ width: "100%" }}
                          label="Scheduled Date"
                          name="scheduledDate"
                          type="date"
                          required
                          shrink={true}
                          value={values.scheduledDate}
                          error={Boolean(errors.scheduledDate && touched.scheduledDate)}
                          errorText={errors.scheduledDate}
                          onChange={(event) => setFieldValue("scheduledDate", event.target.value)}
                          size="big"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormInputField
                          style={{ width: "100%" }}
                          label="Scheduled Time"
                          name="scheduledTime"
                          type="time"
                          required
                          shrink={true}
                          value={values.scheduledTime}
                          error={Boolean(errors.scheduledTime && touched.scheduledTime)}
                          errorText={errors.scheduledTime}
                          onChange={(event) => setFieldValue("scheduledTime", event.target.value)}
                          size="big"
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Paper>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ backgroundColor: "#004c70" }}
                fullWidth
              >
                Save
              </Button>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default ConfigureNotification;
