import { Grid, Typography, IconButton, Skeleton, Box, Divider } from "@mui/material";
import React, { useState, useEffect } from "react";
import ModalUI from "ui-component/ModalUI";
import UpdateTreat from "./UpdateTreat";
import { treatValidationSchema } from "../../Common/ValidationSchema/commonTreatsValidation";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  ACTIVE_STATE,
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  accessToken,
  entitiesInfo,
  gridSpacing,
} from "store/constant";
import SwitchLeftIcon from "@mui/icons-material/SwitchLeft";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import { useContext } from "react";
import { createCommonTreats, deleteCommonTreats, getAllCommonTreats } from "services/doctorService";
import { useCallback } from "react";
import Reveal from "views/utilities/Reveal";
import InfoIcon from "@mui/icons-material/Info";
import InfoModal from "../InfoModal";

const CommonTreats = () => {
  const [treats, setTreats] = useState([]);
  const [displayTreats, setDisplayTreats] = useState([]);
  const [newTreat, setNewTreat] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const { handleClick } = useContext(ToastContext);
  const accessTokenValue = accessToken();
  const [openDialog, setOpenDialog] = useState(false);
  const entityInfo = entitiesInfo.find((entity) => entity.id === "CommonTreats");

  const fetchData = useCallback(async () => {
    try {
      const response = await getAllCommonTreats();
      setTreats(response?.data);
    } catch (error) {
      console.error("Error fetching common-treatments");
    }
  }, []);

  const handleDelete = async (commonTreat) => {
    if (commonTreat.status === ACTIVE_STATE) {
      try {
        await deleteCommonTreats(commonTreat.id);
        handleClick("success", "Common-treatement has been marked Inactive!");
        fetchData();
      } catch (e) {
        handleClick(
          "error",
          "There seems to be an error marking the Common-treatement as Inactive."
        );
      }
    } else {
      handleClick("info", "Common-treatement is already inactive!");
    }
  };

  const [error, setError] = useState("");

  const clearSearch = () => {
    setSearchQuery("");
  };

  const validateTreat = async (treat) => {
    try {
      await treatValidationSchema.validate({ treat });
      setError("");
    } catch (e) {
      setError(e.message);
    }
  };

  const addTreat = async () => {
    const data = { treatmentName: newTreat };

    if (treats.some((t) => t.treatmentName.toLowerCase() === newTreat.toLowerCase())) {
      handleClick("error", "This treatment has already been recorded.");
      return;
    }

    if (error) {
      return;
    }

    try {
      const res = await createCommonTreats(data);
      handleClick("success", "Treatment has been successfully added.");
      setNewTreat("");
      setError("");
      setTreats([...treats, res.data]);
    } catch (e) {
      handleClick("error", "There seems to be an error creating the treatment.");
    }
  };

  useEffect(() => {
    setDisplayTreats(
      treats.filter((s) =>
        s.treatmentName.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())
      )
    );
  }, [treats, searchQuery]);

  useEffect(() => {
    fetchData();
  }, [accessTokenValue, fetchData]);

  const closeModal = () => {
    setOpen(false);
    setSelected(null);
  };

  const openUpdateModal = (spec) => {
    setSelected(spec);
    setOpen(true);
  };

  const columns = [
    { field: "Sr. No.", label: "Sr. No" },
    { field: "treatmentName", label: "Treatments" },
    { field: "Actions", label: "Actions" },
  ];

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: openUpdateModal,
    },
    {
      label: (rowData) => {
        return rowData.status === ACTIVE_STATE ? "Mark as Inactive" : "Mark as Active";
      },
      icon: <SwitchLeftIcon style={{ color: "#004C70" }} />,
      onClick: handleDelete,
    },
  ];

  return (
    <CommonTreatsSkeleton>
      <Reveal>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h2 style={{ marginBottom: "15px", fontSize: "25px", marginTop: "15px" }}>
            Common Treatments
          </h2>
          {entityInfo && (
            <>
              <InfoIcon
                style={{ cursor: "pointer", marginLeft: "15px" }}
                onClick={() => setOpenDialog(true)}
              />
              <InfoModal
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                entityInfo={{ ...entityInfo, title: "Common Treatments" }}
              />
            </>
          )}
        </div>
        <Divider sx={{ mb: "10px", borderBottomWidth: 2 }} />
        <Grid container spacing={5}>
          <Grid item xs={5}>
            <Typography
              sx={{
                fontSize: "19px",
                fontWeight: 600,
                color: "#004C70",
              }}
            >
              Search any Treatment
            </Typography>
            <Grid sx={{ mt: 2 }}>
              <FormInputField
                style={{ width: "90%" }}
                label={"Search Treatments"}
                value={searchQuery}
                startAdornment={<i className="ri-search-line ri-lg" />}
                endAdornment={
                  <IconButton onClick={clearSearch} edge="end">
                    {<ClearOutlinedIcon fontSize="small" />}
                  </IconButton>
                }
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                size="small"
              ></FormInputField>
            </Grid>
          </Grid>

          <Grid item xs={7}>
            <Typography
              sx={{
                fontSize: "19px",
                fontWeight: 600,
                color: "#004C70",
              }}
            >
              Add a new Treatment
            </Typography>
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={8} sx={{ mr: 2 }}>
                <FormInputField
                  label={"Add Treatment"}
                  value={newTreat}
                  error={Boolean(error)}
                  errorText={error}
                  onChange={(e) => {
                    setNewTreat(e.target.value);
                    validateTreat(e.target.value);
                  }}
                  startAdornment={<DriveFileRenameOutlineOutlinedIcon />}
                  size="small"
                ></FormInputField>
              </Grid>
              <Grid item sx={{ mt: 0.5 }}>
                <CustomButton
                  className="btn--primary"
                  disabled={newTreat === ""}
                  onClick={addTreat}
                  label="Add"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          sx={{
            mt: 4,
            textAlign: "center",
          }}
        ></Grid>
        <CustomizedTable columns={columns} tableData={displayTreats} actions={actions} />

        <ModalUI
          visible={open}
          close={closeModal}
          title="Update Treatment"
          component={
            <UpdateTreat
              selected={selected}
              close={() => {
                closeModal();
                fetchData();
              }}
            />
          }
        />
      </Reveal>
    </CommonTreatsSkeleton>
  );
};

const CommonTreatsSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"}>
          <Skeleton
            sx={{ borderRadius: "4px", mr: "10%" }}
            animation="wave"
            variant="rectangular"
            width="35%"
            height={80}
          />
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="40%"
            height={80}
          />
        </Grid>

        <Grid item xs={12}>
          <Skeleton
            sx={{ borderRadius: "4px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={350}
          />
        </Grid>

        <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
          <Grid item xs={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
          <Grid item xs={2}>
            <Skeleton
              sx={{ borderRadius: "4px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={30}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CommonTreats;
