// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: hsla(171, 23%, 94%, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.overlay .spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.overlay .spinner .loader-message {
  font-size: 18px;
  color: #004c70;
  font-weight: 600;
}
.overlay .spinner .dots {
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
}
.overlay .spinner .dot {
  width: 5px;
  height: 5px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #004c70; /* Dot color */
  animation: bounce 1.5s infinite;
}
.overlay .spinner .dot:nth-child(1) {
  animation-delay: 0s;
}
.overlay .spinner .dot:nth-child(2) {
  animation-delay: 0.3s;
}
.overlay .spinner .dot:nth-child(3) {
  animation-delay: 0.6s;
}
@keyframes bounce {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/loader.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,aAAA;EACA,0CAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AACI;EACE,eAAA;EACA,cAAA;EACA,gBAAA;AACN;AAEI;EACE,aAAA;EACA,qBAAA;EACA,kBAAA;AAAN;AAGI;EACE,UAAA;EACA,WAAA;EACA,aAAA;EACA,kBAAA;EACA,yBAAA,EAAA,cAAA;EACA,+BAAA;AADN;AAII;EACE,mBAAA;AAFN;AAKI;EACE,qBAAA;AAHN;AAMI;EACE,qBAAA;AAJN;AAOI;EACE;IAEE,mBAAA;EANN;EAQI;IACE,qBAAA;EANN;AACF","sourcesContent":[".overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  background-color: hsla(171, 23%, 94%, 0.8);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 9999;\n\n  .spinner {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    .loader-message {\n      font-size: 18px;\n      color: #004c70;\n      font-weight: 600;\n    }\n\n    .dots {\n      display: flex;\n      align-items: flex-end;\n      margin-bottom: 5px;\n    }\n\n    .dot {\n      width: 5px;\n      height: 5px;\n      margin: 0 5px;\n      border-radius: 50%;\n      background-color: #004c70; /* Dot color */\n      animation: bounce 1.5s infinite;\n    }\n\n    .dot:nth-child(1) {\n      animation-delay: 0s;\n    }\n\n    .dot:nth-child(2) {\n      animation-delay: 0.3s;\n    }\n\n    .dot:nth-child(3) {\n      animation-delay: 0.6s;\n    }\n\n    @keyframes bounce {\n      0%,\n      100% {\n        transform: scale(1);\n      }\n      50% {\n        transform: scale(1.5);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
