import { Grid, Typography } from "@mui/material";
import SwitchLeftIcon from "@mui/icons-material/SwitchLeft";
import { Box } from "@mui/system";
import CustomButton from "ui-component/custom-components/CustomButton";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import ModalUI from "ui-component/ModalUI";
import { useState } from "react";
import { useEffect } from "react";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { Article, CorporateFare, EmojiEvents, Newspaper } from "@mui/icons-material";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import dayjs from "dayjs";
import { ACTIVE_STATE, startsWithAlphabetic } from "store/constant";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  createDoctorAwards,
  createPublications,
  deleteDoctorAwards,
  deletePublications,
  updateDoctorAwards,
  updatePublications,
} from "services/doctorService";
import Reveal from "views/utilities/Reveal";

const calendarValue = (year) => {
  if (year) {
    return dayjs(`${year}-01-01`);
  }

  return null;
};

const DoctorAwardsAndPublications = ({
  doctorDetails,
  viewOnlyPage,
  setDoctorDetails,
  readOnly,
  setReadOnly,
  refreshData,
  ...others
}) => {
  const [openAward, setOpenAward] = useState(false);
  const [openPublication, setOpenPublication] = useState(false);

  const [selectedAward, setSelectedAward] = useState(null);
  const [selectedPublication, setSelectedPublication] = useState(null);
  const { handleClick } = useContext(ToastContext);

  const MIN_AGE_FOR_AWARD = 12;
  const MIN_AGE_FOR_PUBLICATION = 12;

  const doctorDateOfBirth = dayjs(doctorDetails?.userResponse?.dateOfBirth);
  const minAwardDate = doctorDateOfBirth.add(MIN_AGE_FOR_AWARD, "year").startOf("year");
  const minPublicationDate = doctorDateOfBirth.add(MIN_AGE_FOR_PUBLICATION, "year").startOf("year");

  const closeModal = () => {
    setOpenAward(false);
    setOpenPublication(false);
    setSelectedAward(null);
    setSelectedPublication(null);
  };

  const openAddAward = () => {
    setOpenAward(true);
  };

  const openUpdateAward = (award) => {
    setOpenAward(true);
    setSelectedAward(award);
  };

  const openAddPublication = () => {
    setOpenPublication(true);
  };

  const openUpdatePublication = (publication) => {
    setOpenPublication(true);
    setSelectedPublication(publication);
  };

  const handleAwardMarkInactive = async (row) => {
    if (row.status === ACTIVE_STATE) {
      try {
        await deleteDoctorAwards(row.id);
        handleClick("success", "Award marked Inactive");
      } catch (error) {
        handleClick("error", "There seems to be an error marking the award as inactive.");
      }
    } else {
      handleClick("info", "Award is already inactive!");
    }
  };

  const handlePublicationMarkInactive = async (row) => {
    if (row.status === ACTIVE_STATE) {
      try {
        await deletePublications(row.id);
        handleClick("success", "Publication has been marked inactive");
      } catch (error) {
        handleClick("error", "There seems to be an error marking the publication inactive.");
      }
    } else {
      handleClick("error", "Publication is already inactive!");
    }
  };

  const awardActions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: openUpdateAward,
    },
    {
      label: (rowData) => {
        return rowData.status === ACTIVE_STATE ? "Mark as Inactive" : "Mark as Active";
      },
      icon: <SwitchLeftIcon style={{ color: "#004C70" }} />,
      onClick: handleAwardMarkInactive,
    },
  ];

  const publicationActions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-fill ri-xl icon-primary-blue" />,
      onClick: openUpdatePublication,
    },
    {
      label: (rowData) => {
        return rowData.status === ACTIVE_STATE ? "Mark as Inactive" : "Mark as Active";
      },
      icon: <SwitchLeftIcon style={{ color: "#004C70" }} />,
      onClick: handlePublicationMarkInactive,
    },
  ];

  const awardColumns = [
    { field: "awardName", label: "Award Name" },
    { field: "awardedBy", label: "Award By" },
    { field: "awardReceivedYear", label: "Year" },
    ...(!viewOnlyPage ? [{ field: "Actions", label: "ACTIONS", actions: true }] : []),
  ];

  const publicationColumns = [
    { field: "paperName", label: "Publication Name" },
    { field: "publishedIn", label: "Published in" },
    { field: "yearOfPublication", label: "Year of Publications" },
    ...(!viewOnlyPage ? [{ field: "Actions", label: "ACTIONS", actions: true }] : []),
  ];

  const currentDate = dayjs();

  return (
    <Reveal>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <Typography sx={{ fontSize: "14px", color: "#004C70", fontWeight: "600" }}>
          Awards
        </Typography>
        {!viewOnlyPage && (
          <CustomButton
            onClick={openAddAward}
            label="Add"
            className="ri-add-fill ri-lg btn--primary"
          />
        )}
      </Box>

      <CustomizedTable
        columns={awardColumns}
        tableData={doctorDetails?.awards}
        actions={awardActions}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <Typography sx={{ fontSize: "14px", color: "#004C70", fontWeight: "600" }}>
          Publications
        </Typography>
        {!viewOnlyPage && (
          <CustomButton
            onClick={openAddPublication}
            label="Add"
            className="ri-add-fill ri-lg btn--primary"
          />
        )}
      </Box>

      <CustomizedTable
        columns={publicationColumns}
        tableData={doctorDetails?.publications}
        actions={publicationActions}
      />

      {/* Awards Modal */}
      <ModalUI
        visible={openAward}
        close={closeModal}
        title={selectedAward ? "Update Award" : "Add Award"}
        component={
          <DoctorAwardModal
            minAwardDate={minAwardDate}
            awards={doctorDetails?.awards}
            selected={selectedAward}
            handleClick={handleClick}
            close={() => {
              closeModal();
            }}
            currentDate={currentDate}
            doctorId={doctorDetails?.id}
            refreshData={refreshData}
          />
        }
      />

      {/* Publication Modal */}
      <ModalUI
        visible={openPublication}
        close={closeModal}
        title={selectedPublication ? "Update Publication" : "Add Publication"}
        component={
          <DoctorPublicationModal
            minPublicationDate={minPublicationDate}
            publications={doctorDetails?.publications}
            selected={selectedPublication}
            handleClick={handleClick}
            close={() => {
              closeModal();
            }}
            currentDate={currentDate}
            doctorId={doctorDetails?.id}
            refreshData={refreshData}
          />
        }
      />
    </Reveal>
  );
};

const DoctorAwardModal = ({
  minAwardDate,
  awards,
  currentDate,
  selected,
  handleClick,
  close,
  doctorId,
  refreshData,
}) => {
  const [awardName, setAwardName] = useState(selected?.awardName ? selected?.awardName : "");
  const [awardedBy, setAwardedBy] = useState(selected?.awardedBy ? selected?.awardedBy : "");
  const [year, setYear] = useState(
    selected?.awardReceivedYear ? calendarValue(selected?.awardReceivedYear) : null
  );
  const [errors, setErrors] = useState({});

  const [awardNameTouched, setAwardNameTouched] = useState(false);
  const [awardedByTouched, setAwardedByTouched] = useState(false);
  const pattern = /^[a-zA-Z\s'-.()]+$/;

  const validateAwardName = (errors, awardName) => {
    if (!awardName) {
      errors.awardName = "Please enter award name.";
    } else if (awardName.length < 3) {
      errors.awardName = "Award name must be at least 3 characters long";
    } else if (awardName.length > 100) {
      errors.awardName = "Award name cannot be longer than 100 characters";
    } else if (!pattern.test(awardName)) {
      errors.awardName =
        "Award name can only contain letters and following special characters: .-'()";
    } else if (!startsWithAlphabetic(awardName)) {
      errors.awardName = "Award name must start with alphabet";
    }
  };

  const validateAwardedBy = (errors, awardedBy) => {
    if (!awardedBy) {
      errors.awardedBy = "Please enter awardedBy name";
    } else if (awardedBy.length < 3) {
      errors.awardedBy = "Name must be at least 3 characters long";
    } else if (awardedBy.length > 100) {
      errors.awardedBy = "Name cannot be longer than 100 characters";
    } else if (!pattern.test(awardedBy)) {
      errors.awardedBy = "Name can only contain letters and following special characters: .-'()";
    } else if (!startsWithAlphabetic(awardedBy)) {
      errors.awardedBy = "Name must start with alphabet";
    }
  };

  const validate = () => {
    const errors = {};
    setAwardNameTouched(true);
    validateAwardName(errors, awardName);

    setAwardedByTouched(true);
    validateAwardedBy(errors, awardedBy);

    if (year === null) {
      errors.year = "Please enter valid year.";
    } else if (!(year?.isValid ? year.isValid() : true)) {
      errors.year = "Please enter valid 'Date' value.";
    } else if (year > currentDate) {
      errors.year = "Date cannot be in future.";
    } else if (year.isBefore(minAwardDate)) {
      errors.year = `Year should be at least ${minAwardDate.year()} for awards.`;
    }

    return errors;
  };

  useEffect(() => {
    if (selected && awards) {
      awards.find((award) => {
        return award.id === selected.id;
      });
    }
  }, [selected, awards]);

  const saveAward = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});

      let data = {
        doctorId: doctorId,
        awardName: awardName,
        awardedBy: awardedBy,
        awardReceivedYear: year?.$y ? year.$y : year.year(),
      };

      if (selected) {
        try {
          const postData = {
            ...data,
            id: selected?.id,
          };
          await updateDoctorAwards(selected.id, postData);
          handleClick("success", "Award has been successfully updated.");
          close();
          refreshData();
        } catch (error) {
          handleClick("error", "There seems to be an error updating the award.");
          close();
        }
      } else {
        try {
          const postData = { ...data };
          await createDoctorAwards(postData);
          handleClick("success", "Award has been successfully added.");
          close();
          refreshData();
        } catch (error) {
          handleClick("error", "There seems to be an error adding the award.");
          close();
        }
      }
    }
  };

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: "10px", width: "400px" }}>
        <Grid item xs={12}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Award Name"
            name="awardName"
            value={awardName}
            required
            onBlur={(e) => {
              setAwardNameTouched(true);
              const newError = {};
              validateAwardName(newError, awardName);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setAwardName(e.target.value);
              if (awardNameTouched) {
                const newError = {};
                validateAwardName(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.awardName;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            size={"big"}
            startAdornment={<EmojiEvents />}
            error={Boolean(errors.awardName)}
            errorText={errors.awardName}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Awarded By"
            name="awardedBy"
            required
            value={awardedBy}
            onBlur={(e) => {
              setAwardedByTouched(true);
              const newError = {};
              validateAwardedBy(newError, awardedBy);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setAwardedBy(e.target.value);
              if (awardedByTouched) {
                const newError = {};
                validateAwardedBy(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.awardedBy;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            size={"big"}
            startAdornment={<CorporateFare />}
            error={Boolean(errors.awardedBy)}
            errorText={errors.awardedBy}
          />
        </Grid>
        <Grid item xs={12}>
          <FormDatePicker
            label={"Year"}
            required
            style={{ width: "100%", marginTop: "7px" }}
            disableFuture
            minDate={minAwardDate}
            value={year}
            onChange={(year) => {
              setYear(year);
              if (year === null) {
                setErrors({ ...errors, year: "Please select a valid year." });
              } else if (!year.isValid()) {
                setErrors({ ...errors, year: "Please select a valid 'Year' value." });
              } else if (year > currentDate) {
                setErrors({ ...errors, year: "Year cannot be in a future." });
              } else if (year.isBefore(minAwardDate)) {
                setErrors({
                  ...errors,
                  year: `Year should be at least ${minAwardDate.year()} for awards.`,
                });
              } else {
                const { year, ...newError } = errors;
                setErrors(newError);
              }
            }}
            views={["year"]}
            openTo="year"
            disableBorder={false}
            error={Boolean(errors.year)}
            errorText={errors.year}
          />
        </Grid>

        <Grid item sx={{ mt: 1 }} container justifyContent={"center"}>
          <CustomButton
            onClick={saveAward}
            label={"Save"}
            className={"btn--secondary"}
            style={{ marginLeft: "auto" }}
          />
        </Grid>
      </Grid>
    </>
  );
};

const DoctorPublicationModal = ({
  minPublicationDate,
  publications,
  selected,
  handleClick,
  close,
  doctorId,
  refreshData,
  currentDate,
}) => {
  const [paperName, setPaperName] = useState(selected?.paperName ? selected?.paperName : "");
  const [publishedIn, setPublishedIn] = useState(
    selected?.publishedIn ? selected?.publishedIn : ""
  );
  const [yearOfPublication, setYearOfPublication] = useState(
    selected?.yearOfPublication ? calendarValue(selected?.yearOfPublication) : null
  );

  const [paperNameTouched, setPaperNameTouched] = useState(false);
  const [publishedInTouched, setPublishedInTouched] = useState(false);
  const pattern = /^[a-zA-Z0-9\s'-.()]+$/;

  const [errors, setErrors] = useState({});

  const validatePaperName = (errors, paperName) => {
    if (!paperName) {
      errors.paperName = "Please enter the paper name.";
    } else if (paperName.length < 3) {
      errors.paperName = "Paper name must be at least 3 characters long";
    } else if (paperName.length > 100) {
      errors.paperName = "Paper name cannot be longer than 100 characters";
    } else if (!pattern.test(paperName)) {
      errors.paperName = "Paper name can only contain following special characters: .-'()";
    } else if (!startsWithAlphabetic(paperName)) {
      errors.paperName = "Paper Name must start with alphabet";
    }
  };

  const validatePublishedIn = (errors, publishedIn) => {
    if (!publishedIn) {
      errors.publishedIn = "Please enter the publication name.";
    } else if (publishedIn.length < 3) {
      errors.publishedIn = "Must be at least 3 characters long";
    } else if (publishedIn.length > 100) {
      errors.publishedIn = "Cannot be longer than 100 characters";
    } else if (!pattern.test(publishedIn)) {
      errors.publishedIn = "can only contain following special characters: .-'()";
    } else if (!startsWithAlphabetic(publishedIn)) {
      errors.publishedIn = "Name must start with alphabet";
    }
  };

  const validate = () => {
    const errors = {};

    setPaperNameTouched(true);
    validatePaperName(errors, paperName);

    setPublishedInTouched(true);
    validatePublishedIn(errors, publishedIn);

    if (yearOfPublication === null) {
      errors.yearOfPublication = "Please select the year of publication.";
    } else if (!(yearOfPublication?.isValid ? yearOfPublication.isValid() : true)) {
      errors.yearOfPublication = "Please select a valid 'Year' value.";
    } else if (yearOfPublication > currentDate) {
      errors.yearOfPublication = "Year cannot be in future.";
    } else if (yearOfPublication.isBefore(minPublicationDate)) {
      errors.yearOfPublication = `Year should be at least ${minPublicationDate.year()} for publications.`;
    }

    return errors;
  };

  useEffect(() => {
    if (selected && publications) {
      publications.find((publication) => {
        return publication.id === selected.id;
      });
    }
  }, [selected, publications]);

  const savePublication = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});

      let data = {
        doctorId: doctorId,
        paperName: paperName,
        publishedIn: publishedIn,
        yearOfPublication: yearOfPublication?.$y ? yearOfPublication.$y : yearOfPublication.year(),
      };

      if (selected) {
        const postData = {
          ...data,
          id: selected?.id,
        };
        try {
          await updatePublications(selected.id, postData);
          handleClick("success", "Publication has been successfully updated.");
          close();
          refreshData();
        } catch (error) {
          handleClick("error", "There seems to be an error updating the publication.");
          close();
        }
      } else {
        try {
          const postData = { ...data };
          await createPublications(postData);
          handleClick("success", "Publication has been successfully added.");
          refreshData();
          close();
        } catch (error) {
          handleClick("error", "There seems to be an error adding the publication.");
          close();
        }
      }
    }
  };

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: "10px", width: "400px" }}>
        <Grid item xs={12}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Paper Name"
            name="paperName"
            value={paperName}
            required
            onBlur={(e) => {
              setPaperNameTouched(true);
              const newError = {};
              validatePaperName(newError, paperName);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setPaperName(e.target.value);
              if (paperNameTouched) {
                const newError = {};
                validatePaperName(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.paperName;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            size={"big"}
            startAdornment={<Article />}
            error={Boolean(errors.paperName)}
            errorText={errors.paperName}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Published In"
            name="publishedIn"
            value={publishedIn}
            required
            onBlur={(e) => {
              setPublishedInTouched(true);
              const newError = {};
              validatePublishedIn(newError, publishedIn);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setPublishedIn(e.target.value);
              if (publishedInTouched) {
                const newError = {};
                validatePublishedIn(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.publishedIn;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            size={"big"}
            startAdornment={<Newspaper />}
            error={Boolean(errors.publishedIn)}
            errorText={errors.publishedIn}
          />
        </Grid>
        <Grid item xs={12}>
          <FormDatePicker
            label={"Year of publication"}
            style={{ width: "100%", marginTop: "7px" }}
            disableFuture
            minDate={minPublicationDate}
            required
            value={yearOfPublication}
            onChange={(year) => {
              setYearOfPublication(year);
              if (year === null) {
                setErrors({
                  ...errors,
                  yearOfPublication: "Please select the year of publication.",
                });
              } else if (!year.isValid()) {
                setErrors({
                  ...errors,
                  yearOfPublication: "Please select a valid 'Year' value.",
                });
              } else if (year > currentDate) {
                setErrors({
                  ...errors,
                  yearOfPublication: "Date cannot be in a future.",
                });
              } else if (year.isBefore(minPublicationDate)) {
                setErrors({
                  ...errors,
                  yearOfPublication: `Year should be at least ${minPublicationDate.year()} for publications.`,
                });
              } else {
                const { yearOfPublication, ...newError } = errors;
                setErrors(newError);
              }
            }}
            views={["year"]}
            openTo="year"
            disableBorder={false}
            error={Boolean(errors.yearOfPublication)}
            errorText={errors.yearOfPublication}
          />
        </Grid>

        <Grid item sx={{ mt: 1 }} container justifyContent={"center"}>
          <CustomButton
            onClick={savePublication}
            label={"Save"}
            className={"btn--secondary"}
            style={{ marginLeft: "auto" }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DoctorAwardsAndPublications;
