import * as Yup from "yup";

export const checkoutValidation = (checkoutType, menuItemsClinic) =>
  Yup.object().shape({
    ...(menuItemsClinic.length > 1 && {
      selectedOrganizations: Yup.array().min(1, "Please select at least one organization"),
    }),
    selectedAppointmentOption: Yup.string().required("Please select an option"),
    ...(checkoutType === "hours" && {
      hours: Yup.string()
        .required("Please enter the hours.")
        .test("valid hour", "hours should be in range 1 to 24", function (value) {
          if (Number(value) < 1 || Number(value) > 24) {
            return false;
          }
          return true;
        }),
    }),
  });
