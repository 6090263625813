import * as Yup from "yup";

export const articleValidationSchema = Yup.object().shape({
  contentType: Yup.string().required("Please select a content type."),
  title: Yup.string().required("Please enter a content title."),
  description: Yup.string().required("Please enter the description."),
});

export const mediaValidationSchema = Yup.object().shape({
  contentType: Yup.string().required("Please select a content type."),
  title: Yup.string().required("Please enter a content title."),
  link: Yup.string().required("Please provide a link."),
});

export const campaignValidationSchema = Yup.object().shape({
  contentType: Yup.string().required("Please select a content type."),
  title: Yup.string().required("Please enter a content title."),
});
