import * as React from "react";
import { useState, useEffect } from "react";
import { Box, FormControl } from "@mui/material";
import { Formik } from "formik";
import Grid from "@mui/material/Grid";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { useLocation, useNavigate } from "react-router";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import {
  CurrencyRupeeOutlined,
  GradingOutlined,
  HomeRepairServiceOutlined,
  PercentOutlined,
} from "@mui/icons-material";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import { ORGANIZATION } from "store/constant";
import { useContext } from "react";
import { updateAppointmentStatus } from "services/Appointments";
import {
  generateBillingInvoices,
  getServiceConfiguration,
  getTaxRates,
} from "services/BillingService";
import Reveal from "views/utilities/Reveal";
import dayjs from "dayjs";

const GenerateInvoice = ({ ...others }) => {
  const { handleClick } = useContext(ToastContext);

  // access token
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const { state } = useLocation();
  const doctorId = state ? state.doctorId : "";
  const patientId = state ? state.patientId : "";
  const appointmentId = state ? state.appointmentId : "";
  const orgId = state ? state.orgId : "";
  const [services, setServices] = useState([]);
  const [serviceAmount, setserviceAmount] = useState([""]);
  const [serviceIds, setServiceIds] = useState([]);
  const [discountValue, setdiscountValue] = useState("");
  const [totalBillAmount, settotalBillAmount] = useState("");
  const [totalPriceOnWhichDiscoutIsApplied, setTotalPriceOnWhichDiscoutIsApplied] = useState("");
  const [checked, setChecked] = useState(false);
  const [isDiscount, setisDiscount] = useState(false);
  const [dType, setdType] = useState("Amount");
  const [dValue, setdValue] = useState("");
  const [taxRates, settaxRates] = useState([]);
  const [errors, setErrors] = useState({});
  const formRef = React.useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getServiceConfiguration(orgId, null, ORGANIZATION);
        setServices(response?.data);
        const taxRateResponse = await getTaxRates();
        if (taxRateResponse && taxRateResponse.data) {
          settaxRates(taxRateResponse.data);
        }
      } catch (error) {
        console.error("Fetch Information failed");
      }
    };
    fetchData();
  }, [accessToken, doctorId, handleClick, orgId]);

  const handleMultiChange = (event) => {
    const {
      target: { value },
    } = event;
    setServiceIds(value);
    if (value.length > 0) {
      setErrors({});
    }
    handleServiceAmount(value);
    if (value.length === 0) {
      settotalBillAmount(0);
      setdiscountValue(0);
    }
  };

  const handleServiceAmount = (value) => {
    let totalAmount = 0;
    const gstAmount = getGstAmount(value);
    value.forEach((id) => {
      const tempPrice = services.find((s) => s.id === id).price;
      totalAmount += Number(tempPrice);
    });
    setTotalPriceOnWhichDiscoutIsApplied(totalAmount + gstAmount);
    setserviceAmount(totalAmount);
    if (checked) {
      calculateDiscount(value, dType, discountValue, totalAmount + gstAmount);
    } else {
      settotalBillAmount(totalAmount + gstAmount);
    }
  };

  const handleCheckboxChange = (event) => {
    const gstAmount = getGstAmount(serviceIds);
    setChecked(event.target.checked);
    setisDiscount(event.target.checked);
    if (event.target.checked) {
      calculateDiscount(serviceIds, dType, discountValue, serviceAmount + gstAmount);
    } else {
      settotalBillAmount(serviceAmount + gstAmount);
    }
  };

  const handleDiscount = (event) => {
    if (dType === "Percentage" && event.target.value > 100) {
      const { discountValue, ...newErrors } = { ...errors };
      newErrors.discountValue = "discount percentage can not be more than 100";
      setErrors(newErrors);
    } else if (dType === "Amount" && event.target.value > totalPriceOnWhichDiscoutIsApplied) {
      const { discountValue, ...newErrors } = { ...errors };
      newErrors.discountValue = `Please check discount can't be greater than total amount: ${totalPriceOnWhichDiscoutIsApplied}`;
      setErrors(newErrors);
    } else {
      const { discountValue, ...newErrors } = { ...errors };
      setErrors(newErrors);
    }
    setdiscountValue(event.target.value);
    const gstAmount = getGstAmount(serviceIds);
    calculateDiscount(serviceIds, dType, event.target.value, serviceAmount + gstAmount);
  };

  const handleDiscountTypeChange = (event) => {
    if (event.target.value === "Percentage" && discountValue > 100) {
      const { discountValue, ...newErrors } = { ...errors };
      newErrors.discountValue = "discount percentage can not be more than 100";
      setErrors(newErrors);
    } else if (dType === "Amount" && event.target.value > totalPriceOnWhichDiscoutIsApplied) {
      const { discountValue, ...newErrors } = { ...errors };
      newErrors.discountValue = `Please check discount can't be greater than total amount: ${totalPriceOnWhichDiscoutIsApplied}`;
      setErrors(newErrors);
    } else {
      const { discountValue, ...newErrors } = { ...errors };
      setErrors(newErrors);
    }
    setdType(event.target.value);
    const gstAmount = getGstAmount(serviceIds);
    calculateDiscount(serviceIds, event.target.value, discountValue, serviceAmount + gstAmount);
  };

  const getGstAmount = (selectedServices) => {
    let gstAmount = 0;
    selectedServices.forEach((id) => {
      const sObj = services.find((s) => s.id === id);
      const gstPercentage = taxRates.find((t) => t.id === sObj.taxId)?.rate;
      if (gstPercentage) {
        gstAmount += (sObj.price * gstPercentage) / 100;
      }
    });
    return gstAmount;
  };

  const calculateDiscount = (selectedServices, dType, dValue, serviceAmountValue) => {
    let bill = 0;
    if (dType === "Amount") {
      bill = Number(serviceAmountValue) - Number(dValue);
      setdValue(dValue);
    } else {
      const discount = (Number(serviceAmountValue) * dValue) / 100;
      setdValue(discount);
      bill = Number(serviceAmountValue) - discount;
    }
    // const gstAmount = getGstAmount(selectedServices);
    settotalBillAmount(bill);
  };

  const validate = () => {
    const newErrors = {};
    if (serviceIds.length === 0) {
      newErrors.service = "Please select at least one service.";
    }

    if (checked) {
      if (!discountValue) {
        newErrors.discountValue = "Please enter a discount value.";
      } else if (errors?.discountValue) {
        newErrors.discountValue = { ...errors }.discountValue;
      }
    }
    return newErrors;
  };

  return (
    <Reveal>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={{
          amount: serviceAmount,
          discount: discountValue,
          totalAmount: totalBillAmount,
        }}
        onSubmit={async (values) => {
          // toDO when adjustmentAmount is added it should be added in api body
          const validationErrors = validate();
          if (services.length === 0) {
            handleClick("error", "Please contact your organization to add services.");
          } else if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
          } else {
            setErrors({});
            const gstAmount = getGstAmount(serviceIds);
            const totalNetBillAmount = gstAmount + serviceAmount;
            // const adjustmentAmount = totalNetBillAmount - values.totalAmount;
            let invoiceItemArray = [];
            serviceIds.forEach((s) => {
              const selService = services.find((ser) => ser.id === s);
              const data = {
                id: null,
                invoiceId: null,
                description: null,
                service: selService,
                quantity: 1,
                price: selService.price,
                status: "ACTIVE",
              };
              invoiceItemArray.push(data);
            });
            const body = {
              description: null,
              id: null,
              appointmentId: appointmentId,
              organizationId: orgId,
              patientId: patientId,
              doctorId: doctorId,
              issueDate: dayjs(),
              totalAmount: totalNetBillAmount,
              invoiceStatus: "PENDING",
              status: "ACTIVE",
              adjustmentType: checked && dValue ? "DISCOUNT" : null,
              adjustmentAmount: checked && dValue ? dValue : null,
              invoiceItems: invoiceItemArray,
            };
            try {
              const response = await generateBillingInvoices(body);

              //  once the invoice is generated then appointment status should be changed to COMPLETED
              try {
                await updateAppointmentStatus(appointmentId, "COMPLETED");
              } catch (error) {
                handleClick("error", "There seems to be an error updating the appointment status");
              }
              navigate("/home/payment", {
                state: { invoiceId: response.data?.id, appointmentId: appointmentId },
              });
              handleClick("success", "Invoice has been successfully created.");
            } catch (error) {
              handleClick("error", "There seems to be an error creating the invoice");
            }
          }
        }}
      >
        {({ values, touched, handleBlur, handleChange, handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit} {...others}>
            <h2 style={{ marginBottom: "30px" }}>Generate Bill</h2>
            <Grid container spacing={2}>
              {services.length > 0 && (
                <Grid item xs={4}>
                  <FormSelectField
                    style={{ width: "100%", marginRight: "30px" }}
                    label="Service"
                    name={"service"}
                    onBlur={handleBlur}
                    onChange={handleMultiChange}
                    startAdornment={<HomeRepairServiceOutlined />}
                    multiple
                    menuItems={services.map((el) => {
                      return {
                        ...el,
                        value: el?.id,
                        menuLabel: (
                          <>
                            <Checkbox checked={serviceIds.indexOf(el?.id) > -1} />
                            <ListItemText primary={el?.name} />
                          </>
                        ),
                      };
                    })}
                    required
                    error={Boolean(errors.service)}
                    errorText={errors.service}
                    value={serviceIds}
                    renderValue={(selected) =>
                      selected
                        .map((id) => services.find((service) => service.id === id)?.name)
                        .join(", ")
                    }
                    size={"big"}
                  ></FormSelectField>
                </Grid>
              )}
              <Grid item xs={8}>
                <FormInputField
                  style={{ width: "50%", marginRight: "30px" }}
                  label="Amount"
                  name="amount"
                  value={values.amount}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  startAdornment={<CurrencyRupeeOutlined />}
                  size={"big"}
                  readOnly={true}
                />
              </Grid>
              {serviceIds.length > 0 && (
                <>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={handleCheckboxChange}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                        />
                      }
                      label="Is discounts available ??"
                    />
                  </Grid>
                  {isDiscount === true && (
                    <>
                      <Grid item xs={3}>
                        <Reveal>
                          <FormControl>
                            <FormLabel id="dType">Discount Type</FormLabel>
                            <RadioGroup
                              aria-labelledby="dType"
                              defaultValue="Amount"
                              name="radio-buttons-group"
                              value={dType}
                              onChange={handleDiscountTypeChange}
                            >
                              <FormControlLabel value="Amount" control={<Radio />} label="Amount" />
                              <FormControlLabel
                                value="Percentage"
                                control={<Radio />}
                                label="Percentage"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Reveal>
                      </Grid>
                      <Grid item xs={3}>
                        <Reveal>
                          <FormInputField
                            style={{
                              width: "100%",
                              marginRight: "30px",
                              marginBottom: "25px",
                            }}
                            label="Discount"
                            type="number"
                            name="discount"
                            error={Boolean(errors?.discountValue)}
                            errorText={errors?.discountValue}
                            value={values.discount}
                            onBlur={handleBlur}
                            onChange={handleDiscount}
                            startAdornment={
                              dType === "Amount" ? <CurrencyRupeeOutlined /> : <PercentOutlined />
                            }
                            size={"big"}
                          />
                        </Reveal>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Reveal>
                      <h4>Total amounts will be including GST of individual services !!</h4>
                      <FormInputField
                        style={{
                          width: "50%",
                          marginTop: "20px",
                          marginRight: "30px",
                          marginBottom: "25px",
                        }}
                        label="Total Amount"
                        type="number"
                        name="totalAmount"
                        value={parseFloat(values.totalAmount).toFixed(2)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        startAdornment={<GradingOutlined />}
                        readOnly={true}
                        size={"big"}
                      />
                    </Reveal>
                  </Grid>
                </>
              )}
            </Grid>

            <Box sx={{ mt: 2 }}>
              <CustomButton className="btn--primary" type="submit" label="Generate Invoice" />
            </Box>
          </form>
        )}
      </Formik>
    </Reveal>
  );
};

export default GenerateInvoice;
