export const SET_USER_DATA = "SET_USER_DATA";
export const SET_PARENT_FAMLIY_ID = "SET_PARENT_FAMLIY_ID";
export const SET_ACTIVE_MEMBER = "SET_ACTIVE_MEMBER";
export const SET_USER_FEATURES = "SET_USER_FEATURES";
export const SET_USER_ORG_ID = "SET_ORG_ID";
export const SET_FRONT_DESK_USER_LINKING = "SET_FRONT_DESK_USER_LINKING";
export const SET_RENDER_SIDEBAR = "SET_RENDER_SIDEBAR";

export const setRenderSidebar = (data) => {
  return { type: SET_RENDER_SIDEBAR, payload: data };
};

export const setUserData = (data) => {
  return { type: SET_USER_DATA, payload: data };
};

export const setParentFamilyId = (data) => {
  return { type: SET_PARENT_FAMLIY_ID, payload: data };
};

export const setActiveMember = (data) => {
  return { type: SET_ACTIVE_MEMBER, payload: data };
};

export const setUserFeatures = (data) => {
  return { type: SET_USER_FEATURES, payload: data };
};

export const setUserOrgId = (data) => {
  return { type: SET_USER_ORG_ID, payload: data };
};

export const setFrontDeskUserLinking = (data) => {
  return { type: SET_FRONT_DESK_USER_LINKING, payload: data };
};
