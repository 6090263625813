import { startsAndEndsWithAlphabetic } from "store/constant";
import * as Yup from "yup";

export const roleSchema = Yup.object().shape({
  roleId: Yup.string().required("Please select your role."),
});

export const step0 = Yup.object().shape({
  fullName: Yup.string()
    .required("Kindly provide your full name")
    .min(3, "Minimum 3 characters are required")
    .max(50, "Maximum 100 characters are allowed")
    .test(
      "contains-alphabetic",
      "Name must start and end with alphabet",
      startsAndEndsWithAlphabetic
    )
    .matches(/^[a-zA-Z\s]*$/, "Name should only contain letters and space"),
});

export const step1 = Yup.object().shape({
  gender: Yup.string().required("Kindly provide your gender."),
});

export const step2 = Yup.object().shape({});

export const step3 = Yup.object().shape({
  emailId: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Please enter a valid email.")
    .max(255, "Too long contact email. Please enter a valid email.")
    .required("Please enter a valid email"),
});

export const step4 = Yup.object().shape({
  doctorRegistration: Yup.object().shape({
    registrationCouncil: Yup.string().required("Kindly provide registration council"),
    registrationId: Yup.string().required("Kindly provide registration id"),
  }),
});

export const step5 = Yup.object().shape({
  organization: Yup.object().shape({
    name: Yup.string()
      .required("Please enter the organization name.")
      .min(3, "Minimum 3 characters are required")
      .max(100, "Too long organization name. Please enter a valid name.")
      .test(
        "contains-alphabetic",
        "Hospital name must start and end with alphabet",
        startsAndEndsWithAlphabetic
      )
      .matches(/^[a-zA-Z0-9 .,'-]*$/, "Only letters, numbers, spaces, and .,'- are allowed"),
    address: Yup.string()
      .required("Please enter the organization address.")
      .min(3, "Minimum 3 characters are allowed")
      .max(256, "Maximum 256 characters are allowed"),
    // adState: Yup.object().required("Please select the state"),
    // adDistrict: Yup.object().required("Required"),
    adPincode: Yup.string()
      .matches(/^\d{6}$/, "Please enter a valid 6-digit pincode.") // Validates that it is a 6-digit number
      .required("Please enter a valid 6-digit pincode."),
  }),
});
