import Reveal from "views/utilities/Reveal";
import { useParams } from "react-router-dom";
import { downloadPDF } from "services/PrescriptionsService";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { useContext, useEffect, useState } from "react";

const PrescriptionDesign = () => {
  const { id } = useParams();
  const { handleClick } = useContext(ToastContext);
  const [pdfUrl, setPdfUrl] = useState(null);
  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await downloadPDF(id);
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        setPdfUrl(url);
      } catch (error) {
        handleClick("error", "Error loading PDF");
      }
    };
    fetchPdf();
  }, [id]);

  const handleDownload = async (appointmentId) => {
    try {
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "prescription.pdf";
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(pdfUrl);
    } catch (error) {
      handleClick("error", "There seems to be an error downloading PDF");
    }
  };

  return (
    <>
      <button
        className="btn btn-primary-blue"
        style={{
          position: "absolute",
          top: "19px",
          right: "90px",
          zIndex: "9",
          marginRight: "0px",
          borderRadius: "6px",
          background: "#29BF91",
          border: "none",
          padding: "0px",
          cursor: "pointer",
          height: "40px",
          width: "100px",
        }}
        onClick={() => handleDownload(id, handleClick)}
      >
        <i className="ri-file-download-line " style={{ marginRight: "3px" }}></i>
        Download
      </button>
      <Reveal className="prescription-design">
        <div>
          {pdfUrl && (
            <iframe
              id="prescription"
              title="prescription"
              src={`${pdfUrl}#toolbar=0`}
              width="100%"
              height="700"
              style={{ border: "none" }}
            ></iframe>
          )}
        </div>
      </Reveal>
    </>
  );
};

export default PrescriptionDesign;
