import React from "react";
import "../../assets/scss/style.scss";

const CustomTabCard = ({ title, value, isActive, onClick }) => {
  return (
    // <li className={`custom-tab-card ${isActive ? "mui--is-active" : ""}`}>
    <li className={`custom-tab-card mui--is-active `}>
      <a data-mui-toggle="tab" data-mui-controls="pane-default-1" onClick={onClick}>
        <h6 className="tab-title">{title}</h6>
        <p className="tab-value">{value}</p>
      </a>
    </li>
  );
};

export default CustomTabCard;
