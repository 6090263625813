import * as Yup from "yup";

export const AddAvailabilityValidationSchema = Yup.object().shape({
  // consultationType: Yup.string().required("Required"),
  // organization: Yup.string().required("Required"),
  startTime: Yup.string().required("Please select a start time."),
  endTime: Yup.string().required("Please select a end time."),
});

export const AddAvailabilityValidationSchema2 = Yup.object().shape({
  // consultationType: Yup.string().required("Please select consultation type"),
  organization: Yup.string().required("Please select an organization."),
  startTime: Yup.string().required("Please select a start time."),
  endTime: Yup.string().required("Please select a end time."),
});
