import { Badge, Grid, Skeleton, Tooltip } from "@mui/material";
import "assets/scss/prescriptionPad.scss";
import "assets/scss/style.scss";
import { Menu, MenuList } from "@mui/material";
import CustomButton from "ui-component/custom-components/CustomButton";
import CustomMenuItem from "../../../../ui-component/custom-components/CustomMenuItem";
import { calculateAge } from "utils/calculate-age";
import { useEffect, useState } from "react";
import { getAppointmentsByPatientId } from "services/Appointments";
import { getPatientLifestyleByPatientId, getProfilePicture } from "services/patientService";
import { COMPLETED } from "store/constant";
import dayjs from "dayjs";
import { getPatientChronicDiseaseByPatientId } from "services/patientService";
import { getColorAndBackgroundColorBySeverity } from "utils/calculate-vital-color";
import { api } from "services/AxiosInterceptor";
import { Liquor, SmokingRoomsOutlined } from "@mui/icons-material";

const PatientCard = ({
  selectedAppointment,
  isPatientData,
  isTeleconsultation = false,
  expandPatientDetails = false,
  showPatientVitals = false,
  handleClick,
}) => {
  // calculate age using dateOfBirth
  const now = dayjs();
  const [showVisited, setShowVisited] = useState(false);
  const [lastVisited, setLastVisited] = useState(null);
  const [chronicDiseases, setChronicDiseases] = useState([]);
  const [profileImageFileWithContent, setProfileImageFileWithContent] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedAppointment.patientId) {
          const response = await getPatientChronicDiseaseByPatientId(selectedAppointment.patientId);
          setChronicDiseases(response.data);
        }
      } catch (error) {
        console.error("Error fetching patient details");
      }
    };

    // const patientGptResponse = async () => {
    //   try {
    //     const res = await api.post(`api/ehr/ehr-summary/${selectedAppointment?.patientId}`);
    //     setPatientEhrResponseFromGpt(res?.data);
    //   } catch (e) {
    //     console.error(e);
    //   }
    // };
    // patientGptResponse();

    fetchData();
  }, [selectedAppointment?.patientId]);

  useEffect(() => {
    const fetchPatientLastVisited = async () => {
      try {
        if (selectedAppointment?.patient?.user?.roleBasedId) {
          const { data } = await getAppointmentsByPatientId(
            selectedAppointment?.patient?.user.roleBasedId
          );
          const filteredAppointments = data.filter(
            (appointment) => appointment.appointmentStatus === COMPLETED
          );
          if (filteredAppointments.length !== 0) {
            const recent = filteredAppointments.reduce((mostRecent, current) => {
              const currentDate = dayjs(current.appointmentDate);
              const mostRecentDate = dayjs(mostRecent.appointmentDate);
              return currentDate > mostRecentDate ? current : mostRecent;
            });
            setLastVisited(now.diff(dayjs(recent.appointmentDate), "day"));
            setShowVisited(true);
          }
        }
      } catch (error) {
        console.error("Error fetching latest appointment.");
      }
    };
    fetchPatientLastVisited();
  }, [now, selectedAppointment?.patient?.user?.roleBasedId]);

  useEffect(() => {
    const fetchProfilePic = async () => {
      setIsLoading(true);
      try {
        const profilePicResponse = await getProfilePicture(
          selectedAppointment.patient?.user?.id || selectedAppointment.patient?.userId
        );
        const content = `data:image/${profilePicResponse.data.extension};base64,${profilePicResponse.data.document}`;
        const profilePicWithContent = {
          filename: profilePicResponse.data.filename,
          content: content,
        };
        setProfileImageFileWithContent(profilePicWithContent);
      } catch (error) {
        setProfileImageFileWithContent(null);
        console.error("Error fetching profile picture", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchProfilePic();
  }, [selectedAppointment?.patient?.user?.id, selectedAppointment.patient?.userId]);
  const [lifestyleHabits, setLifestyleHabits] = useState({
    Eggetarian: false,
    "Non-vegetarian": false,
    Vegetarian: false,
    "Consuming Tobacco": false,
    Drinking: false,
    Smoking: false,
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!selectedAppointment?.patient?.user.roleBasedId) return;

        const lifestyleResponse = await getPatientLifestyleByPatientId(
          selectedAppointment?.patient?.user.roleBasedId
        );

        const matchedHabits = lifestyleResponse.data.flatMap((responseObj) => {
          const responseArray = responseObj.response.split(",");
          return responseArray.filter((response) =>
            lifestyleHabits.hasOwnProperty(response.trim())
          );
        });

        // re-initialize, when patient id change, to avoid data being persisted
        const updatedLifestyleHabits = {
          Eggetarian: false,
          "Non-vegetarian": false,
          Vegetarian: false,
          "Consuming Tobacco": false,
          Drinking: false,
          Smoking: false,
        };

        matchedHabits.forEach((habit) => {
          if (!updatedLifestyleHabits[habit]) {
            updatedLifestyleHabits[habit] = true;
          }
        });
        setLifestyleHabits(updatedLifestyleHabits);
      } catch (error) {
        console.error("Error fetching data");
      }
    };

    fetchData();
  }, [selectedAppointment?.patient?.user?.roleBasedId]);
  return (
    <>
      <Grid item md={12} className="px-0">
        <div className="row align-items-center">
          <div className="col mb-0 mr-2" style={{ width: "20%" }}>
            {isLoading ? (
              <Skeleton variant="circular" animation="wave" width={"50px"} height={"50px"} />
            ) : (
              <img
                style={{
                  borderRadius: "50%",
                  marginRight: "6px",
                  width: "50px",
                  height: "50px",
                }}
                className="patient-avatar"
                src={
                  profileImageFileWithContent
                    ? profileImageFileWithContent.content
                    : (selectedAppointment.patient?.user?.gender ||
                        selectedAppointment.patient?.gender) === "FEMALE"
                    ? "/images/woman.png"
                    : "/images/man.png"
                }
                alt=""
              ></img>
            )}
          </div>
          <div className="col flex-direction-column mb-0 flex-grow-1" style={{ width: "40%" }}>
            <p className="patient-name my-0">
              {selectedAppointment.patient?.user?.name || selectedAppointment.patient?.patientName}
            </p>
            <p className="patient-details" style={{ marginTop: "5px" }}>
              {(selectedAppointment.patient?.user?.dateOfBirth != null ||
                selectedAppointment.patient?.dateOfBirth) && (
                <span className="additonal-details">
                  {`${calculateAge(
                    selectedAppointment.patient?.user?.dateOfBirth ||
                      selectedAppointment.patient?.dateOfBirth
                  )} years`}
                </span>
              )}
              {(selectedAppointment.patient?.user?.dateOfBirth ||
                selectedAppointment.patient?.dateOfBirth) &&
                (selectedAppointment.patient?.user?.gender ||
                  selectedAppointment.patient?.gender) && (
                  <span className="additonal-details">
                    &nbsp;<strong>{"|"}</strong>&nbsp;
                  </span>
                )}
              <span className="additonal-details">
                {selectedAppointment.patient?.user?.gender || selectedAppointment.patient?.gender}
              </span>
            </p>
          </div>
          <div className="lifestyles" style={{ marginLeft: "3px" }}>
            {lifestyleHabits["Drinking"] && (
              <Tooltip title="Drinking" arrow>
                <Liquor
                  sx={{
                    p: "5px",
                    borderRadius: "20px",
                    fontSize: "30px",
                    fill: "#DD2025",
                    backgroundColor: "#F2F6F8",
                    mr: "10px",
                  }}
                />
              </Tooltip>
            )}
            {lifestyleHabits["Smoking"] && (
              <Tooltip title="Smoking" arrow>
                <SmokingRoomsOutlined
                  sx={{
                    p: "5px",
                    borderRadius: "20px",
                    fontSize: "30px",
                    fill: "#F6AB65",
                    backgroundColor: "#F2F6F8",
                    mr: "10px",
                  }}
                />
              </Tooltip>
            )}

            {lifestyleHabits["Consuming Tobacco"] && (
              <Tooltip title="Consuming Tobacco" arrow>
                <img
                  src="/images/tobacco.svg"
                  style={{
                    padding: "5px",
                    marginRight: "10px",
                    height: "30px",
                    borderRadius: "20px",
                    backgroundColor: "#F2F6F8",
                  }}
                  alt="Consuming Tobacoo"
                ></img>
              </Tooltip>
            )}

            {lifestyleHabits["Vegetarian"] && (
              <Tooltip title="Vegetarian" arrow>
                <img
                  src="/images/vegetarian-diet.svg"
                  style={{
                    padding: "5px",
                    marginRight: "10px",
                    height: "30px",
                    borderRadius: "20px",
                    backgroundColor: "#F2F6F8",
                  }}
                  alt="Vegetarian"
                ></img>
              </Tooltip>
            )}

            {lifestyleHabits["Eggetarian"] && (
              <Tooltip title="Eggetarian" arrow>
                <img
                  src="/images/eggetarian-diet.svg"
                  style={{
                    padding: "5px",
                    marginRight: "10px",
                    height: "40px",
                    borderRadius: "20px",
                    backgroundColor: "#F2F6F8",
                  }}
                  alt="Eggetarian"
                ></img>
              </Tooltip>
            )}

            {lifestyleHabits["Non-vegetarian"] && (
              <Tooltip title="Non-vegetarian" arrow>
                <img
                  src="/images/non-vegetarian-diet.svg"
                  style={{
                    padding: "5px",
                    marginRight: "10px",
                    height: "40px",
                    borderRadius: "20px",
                    backgroundColor: "#F2F6F8",
                  }}
                  alt="Non-vegetarian"
                ></img>
              </Tooltip>
            )}
          </div>
          {isPatientData && isPatientData.isPatientPage && (
            <>
              <div className="col pl-10 align-self-start">
                <CustomButton
                  iconButton={<i className="ri-more-2-fill" />}
                  onClick={(event) =>
                    isPatientData.handleMenuOpen(event, selectedAppointment.patient)
                  }
                ></CustomButton>
                <Menu
                  elevation={1}
                  anchorEl={isPatientData.anchorEl}
                  open={Boolean(isPatientData.anchorEl)}
                  onClose={isPatientData.handleMenuClose}
                  PaperProps={{ style: { borderRadius: "4px" } }}
                >
                  <MenuList dense sx={{ p: 0 }}>
                    {isPatientData.actions.map((action, actionIndex) => {
                      return (
                        <CustomMenuItem
                          disabled={
                            action?.checkDisable
                              ? action.checkDisable(isPatientData.clickedRow)
                              : undefined
                          }
                          key={actionIndex}
                          text={
                            typeof action.label === "function"
                              ? action.label(selectedAppointment.patient.status)
                              : action.label
                          }
                          icon={action.icon}
                          onClick={() => {
                            isPatientData.handleMenuClose();
                            action.onClick(isPatientData.clickedRow);
                          }}
                        />
                      );
                    })}
                  </MenuList>
                </Menu>
              </div>
            </>
          )}
        </div>
      </Grid>
      <Grid item md={12} className="px-0">
        <div
          style={{ display: "flex", flexWrap: "wrap", alignItems: "end" }}
          className="row mb-2 badge-row"
        >
          {chronicDiseases.map((chronic, index) => {
            const colorCode = getColorAndBackgroundColorBySeverity(chronic.severity);

            return (
              <Badge
                key={index}
                className={isTeleconsultation ? "badge badge-dark" : "badge badge-2x f-size-12"}
                style={{
                  color: isTeleconsultation ? "" : "colorCode?.color",
                  backgroundColor: isTeleconsultation
                    ? "rgba(0,0,0,0.2) !important"
                    : "colorCode?.backgroundColor",
                }}
              >
                {chronic.chronicDiseaseResponse.name}
              </Badge>
            );
          })}
        </div>
      </Grid>
      {/* <div>
            <p>
              {isExpanded
                ? patientEhrResponseFromGpt
                : getTruncatedText(patientEhrResponseFromGpt, 100)}
            </p>
            {patientEhrResponseFromGpt.length > 100 && ( // Show "Read More" button only if text exceeds limit
              <span className="hover" onClick={toggleReadMore} style={{ color: "#004c70" }}>
                {isExpanded ? "Read Less" : "Read More"}
              </span>
            )}
          </div> */}
      {showVisited && (
        <Grid item md={12} className="px-0 patient-details mb-2 row">
          <img src="/images/hc_rx.svg" alt="hc_rx"></img>
          <span className="additonal-details" style={{ padding: "0 6px" }}>
            Last visited {lastVisited !== 0 ? lastVisited : ""}{" "}
            {lastVisited <= 1 ? (lastVisited === 0 ? "today" : "day ago") : "days ago"}
          </span>
        </Grid>
      )}
    </>
  );
};

export default PatientCard;
