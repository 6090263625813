import { useContext } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { completeBookingUhi } from "services/doctorService";
import { getUhiAppointmentData } from "store/Reducers/appointmentDataReducer";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";

const PatientPayment = () => {
  const uhiAppointmentData = useSelector(getUhiAppointmentData);
  const { handleClick } = useContext(ToastContext);
  const navigate = useNavigate();

  const handlePaymentSuccess = async () => {
    try {
      const data = {
        request: {
          transactionId: uhiAppointmentData.request.transactionId,
          providerId: uhiAppointmentData.request.providerId,
          providerUri: uhiAppointmentData.request.providerUri,
          paymentAmount: uhiAppointmentData.request.totalFees,
          paymentStatus: "PAID",
          paymentMode: "UPI",
          paymentVpa: "",
          fulfillmentId: uhiAppointmentData.request.fulfillmentId,
          starttime: uhiAppointmentData.request.starttime,
          endtime: uhiAppointmentData.request.endtime,
          orderId: uhiAppointmentData.request.orderId,
        },
        appointmentResponse: {
          ...uhiAppointmentData.appointmentResponse,
          appointmentStatus: "CONFIRMED",
          orderId: uhiAppointmentData.request.orderId,
          doctorDetails: JSON.stringify(uhiAppointmentData.appointmentResponse.doctorDetails),
          orgDetails: JSON.stringify(uhiAppointmentData.appointmentResponse.orgDetails),
          providerDetails: JSON.stringify(uhiAppointmentData.appointmentResponse.providerDetails),
        },
      };
      const response = await completeBookingUhi(data);
      handleClick("success", "Appointment has been successfully booked.");
      navigate("/home/dashboard");
    } catch (error) {
      handleClick("There seems to be an error confirming the appointment.");
    }
  };

  return (
    <>
      <div>
        <div style={{ display: "flex" }}>
          <h2 style={{ display: "inline" }}>Payment</h2>
        </div>
        <CustomButton
          className="btn--secondary"
          label="Completed"
          onClick={handlePaymentSuccess}
          style={{ marginLeft: "auto" }}
        ></CustomButton>
      </div>
    </>
  );
};

export default PatientPayment;
