import { useState, useEffect } from "react";
import { Box, InputAdornment } from "@mui/material";
import { Formik } from "formik";
import * as React from "react";
import CustomButton from "ui-component/custom-components/CustomButton";
import {
  createHprId,
  getCategories,
  getDistricts,
  getStates,
  getSubcategories,
} from "services/HprService";
import createHprSchema from "../../Common/ValidationSchema/createHprSchema";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { data } from "store/constant";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import {
  AbcOutlined,
  CalendarTodayOutlined,
  CategoryOutlined,
  HomeOutlined,
  PasswordOutlined,
  PersonOutlineOutlined,
} from "@mui/icons-material";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import { useContext } from "react";
import Reveal from "views/utilities/Reveal";

const CreateHprId = ({ ...others }) => {
  const { handleClick } = useContext(ToastContext);

  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  const fetchDistricts = async (stateCode) => {
    try {
      const response = await getDistricts(stateCode);
      setDistricts(response?.data);
    } catch (error) {
      console.error("Error fetching districts");
    }
  };

  const fetchSubcategories = async (categoryCode) => {
    try {
      const response = await getSubcategories(categoryCode);
      setSubcategories(response?.data);
    } catch (error) {
      console.error("Error fetching sub-categories");
    }
  };

  const fetchAllStates = async () => {
    try {
      const response = await getStates();
      setStates(response?.data);
    } catch (error) {
      console.error("Error fetching states");
    }
  };

  const fetchCategories = async (stateCode) => {
    try {
      const response = await getCategories(stateCode);
      setCategories(response?.data);
    } catch (error) {
      console.error("Error fetching categories");
    }
  };

  const handleSave = async (values) => {
    try {
      const requestData = {
        idType: values.idType,
        domainName: values.domainName,
        email: values.email,
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        password: values.password,
        profilePhoto: data()?.profilePhoto,
        stateCode: values.stateCode,
        districtCode: values.districtCode,
        pincode: values.pincode,
        txnId: values.txnId,
        hprId: values.hprId,
        hpCategoryCode: values.hpCategoryCode,
        hpSubCategoryCode: values.hpSubCategoryCode,
      };
      await createHprId(requestData);
      handleClick("success", "HPR ID has been successfully created");
    } catch (error) {
      handleClick("error", error?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchAllStates();
    fetchCategories();
  }, []);

  return (
    <Reveal>
      <Formik
        enableReinitialize={true}
        initialValues={{
          idType: "",
          domainName: "",
          email: "",
          firstName: data()?.firstName ? data()?.firstName : "",
          middleName: data()?.middleName ? data()?.middleName : "",
          lastName: data()?.lastName ? data()?.lastName : "",
          password: "",
          confirmPassword: "",
          profilePhoto: "",

          stateCode: "",
          districtCode: "",
          pincode: data()?.pincode,
          txnId: data()?.txnId,
          hprId: "",
          hpCategoryCode: "",
          hpSubCategoryCode: "",
          dayOfBirth: data()?.dayOfBirth,
          monthOfBirth: data()?.monthOfBirth,
          yearOfBirth: data()?.yearOfBirth,
          address: data()?.address,
          gender: data()?.gender,
        }}
        validationSchema={createHprSchema}
        onSubmit={async (values, { resetForm }) => {
          handleSave(values);
          resetForm();
        }}
      >
        {({ values, errors, touched, setFieldValue, handleBlur, handleChange, handleSubmit }) => (
          <Box>
            <form noValidate onSubmit={handleSubmit} {...others}>
              <h2>Create your HPR Id</h2>

              <div
                className="image"
                style={{
                  maxWidth: "120px",
                  margin: "auto",
                  marginBottom: "30px",
                }}
              >
                <img
                  src={`data:image/jpeg;base64,${data()?.profilePhoto}`}
                  style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                  alt="Profile"
                  onError={(e) => handleClick("error", "Error loading image")}
                />
              </div>
              <FormInputField
                style={{
                  width: "30%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="First Name"
                name="firstName"
                required
                value={values.firstName}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.firstName && errors.firstName)}
                errorText={errors.firstName}
                startAdornment={<PersonOutlineOutlined />}
                size={"big"}
              />

              <FormInputField
                style={{
                  width: "30%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="Middle Name"
                name="middleName"
                required
                value={values.middleName}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.middleName && errors.middleName)}
                errorText={errors.middleName}
                startAdornment={<PersonOutlineOutlined />}
                size={"big"}
              />

              <FormInputField
                style={{
                  width: "30%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="Last Name"
                name="lastName"
                required
                value={values.lastName}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.lastName && errors.lastName)}
                errorText={errors.lastName}
                startAdornment={<PersonOutlineOutlined />}
                size={"big"}
              />

              <FormInputField
                style={{
                  width: "30%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="Day"
                type="integer"
                name="dayOfBirth"
                required
                value={values.dayOfBirth}
                onBlur={handleBlur}
                readOnly
                onChange={handleChange}
                error={Boolean(touched.dayOfBirth && errors.dayOfBirth)}
                errorText={errors.dayOfBirth}
                startAdornment={<CalendarTodayOutlined />}
                size={"big"}
              />

              <FormInputField
                style={{
                  width: "30%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="Month"
                type="integer"
                name="monthOfBirth"
                required
                readOnly
                value={values.monthOfBirth}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.monthOfBirth && errors.monthOfBirth)}
                errorText={errors.monthOfBirth}
                startAdornment={<CalendarTodayOutlined />}
                size={"big"}
              />

              <FormInputField
                style={{
                  width: "30%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="Year"
                type="integer"
                name="yearOfBirth"
                required
                readOnly
                value={values.yearOfBirth}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.yearOfBirth && errors.yearOfBirth)}
                errorText={errors.yearOfBirth}
                startAdornment={<CalendarTodayOutlined />}
                size={"big"}
              />

              <FormInputField
                style={{
                  width: "30%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="Email"
                type="email"
                name="email"
                required
                value={values?.email}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.email && errors.email)}
                errorText={errors.email}
                startAdornment={<i className="ri-mail-line ri-lg" />}
                size={"big"}
              />

              <FormInputField
                style={{
                  width: "30%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="Password"
                required
                type="password"
                name="password"
                value={values?.password}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.password && errors.password)}
                errorText={errors.password}
                startAdornment={<PasswordOutlined />}
                size={"big"}
              />

              <FormInputField
                style={{
                  width: "30%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="Confirm Password"
                required
                type="password"
                name="confirmPassword"
                value={values?.confirmPassword}
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                }}
                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                errorText={errors.confirmPassword}
                startAdornment={<PasswordOutlined />}
                size={"big"}
              />
              {/* {values.passwordsMatch === false && (
                    <FormHelperText error>Passwords do not match</FormHelperText>
                  )} */}

              <FormSelectField
                style={{
                  width: "30%",
                  marginBottom: "25px",
                  marginRight: "30px",
                }}
                label="State"
                name="stateCode"
                onBlur={handleBlur}
                required
                onChange={(e) => {
                  handleChange(e);
                  fetchDistricts(e.target.value);
                }}
                startAdornment={<HomeOutlined />}
                menuItems={states.map((el) => {
                  return {
                    ...el,
                    value: el?.code,
                    menuLabel: el?.name,
                  };
                })}
                error={Boolean(touched.stateCode && errors.stateCode)}
                errorText={errors.stateCode}
                value={values.stateCode}
                size={"big"}
              />

              <FormSelectField
                style={{
                  width: "30%",
                  marginBottom: "25px",
                  marginRight: "30px",
                }}
                label="District"
                name="districtCode"
                required
                onBlur={handleBlur}
                onChange={handleChange}
                startAdornment={<HomeOutlined />}
                menuItems={districts.map((el) => {
                  return {
                    ...el,
                    value: el?.code,
                    menuLabel: el?.name,
                  };
                })}
                error={Boolean(touched.districtCode && errors.districtCode)}
                errorText={errors.districtCode}
                value={values.districtCode}
                size={"big"}
              />

              <FormInputField
                style={{
                  width: "30%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="Gender"
                name="gender"
                value={values.gender}
                readOnly
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.gender && errors.gender)}
                errorText={errors.gender}
                startAdornment={<PersonOutlineOutlined />}
                size={"big"}
              />

              <FormSelectField
                style={{
                  width: "96%",
                  marginBottom: "25px",
                  marginRight: "30px",
                }}
                label="Healthcare Professional Category"
                name="hpCategoryCode"
                required
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                  fetchSubcategories(e.target.value);
                }}
                startAdornment={<CategoryOutlined />}
                menuItems={categories.map((el) => {
                  return {
                    ...el,
                    value: el?.code,
                    menuLabel: el?.name,
                  };
                })}
                error={Boolean(touched.hpCategoryCode && errors.hpCategoryCode)}
                errorText={errors.hpCategoryCode}
                value={values.hpCategoryCode}
                size={"big"}
              />

              <FormSelectField
                style={{
                  width: "96%",
                  marginBottom: "25px",
                  marginRight: "30px",
                }}
                label="Healthcare Professional Subcategory"
                name="hpSubCategoryCode"
                required
                onBlur={handleBlur}
                onChange={handleChange}
                startAdornment={<CategoryOutlined />}
                menuItems={subcategories.map((el) => {
                  return {
                    ...el,
                    value: el?.code,
                    menuLabel: el?.name,
                  };
                })}
                error={Boolean(touched.hpSubCategoryCode && errors.hpSubCategoryCode)}
                errorText={errors.hpSubCategoryCode}
                value={values.hpSubCategoryCode}
                size={"big"}
              />

              <FormInputField
                style={{
                  width: "96%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="Address"
                name="address"
                type="text"
                readOnly
                required
                value={values.address}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.address && errors.address)}
                errorText={errors.address}
                startAdornment={<HomeOutlined />}
                size={"big"}
              />

              <FormInputField
                style={{
                  width: "96%",
                  marginRight: "30px",
                  marginBottom: "25px",
                }}
                label="Healthcare Professional Id/User Id"
                required
                name="hprId"
                type="text"
                value={values.hprId}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.hprId && errors.hprId)}
                errorText={errors.hprId}
                startAdornment={<AbcOutlined />}
                endAdornment={<InputAdornment position="end">eg: sam@hpr.abdm</InputAdornment>}
                size={"big"}
              />

              <Box sx={{ mt: 2 }}>
                <CustomButton
                  className="btn--primary"
                  variant="contained"
                  label="Create"
                  onClick={handleSubmit}
                />
              </Box>
            </form>
          </Box>
        )}
      </Formik>
    </Reveal>
  );
};

export default CreateHprId;
