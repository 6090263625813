import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, useMediaQuery } from "@mui/material";

// project imports
import Breadcrumbs from "ui-component/extended/Breadcrumbs";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { appEnv, isDemo, isOTPLogin, mobilenumber, PATIENT, username } from "store/constant";
import { setActiveMember, setParentFamilyId, setUserData } from "store/Actions/userActions";

// import Customization from '../Customization';
import navigation from "menu-items";
import { SET_MENU } from "store/actions";

// assets
import { IconChevronRight } from "@tabler/icons";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import "../../assets/scss/style.scss";
import SecondarySidebar from "./Sidebar/SecondarySidebar";
import { SidebarUtilProvider } from "layout/SidebarUtilContext";
import { useContext } from "react";
import { getPrimaryUserByMobileNumber } from "services/userService";
import SyncModal from "views/Components/Sync/SyncModal";
import RIf from "ui-component/RIf";

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("lg"));

  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  const { handleClick } = useContext(ToastContext);
  const [roleName, setRoleName] = useState(null);
  const [, setSecondarySidebarVisible] = useState(false);
  const [settingsIconChecked, setSettingsIconChecked] = useState(false);
  const [routeBeforeSettingsSection, setRouteBeforeSettingsSection] = useState("");
  // const [showContent, setshowContent] = useState(true);
  // const [isClinicAdmin, setisClinicAdmin] = useState(false);
  // const [orgArr, setorgArr] = useState([]);
  const mNumber = mobilenumber();
  const uId = username();
  const location = useLocation();

  const isRunningOnElectron = appEnv === "electron";

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!isOTPLogin()) {
          return;
        }
        const response = await getPrimaryUserByMobileNumber(mNumber);
        setRoleName(response.data?.roleName);
        localStorage.setItem("currentActiveUser", JSON.stringify(response.data));
        localStorage.setItem("loggedInUser", JSON.stringify(response.data));
        dispatch(setUserData(response?.data));
        dispatch(setParentFamilyId(response?.data?.id));
        dispatch(setActiveMember(response?.data));
      } catch (error) {
        console.error("Error fetching User info");
      }
    };
    fetchData();
  }, [mNumber, uId]);

  useEffect(() => {
    dispatch({ type: SET_MENU, opened: !matchDownMd });
  }, [matchDownMd]);

  const [mainClass, setMainClass] = useState(isDemo() ? "main shrink" : "main");

  const toggleSecondarySidebar = (visible) => {
    setSecondarySidebarVisible(visible);
  };
  const [secondarySidebarData, setSecondarySidebarData] = useState(null);
  const [secondarySidebarTitle, setSecondarySidebarTitle] = useState(null);

  return (
    <Box className={mainClass} id="main">
      <>
        <Sidebar
          mainClass={mainClass}
          toggleMainClass={setMainClass}
          toggleSecondaryBar={toggleSecondarySidebar}
          setSecondarySidebarData={setSecondarySidebarData}
          setSecondarySideBarTitle={setSecondarySidebarTitle}
          setSettingsIconChecked={setSettingsIconChecked}
          setRouteBeforeSettingsSection={setRouteBeforeSettingsSection}
        />
        {secondarySidebarData && (
          <SecondarySidebar
            toggleMainClass={setMainClass}
            data={secondarySidebarData}
            title={secondarySidebarTitle}
          />
        )}
        <div className="wrapper">
          <div className="main-content custom-scrollbar-main">
            {roleName && roleName !== PATIENT && location.pathname !== "/home/doctorSearch" && (
              <Header
                toggleMainClass={setMainClass}
                setSecondarySidebarData={setSecondarySidebarData}
                handleLeftDrawerToggle={handleLeftDrawerToggle}
                settingsIconChecked={settingsIconChecked}
                routeBeforeSettingsSection={routeBeforeSettingsSection}
                setRouteBeforeSettingsSection={setRouteBeforeSettingsSection}
              />
            )}

            <div
              className="mui-row align-items-center custom-tab-structure"
              style={{
                paddingTop: roleName === PATIENT ? "30px" : undefined,
              }}
            >
              <Breadcrumbs
                separator={IconChevronRight}
                navigation={navigation}
                icon
                title
                rightAlign
              />
              <SidebarUtilProvider
                mainClass={mainClass}
                toggleMainClass={setMainClass}
                setSecondarySideBarData={setSecondarySidebarData}
              >
                <Outlet />
              </SidebarUtilProvider>
            </div>
          </div>
        </div>
        <RIf show={isRunningOnElectron}>
          <SyncModal />
        </RIf>
      </>
      {/* )} */}
    </Box>
  );
};

export default MainLayout;
