import {
  Card,
  CardContent,
  Checkbox,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useRef } from "react";

const LabReportVitalCard = ({
  vital,
  handleCheckBoxOnChange,
  delimeter,
  handleUpdate,
  setContainerErrors,
}) => {
  const [readOnly, setReadOnly] = useState(true);
  const [vitalValue, setVitalValue] = useState(vital?.value);
  const inputRef = useRef(null);
  const [hasError, setHasError] = useState(false);

  const handleKeyPress = (event) => {
    const pressedKey = event.key;
    if (pressedKey === "Enter") {
      setReadOnly(true);
      setVitalValue(event.target.value);
      handleUpdate(vital, vitalValue);
    }
  };

  const handleBlur = () => {
    setReadOnly(true);
    handleUpdate(vital, vitalValue);
  };

  const handleChange = (event) => {
    if (Number(event.target.value)) {
      setHasError(false);
      setContainerErrors((prevErrors) => {
        const { [vital.vitalSignTypeId]: currentError, ...restErrors } = prevErrors;
        return restErrors;
      });
    } else {
      setHasError(true);
      setContainerErrors((prevErrors) => {
        return { ...prevErrors, [vital.vitalSignTypeId]: true };
      });
    }
    setVitalValue(event.target.value);
  };

  const handleDoubleClick = () => {
    setReadOnly(false);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <Grid item xs={4}>
      <Card variant="outlined" sx={{ border: hasError ? "1px solid red" : undefined }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div style={{ width: "fit-content" }}>
            <Checkbox
              defaultChecked
              onChange={handleCheckBoxOnChange}
              value={vital?.dateFrom + delimeter + vital?.vitalSignTypeId}
            />
          </div>
          <div style={{ width: "60%" }}>
            <CardContent>
              <Grid display={"flex"}>
                <FormControl sx={{ paddingRight: "0.25rem" }}>
                  <TextField
                    error={hasError ? true : false}
                    onDoubleClick={handleDoubleClick}
                    sx={{
                      "& .MuiFilledInput-root": {
                        backgroundColor: readOnly ? "transparent" : "#e3e3e3",
                        "&:before": {
                          border: "none",
                        },
                        "&:hover:not(.Mui-disabled):before": {
                          border: "none",
                        },
                        "&:after": {
                          border: "none",
                        },
                      },
                      "& .MuiFilledInput-root:hover": {
                        background: "transparent",
                      },
                      "& .MuiFilledInput-input": {
                        padding: 0,
                        color: readOnly ? "#004c70" : "black",
                        fontSize: readOnly ? "1.25rem" : "1rem",
                        fontWeight: readOnly ? "600" : "none",
                        cursor: readOnly ? "pointer" : undefined,
                        textAlign: "right",
                        backgroundColor: readOnly ? "transparent" : "#e3e3e3",
                      },
                    }}
                    variant="filled"
                    inputRef={inputRef}
                    value={vitalValue}
                    onKeyDown={handleKeyPress}
                    onBlur={handleBlur}
                    inputProps={{
                      readOnly: readOnly,
                      backgrouncolor: "transparent",
                    }}
                    size="small"
                    multiline={false}
                    onChange={handleChange}
                  ></TextField>
                </FormControl>
                <Typography
                  variant="h4"
                  sx={{ color: "#004c70" }}
                  display={"flex"}
                  alignItems={"center"}
                >
                  {vital?.vitalSignTypeResponse?.unitAbbreviation}
                </Typography>
              </Grid>
              <Typography variant="h6" component="div" textAlign={"right"}>
                {vital?.vitalSignTypeResponse?.displayName}
              </Typography>
            </CardContent>
          </div>
        </div>
      </Card>
    </Grid>
  );
};

export default LabReportVitalCard;
