export const prescriptionGuideText = {
  intro:
    "Welcome to the Prescription Pad, your advanced tool for efficient and accurate patient care.",
  "step-1":
    "Here you can view the patient’s essential details, vitals, and health-related data, all in one place for quick reference.",
  "step-2":
    "Access a comprehensive overview of the patient's health, including key metrics and visual charts to track vitals over time.",
  "step-3":
    "This is your Prescription Pad, where you can conveniently write and manage prescriptions with ease.",
  "step-4":
    "Leverage the power of IntelliRx™ to draft SOAP notes seamlessly via voice dictation and smart assistance, using the integrated search feature.",
  "step-5":
    "Here, you can quickly review the patient's past prescriptions for an informed, consistent treatment plan.",
  "step-6":
    "Utilize the templates feature to create and save prescription templates, allowing you to streamline future consultations and save valuable time.",
  "step-7":
    "Complete your consultation here with confidence, ensuring all steps have been efficiently managed.",
};

export const ehrSummaryGuideText = {
  intro:
    "Welcome to the Comprehensive EHR Summary, here you can find all the key points of a patient's health record.",
  "step-1":
    "Here you can view the patient’s basic details, ehr summary, vitals & health-related data, all in one place for quick reference.",
  "step-2": "System generated EHR summary highlighting the key points.",
  "step-3": "This where the system displays the key patient vitals with the trends.",
  "step-4": "Here we have graphs to represent the long term patterns in the patient's health.",
};

export const detailedEhrGuideText = {
  intro:
    "Welcome to the Detailed EHR Summary, here you can find patient's health data organized & presented relevantly to assist you to find the right information efficiently.",
  "step-1":
    "Here you can view the various organs of the human body, upon selecting on a specific organ you can filter the information displayed on the page.",
  "step-2": "Choose your specialization to tailor the system to your specific needs.",
  "step-3": "Here we present the patient's data in most efficient & clear way possible",
  "step-4":
    "The data represented on the screen is dynamic & change according to focused organ. Please try exploring.",
};

export const prescriptionGuideTextForTeleconsultation = {
  intro:
    "Welcome to the Teleconsultation Prescription Pad – your cutting-edge solution for delivering efficient and accurate patient care remotely. Streamline your virtual consultations with this powerful tool designed for today’s healthcare needs.",
  "step-1":
    "Easily view the patient’s essential details, vitals, and health-related data, all in one comprehensive view for quick and informed decision-making.",
  "step-2":
    "Utilize IntelliRx™ to seamlessly create SOAP notes through voice dictation and smart assistance, ensuring precision and ease in your documentation process.",
  "step-3":
    "Manage and write prescriptions effortlessly using your Prescription Pad, providing a smooth, intuitive experience tailored for efficient patient care.",
  "step-4":
    "Share live prescriptions with patients in real-time, enhancing communication and ensuring timely access to the prescribed treatment.",
  "step-5":
    "Review past prescriptions to maintain consistency and accuracy in treatment plans, enabling more informed decisions and better patient outcomes.",
  "step-6":
    "Save time by using pre-built templates for prescriptions. Customize and reuse them for future consultations, streamlining the prescribing process.",
  "step-7":
    "Complete your teleconsultation with confidence, ensuring that every step has been addressed and the patient receives comprehensive care.",
};
