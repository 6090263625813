import React, { createContext, useState, useContext } from "react";

const OverlayContext = createContext();

export const OverlayProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [disabled, setDisabled] = useState(true);

  // const toggleOverlay = () => {
  //     setIsOpen(prev => !prev);
  //     // localStorage.setItem("overlayIsOpen", isOpen)
  // }

  return (
    <OverlayContext.Provider value={{ isOpen, setIsOpen, disabled, setDisabled }}>
      {children}
    </OverlayContext.Provider>
  );
};

export const useOverlay = () => useContext(OverlayContext);
