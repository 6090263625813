import { api as API } from "./AxiosInterceptor";

export const getAllReviews = () => API.get(`/api/reviews`);
export const getReviewById = (id) => API.get(`/api/reviews/${id}`);
export const createReviews = (data) => API.post(`/api/reviews`, data);
export const updateReviews = (id, data) => API.put(`/api/reviews/${id}`, data);
export const deleteReview = (id) => API.delete(`/api/reviews/${id}`);
export const getReviewsByDocId = (docId) => API.get(`/api/reviews/doctor/${docId}`);
export const getReviewsByPatientId = (patientId) => API.get(`/api/reviews/patient/${patientId}`);

// Get Reviews by Doctor ID with Patient Profile Picture
export const getReviewsByDocIdWithPatientPic = (docId) =>
  API.get(`/api/reviews/doctor`, {
    params: { doctorId: docId },
  });
