import * as Yup from "yup";

export const familyMemberSchema = (isDateValid) =>
  Yup.object().shape({
    firstName: Yup.string()
      .required("Please enter the first name of your family member.")
      .min(3, "Minimum 2 characters are required")
      .max(50, "Maximum 50 characters are allowed")
      .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
    middleName: Yup.string()
      .min(1, "Minimum 1 character is required")
      .max(50, "Maximum 50 characters allowed")
      .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
    lastName: Yup.string()
      .required("Please enter the last name of your family member.")
      .min(3, "Minimum 3 characters are required")
      .max(50, "Maximum 50 characters are allowed")
      .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
    gender: Yup.string().required("Please select the gender."),
    address: Yup.string()
      .min(3, "Minimum 3 characters are required")
      .max(256, "Maximum 256 characters are allowed"),
    emailId: Yup.string()
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Please enter a valid email.")
      .required("Please enter a valid email.")
      .max(255, "Maximum 255 characters are allowed"),
    relationship: Yup.string().required("Please select a relationship."),
    ...(isDateValid && {
      mobileNumber: Yup.string()
        .required("Please enter a valid mobile number.")
        .matches(/^[6-9][0-9]{9}$/, "Please enter a valid mobile number"),
    }),
  });

export const familyMemberSchemaForExistingUser = Yup.object().shape({
  relationship: Yup.string().required("Please select a relationship."),
});
