import {
  ClearOutlined,
  EMobiledataOutlined,
  FeaturedPlayListOutlined,
  FindInPageOutlined,
  PersonOutlineOutlined,
} from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import dayjs from "dayjs";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  createPatientPhysicalExam,
  deletePatientPhysicalExam,
  editPatientPhysicalExam,
  getPatientPhysicalExamByPatientId,
} from "services/patientService";
import ModalUI from "ui-component/ModalUI";
import CustomButton from "ui-component/custom-components/CustomButton";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import CustomizedTable from "ui-component/custom-components/CustomizedTable";
import FormDatePicker from "ui-component/custom-components/Form-components/FormDatePicker";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import Reveal from "views/utilities/Reveal";
import {
  DATE_FORMAT,
  DATE_FORMAT_DMY,
  startsAndEndsWithAlphabetic,
  startsWithAlphabetic,
} from "store/constant";

const columns = [
  { field: "Sr. No.", label: "Sr. No" },
  { field: "examType", label: "Exam Type" },
  { field: "findings", label: "Findings" },
  { field: "recommendation", label: "Recommendation" },
  { field: "dateExamined", label: "Date of exam" },
  { field: "examiningDoctor", label: "Examining Doctor" },
  { field: "Actions", label: "Actions" },
];

const PatientPhysicalExam = ({ patientDOB, patientId }) => {
  const [patientPhysicalExams, setPatientPhysicalExams] = useState([]);
  const [displayPatientPhysicalExams, setDisplayPatientPhysicalExams] = useState([]);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const { handleClick } = useContext(ToastContext);

  const clearSearch = () => {
    setSearchQuery("");
  };

  const handleMarkInactive = async (row) => {
    try {
      await deletePatientPhysicalExam(row.id);
      handleClick("success", "Patient Physical-exam has been successfully deleted.");
      const index = patientPhysicalExams.findIndex((el) => el.id === row.id);
      patientPhysicalExams.splice(index, 1);
      setDisplayPatientPhysicalExams(patientPhysicalExams);
    } catch (error) {
      handleClick("error", "There seems to be an error deleting the Patient Physical-exam.");
    }
  };

  const closeModal = () => {
    setOpen(false);
    setSelected(null);
  };

  const openUpdateModal = (pPhysicalExam) => {
    setSelected(pPhysicalExam);
    setOpen(true);
  };

  const openCreateModal = () => {
    setOpen(true);
  };

  const fetchPatientPhysicalExams = async () => {
    try {
      const response = await getPatientPhysicalExamByPatientId(patientId);
      const updatedResponse = response.data.map((el) => ({
        ...el,
        dateExamined: el.dateExamined ? dayjs(el.dateExamined).format(DATE_FORMAT_DMY) : null,
      }));
      setPatientPhysicalExams(updatedResponse);
    } catch (error) {
      console.error("Error fetching patient physical exam");
    }
  };

  const actions = [
    {
      label: "Edit",
      icon: <i className="ri-edit-line ri-xl icon-primary-blue" />,
      onClick: openUpdateModal,
    },
    {
      label: (rowData) => {
        return "Delete";
      },
      icon: <i className="ri-delete-bin-fill ri-xl icon-primary-blue" />,
      onClick: handleMarkInactive,
    },
  ];

  useEffect(() => {
    fetchPatientPhysicalExams();
  }, []);

  useEffect(() => {
    setDisplayPatientPhysicalExams(
      patientPhysicalExams.filter((item) => {
        const values = Object.values(item);
        const lowerCaseQuery = searchQuery.toLowerCase();
        return values.some((value, index) => {
          if (typeof value === "string" && index !== 7) {
            return value.toLowerCase().includes(lowerCaseQuery);
          }
          return false;
        });
      })
    );
  }, [patientPhysicalExams, searchQuery]);

  return (
    <Reveal>
      <Grid display={"flex"} alignItems={"center"} sx={{ mb: 1 }}>
        <FormInputField
          style={{ width: "40%" }}
          label={"Search Procedure"}
          value={searchQuery}
          startAdornment={<i className="ri-search-line ri-lg" />}
          endAdornment={
            <IconButton onClick={clearSearch} edge="end">
              {<ClearOutlined fontSize="small" />}
            </IconButton>
          }
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          size="small"
        ></FormInputField>
        <CustomButton
          style={{ marginLeft: "auto" }}
          label={"Add"}
          className={"ri-add-fill ri-lg btn--primary"}
          onClick={openCreateModal}
        />
      </Grid>

      <CustomizedTable
        columns={columns}
        tableData={displayPatientPhysicalExams}
        actions={actions}
        rowsPerPageInTable={10}
      />

      <ModalUI
        visible={open}
        close={closeModal}
        title={selected ? "Update Patient Physical-exam" : "Create Patient Physical-exam"}
        component={
          <PatientPhysicalExamModal
            selected={selected}
            handleClick={handleClick}
            close={(operation) => {
              if (operation === "save") {
                closeModal();
                fetchPatientPhysicalExams();
              } else {
                closeModal();
              }
            }}
            patientDOB={patientDOB}
            patientId={patientId}
          />
        }
      />
    </Reveal>
  );
};

const PatientPhysicalExamModal = ({ patientDOB, patientId, selected, handleClick, close }) => {
  const [examType, setExamType] = useState(selected?.examType ? selected?.examType : "");
  const [examTypeTouched, setExamTypeTouched] = useState(false);

  const [findings, setFindings] = useState(selected?.findings ? selected?.findings : "");
  const [findingsTouched, setFindingsTouched] = useState(false);

  const [recommendation, setRecommendation] = useState(
    selected?.recommendation ? selected?.recommendation : ""
  );
  const [recommendationTouched, setRecommendationTouched] = useState(false);

  const [dateExamined, setDateExamined] = useState(
    selected?.dateExamined
      ? dayjs(selected?.dateExamined, DATE_FORMAT_DMY).format(DATE_FORMAT)
      : null
  );

  const [examiningDoctor, setExaminingDoctor] = useState(
    selected?.examiningDoctor ? selected?.examiningDoctor : ""
  );
  const [examiningDoctorTouched, setExamingDoctorTouched] = useState(false);

  const currentDate = dayjs();
  const minDateForPhysicalExam = dayjs(patientDOB ? patientDOB : "1900-01-01");
  const [dateTouched, setDateTouched] = useState(false);

  const [errors, setErrors] = useState({});

  const validateExamType = (errors, examType) => {
    const pattern = /^[a-zA-Z\s]+$/;
    if (!examType) {
      errors.examType = "Please select exam type.";
    } else if (examType.length < 3) {
      errors.examType = "Must be at least 3 characters long";
    } else if (examType.length > 100) {
      errors.examType = "Cannot be longer than 100 characters";
    } else if (!pattern.test(examType)) {
      errors.examType = "can only contain letters and space";
    } else if (!startsAndEndsWithAlphabetic(examType)) {
      errors.examType = "Must start and end with alphabet";
    }
  };

  const validateFindings = (errors, findings) => {
    if (!findings) {
      errors.findings = "Please enter findings.";
    } else if (findings.length < 3) {
      errors.findings = "Must be at least 3 characters long";
    } else if (findings.length > 255) {
      errors.findings = "Cannot be longer than 255 characters";
    } else if (!startsWithAlphabetic(findings)) {
      errors.findings = "Must start with alphabet";
    }
  };

  const validateRecommendation = (errors, recommendation) => {
    if (!recommendation) {
      errors.recommendation = "Please enter the recommendation.";
    } else if (recommendation.length < 3) {
      errors.recommendation = "Must be at least 3 characters long";
    } else if (recommendation.length > 255) {
      errors.recommendation = "Cannot be longer than 255 characters";
    } else if (!startsWithAlphabetic(recommendation)) {
      errors.recommendation = "Must start with alphabet";
    }
  };

  const validateExaminingDoctor = (errors, examiningDoctor) => {
    const pattern = /^[a-zA-Z\s]+$/;
    if (!examiningDoctor) {
      errors.examiningDoctor = "Please enter the examining doctor name.";
    } else if (examiningDoctor.length < 3) {
      errors.examiningDoctor = "Name must be at least 3 characters long";
    } else if (examiningDoctor.length > 100) {
      errors.examiningDoctor = "Name cannot be longer than 100 characters";
    } else if (!pattern.test(examiningDoctor)) {
      errors.examiningDoctor = "Name can only contain letters and space";
    } else if (!startsAndEndsWithAlphabetic(examiningDoctor)) {
      errors.examiningDoctor = "Name must start and end with alphabet";
    }
  };

  const validate = () => {
    const errors = {};

    setExamTypeTouched(true);
    validateExamType(errors, examType);

    setFindingsTouched(true);
    validateFindings(errors, findings);

    setRecommendationTouched(true);
    validateRecommendation(errors, recommendation);

    setExamingDoctorTouched(true);
    validateExaminingDoctor(errors, examiningDoctor);

    setDateTouched(true);
    if (dateExamined === null) {
      errors.dateExamined = "Please select a valid date.";
    } else if (!(dateExamined?.isValid ? dateExamined.isValid() : true)) {
      errors.dateExamined = "Please selct a valid 'Date' value.";
    } else if (dateExamined > currentDate) {
      errors.dateExamined = "Date cannot be in future.";
    } else if (dateExamined < minDateForPhysicalExam) {
      errors.datePerformed = "Date can't be less than DOB.";
    }

    return errors;
  };

  const savePatientPhysicalExam = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});

      let data = {
        patientId,
        examType,
        findings,
        recommendation,
        dateExamined: dayjs(dateExamined).format(DATE_FORMAT),
        examiningDoctor,
      };

      if (selected) {
        try {
          await editPatientPhysicalExam(selected.id, data);
          handleClick("success", "Patient physical-exam has been successfully updated!");
          close("save");
        } catch (error) {
          handleClick("error", "There seems to be an error updating patient physical-exam.");
        }
      } else {
        try {
          await createPatientPhysicalExam(data);
          handleClick("success", "Patient physical-exam has been successfully created!");
          close("save");
        } catch (error) {
          handleClick("error", "There seems to be an error creating the patient physical-exam.");
        }
      }
    }
  };

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: "10px", width: "400px" }}>
        <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Exam Type"
            name="examType"
            required
            value={examType}
            onBlur={(e) => {
              setExamTypeTouched(true);
              const newError = {};
              validateExamType(newError, examType);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setExamType(e.target.value);
              if (examTypeTouched) {
                const newError = {};
                validateExamType(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.examType;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<EMobiledataOutlined />}
            size={"big"}
            error={Boolean(errors.examType)}
            errorText={errors.examType}
          />
        </Grid>
        <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Findings"
            name="findings"
            required
            value={findings}
            onBlur={(e) => {
              setFindingsTouched(true);
              const newError = {};
              validateFindings(newError, findings);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setFindings(e.target.value);
              if (findingsTouched) {
                const newError = {};
                validateFindings(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.findings;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<FindInPageOutlined />}
            size={"big"}
            error={Boolean(errors.findings)}
            errorText={errors.findings}
          />
        </Grid>
        <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Recommendation"
            name="recommendation"
            required
            value={recommendation}
            onBlur={(e) => {
              setRecommendationTouched(true);
              const newError = {};
              validateRecommendation(newError, recommendation);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setRecommendation(e.target.value);
              if (recommendationTouched) {
                const newError = {};
                validateRecommendation(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.recommendation;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<FeaturedPlayListOutlined />}
            size={"big"}
            error={Boolean(errors.recommendation)}
            errorText={errors.recommendation}
          />
        </Grid>
        <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
          <FormDatePicker
            inputFormat={DATE_FORMAT_DMY}
            label={"Date of exam"}
            disableFuture
            value={dateExamined}
            minDate={minDateForPhysicalExam}
            required
            size={"big"}
            onChange={(date) => {
              setDateExamined(date);
              if (date === null) {
                setErrors({ ...errors, dateExamined: "Please select a valid date." });
              } else if (!date.isValid()) {
                setErrors({ ...errors, dateExamined: "Please select a valid 'Date' value." });
              } else if (date > currentDate) {
                setErrors({
                  ...errors,
                  dateExamined: "Date cannot be in a future.",
                });
              } else if (date < minDateForPhysicalExam) {
                setErrors({
                  ...errors,
                  dateExamined: "Date can't be less than Date of birth.",
                });
              } else {
                const { dateExamined, ...newError } = errors;
                setErrors(newError);
              }
            }}
            style={{
              width: "100%",
              marginRight: "30px",
              marginTop: "7px",
            }}
            error={Boolean(errors.dateExamined && dateTouched)}
            errorText={errors.dateExamined}
          ></FormDatePicker>
        </Grid>
        <Grid item xs={12} sx={{ ml: 2, mr: 2 }}>
          <FormInputField
            style={{ width: "100%", marginTop: "7px" }}
            label="Examining Doctor"
            required
            name="examiningDoctor"
            value={examiningDoctor}
            onBlur={(e) => {
              setExamingDoctorTouched(true);
              const newError = {};
              validateExaminingDoctor(newError, examiningDoctor);
              setErrors({ ...errors, ...newError });
            }}
            onChange={(e) => {
              setExaminingDoctor(e.target.value);
              if (examiningDoctorTouched) {
                const newError = {};
                validateExaminingDoctor(newError, e.target.value);
                const updatedErrors = { ...errors };
                delete updatedErrors.examiningDoctor;
                setErrors({ ...updatedErrors, ...newError });
              }
            }}
            startAdornment={<PersonOutlineOutlined />}
            size={"big"}
            error={Boolean(errors.examiningDoctor)}
            errorText={errors.examiningDoctor}
          />
        </Grid>

        <Grid item sx={{ mt: 1 }} container justifyContent={"center"}>
          <CustomButton
            label={"Cancel"}
            className={"btn--error"}
            style={{ width: "100px" }}
            onClick={() => close("cancel")}
          />

          <CustomButton
            onClick={savePatientPhysicalExam}
            label={"Save"}
            className={"btn--secondary"}
            style={{ marginLeft: "10px", width: "100px" }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PatientPhysicalExam;
