import React, { useEffect, useState } from "react";
import "assets/scss/DoctorReview.scss";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { borderRadius } from "@mui/system";
import { getReviewsByDocId, getReviewsByDocIdWithPatientPic } from "services/ReviewService";

const DoctorReviews = ({ doctorDetails }) => {
  const [searchItem, setSearchItem] = useState("");
  const [doctorReviews, setDoctorReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [ratings, setRatings] = useState([]);
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [profileImage, setProfileImage] = useState(null);

  useEffect(() => {
    const fetchDoctorReviews = async () => {
      try {
        const res = await getReviewsByDocIdWithPatientPic(doctorDetails?.id);
        setDoctorReviews(res?.data);
        calculateRatings(res?.data);

        res?.data?.forEach((review) => {
          if (review?.patientProfilePic) {
            const content = `data:image/${review?.patientProfilePic?.extension};base64,${review?.patientProfilePic?.document}`;

            setProfileImage((prevState) => ({
              ...prevState,
              [review?.patientId]: {
                filename: review?.patientProfilePic?.filename,
                content,
              },
            }));
          } else {
            setProfileImage((prevState) => ({
              ...prevState,
              [review?.patientId]: {
                content: null,
              },
            }));
          }
        });
      } catch (e) {
        console.error(e);
      }
    };
    fetchDoctorReviews();
  }, []);

  useEffect(() => {
    if (searchItem.trim() === "") {
      // If search input is empty, show all reviews
      setFilteredReviews(doctorReviews);
    } else {
      // Filter reviews based on patientName
      const filtered = doctorReviews.filter((review) =>
        review.patientName.toLowerCase().includes(searchItem.toLowerCase())
      );
      setFilteredReviews(filtered);
    }
  }, [searchItem, doctorReviews]);

  //   const fetchUserProfilePicture

  const calculateRatings = (reviews) => {
    if (!reviews || reviews.length === 0) return;

    const totalReviews = reviews.length;
    const ratingCounts = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
    let sum = 0;

    // Count each rating
    reviews.forEach((review) => {
      sum += review.rating;
      ratingCounts[review.rating]++;
    });

    const avgRating = (sum / totalReviews).toFixed(1); // Round to 1 decimal place
    setAverageRating(avgRating);

    const calculatedRatings = Object.keys(ratingCounts).map((key) => {
      const percentage = `${Math.round((ratingCounts[key] / totalReviews) * 100)}%`;
      return { number: key, percentage };
    });

    const sortedRatings = [...calculatedRatings].sort((a, b) => b.number - a.number);

    setRatings(sortedRatings);
  };

  const getColorForRating = (rating) => {
    switch (parseInt(rating, 10)) {
      case 1:
        return "#DF6E6A";
      case 2:
        return "#F0B53F";
      default:
        return "#018270";
    }
  };

  const renderStars = (rating) => {
    const totalStars = 5;
    let stars = [];

    for (let i = 1; i <= totalStars; i++) {
      stars.push(
        <i
          key={i}
          className={`ri-star-fill ri-sm ${i <= rating ? "filled" : "unfilled"}`}
          style={{
            color: i <= rating ? "#ffc107" : "#e4e5e9", // Yellow for filled stars, grey for unfilled stars
            marginRight: "4px",
          }}
        />
      );
    }

    return stars;
  };

  return (
    <div className="doctor-review">
      {doctorReviews && doctorReviews.length > 0 ? (
        <>
          <div className="review-header">
            <h2>Reviews</h2>
          </div>
          <div className="review-ratings">
            <div className="review">
              <div>
                <span style={{ fontSize: "2.1rem", fontWeight: 500, color: "black" }}>
                  {averageRating}
                </span>
                <span>
                  <i style={{ color: "black" }} className="ri-star-fill ri-xl" />
                </span>
              </div>

              <p style={{ marginTop: "4px" }}>{doctorReviews.length} reviews</p>
            </div>
            <div className="rating-list">
              {ratings.map((rating, index) => (
                <div className="rating-item" key={index}>
                  <i
                    className="ri-star-fill ri-lg"
                    style={{ color: getColorForRating(rating.number), marginRight: "8px" }}
                  />
                  {rating?.number}
                  <div className="custom-progress-bar">
                    <div
                      className="custom-progress-bar-fill"
                      style={{
                        width: rating?.percentage,
                        backgroundColor: getColorForRating(rating.number),
                      }} // Set width dynamically based on percentage
                    />
                  </div>
                  <p>{rating.percentage}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="write-review"></div>
          <div className="review-list">
            <div className="review-list-header">
              <FormInputField
                style={{ width: "30%", borderRadius: "30px" }}
                size="small"
                // label={"Search"}
                placeholder={"Search Reviews..."}
                value={searchItem}
                onChange={(e) => {
                  setSearchItem(e.target.value);
                }}
                startAdornment={<i className="ri-search-line ri-lg" />}
                endAdorment={
                  <i
                    className="ri-close-line ri-lg"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSearchItem("");
                    }}
                  />
                }
              />
            </div>
            {/* {filteredReviews.map((rating, index) => (
              <div className="review-item" key={index}>
                <div className="patient-details">
                  <div className="profile-pic">
                    <img
                      style={{ width: "50px ", height: "50px", borderRadius: "50%" }}
                      src={profileImage[rating?.patientId]?.content || "/images/patient-image.png"}
                      alt=""
                    />
                  </div>
                  <div className="patient-details-body">
                    <div className="patient-details-name">
                      <p>{rating.patientName}</p>
                    </div>
                    <div className="rating">
                      <div>{renderStars(rating.rating)}</div>
                      <span>25 aug 2024</span>
                    </div>
                  </div>
                </div>
                <div className="patient-review">
                  <p>{rating.description}</p>
                </div>
                <p style={{ marginTop: "0.5em", color: "grey" }}>
                  Was this review helpful{" "}
                  <span style={{ color: "#004c70", marginLeft: ".3rem", cursor: "pointer" }}>
                    Yes
                  </span>{" "}
                  <span style={{ color: "lightgrey", marginLeft: ".3rem", cursor: "pointer" }}>
                    No
                  </span>{" "}
                  <span style={{ color: "red", marginLeft: ".3rem", cursor: "pointer" }}>
                    Report
                  </span>
                </p>
              </div>
              
            ))} */}

            {filteredReviews.map((rating, index) => (
              <div key={index}>
                <div className="review-item">
                  <div className="patient-details">
                    <div className="profile-pic">
                      <img
                        style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                        src={
                          profileImage[rating?.patientId]?.content || "/images/patient-image.png"
                        }
                        alt=""
                      />
                    </div>
                    <div className="patient-details-body">
                      <div className="patient-details-name">
                        <p>{rating.patientName}</p>
                      </div>
                      <div className="rating">
                        <div>{renderStars(rating.rating)}</div>
                        <span>25 Aug 2024</span>
                      </div>
                    </div>
                  </div>
                  <div className="patient-review">
                    <p>{rating.description}</p>
                  </div>
                  <p style={{ marginTop: "0.5em", color: "grey" }}>
                    Was this review helpful
                    <span style={{ color: "#004c70", marginLeft: ".3rem", cursor: "pointer" }}>
                      Yes
                    </span>
                    <span style={{ color: "lightgrey", marginLeft: ".3rem", cursor: "pointer" }}>
                      No
                    </span>
                    <span style={{ color: "red", marginLeft: ".3rem", cursor: "pointer" }}>
                      Report
                    </span>
                  </p>
                </div>
                <hr
                  style={{
                    borderColor: "lightgrey",
                    borderWidth: "0.2px",
                    margin: "1em 0",
                    width: "100%",
                  }}
                />
              </div>
            ))}
          </div>
        </>
      ) : (
        <>No reviews available</>
      )}
    </div>
  );
};

export default DoctorReviews;
