// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.doctor-availability .organization {
  margin-bottom: 20px;
}
.doctor-availability .organization .organization-name {
  font-size: 18px;
  color: #004c70;
  font-weight: 550;
}
.doctor-availability .organization .organization-address {
  font-size: 13px;
  color: #616b67;
  font-weight: 500;
}
.doctor-availability .timing {
  margin-bottom: 5px;
  font-size: 13px;
  color: #616b67;
  font-weight: 500;
}
.doctor-availability .edit-timing {
  font-size: 14px;
  font-weight: 600;
  padding: 7px 0px;
  width: 25%;
}
.doctor-availability .day-icon {
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 18px;
}
.doctor-availability .week {
  display: flex;
}
.doctor-availability .week .day-card {
  padding: 0 10px;
  height: fit-content;
  margin-right: 30px;
}
.doctor-availability .week .day-card .day {
  font-size: 14px;
  color: #004c70;
  font-weight: 500;
}

.cst-title {
  font-size: 14px;
  color: #004c70;
  font-weight: 550;
}
.cst-title.no-text {
  margin-top: 10px;
  color: #a8aeb1;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/availability.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;AAAJ;AACI;EACE,eAAA;EACA,cAAA;EACA,gBAAA;AACN;AAEI;EACE,eAAA;EACA,cAAA;EACA,gBAAA;AAAN;AAGE;EACE,kBAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;AADJ;AAGE;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,UAAA;AADJ;AAIE;EACE,gBAAA;EACA,qBAAA;EACA,iBAAA;AAFJ;AAKE;EACE,aAAA;AAHJ;AAII;EACE,eAAA;EACA,mBAAA;EACA,kBAAA;AAFN;AAIM;EACE,eAAA;EACA,cAAA;EACA,gBAAA;AAFR;;AAQA;EACE,eAAA;EACA,cAAA;EACA,gBAAA;AALF;AAOE;EACE,gBAAA;EACA,cAAA;AALJ","sourcesContent":[".doctor-availability {\n  .organization {\n    margin-bottom: 20px;\n    .organization-name {\n      font-size: 18px;\n      color: #004c70;\n      font-weight: 550;\n    }\n\n    .organization-address {\n      font-size: 13px;\n      color: #616b67;\n      font-weight: 500;\n    }\n  }\n  .timing {\n    margin-bottom: 5px;\n    font-size: 13px;\n    color: #616b67;\n    font-weight: 500;\n  }\n  .edit-timing {\n    font-size: 14px;\n    font-weight: 600;\n    padding: 7px 0px;\n    width: 25%;\n  }\n\n  .day-icon {\n    font-weight: 600;\n    letter-spacing: 0.1px;\n    line-height: 18px;\n  }\n\n  .week {\n    display: flex;\n    .day-card {\n      padding: 0 10px;\n      height: fit-content;\n      margin-right: 30px;\n\n      .day {\n        font-size: 14px;\n        color: #004c70;\n        font-weight: 500;\n      }\n    }\n  }\n}\n\n.cst-title {\n  font-size: 14px;\n  color: #004c70;\n  font-weight: 550;\n\n  &.no-text {\n    margin-top: 10px;\n    color: #a8aeb1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
