import { useState, useEffect } from "react";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { Formik } from "formik";
import { useNavigate } from "react-router";
import { api } from "services/AxiosInterceptor";
import { updateUserProfileSchema } from "../Common/ValidationSchema/manageUserValidation";
import * as React from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import {
  SKELETON_LOADING_TIME_IN_MILLISECONDS,
  accessToken,
  genderList,
  isOTPLogin,
  mobilenumber,
  timeFormat,
  timeFormats,
  username,
} from "store/constant";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SubCard from "ui-component/cards/SubCard";
import FormSelectField from "ui-component/custom-components/Form-components/FormSelectField";
import { useContext } from "react";
import { SidebarUtilContext } from "layout/SidebarUtilContext";
import { getPrimaryUserByMobileNumber } from "services/userService";
import Reveal from "views/utilities/Reveal";
import { AccessTime } from "@mui/icons-material";

const UpdateProfile = ({ ...others }) => {
  const { handleClick } = useContext(ToastContext);

  const { toggleMainClass } = useContext(SidebarUtilContext);
  const userName = username();

  const defaultValues = {
    firstName: "",
    middleName: "",
    lastName: "",
    mobileNumber: "",
    emailId: "",
    password: "",
    address: "",
  };

  const [user, setCurrentUser] = useState(defaultValues);
  const accessTokenValue = accessToken();
  const [timeFormatForUser, setTimeFormatForUser] = useState(timeFormat());

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isOTPLogin()) {
          const response = await getPrimaryUserByMobileNumber(mobilenumber());
          setCurrentUser(response.data);
        }
      } catch (error) {
        console.error("Error fetching user");
      }
    };
    fetchData();
  }, [accessTokenValue, userName, handleClick]);

  const changeTimeFormat = (e) => {
    setTimeFormatForUser(e.target.value);
  };

  const navigate = useNavigate();

  const navigateToUsers = () => {
    toggleMainClass((prevClass) => {
      if (
        prevClass === "main responsive-shrink" ||
        prevClass === "main shrink" ||
        prevClass === "main sub-sidebar"
      ) {
        return "main";
      } else {
        return prevClass;
      }
    });
    navigate("/home/dashboard");
  };

  return (
    <UpdateProfileSkeleton>
      <Reveal>
        <Formik
          enableReinitialize={true}
          initialValues={{
            firstName: user.firstName ? user.firstName : "",
            middleName: user.middleName ? user.middleName : "",
            lastName: user.lastName ? user.lastName : "",
            mobileNumber: user.mobileNumber ? user.mobileNumber : "",
            email: user.emailId ? user.emailId : "",
            address: user.address ? user.address : "",
            gender: user.gender ? user.gender : "",
          }}
          validationSchema={updateUserProfileSchema}
          onSubmit={async (values) => {
            const data = {
              ...user,
              firstName: values.firstName,
              middleName: values.middleName,
              lastName: values.lastName,
              mobileNumber: values.mobileNumber,
              emailId: values.email,
              userName: values.email,
              address: values.address,
              gender: values.gender ? values.gender : "",
              prefers24HrFormat: timeFormatForUser === "24" ? true : false,
            };
            try {
              await api.put(`/api/users/${user.id}`, data);
              localStorage.setItem("timeFormat", timeFormatForUser);
              handleClick("success", "Your profile has been successfully updated!");
              setTimeout(() => {
                navigateToUsers();
              }, 1000);
            } catch (e) {
              handleClick("error", "There seems to be an error updating your profile.");
            }
          }}
        >
          {({ values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
            <form noValidate onSubmit={handleSubmit} {...others}>
              <Grid display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                <Typography
                  sx={{
                    fontSize: "21px",
                    fontWeight: 600,
                    color: "#004C70",
                  }}
                >
                  {"Update Profile"}
                </Typography>
              </Grid>

              <SubCard style={{ padding: "15px", marginTop: "10px" }}>
                <FormInputField
                  style={{
                    width: "30%",
                    marginRight: "3.33%",
                    marginBottom: "25px",
                  }}
                  label="First Name"
                  name="firstName"
                  required
                  value={values.firstName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.firstName && errors.firstName)}
                  errorText={errors.firstName}
                  startAdornment={<PersonOutlineOutlinedIcon />}
                  size={"big"}
                />

                <FormInputField
                  style={{
                    width: "30%",
                    marginRight: "3.33%",
                    marginBottom: "25px",
                  }}
                  label="Middle Name"
                  name="middleName"
                  value={values.middleName}
                  error={Boolean(touched.middleName && errors.middleName)}
                  errorText={errors.middleName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  startAdornment={<PersonOutlineOutlinedIcon />}
                  size={"big"}
                />

                <FormInputField
                  style={{
                    width: "30%",
                    marginRight: "3.33%",
                    marginBottom: "25px",
                  }}
                  label="Last Name"
                  name="lastName"
                  required
                  value={values.lastName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.lastName && errors.lastName)}
                  errorText={errors.lastName}
                  startAdornment={<PersonOutlineOutlinedIcon />}
                  size={"big"}
                />

                <FormSelectField
                  style={{
                    width: "30%",
                    marginBottom: "25px",
                    marginRight: "3.33%",
                  }}
                  label="Gender"
                  name="gender"
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  startAdornment={<PersonOutlineOutlinedIcon />}
                  menuItems={genderList}
                  value={values.gender}
                  size={"big"}
                ></FormSelectField>

                <FormInputField
                  style={{
                    width: "30%",
                    marginRight: "3.33%",
                    marginBottom: "25px",
                  }}
                  label="Mobile Number"
                  type={"tel"}
                  required
                  name="mobileNumber"
                  disabled={Boolean(values.mobileNumber)}
                  value={values.mobileNumber}
                  onBlur={handleBlur}
                  inputProps={{
                    maxLength: 10,
                  }}
                  onChange={handleChange}
                  error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                  errorText={errors.mobileNumber}
                  startAdornment={<i className="ri-phone-line ri-xl" />}
                  size={"big"}
                />

                <FormInputField
                  style={{
                    width: "30%",
                    marginRight: "3.33%",
                    marginBottom: "25px",
                  }}
                  label="Area of Intervention / Address"
                  type={"text"}
                  name="address"
                  required
                  value={values.address}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.address && errors.address)}
                  errorText={errors.address}
                  startAdornment={<HomeOutlinedIcon />}
                  size={"big"}
                />

                <FormInputField
                  // className='cst-input'
                  style={{
                    width: "30%",
                    marginRight: "3.33%",
                    marginBottom: "25px",
                  }}
                  label="Email"
                  type={"email"}
                  name="email"
                  disabled={Boolean(values.email)}
                  required
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.email && errors.email)}
                  errorText={errors.email}
                  startAdornment={<i className="ri-mail-line ri-lg" />}
                  size={"big"}
                />

                <FormSelectField
                  style={{
                    width: "30%",
                    marginBottom: "25px",
                    marginRight: "3.33%",
                  }}
                  label="Time Format"
                  name="Time Format"
                  onBlur={handleBlur}
                  onChange={changeTimeFormat}
                  startAdornment={<AccessTime />}
                  menuItems={timeFormats}
                  value={timeFormatForUser}
                  size={"big"}
                ></FormSelectField>
              </SubCard>

              <Box sx={{ mt: 2 }}>
                <CustomButton className="btn--primary" type="submit" label="Save"></CustomButton>
              </Box>
            </form>
          )}
        </Formik>
      </Reveal>
    </UpdateProfileSkeleton>
  );
};

export const UpdateProfileSkeleton = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, SKELETON_LOADING_TIME_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return children;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} display={"flex"}>
          <Grid item xs={3}>
            <Skeleton
              sx={{ borderRadius: "8px" }}
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Skeleton
            sx={{ borderRadius: "8px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={270}
          />
        </Grid>
        <Grid item xs={1.5}>
          <Skeleton
            sx={{ borderRadius: "8px" }}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={35}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UpdateProfile;
