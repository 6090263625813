import * as Yup from "yup";

export const basicSchema = Yup.object().shape({
  firstName: Yup.string()

    .required("Please enter first name.")

    .min(3, "Name is too short. Please enter a valid name.")
    .max(50, "Name is too long. Please enter a valid name.")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  mobileNumber: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, "Please enter a valid Mobile Number")
    .required("Please enter a valid mobile number."),
  email: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Please enter a valid email.")
    .required("Please provide a valid email.")
    .max(255, "Maximum 255 characters are allowed"),
  // emergencyContactNumber: Yup.string().matches(
  //   /^[6-9][0-9]{9}$/,
  //   "Please enter Valid Mobile Number"
  // ),
  lastName: Yup.string()

    .required("Please enter last name.")

    .min(3, "Name is too short. Please enter a valid name.")
    .max(50, "Name is too long. Please enter a valid name.")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  gender: Yup.string().required("Please select your gender."),
});
