import { startsAndEndsWithAlphabetic } from "store/constant";
import * as Yup from "yup";

const containsAlphabetic = (value) => /[a-zA-Z]/.test(value);

export const RegisterOrgSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please provide a valid organization name.")
    .min(3, "Minimum 3 characters are required")
    .max(100, "The organization name is too long. Kindly provide name upto 100 characters.")
    .test(
      "contains-alphabetic",
      "Hospital name must contain alphabetic characters",
      containsAlphabetic
    )
    .matches(/^[a-zA-Z0-9 .,'-]*$/, "Only letters, numbers, spaces, and .,'- are allowed"),

  code: Yup.string()
    .min(2, "The organization code is too short. Try code with minimum 2 characters.")
    .max(30, "The organization code is too long. Try code upto 30 characters.")
    .required("The code is required. Kindly provide a valid one."),

  address: Yup.string()
    .required("Please enter a valid address.")
    .min(3, "Minimum 3 characters are required")
    .max(256, "Maximum 256 characters are allowed"),

  // adDistrict: Yup.object().required("District is required"),

  // adState: Yup.object().required("State is required"),

  adPincode: Yup.string()
    .matches(/^\d{6}$/, "Please enter a valid 6-digit pincode.") // Validates that it is a 6-digit number
    .required("Please enter a valid 6-digit pincode."),

  contactName: Yup.string()
    .required("Please enter a valid contact name.")
    .min(3, "Minimum 3 characters are required")
    .max(100, "Contact name is too long. Please enter a valid name.")
    .test(
      "contains-alphabetic",
      "Name must start and end with alphabet",
      startsAndEndsWithAlphabetic
    )
    .matches(/^[a-zA-Z\s]*$/, "Name should only contain letters and space"),

  contactMobile: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, "Please enter a valid Mobile Number")
    .required("The contact mobile is mandatory. Kindly provide a valid one."),

  contactEmail: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Please enter a valid email.")
    .required("Contact email is mandatory. Kindly provide a valid one.")
    .max(255, "Maximum 255 characters are allowed"),

  since_when: Yup.date().required("Please select a valid year."),
});

export const s = Yup.object().shape({});
