import {
  SET_ACTIVE_MEMBER,
  SET_PARENT_FAMLIY_ID,
  SET_USER_DATA,
  SET_USER_FEATURES,
  SET_USER_ORG_ID,
  SET_FRONT_DESK_USER_LINKING,
  SET_RENDER_SIDEBAR,
} from "store/Actions/userActions";
import { orgId } from "store/constant";

const initialState = {
  user: null,
  activeMember: null,
  parentMemberId: null,
  frontDeskUserLinking: null,
  renderSidebar: false,
};

const userReducer = (state = initialState, action) => {
  switch (action?.type) {
    case SET_USER_DATA: {
      return { ...state, user: action.payload };
    }
    case SET_PARENT_FAMLIY_ID: {
      localStorage.setItem("parentMemberId", JSON.stringify(action.payload));
      return { ...state, parentMemberId: action.payload };
    }
    case SET_ACTIVE_MEMBER: {
      localStorage.setItem("activeMember", JSON.stringify(action.payload));
      return { ...state, activeMember: action.payload };
    }
    case SET_USER_FEATURES: {
      localStorage.setItem("userFeatures", JSON.stringify(action.payload));
      return { ...state, userFeatures: action.payload };
    }
    case SET_USER_ORG_ID: {
      localStorage.setItem("orgId", action.payload);
      return { ...state, orgId: action.payload };
    }
    case SET_FRONT_DESK_USER_LINKING: {
      localStorage.setItem("frontDeskUserLinking", action.payload);
      return { ...state, frontDeskUserLinking: action.payload };
    }
    case SET_RENDER_SIDEBAR: {
      return { ...state, renderSidebar: action.payload };
    }
    default:
      return state;
  }
};

export default userReducer;
export const getUserData = (state) => state.user.user;
export const getParentMemberId = (state) => state.user?.parentMemberId;
export const getActiveMember = (state) => state.user?.activeMember;
export const getUserFeatures = (state) => state.user?.userFeatures;
export const getUserOrgId = (state) => (state.user?.orgId ? state.user.orgId : orgId());
export const getFrontDeskUserLinking = (state) => state.user?.frontDeskUserLinking;
export const getRenderSidebar = (state) => state.user?.renderSidebar;
