import dayjs from "dayjs";
import {
  getAppointmentById,
  getFilteredAppointments,
  updateAppointmentStatus,
} from "services/Appointments";
import {
  CANCELLED,
  CHECKED_IN,
  DATE_FORMAT,
  DAY_GRID_CALENDAR,
  MONTH_GRID_CALENDAR,
  NO_SHOW,
  RESCHEDULED,
  SCHEDULED,
  WEEK_GRID_CALENDAR,
} from "store/constant";

// filter the appointments based on the view shown in calendar
export const updateFilteredAppointment = (allEvents, viewInfo, calendarView) => {
  let filteredAppointments = [];
  if (viewInfo) {
    const selectedStartDate = dayjs(viewInfo.start).format(DATE_FORMAT);
    const selectedEndDate = dayjs(viewInfo.end).format(DATE_FORMAT);

    if (calendarView === DAY_GRID_CALENDAR) {
      filteredAppointments = allEvents.filter(
        (event) => dayjs(event.start).format(DATE_FORMAT) === selectedStartDate
      );
    } else if (calendarView === WEEK_GRID_CALENDAR) {
      filteredAppointments = allEvents.filter(
        (event) =>
          dayjs(event.start).format(DATE_FORMAT) > selectedStartDate &&
          dayjs(event.end).format(DATE_FORMAT) < selectedEndDate
      );
    } else if (calendarView === MONTH_GRID_CALENDAR) {
      const monthStartDate = dayjs(viewInfo.view.currentStart).format(DATE_FORMAT);
      const monthEndDate = dayjs(viewInfo.view.currentEnd).format(DATE_FORMAT);

      filteredAppointments = allEvents.filter(
        (event) =>
          dayjs(event.start).format(DATE_FORMAT) >= monthStartDate &&
          dayjs(event.end).format(DATE_FORMAT) < monthEndDate
      );
    }
  } else {
    const today = dayjs();
    const formattedToday = today.format(DATE_FORMAT);
    filteredAppointments = allEvents.filter(
      (event) => dayjs(event.start).format(DATE_FORMAT) === formattedToday
    );
  }
  return filteredAppointments;
};

// when doctor click on appointment count on month view of calendar
export const handleMonthViewMoreClick = async (eventInfo, doctorAppointments) => {
  const selectedDate = eventInfo.event.start.toISOString().split("T")[0];
  const appointmentOnSelectedDate = doctorAppointments.filter((appointment) => {
    return (
      appointment.appointmentStatus !== CANCELLED &&
      appointment.appointmentStatus !== NO_SHOW &&
      appointment.appointmentDate === selectedDate &&
      appointment.type === eventInfo.event.extendedProps.appointmentType
    );
  });
  const appointmentDetails = await Promise.all(
    appointmentOnSelectedDate.map(async (appointment) => {
      try {
        const response = await getAppointmentById(appointment.id);
        return response.data;
      } catch (error) {
        console.error("error fetching patient appointments");
      }
    })
  );
  return appointmentDetails;
};

// create events for calendar using doctor details and appointments
export const getAppointmentEvents = async (doctorDetail, doctorOrgId) => {
  const appointmentInfoData = {
    appointmentData: [],
    doctorAppointments: [],
    allEvents: [],
  };

  const doctorId = doctorDetail?.id || null;

  try {
    // fetch doctor's appointment
    const appointmentResponse = await getFilteredAppointments({
      doctorId: doctorId,
      orgId: doctorOrgId,
    });
    let apIdArr = [];
    const appointmentData = appointmentResponse?.data.content.map((appointment) => {
      if (appointment.orgId === doctorOrgId) {
        // starting of todays date
        const currDate = dayjs().startOf("day");
        const isDayPassed = dayjs(appointment.appointmentDate).valueOf() < currDate.valueOf();
        // appointment status should be changed to NO_SHOW
        if (
          isDayPassed &&
          [SCHEDULED, RESCHEDULED, CHECKED_IN].includes(appointment?.appointmentStatus)
        ) {
          apIdArr.push(appointment.id);
        }
      }
      const obj = {
        appointmentTime: appointment.appointmentTime,
        appointmentDate: appointment.appointmentDate,
      };
      return obj;
    });
    if (apIdArr.length > 0) {
      apIdArr.forEach(async (id) => {
        try {
          await updateAppointmentStatus(id, "NO_SHOW");
        } catch (error) {
          console.error("error", "There seems to be an error updating appointment");
        }
      });
    }
    appointmentInfoData.appointmentData = [...appointmentData];
    appointmentInfoData.doctorAppointments = appointmentResponse.data.content.filter(
      (app) => app.orgId === doctorOrgId
    );
    const allEvents = appointmentResponse.data.content
      .filter((app) => app.orgId === doctorOrgId)
      .map((appointment, index) => {
        const startTime = dayjs(`${appointment.appointmentDate}T${appointment.appointmentTime}`);
        const endTime = startTime.add(
          doctorDetail?.avgAppointmentDuration <= 15 ? 30 : 60,
          "minute"
        );
        return {
          start: startTime.toDate(),
          end: endTime.toDate(),
          title: appointment.patientName,
          appointmentType: appointment?.type,
          appointmentId: appointment.id,
          eventSelected: index === 0,
          appointmentStatus: appointment?.appointmentStatus,
        };
      });

    appointmentInfoData.allEvents = [...allEvents];
    return appointmentInfoData;
  } catch (error) {
    console.error("Error fetching details");
  }
};
