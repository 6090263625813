import { useLoader } from "layout/LoaderContext";
import PropTypes from "prop-types";

const ComponentLoader = ({ loaderMessage }) => {
  const { loading } = useLoader();

  if (loading) {
    return;
  }

  return (
    <div className="component-loader">
      <div className="component-spinner"></div>
      <p className="component-loader-message">{loaderMessage}</p>
    </div>
  );
};

ComponentLoader.propTypes = {
  loaderMessage: PropTypes.string,
};

export default ComponentLoader;
