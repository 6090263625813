import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  LineChart,
  BarChart,
  PieChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  Bar,
  Pie,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { checkQuery } from "services/DashboardService";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ModalUI from "ui-component/ModalUI";
import { ToastContext } from "ui-component/custom-components/CustomToast";

const ChartDisplay = ({ querydata, editChart, onFilterParamsChange }) => {
  const {
    chartType,
    xAxis,
    yAxis,
    queryData,
    Section,
    Value,
    Columns,
    title,
    options,
    queryText,
    params,
  } = querydata || {};
  const [chartData, setChartData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterParams, setFilterParams] = useState(options ? { ...options.params } : { ...params });
  const { handleClick } = useContext(ToastContext);

  useEffect(() => {
    if (queryData && queryData.data) {
      let newChartData = [];
      const alteredData = queryData.data.map((item) => {
        return Object.keys(item).reduce((acc, key) => {
          acc[key.toLowerCase()] = item[key];
          return acc;
        }, {});
      });
      if (chartType === "Bar Chart" || chartType === "Line Chart") {
        newChartData = alteredData.map((object) => ({
          [xAxis]: object[xAxis.toLowerCase()],
          [yAxis]: object[yAxis.toLowerCase()],
        }));
      } else if (chartType === "Pie Chart") {
        newChartData = alteredData.map((object) => ({
          [Section[0].toLowerCase()]: object[Section[0].toLowerCase()],
          [Value[0].toLowerCase()]: object[Value[0].toLowerCase()],
        }));
      } else {
        newChartData = queryData.data;
      }
      setChartData(newChartData);
    }
  }, [queryData, xAxis, yAxis, Section, Value, chartType]);

  const renderTable = () => (
    <ResponsiveContainer width="100%" height="100%">
      <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#004c70", color: "white" }}>
              <TableCell>Sr. No</TableCell>
              {Columns.map((column, index) => (
                <TableCell key={index}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {chartData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                {Columns.map((column, colIndex) => {
                  const cellData = row[column];
                  const isValidData =
                    typeof cellData === "string" ||
                    typeof cellData === "number" ||
                    React.isValidElement(cellData);
                  return <TableCell key={colIndex}>{isValidData ? cellData : "N/A"}</TableCell>;
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ResponsiveContainer>
  );

  const renderChartComponents = () => {
    if (chartData.length === 0) {
      return <Typography variant="body1">No data to display.</Typography>;
    }

    const renderCard = () => {
      return (
        <ResponsiveContainer width="100%" height="100%">
          <Card className="info-card">
            <CardContent
              style={{
                height: "100%",
                padding: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div className="card-field-name">{title}</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <span className="card-field-value">
                    {chartData[0][Object.keys(chartData[0])]}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                ></div>
              </div>
            </CardContent>
          </Card>
        </ResponsiveContainer>
      );
    };
    switch (chartType) {
      case "Count Card":
        return renderCard();
      case "Line Chart":
        return (
          <ResponsiveContainer width="100%" height={300}>
            <LineChart component={Paper} sx={{ minWidth: 300, minHeight: 300 }} data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={xAxis} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey={yAxis} stroke="#004c70" />
            </LineChart>
          </ResponsiveContainer>
        );

      case "Bar Chart":
        return (
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={xAxis} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey={yAxis} fill="#004c70" />
            </BarChart>
          </ResponsiveContainer>
        );

      case "Pie Chart":
        return (
          <ResponsiveContainer width="100%" height={300}>
            <PieChart minWidth={300} minHeight={300}>
              <Pie
                data={chartData}
                dataKey={Value[0].toLowerCase()}
                nameKey={Section[0].toLowerCase()}
                cx="50%"
                cy="50%"
                outerRadius={100}
                fill="#004c70"
                label
              >
                {chartData.map((entry, idx) => (
                  <Cell
                    key={`cell-${idx}`}
                    fill={`#${Math.floor(Math.random() * 16777215).toString(16)}`}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        );

      case "Table":
        return renderTable();

      default:
        return (
          <Typography variant="body1">Select a chart type and generate data to display.</Typography>
        );
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    const [parentKey, childKey] = name.split(".");

    setFilterParams((prevParams) => ({
      ...prevParams,
      [parentKey]: {
        ...prevParams[parentKey],
        [childKey]: value,
      },
    }));
  };

  const renderFilterFields = () => {
    return Object.keys(filterParams).map((key) => (
      <Grid item container xs={12} sx={{ marginTop: "10px" }} key={key}>
        {
          key === "dateRange"
            ? Object.keys(filterParams[key]).map((filterKey) => (
                <Grid item xs={6} key={filterKey}>
                  <TextField
                    fullWidth
                    label={filterKey}
                    type="date"
                    name={`${key}.${filterKey}`}
                    value={filterParams[key][filterKey]}
                    onChange={handleFilterChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              ))
            : null /* Add other conditions here if needed */
        }
      </Grid>
    ));
  };

  const saveDetails = async () => {
    try {
      let queryParams = Object.keys(filterParams).reduce((acc, filter) => {
        return { ...acc, ...filterParams[filter] };
      }, {});

      const data = {
        query: queryText,
        params: queryParams,
        status: "ACTIVE",
      };
      const response = await checkQuery(data);
      const newChartData = response.data.data;
      setChartData(newChartData);
      onFilterParamsChange(newChartData, filterParams);
    } catch (error) {
      handleClick("error", error?.response?.data?.message);
    }
  };

  return (
    <Grid container spacing={2}>
      {editChart &&
        ((options && options.params && Object.keys(options.params).length !== 0) ||
          (params && Object.keys(params).length !== 0)) && (
          <Grid item xs={12}>
            <FilterAltIcon onClick={() => setIsModalOpen(true)} style={{ cursor: "pointer" }} />
          </Grid>
        )}
      {chartType !== "Count Card" && (
        <Grid item xs={12} display="flex" justifyContent="center">
          <Typography className="card-field-value">{title}</Typography>
        </Grid>
      )}
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <Box>{renderChartComponents()}</Box>
        </Grid>
      </Grid>
      {editChart &&
        ((options && options.params && Object.keys(options.params).length !== 0) ||
          (params && Object.keys(params).length !== 0)) && (
          <Grid item>
            <Button onClick={saveDetails}>Save</Button>
          </Grid>
        )}
      <ModalUI
        visible={isModalOpen}
        close={() => setIsModalOpen(false)}
        title="Set filter"
        style={{ padding: 20 }}
        component={renderFilterFields()}
      />
    </Grid>
  );
};

export default ChartDisplay;
