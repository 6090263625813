import * as Yup from "yup";

export const doctorSchemaForClinicAdmin = Yup.object().shape({
  firstName: Yup.string()
    .required("Please enter your first name.")
    .min(2, "Minimum 2 characters are required")
    .max(50, "Maximum 50 characters are allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  middleName: Yup.string()
    .min(1, "Minimum 1 character is required")
    .max(50, "Maximum 50 characters are allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  lastName: Yup.string()
    .required("Please enter your last name.")
    .min(2, "Minimum 2 characters are required")
    .max(50, "Maximum 50 characters are allowed")
    .matches(/^[a-zA-Z]*$/, "Name should only contain letters and not space"),
  mobileNumber: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, "Please enter a valid mobile number")
    .required("Please enter a valid mobile number."),
  specialization: Yup.string().required("Please select a specialization."),
  gender: Yup.string().required("Please select your gender."),
  email: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Please enter a valid email.")
    .required("Please enter a valid email.")
    .max(255, "Maximum 255 characters are allowed"),
});

export const doctorSchemaForSuperAdmin = doctorSchemaForClinicAdmin.concat(
  Yup.object().shape({
    doctorOrganizationAssociation: Yup.array().min(1, "Please select at least one organization"),
  })
);
