import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { DescriptionOutlined, DriveFileRenameOutlineOutlined } from "@mui/icons-material";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";
import { updateTaxRates } from "services/BillingService";
import {
  taxRateSchema,
  taxRateDescriptionSchema,
} from "../../Common/ValidationSchema/taxRatesValidations";

const UpdateTaxRates = ({ selected, close }) => {
  const [taxRate, settaxRate] = useState(selected?.rate);
  const [taxRatedescription, settaxRatedescription] = useState(selected?.description);
  const { handleClick } = useContext(ToastContext);
  const [errors, setErrors] = useState({ taxRate: "", taxRateDescription: "" });
  const [taxRateTouched, setTaxRateTouched] = useState(false);
  const [taxRateDescriptionTouched, setTaxRateDescriptionTouched] = useState(false);

  const validateTaxRate = async (taxRate) => {
    try {
      await taxRateSchema.validate({ taxRate });
      setErrors((prev) => ({ ...prev, taxRate: "" }));
    } catch (e) {
      setErrors((prev) => ({ ...prev, taxRate: e.message }));
    }
  };

  const validateTaxRateDescription = async (taxRateDescription) => {
    try {
      await taxRateDescriptionSchema.validate({ taxRateDescription });
      setErrors((prev) => ({ ...prev, taxRateDescription: "" }));
    } catch (e) {
      setErrors((prev) => ({ ...prev, taxRateDescription: e.message }));
    }
  };

  const updateTaxRate = async () => {
    await validateTaxRate(taxRate);
    await validateTaxRateDescription(taxRateDescriptionSchema);
    if (errors.taxRate || errors.taxRateDescription) {
      handleClick(
        "error",
        errors.taxRate +
          (errors.taxRate && errors.taxRateDescription ? ", " : "") +
          errors.taxRateDescription
      );
    } else {
      setErrors({});

      if (selected) {
        const data = {
          id: selected.id,
          rate: taxRate,
          description: taxRatedescription,
        };
        try {
          await updateTaxRates(selected.id, data);
          handleClick("success", "Tax Rate has been successfully updated.");
          close();
        } catch (e) {
          handleClick("error", "There seems to be an error updating the Tax Rate.");
        }
      }
    }
  };

  return (
    <Box
      flex="1"
      alignSelf={"center"}
      minHeight="18vh"
      p={3}
      borderRadius={3}
      bgcolor={"background.default"}
      color={"text.primary"}
    >
      <Typography variant="h4" textAlign="center">
        Update Tax Rate
      </Typography>

      <Grid container spacing={2} sx={{ maxWidth: "350px", marginTop: "5px" }}>
        <Grid item xs={12}>
          <FormInputField
            label={"Tax Rate"}
            value={taxRate}
            error={Boolean(errors.taxRate)}
            errorText={errors.taxRate}
            onBlur={(e) => {
              setTaxRateTouched(true);
              validateTaxRate(e.target.value);
            }}
            onChange={(e) => {
              settaxRate(e.target.value);
              if (taxRateTouched) {
                validateTaxRate(e.target.value);
              }
            }}
            startAdornment={<DriveFileRenameOutlineOutlined />}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "10px" }}>
          <FormInputField
            label={"Tax Rate Description"}
            value={taxRatedescription}
            error={Boolean(errors.taxRateDescription)}
            errorText={errors.taxRateDescription}
            onBlur={(e) => {
              setTaxRateDescriptionTouched(true);
              validateTaxRateDescription(e.target.value);
            }}
            onChange={(e) => {
              settaxRatedescription(e.target.value);
              if (taxRateDescriptionTouched) {
                validateTaxRateDescription(e.target.value);
              }
            }}
            startAdornment={<DescriptionOutlined />}
            size="small"
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 3 }} display="flex" justifyContent="center" alignItems="center">
        <CustomButton
          className="btn--primary"
          onClick={updateTaxRate}
          label="Update"
          disabled={taxRate === ""}
        />
      </Box>
    </Box>
  );
};

export default UpdateTaxRates;
