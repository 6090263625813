import { useState } from "react";
import { useEffect } from "react";
import { getEhrData, getEhrSummary } from "services/PrescriptionsService";
import { getChartByVitalType, getEhrDataPoints } from "utils/ehr-data-utils";
import ComponentLoader from "ui-component/custom-components/ComponentLoader";
import PropTypes from "prop-types";

const EhrSummary = ({ patientId }) => {
  const [ehrSummary, setEhrSummary] = useState("");
  const [ehrData, setEhrData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const VitalArray = ["BLOOD_PRESSURE", "BLOOD_OXYGEN", "RESPIRATORY_RATE", "HEART_RATE"];

    const fetchData = async () => {
      setLoading(true);
      
      try {
        if (patientId) {

          const ehrSummaryResponse = await getEhrSummary(patientId);
          if (!ehrSummaryResponse) {
            setEhrSummary(null);
          }
          const indexOfFirstDiv = ehrSummaryResponse.data.indexOf("<div>");
          const indexOflastDiv = ehrSummaryResponse.data.lastIndexOf("</div>") + 5;

          setEhrSummary(ehrSummaryResponse.data.substring(indexOfFirstDiv, indexOflastDiv + 1));
          const ehrResponse = await getEhrData(patientId);
          const ehrDataPoints = getEhrDataPoints(ehrResponse.data);
          let newVitalData = ehrDataPoints.filter((d) => VitalArray.includes(d.key));
          setEhrData(newVitalData);
        }
      } catch (error) {
        console.error("Error fetching details");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [patientId]);

  if (loading) {
    return <ComponentLoader></ComponentLoader>;
  }
  return (
    <div className="justify-content-space-between align-items-center">
      {ehrSummary && patientId ? (
        <>
          <div style={{ paddingLeft: "0px" }} className="card-title">
            EHR Summary
          </div>
          <div style={{ padding: "15px" }} dangerouslySetInnerHTML={{ __html: ehrSummary }}></div>
        </>
      ) : (
        <div>EHR not available</div>
      )}

      {ehrData?.map((vital) => {
        return (
          <div style={{ marginTop: "10px" }}>
            {getChartByVitalType(
              vital?.key,
              vital?.value,
              vital?.value[0]?.vitalSignTypeResponse?.displayName
            )}
          </div>
        );
      })}
    </div>
  );
};

EhrSummary.propTypes = {
  patientId: PropTypes.string.isRequired, // patient Id of the patient, you need ehr summary for
};

export default EhrSummary;
