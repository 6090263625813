import { Box, Typography, Grid } from "@mui/material";
import React, { useState } from "react";
import {
  procedureDescriptionSchema,
  procedureSchema,
} from "../../Common/ValidationSchema/procedureValidation";
import CustomButton from "ui-component/custom-components/CustomButton";
import FormInputField from "ui-component/custom-components/Form-components/FormInputField";
import { DescriptionOutlined, DriveFileRenameOutlineOutlined } from "@mui/icons-material";
import { updateProcedures } from "services/EntitiesServices";
import { useContext } from "react";
import { ToastContext } from "ui-component/custom-components/CustomToast";

const UpdateProcedure = ({ selected, close }) => {
  const [name, setName] = useState(selected?.name);
  const [description, setDescription] = useState(selected?.description);
  const { handleClick } = useContext(ToastContext);
  const [errors, setErrors] = useState({
    procedure: "",
    procedureDescription: "",
  });
  const [procedureTouched, setProcedureTouched] = useState(false);
  const [procedureDescriptionTouched, setProcedureDescriptionTouched] = useState(false);

  const validateProcedure = async (procedure) => {
    try {
      await procedureSchema.validate({ procedure });
      setErrors((prevErrors) => ({ ...prevErrors, procedure: "" }));
    } catch (e) {
      setErrors((prevErrors) => ({ ...prevErrors, procedure: e.message }));
    }
  };
  const validateProcedureDescription = async (procedureDescription) => {
    try {
      await procedureDescriptionSchema.validate({ procedureDescription });
      setErrors((prevErrors) => ({ ...prevErrors, procedureDescription: "" }));
    } catch (e) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        procedureDescription: e.message,
      }));
    }
  };

  const UpdateProcedure = async () => {
    await validateProcedure(name);
    await validateProcedureDescription(description);
    if (errors.procedure || errors.procedureDescription) {
      handleClick(
        "error",
        errors.procedure +
          (errors.procedure && errors.procedureDescription ? ", " : "") +
          errors.procedureDescription
      );
      return;
    } else {
      setErrors({});

      if (selected) {
        const data = {
          id: selected.id,
          name: name,
          description: description,
        };
        try {
          await updateProcedures(selected.id, data);
          handleClick("success", "Procedure has been successfully updated.");
          close();
        } catch (e) {
          handleClick("error", "There seems to be an error updating the procedure.");
        }
      }
    }
  };

  return (
    <Box
      flex="1"
      alignSelf={"center"}
      minHeight="18vh"
      p={3}
      borderRadius={3}
      bgcolor={"background.default"}
      color={"text.primary"}
    >
      <Typography variant="h4" textAlign="center">
        Update Procedure
      </Typography>
      <Grid container spacing={2} sx={{ maxWidth: "350px", marginTop: "5px" }}>
        <Grid item xs={12}>
          <FormInputField
            label={"Procedure Name"}
            value={name}
            error={Boolean(errors.procedure)}
            errorText={errors.procedure}
            onBlur={(e) => {
              setProcedureTouched(true);
              validateProcedure(e.target.value);
            }}
            onChange={(e) => {
              setName(e.target.value);
              if (procedureTouched) {
                validateProcedure(e.target.value);
              }
            }}
            startAdornment={<DriveFileRenameOutlineOutlined />}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "10px" }}>
          <FormInputField
            label={"Procedure Description"}
            value={description}
            error={Boolean(errors.procedureDescription)}
            errorText={errors.procedureDescription}
            onBlur={(e) => {
              setProcedureDescriptionTouched(true);
              validateProcedureDescription(e.target.value);
            }}
            onChange={(e) => {
              setDescription(e.target.value);
              if (procedureDescriptionTouched) {
                validateProcedureDescription(e.target.value);
              }
            }}
            startAdornment={<DescriptionOutlined />}
            size="small"
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 3 }} display="flex" justifyContent="center" alignItems="center">
        <CustomButton
          className="btn--primary"
          onClick={UpdateProcedure}
          label="Update"
          disabled={name === ""}
        />
      </Box>
    </Box>
  );
};

export default UpdateProcedure;
